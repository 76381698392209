import { Card } from '../Login/styles';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Form from '../../../components/Formik/Form';
import { Formik, Form as FormFormik } from 'formik';
import logo from '../../../assets/images/logo-centralizada.png';
import { useDispatch, useSelector } from 'react-redux';
import phoneMask from '../../../utils/masks/phoneMask';
import TermsOfUse from '../../../components/Modals/TermsOfUse';
import withRouter from '../../../components/common/withRouter';
import FieldPassword from '../../../components/ui/FieldPassword';
import useRegisterUserSchema from '../../../schemas/registerUser';
import { Row, Col, CardBody, Container, Button } from 'reactstrap';
import ParticlesAuth from '../../AuthenticationInner/ParticlesAuth';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import {
  getPlans,
  getStates,
  createAccount,
  getMunicipalitiesByState,
} from '../../../store/actions';

const Register = ({ router }) => {
  const { navigate } = router;
  const dispatch = useDispatch();

  const validationSchema = useRegisterUserSchema();

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    plan: null,
    state: null,
    lastName: '',
    password: '',
    termsOfUse: false,
    municipality: null,
    repeatPassword: '',
  };

  const [phone, setPhone] = useState('');
  const [acronym, setAcronym] = useState('');

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isOpenModalTermsUse, setIsOpenModalTermsUse] = useState(false);
  const [isShowRepeatPassword, setIsShowRepeatPassword] = useState(false);

  const accountsSelector = createDynamicSelector('Accounts', ['isLoading']);
  const generalFeatureSelector = createDynamicSelector('GeneralFeature', [
    'plans',
    'states',
    'isLoading',
    'municipalities',
  ]);

  const { isLoading: isLoadingAccounts } = useSelector(accountsSelector);
  const { plans, states, isLoading, municipalities } = useSelector(
    generalFeatureSelector
  );

  const toggle = () => setIsOpenModalTermsUse((prevState) => !prevState);

  const handleChangePhone = (value = '') => {
    setPhone(phoneMask(value));
  };

  const transformData = (values) => {
    const formattedData = {
      nome: values.name,
      email: values.email,
      telefone: values.phone,
      senha: values.password,
      sobrenome: values.lastName,
      plano: { id: values.plan.value },
      municipio: { id: values.municipality.value },
    };

    return formattedData;
  };

  const handleSubmit = (values) => {
    const data = transformData(values);
    dispatch(createAccount({ ...data, navigate }));
  };

  useEffect(() => {
    if (!acronym.length) return;

    dispatch(getMunicipalitiesByState(acronym));
  }, [acronym]);

  useEffect(() => {
    document.title = 'Registro';

    dispatch(getPlans());
    dispatch(getStates());
  }, [dispatch]);

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <img src={logo} alt="log" className="logo" />

                    <h5>Criar nova conta</h5>

                    <p className="text-muted">
                      Obtenha sua conta Multivotos ;)
                    </p>
                  </div>

                  <div className="p-2 mt-4">
                    <Formik
                      enableReinitialize
                      onSubmit={handleSubmit}
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                    >
                      <FormFormik>
                        <Form.Field>
                          <Form.Label>Nome</Form.Label>

                          <Form.Input name="name" placeholder="Seu nome" />

                          <Form.ErrorMessage field="name" />
                        </Form.Field>

                        <Form.Field>
                          <Form.Label>Sobrenome</Form.Label>

                          <Form.Input
                            name="lastName"
                            placeholder="Seu sobrenome"
                          />

                          <Form.ErrorMessage field="lastName" />
                        </Form.Field>

                        <Form.Field>
                          <Form.Label>E-mail</Form.Label>

                          <Form.Input name="email" placeholder="Seu e-mail" />

                          <Form.ErrorMessage field="email" />
                        </Form.Field>

                        <Form.Field>
                          <Form.Label>Telefone</Form.Label>

                          <Form.Input
                            name="phone"
                            value={phone}
                            minLength={14}
                            maxLength={15}
                            placeholder="Seu telefone"
                            onChange={handleChangePhone}
                          />

                          <Form.ErrorMessage field="phone" />
                        </Form.Field>

                        <Form.Field>
                          <Form.Label>Estado</Form.Label>

                          <Form.Select
                            name="state"
                            isLoading={isLoading}
                            noOptionsMessage="Oops, sem estados para exibir"
                            options={states.map(({ id, nome, sigla }) => ({
                              value: id,
                              label: `${nome} - ${sigla}`,
                            }))}
                            onChange={({ label }) => {
                              const [_, acronym] = label.split('-');
                              setAcronym(acronym.trim());
                            }}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Form.Label>Município</Form.Label>

                          <Form.Select
                            name="municipality"
                            isLoading={isLoading}
                            noOptionsMessage="Selecione o estado primeiro ;)"
                            options={municipalities.map(
                              ({ id: value, nome: label }) => ({
                                value,
                                label,
                              })
                            )}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Form.Label>Plano</Form.Label>

                          <Form.Select
                            name="plan"
                            isLoading={isLoading}
                            noOptionsMessage="Oops, sem planos para exibir"
                            options={plans.map(
                              ({ id: value, descricao: label }) => ({
                                value,
                                label,
                              })
                            )}
                          />
                        </Form.Field>

                        <Form.Field>
                          <FieldPassword
                            label="Senha"
                            isShowPassword={isShowPassword}
                            setIsShowPassword={setIsShowPassword}
                            placeholder="Crie sua senha com caracteres variados"
                          />
                        </Form.Field>

                        <Form.Field>
                          <FieldPassword
                            name="repeatPassword"
                            label="Repita sua senha"
                            placeholder="Repita sua senha"
                            isShowPassword={isShowRepeatPassword}
                            setIsShowPassword={setIsShowRepeatPassword}
                          />
                        </Form.Field>

                        <div className="d-flex gap-1 mb-4">
                          <Form.Checkbox name="termsOfUse" />

                          <p className="mb-0 fs-12 text-muted fst-italic">
                            Ao se registrar você concorda com os{' '}
                            <Button
                              type="button"
                              onClick={toggle}
                              title="Termos de uso"
                              className="btn-terms-use text-primary text-decoration-underline fst-normal fw-medium"
                            >
                              Termos de uso
                            </Button>{' '}
                            do Multivotos.
                          </p>

                          <Form.ErrorMessage field="termsOfUse" />
                        </div>

                        <div className="mt-4">
                          <button
                            type="submit"
                            disabled={isLoadingAccounts}
                            className="btn btn-primary w-100"
                          >
                            Registra-se
                          </button>
                        </div>

                        <TermsOfUse
                          toggle={toggle}
                          isOpen={isOpenModalTermsUse}
                        />
                      </FormFormik>
                    </Formik>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Já tem uma conta ?{' '}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(Register);
