import Field from './Field';
import Input from './Input';
import Label from './Label';
import Radio from './Radio';
import Select from './Select';
import Checkbox from './Checkbox';
import ErrorMessage from './ErrorMessage';

const Form = {
  Radio,
  Label,
  Input,
  Field,
  Select,
  Checkbox,
  ErrorMessage,
};

export default Form;
