const maritalStatusField = {
  md: 3,
  type: 'select',
  required: false,
  label: 'Estado Civil',
  name: 'maritalStatus',
  options: [
    { label: 'Casado', value: 'Casado' },
    { label: 'Solteiro', value: 'Solteiro' },
    { label: 'Divorciado', value: 'Divorciado' },
    { label: 'Viúvo', value: 'Viúvo' },
  ],
};

export default maritalStatusField;
