import * as Yup from 'yup';
import validationEmail from '../common/validationEmail';
import validationPhone from '../common/validationPhone';

const useRegisterUserSchema = () => {
  return Yup.object().shape({
    ...validationPhone(),
    ...validationEmail(),
    name: Yup.string().required('Informe seu nome'),
    plan: Yup.object().required('Escolha um plano'),
    state: Yup.object().required('Informe o estado'),
    lastName: Yup.string().required('Informe seu sobrenome'),
    municipality: Yup.object().required('Informe o município'),
    password: Yup.string()
      .min(6, 'A senha deve ter no mínimo 6 caracteres')
      .required('Informe sua senha'),
    repeatPassword: Yup.string()
      .min(6, 'A senha deve ter no mínimo 6 caracteres')
      .oneOf([Yup.ref('password'), null], 'As senhas não coincidem')
      .required('Repita sua senha'),
    termsOfUse: Yup.boolean().test(
      'is-true',
      'Para prosseguir você precisa aceitar nossos termos de uso',
      (value) => !!value
    ),
  });
};

export default useRegisterUserSchema;
