import React, { useEffect } from 'react';
import { Button, Card, CardBody } from './styles';
import { CardHeader, Container } from 'reactstrap';
import { logoutUser } from '../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import withRouter from '../../../components/common/withRouter';
import createDynamicSelector from '../../../components/common/createDynamicSelector';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Login', ['isUserLogout']);
  const { isUserLogout } = useSelector(selector);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  const handleCancelLogout = () => navigate(-1);

  useEffect(() => {
    document.title = 'Sair';
  }, []);

  if (isUserLogout) return <Navigate to="/login" />;

  return (
    <Container className="page-content" fluid>
      <Card>
        <CardHeader>
          <h5>Tem certeza de que deseja sair?</h5>
        </CardHeader>

        <CardBody>
          <Button
            color="light"
            type="button"
            className="btn"
            title="Cancelar"
            onClick={handleCancelLogout}
          >
            Cancelar
          </Button>

          <Button
            title="Sair"
            type="button"
            onClick={handleLogout}
            className="btn btn-primary"
          >
            Sair
          </Button>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withRouter(Logout);
