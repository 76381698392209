import withRouter from '../../../components/common/withRouter';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';
import StatusItem from '../../../components/ui/StatusItem';
import { managementListRootUsers } from '../../../store/actions';
import headerTitles from './steps/headerTitles';

const ListingRootUsers = () => {
  const selectorOptions = ['isLoading', 'rootUsers'];
  const [name, lastName, email, phone, situation] = headerTitles;

  const handleData = (rootUsers) => {
    return rootUsers.map((data) => {
      return {
        [name]: data.nome || '--',
        [lastName]: data.sobrenome || '--',
        [email]: data.email || '--',
        [phone]: data.telefone || '--',
        [situation]: <StatusItem status={data.ativo} labelFalse="Bloqueado" />,
      };
    });
  };

  return (
    <ListingPrototype
      handleData={handleData}
      entityName={'Management'}
      headerTitles={headerTitles}
      title={'Administração - Usuários Root'}
      dataSelector={managementListRootUsers}
      selectorOptions={selectorOptions}
      isShowButtonCreateNewItem={false}
      emptyMessage={'Oops, sem usuário(s) para exibir.'}
    />
  );
};

export default withRouter(ListingRootUsers);
