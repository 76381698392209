import handleCreateFilterObject from '../../../../utils/transformsValues/handleCreateFilterObject';

const handleDataSubmit = ({ data }) => {
  const filtros = handleCreateFilterObject(data);

  const formattedData = {
    filtros,
    titulo: data.title,
    modelo: data.model,
    meio: data.quite.value,
  };

  return formattedData;
};

export default handleDataSubmit;
