import Form from '../../Formik/Form';
import { SpanRequiredInput } from '../../Prototypes/FormPrototype/components/FormField/styles';

const FieldPassword = ({
  isShowPassword,
  label = 'Senha',
  name = 'password',
  setIsShowPassword,
  isShowRequired = false,
  placeholder = 'Entre com sua senha',
}) => {
  const handleShowPassword = () => {
    setIsShowPassword((prevState) => !prevState);
  };

  return (
    <>
      <div>
        <Form.Label htmlFor={name}>{label}</Form.Label>
        {isShowRequired && <SpanRequiredInput> *</SpanRequiredInput>}
      </div>

      <div className="position-relative auth-pass-inputgroup mb-3">
        <Form.Input
          name={name}
          placeholder={placeholder}
          type={isShowPassword ? 'text' : 'password'}
        />

        <Form.ErrorMessage field={name} />

        <button
          type="button"
          onClick={handleShowPassword}
          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
        >
          <i className="ri-eye-fill align-middle" />
        </button>
      </div>
    </>
  );
};

export default FieldPassword;
