export const MANAGEMENT_LIST_ACCOUNTS = 'MANAGEMENT_LIST_ACCOUNTS';
export const MANAGEMENT_ERROR = 'MANAGEMENT_ERROR';
export const MANAGEMENT_SUCCESS = 'MANAGEMENT_SUCCESS';
export const MANAGEMENT_LIST_ROOT_USERS = 'MANAGEMENT_LIST_ROOT_USERS';
export const MANAGEMENT_LIST_PLANS = 'MANAGEMENT_LIST_PLANS';
export const MANAGEMENT_LIST_WHATSAPP_SLOTS = 'MANAGEMENT_LIST_WHATSAPP_SLOTS';
export const MANAGEMENT_FREE_WHATSAPP_SLOT = 'MANAGEMENT_FREE_WHATSAPP_SLOT';
export const MANAGEMENT_DISCONNECT_WHATSAPP_SLOT =
  'MANAGEMENT_DISCONNECT_WHATSAPP_SLOT';
export const MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS =
  'MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS';
export const MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT =
  'MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT';
export const MANAGEMENT_IMPORT_WHATSAPP_SLOTS =
  'MANAGEMENT_IMPORT_WHATSAPP_SLOTS';
