import {
  deleteTemplate,
  generateMessages,
  generateTemplateCSV,
  getTemplateById,
  sendTemplateMessages,
} from '../../../../../store/actions';

const actions = (data, dispatch, navigate, setModalData) => [
  {
    id: 1,
    title: 'Editar',
    tag: 'button',
    icon: 'pencil',
    text: 'Editar',
    attributes: {
      onClick: () => navigate(`/templates/update/${data.id}`),
    },
  },
  {
    id: 2,
    title: 'Apagar',
    tag: 'button',
    icon: 'delete',
    text: 'Apagar',
    showCondition: () => data.eleitores == null,
    attributes: {
      onClick: () => {
        const newModalData = {
          isOpen: true,
          body: `Tem certeza que deseja apagar o modelo "${
            data.titulo || '--'
          }"?`,
          title: 'Apagar modelo',
          confirmText: 'Apagar',
          onConfirm: () => {
            dispatch(deleteTemplate({ templateId: data.id }));
          },
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      },
    },
  },
  {
    id: 3,
    title: 'Gerar mensagens',
    tag: 'button',
    icon: 'filter2',
    text: 'Gerar mensagens',
    showCondition: () => data.eleitores == null,
    attributes: {
      onClick: () => {
        const newModalData = {
          isOpen: true,
          body: `Tem certeza que deseja gerar as mensagens do modelo "${
            data.titulo || '--'
          }"?`,
          title: 'Gerar mensagens',
          confirmText: 'Gerar mensagens',
          onConfirm: () => {
            if (data.eleitores == null) {
              dispatch(generateMessages({ templateId: data.id }));
            }
          },
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      },
    },
  },
  {
    id: 4,
    title: 'Listar mensagens',
    tag: 'button',
    icon: 'list',
    text: 'Listar mensagens',
    showCondition: () => data.eleitores != null,
    attributes: {
      onClick: () => {
        if (data.eleitores != null) {
          dispatch(getTemplateById({ templateId: data.id }));
          navigate('/messages');
        }
      },
    },
  },
  {
    id: 5,
    title: 'Gerar CSV',
    tag: 'button',
    icon: 'download',
    text: 'Gerar CSV',
    showCondition: () => data.eleitores != null,
    attributes: {
      onClick: () => {
        if (data.eleitores != null) {
          dispatch(generateTemplateCSV({ templateId: data.id }));
        }
      },
    },
  },
  {
    id: 6,
    title: 'Enviar',
    tag: 'button',
    icon: 'whatsapp',
    text: 'Enviar',
    showCondition: () => data.eleitores == null,
    attributes: {
      onClick: () => {
        const newModalData = {
          isOpen: true,
          body: `Essa ação não pode ser desfeita. Tem certeza que deseja realizá-la?`,
          title: 'Enviar',
          confirmText: 'Enviar',
          onConfirm: () => {
            dispatch(sendTemplateMessages({ templateId: data.id }));
          },
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      },
    },
  },
];

export default actions;
