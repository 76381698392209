import * as Yup from 'yup';
import validationUser from '../common/validationUser';

const useMultipliersSchema = (formRef = {}, isUpdate = false) => {
  const isCpfOptional = true;
  const isShowPasswordField = false;

  const paramsValidationUser = { isUpdate, isCpfOptional, isShowPasswordField };

  return Yup.object().shape({
    ...validationUser(paramsValidationUser),
    level: Yup.object().nullable(),
    isActive: Yup.boolean(),
    group: Yup.object().nullable(),
    photo: Yup.mixed()
      .nullable()
      .test(
        'fileSize',
        `O arquivo é muito grande, use imagens de até ${process.env.REACT_APP_IMAGE_UPLOAD_LIMIT} MB`,
        () => {
          if (formRef.current?.photo.files[0]) {
            const fileSize = formRef.current?.photo.files[0]?.size || 0;
            return (
              fileSize &&
              fileSize <= 1024 * 1024 * process.env.REACT_APP_IMAGE_UPLOAD_LIMIT
            );
          }
          return true;
        }
      ),
    voterTarget: Yup.number().nullable(),
  });
};

export default useMultipliersSchema;
