import * as Yup from 'yup';

const validationPassword = (isOptional = false) => {
  const passwordSchema = Yup.string().min(
    6,
    'A senha deve ter no mínimo 6 caracteres'
  );

  return {
    password: isOptional
      ? passwordSchema
      : passwordSchema.required('Informe sua senha'),
  };
};

export default validationPassword;
