import {
  CHECK_IF_WHATSAPP_IS_CONNECTED,
  DISCONNECT_WHATSAPP,
  GET_PENDING_SYSTEM_PARAMS,
  GET_SYSTEM_PARAMS,
  GET_SYSTEM_PARAMS_DESCRIPTIONS,
  GET_WHATSAPP_QRCODE,
  SAVE_SYSTEM_PARAMS,
  SETTINGS_ERROR,
  SETTINGS_SUCCESS,
  UPDATE_SYSTEM_PARAM,
  UPLOAD_VOTERS_DATA_FILE,
} from './actionTypes';

const initialState = {
  systemParamsDescriptions: {},
  systemParams: [],
  pendingSystemParams: [],
  updateSystemParam: {},
  whatsappQrCode: '',
  isLoading: false,
  isCheckingWhatsAppConnection: false,
  isWhatsAppConnected: null,
  isDisconnectingWhatsApp: false,
  wasDataFileSent: false,
};

const settings = (state = initialState, { type, payload }) => {
  const {
    systemParamsDescriptions = state.systemParamsDescriptions,
    systemParams = state.systemParams,
    pendingSystemParams = state.pendingSystemParams,
    updateSystemParam = state.updateSystemParam,
    whatsappQrCode = state.whatsappQrCode,
    isWhatsAppConnected = state.isWhatsAppConnected,
    wasDataFileSent = state.wasDataFileSent,
  } = payload ?? {};

  switch (type) {
    case GET_SYSTEM_PARAMS_DESCRIPTIONS:
    case GET_SYSTEM_PARAMS:
    case GET_PENDING_SYSTEM_PARAMS:
    case SAVE_SYSTEM_PARAMS:
    case UPDATE_SYSTEM_PARAM:
    case GET_WHATSAPP_QRCODE:
    case UPLOAD_VOTERS_DATA_FILE:
      return {
        ...state,
        isLoading: true,
      };

    case CHECK_IF_WHATSAPP_IS_CONNECTED:
      return {
        ...state,
        isCheckingWhatsAppConnection: true,
      };

    case DISCONNECT_WHATSAPP:
      return {
        ...state,
        isDisconnectingWhatsApp: true,
      };

    case SETTINGS_SUCCESS:
    case SETTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        isCheckingWhatsAppConnection: false,
        isDisconnectingWhatsApp: false,
        systemParamsDescriptions,
        systemParams,
        pendingSystemParams,
        updateSystemParam,
        whatsappQrCode,
        isWhatsAppConnected,
        wasDataFileSent,
      };

    default:
      return { ...state };
  }
};

export default settings;
