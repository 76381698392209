import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import FormPrototype from '../../../../components/Prototypes/FormPrototype';
import withRouter from '../../../../components/common/withRouter';
import useInterestSchema from '../../../../schemas/settings/interest';
import { createInterest } from '../../../../store/actions';
import handleCards from '../steps/handleCards';
import handleDataSubmit from '../steps/handleDataSubmit';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate';

const CreateNewInterest = ({ router }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const initialValues = handleInitialValuesCreate();
  const validationSchema = useInterestSchema();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: rest });

    dispatch(createInterest({ data }));
    formRef.current.reset();
  };

  return (
    <FormPrototype
      ref={formRef}
      pageContent={false}
      router={router}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Interesses"
      onCards={(params) => {
        return handleCards({ ...params });
      }}
    />
  );
};

export default withRouter(CreateNewInterest);
