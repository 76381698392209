import {
  API_ERROR,
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER_SUCCESS,
} from './actionTypes';

const initialState = { isLoading: false, isUserLogout: false };

const login = (state = initialState, { type }) => {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoading: true,
      };

    case API_ERROR:
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUserLogout: false,
      };

    case LOGOUT_USER:
      return {
        ...state,
        isUserLogout: false,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isUserLogout: true,
      };

    default:
      return { ...state };
  }
};

export default login;
