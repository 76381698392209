const moneyMask = (int) => {
  int = int + '';
  int = int.replace(/\D/g, '');
  int = int.replace(/(\d{2})$/, ',$1');
  if (int.length > 6) int = int.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  return int;
};

export default moneyMask;
