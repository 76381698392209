import React from 'react';
import { Input } from 'reactstrap';
import { useFormikContext } from 'formik';

const Radio = ({ name, label, onChange, defaultValue, ...rest }) => {
  const { handleChange, handleBlur, errors, touched } = useFormikContext();

  const hasValidationError = touched[name] && errors[name];
  const isInvalidField = !!hasValidationError;

  const handleRadioChange = (event) => {
    const { target } = event;
    const { value } = target;

    let newValue;
    try {
      newValue = JSON.parse(value);
    } catch (error) {
      newValue = value;
    }

    onChange && onChange(newValue);
    handleChange({ target: { name, value: newValue } });
  };

  return (
    <Input
      name={name}
      type="radio"
      label={label}
      onBlur={handleBlur}
      invalid={isInvalidField}
      onChange={handleRadioChange}
      {...rest}
    />
  );
};

export default Radio;
