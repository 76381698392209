import {
  GET_ME,
  USERS_ERROR,
  USERS_SUCCESS,
  GET_REGISTRANTS,
} from './actionTypes';

export const getMe = () => {
  return {
    type: GET_ME,
    payload: {},
  };
};

export const getRegistrants = () => {
  return {
    type: GET_REGISTRANTS,
    payload: {},
  };
};

export const usersError = () => {
  return {
    type: USERS_ERROR,
    payload: {},
  };
};

export const usersSuccess = (payload) => {
  return {
    type: USERS_SUCCESS,
    payload,
  };
};
