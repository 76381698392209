import handleInitialValuesPersonObject from '../handleInitialValuesPersonObject';

const handleInitialValuesUserObject = (user) => {
  const { email, foto: photo, telefone: phone } = user;
  const person = handleInitialValuesPersonObject(user);

  const values = {
    ...person,
    email: email || '',
    photo: photo || '',
    phone: phone || '',
  };

  return values;
};

export default handleInitialValuesUserObject;
