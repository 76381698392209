export const ADD_TAG = 'ADD_TAG';
export const VOTERS_LIST = 'VOTERS_LIST';
export const CREATE_VOTER = 'CREATE_VOTER';
export const VOTERS_ERROR = 'VOTERS_ERROR';
export const UPDATE_VOTER = 'UPDATE_VOTER';
export const DELETE_VOTER = 'DELETE_VOTER';
export const BIRTHDAYS_LIST = 'BIRTHDAYS_LIST';
export const VOTERS_SUCCESS = 'VOTERS_SUCCESS';
export const GET_VOTER_BY_ID = 'GET_VOTER_BY_ID';
export const VOTERS_LIST_FILTER = 'VOTERS_LIST_FILTER';
export const BIRTHDAYS_LIST_FILTER = 'BIRTHDAYS_LIST_FILTER';
export const AUTOREGISTER_VOTER = 'AUTOREGISTER_VOTER';
export const COUNT_VOTERS_AFFECTED_BY_FILTER =
  'COUNT_VOTERS_AFFECTED_BY_FILTER';
export const CLEAR_VOTERS_AFFECTED_BY_FILTER_COUNTER =
  'CLEAR_VOTERS_AFFECTED_BY_FILTER_COUNTER';
export const GENERATE_VOTERS_CSV = 'GENERATE_VOTERS_CSV';
export const CREATE_MUNICIPAL_RESEARCH = 'CREATE_MUNICIPAL_RESEARCH';
export const GET_MUNICIPAL_RESEARCH_BY_ID = 'GET_MUNICIPAL_RESEARCH_BY_ID';
export const CLEAR_MUNICIPAL_RESEARCH = 'CLEAR_MUNICIPAL_RESEARCH';
export const UPDATE_MUNICIPAL_RESEARCH = 'UPDATE_MUNICIPAL_RESEARCH';
export const GET_MUNICIPAL_RESEARCH_BY_VOTER_ID =
  'GET_MUNICIPAL_RESEARCH_BY_VOTER_ID';
