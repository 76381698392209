import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Alert, Card, CardBody, CardHeader, Col } from 'reactstrap';

const BarChart = ({ data = [], title, categories, titleHeader, colors }) => {
  const series = [{ name: title, data }];

  const options = {
    colors,
    markers: { size: 4 },
    stroke: { curve: 'straight' },
    dataLabels: { enabled: false },
    title: {
      text: title,
      align: 'left',
      style: { fontWeight: 500 },
    },
    chart: {
      height: 350,
      type: 'bar',
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    xaxis: { categories },
    yaxis: {
      labels: {
        formatter: function (value) {
          return parseInt(value);
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '10%',
      },
    },
  };

  return (
    <Col>
      <Card>
        <CardHeader>
          <h5>{titleHeader}</h5>
        </CardHeader>

        <CardBody>
          {data?.length === 0 ? (
            <Alert color="info">Sem dados disponíveis para exibir.</Alert>
          ) : (
            <ReactApexChart
              dir="ltr"
              type="bar"
              height="350"
              series={series}
              options={options}
              className="apex-charts"
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default BarChart;
