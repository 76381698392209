import { all, call, fork, takeEvery } from 'redux-saga/effects';
import {
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VISIBILITY,
} from './actionType';

function changeHTMLAttribute(attribute, value) {
  if (document.documentElement) {
    document.documentElement.setAttribute(attribute, value);
  }
}

function* changeSidebarVisibility({ payload: isVisibility }) {
  try {
    yield call(changeHTMLAttribute, 'data-sidebar-visibility', 'show');
  } catch (error) {
    console.log(error);
  }
}

function* changeLeftsidebarSizeType({ payload: leftsidebarSizetype }) {
  try {
    switch (leftsidebarSizetype) {
      case 'lg':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'lg');
        break;
      case 'md':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'md');
        break;
      case 'sm':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'sm');
        break;
      case 'sm-hover':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'sm-hover');
        break;
      default:
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'lg');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchchangeSidebarVisibility() {
  yield takeEvery(CHANGE_SIDEBAR_VISIBILITY, changeSidebarVisibility);
}

export function* watchChangeLeftsidebarSizeType() {
  yield takeEvery(CHANGE_SIDEBAR_SIZE_TYPE, changeLeftsidebarSizeType);
}

function* layout() {
  yield all([
    fork(watchchangeSidebarVisibility),
    fork(watchChangeLeftsidebarSizeType),
  ]);
}

export default layout;
