const levels = {
  0: {
    text: '-',
    icon: 'medal',
    color: 'dark',
    className: '',
  },
  1: {
    text: 'Bronze',
    icon: 'star',
    color: 'danger',
    className: '',
  },
  2: {
    text: 'Prata',
    icon: 'starHalfFill',
    color: 'light',
    className: 'text-dark',
  },
  3: {
    text: 'Ouro',
    icon: 'starFill',
    color: 'warning',
    className: '',
  },
};

export default levels;
