import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import {
  CHECK_IF_WHATSAPP_IS_CONNECTED,
  DISCONNECT_WHATSAPP,
  DOWNLOAD_DATA_FILE_MODEL,
  GET_PENDING_SYSTEM_PARAMS,
  GET_SYSTEM_PARAMS,
  GET_SYSTEM_PARAMS_DESCRIPTIONS,
  GET_WHATSAPP_QRCODE,
  SAVE_SYSTEM_PARAMS,
  UPDATE_SYSTEM_PARAM,
  UPLOAD_VOTERS_DATA_FILE,
} from './actionTypes';
import {
  getPendingSystemParams as getPendingSystemParamsAction,
  getSystemParams as getSystemParamsAction,
  settingsError,
  settingsSuccess,
} from './actions';
import downloadFile from '../../utils/downloadFile';

function* handleSuccess({ message }) {
  toast('success', message);
  yield put(settingsSuccess());
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(settingsError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* getSystemParamsDescriptions() {
  try {
    const { data: systemParamsDescriptions } = yield call(
      api.get,
      '/parametros/descricoes'
    );
    yield put(settingsSuccess({ systemParamsDescriptions }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar as descrições';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getSystemParams() {
  try {
    const { data: systemParams } = yield call(api.get, '/parametros');
    yield put(settingsSuccess({ systemParams }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar os parâmetros de sistema';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getPendingSystemParams() {
  try {
    const { data: pendingSystemParams } = yield call(
      api.get,
      '/parametros/pendentes'
    );
    yield put(settingsSuccess({ pendingSystemParams }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar os parâmetros de sistema pendentes';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* saveSystemParams({ payload }) {
  try {
    const { data } = payload;
    yield call(api.post, '/parametros', data);
    yield handleSuccess({ message: 'Parâmetro do sistema salvo com sucesso!' });

    yield put(getPendingSystemParamsAction());
    yield put(getSystemParamsAction());
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar os parâmetros de sistema pendentes';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* updateSystemParam({ payload }) {
  try {
    const { updateSystemParam, navigate } = payload;
    yield put(
      settingsSuccess({
        pendingSystemParams: [updateSystemParam.chave],
        updateSystemParam,
      })
    );
    navigate && navigate('/settings/system-params/update');
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar o parâmetro de sistema';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getWhatsAppQrCode() {
  try {
    const { data } = yield call(api.get, '/contas/whatsapp/tela', {
      responseType: 'blob',
    });
    const whatsappQrCode = URL.createObjectURL(data);
    toast('success', 'QrCode recuperado com sucesso!');
    yield put(settingsSuccess({ whatsappQrCode }));
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível recuperar o qrCode.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* checkIfWhatsAppIsConnected() {
  try {
    const { data } = yield call(api.get, '/contas/whatsapp/conectado');
    yield put(settingsSuccess({ isWhatsAppConnected: data }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível carregar informações de conexão com o WhatsApp';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* disconnectWhatsApp() {
  try {
    yield call(api.post, '/contas/whatsapp/desconectar');
    yield put(settingsSuccess({ isWhatsAppConnected: false }));
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível cdesconectar WhatsApp';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* uploadVotersDataFile({ payload }) {
  try {
    const formData = new FormData();
    const { file } = payload;
    formData.append('planilha', file);

    const defaultMessage = 'Arquivo enviado com sucesso!';
    const { data } = yield call(api.post, '/eleitores/importar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(settingsSuccess({ wasDataFileSent: true }));
    yield handleSuccess({ message: data || defaultMessage });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível enviar o arquivo!';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* downloadDataFileModel() {
  try {
    const { data } = yield call(api.get, `/eleitores/importar`, {
      responseType: 'blob',
    });
    downloadFile(data, 'modelo-importar-eleitores.xlsx');
    yield handleSuccess({
      message: 'O download deve iniciar em instantes...',
    });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível baixar o arquivo!';
    yield handleError({ error, errorDefaultMessage });
  }
}

export function* watchGetSystemParamsDescriptions() {
  yield takeEvery(GET_SYSTEM_PARAMS_DESCRIPTIONS, getSystemParamsDescriptions);
}

export function* watchGetSystemParams() {
  yield takeEvery(GET_SYSTEM_PARAMS, getSystemParams);
}

export function* watchGetPendingSystemParams() {
  yield takeEvery(GET_PENDING_SYSTEM_PARAMS, getPendingSystemParams);
}

export function* watchSaveSystemParams() {
  yield takeEvery(SAVE_SYSTEM_PARAMS, saveSystemParams);
}

export function* watchUpdateSystemParam() {
  yield takeEvery(UPDATE_SYSTEM_PARAM, updateSystemParam);
}

export function* watchGetWhatsAppQrCode() {
  yield takeEvery(GET_WHATSAPP_QRCODE, getWhatsAppQrCode);
}

export function* watchCheckIfWhatsAppIsConnected() {
  yield takeEvery(CHECK_IF_WHATSAPP_IS_CONNECTED, checkIfWhatsAppIsConnected);
}

export function* watchDisconnectWhatsApp() {
  yield takeEvery(DISCONNECT_WHATSAPP, disconnectWhatsApp);
}

export function* watchUploadVotersDataFile() {
  yield takeEvery(UPLOAD_VOTERS_DATA_FILE, uploadVotersDataFile);
}

export function* watchDownloadDataFileModel() {
  yield takeEvery(DOWNLOAD_DATA_FILE_MODEL, downloadDataFileModel);
}

function* systemParams() {
  yield all([
    fork(watchGetSystemParamsDescriptions),
    fork(watchGetSystemParams),
    fork(watchGetPendingSystemParams),
    fork(watchSaveSystemParams),
    fork(watchUpdateSystemParam),
    fork(watchGetWhatsAppQrCode),
    fork(watchCheckIfWhatsAppIsConnected),
    fork(watchDisconnectWhatsApp),
    fork(watchUploadVotersDataFile),
    fork(watchDownloadDataFileModel),
  ]);
}

export default systemParams;
