import React from 'react';
import { Badge } from 'reactstrap';
import Icon from '../Icon';
import levels from './steps/levels';

function UserLevel({ level = 0 }) {
  return (
    <Badge
      color={levels[level].color}
      className={'fs-6 ' + levels[level].className}
      title="Nível"
    >
      <Icon name={levels[level].icon} size={1} className="align-middle" />
      <span className="align-middle"> {levels[level].text}</span>
    </Badge>
  );
}

export default UserLevel;
