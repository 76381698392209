import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import withRouter from '../../../components/common/withRouter';
import LoadingPage from '../../../components/ui/LoadingPage';
import useMunicipalResearchNotRegisteredSchema from '../../../schemas/municipalResearchNotRegistered';
import {
  createMunicipalResearch,
  getCouncilors,
  getInterests,
  getMayors,
  getTags,
} from '../../../store/actions';
import handleCards from '../steps/handleCards/municipalResearchNotRegistered';
import handleDataSubmit from '../steps/handleDataSubmit/municipalResearchNotRegistered';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate/municipalResearchNotRegistered';

function CreateMunicipalResearchNotRegistered({ router }) {
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Voters', ['isLoading']);
  const { isLoading } = useSelector(selector);

  const validationSchema = useMunicipalResearchNotRegisteredSchema();
  const [initialValues, setInitialValues] = useState(
    handleInitialValuesCreate()
  );

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: rest });
    setInitialValues(rest);
    dispatch(createMunicipalResearch({ data, navigate }));
  };

  useEffect(() => {
    dispatch(getMayors());
    dispatch(getCouncilors());
    dispatch(getInterests());
    dispatch(getTags());
  }, []);

  if (isLoading) return <LoadingPage />;
  return (
    <FormPrototype
      router={router}
      onCards={handleCards}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Realizar pesquisa municipal"
    />
  );
}

export default withRouter(CreateMunicipalResearchNotRegistered);
