import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import ListingPrototype from '../../../../../components/Prototypes/ListingPrototype';
import {
  managementLinkAccountToWhatsAppSlot,
  managementListSlotFreeAccounts,
} from '../../../../../store/actions';
import headerTitles from './steps/headerTitles';
import ConfirmationModal from '../../../../../components/ui/ConfirmationModal';

const ListingFreeSlotAccounts = ({ uuid, setOuterModalData }) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    isOpen: false,
    onConfirm: () => {},
  });

  const selectorOptions = ['isLoadingFreeSlotAccounts', 'slotFreeAccounts'];
  const [email, candidate, position, city, actions] = headerTitles;

  const handleData = (accounts) => {
    return accounts.map((data) => {
      return {
        [email]: data.email || '--',
        [candidate]: data.nomeCandidato || '--',
        [position]: data.cargoCandidato || '--',
        [city]: data.municipio
          ? data.municipio.nome + ' - ' + data.municipio.siglaEstado
          : '--',
        [actions]: (
          <Button
            color="primary"
            onClick={() => {
              if (uuid) {
                setModalData({
                  isOpen: true,
                  onConfirm: () => {
                    dispatch(
                      managementLinkAccountToWhatsAppSlot({
                        uuid,
                        accountId: data.id,
                      })
                    );
                    setOuterModalData({
                      isOpen: false,
                    });
                  },
                });
              }
            }}
          >
            Vincular
          </Button>
        ),
      };
    });
  };

  return (
    <>
      <ConfirmationModal
        body="Tem certeza que você quer vincular essa conta?"
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title="Selecionar conta para vinculação"
        confirmText="Vincular"
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
      />

      <ListingPrototype
        pageContent={false}
        handleData={handleData}
        entityName={'Management'}
        headerTitles={headerTitles}
        title={'Administração - Slots Whatsapp'}
        dataSelector={managementListSlotFreeAccounts}
        selectorOptions={selectorOptions}
        isShowButtonCreateNewItem={false}
        emptyMessage={'Oops, sem contas para exibir.'}
      />
    </>
  );
};

export default ListingFreeSlotAccounts;
