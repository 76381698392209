import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moneyMask from '../../../utils/masks/moneyMask';
import { Alert, Card, CardBody, CardHeader, Col } from 'reactstrap';

const DonutChart = ({
  colors,
  titleHeader,
  pessoa: name,
  meta: goalTotal,
  eleitores: votersTotal,
}) => {
  const formatPercentage = (val) => val.toFixed(2);
  const formatVotersCount = (count) => moneyMask(count.toFixed(2));

  const getSeriesLabel = (seriesIndex) =>
    seriesIndex === 0 ? 'Pendente' : 'Meta';

  const options = {
    colors,
    chart: { type: 'donut' },
    labels: ['Cadastros registrados', 'Cadastros pendentes'],
    title: {
      align: 'left',
      style: { fontWeight: 500 },
      text: `${name} - Meta de ${goalTotal} cadastros`,
    },
    yaxis: {
      labels: {
        formatter: function (val, opts) {
          const seriesIndex = opts.seriesIndex;
          const seriesLabel = getSeriesLabel(seriesIndex);

          /*
          return `${formatPercentage(val)}% (${formatVotersCount(
            seriesIndex === 0 ? votersTotal : goalTotal - votersTotal
          )} eleitores(as) para ${seriesLabel})`;
          */
          return `${ seriesIndex === 0 ? votersTotal : goalTotal - votersTotal}`;

        },
      },
    },
  };

  const isVotersAndGoal = votersTotal > 0 && goalTotal > 0;

  const progress = isVotersAndGoal ? (votersTotal / goalTotal) * 100 : 0;
  const GoalToBeBeat = isVotersAndGoal ? 100 - progress : 0;

  const series = [progress, GoalToBeBeat];

  const infoMessage =
    votersTotal > 0
      ? `A meta para ${name} não está definida.`
      : `${name} ainda não tem eleitores cadastrados.`;

  return (
    <Col>
      <Card>
        {titleHeader && (
          <CardHeader>
            <h5>{titleHeader}</h5>
          </CardHeader>
        )}

        <CardBody>
          {goalTotal > 0 && votersTotal > 0 ? (
            <ReactApexChart
              dir="ltr"
              type="donut"
              height="200"
              series={series}
              options={options}
              className="apex-charts"
            />
          ) : (
            <Alert color="info">{infoMessage}</Alert>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default DonutChart;
