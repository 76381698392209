const handleInitialValuesCreate = () => {
  const initialValues = {
    cpf: '',
    phone: '',
    email: '',
    photo: null,
    party: null,
    userName: '',
    password: '',
    jingle: null,
    street: null,
    gender: null,
    lastName: '',
    zipCode: null,
    website: null,
    position: null,
    curriculum: null,
    complement: null,
    campaignName: '',
    dateOfBirth: null,
    voterTarget: null,
    electionYear: null,
    municipality: null,
    neighborhood: null,
    addressNumber: null,
    maritalStatus: null,
    candidateNumber: null,
    sexualOrientation: null,
    isUseCurrentUserAsCandidate: false,
  };

  return initialValues;
};

export default handleInitialValuesCreate;
