import * as Yup from 'yup';

const validationAddress = {
  street: Yup.string().nullable(),
  zipCode: Yup.string().nullable(),
  complement: Yup.string().nullable(),
  municipality: Yup.object().nullable(),
  neighborhood: Yup.string().nullable(),
  addressNumber: Yup.number().nullable(),
};

export default validationAddress;
