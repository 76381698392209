import { toast as toastify } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

/**
 *
 * @param {'error' | 'info' | 'success' | 'loading'} type
 * @param {string} message
 * @returns
 */

const toast = (type, message) => {
  return toastify[type](message, {
    position: toastify.POSITION.TOP_CENTER,
  });
};

export default toast;
