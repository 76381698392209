export const TEMPLATE_LIST = 'TEMPLATE_LIST';
export const TEMPLATE_ERROR = 'TEMPLATE_ERROR';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const TEMPLATE_SUCCESS = 'TEMPLATE_SUCCESS';
export const GET_TEMPLATE_BY_ID = 'GET_TEMPLATE_BY_ID';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const GENERATE_TEMPLATE_CSV = 'GENERATE_TEMPLATE_CSV';
export const SEND_TEMPLATE_MESSAGES = 'SEND_TEMPLATE_MESSAGES';
export const UPDATE_TEMPLATE_AND_SEND_MESSAGES =
  'UPDATE_TEMPLATE_AND_SEND_MESSAGES';
export const GET_TEMPLATE_VARIABLES = 'GET_TEMPLATE_VARIABLES';
