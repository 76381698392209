import fields from './steps/fields';
import { useDispatch } from 'react-redux';
import handleInitialValues from './steps/handleInitialValues';
import useBirthdaysFilterSchema from '../../../../schemas/birthdaysFilter';
import { birthdaysList, birthdaysListFilter } from '../../../../store/actions';
import FormFilterPrototype from '../../../../components/Prototypes/FormFilterPrototype';

const FormFilter = () => {
  const dispatch = useDispatch();

  const schema = useBirthdaysFilterSchema();
  const initialValues = handleInitialValues();

  const handleClearFilter = () => {
    dispatch(birthdaysList());
  };

  const handleSubmit = ({ nextDays }) => {
    const { value } = nextDays;

    const payload = { nextDays: value };
    dispatch(birthdaysListFilter(payload));
  };

  return (
    <FormFilterPrototype
      fields={() => fields}
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={initialValues}
      onClearFilter={handleClearFilter}
    />
  );
};

export default FormFilter;
