import phoneMask from '../../../masks/phoneMask';

const phoneField = ({
  form,
  isUpdate,
  onUpdateForm,
  md,
  isRequired = true,
}) => ({
  isRequired,
  type: 'text',
  name: 'phone',
  maxLength: 15,
  label: 'Telefone',
  value: form.phone,
  md: md ? md : !isUpdate ? 4 : 6,
  placeholder: 'Informe o telefone',
  onChange: (value) => {
    const phone = phoneMask(value);
    onUpdateForm('phone', phone);
  },
});

export default phoneField;
