import handleCreateUserObject from '../../../../utils/transformsValues/handleCreateUserObject';

const handleDataSubmit = ({ data, isUpdate }) => {
  const userData = handleCreateUserObject({ data, isUpdate });

  const formattedData = {
    nivel: data.level.value || 0,
    metaEleitores: data.voterTarget || 0,
    usuario: {
      ...userData,
      ativo: data.isActive,
      grupo: data.group.value,
      foto: data.photo || null,
    },
  };

  return formattedData;
};

export default handleDataSubmit;
