import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';
import Icon from '../../../../../components/ui/Icon';
import {
  disconnectWhatsApp,
  getWhatsAppQrCode,
} from '../../../../../store/actions';

export default function QrCodeRequester({
  qrCodeChecksRef,
  qrCodeChecksInitial,
}) {
  const selector = createDynamicSelector('Settings', [
    'isLoading',
    'isWhatsAppConnected',
    'isDisconnectingWhatsApp',
  ]);
  const { isLoading, isWhatsAppConnected, isDisconnectingWhatsApp } =
    useSelector(selector);

  const dispatch = useDispatch();

  const isRequestButtonLoading =
    isLoading || isWhatsAppConnected || isWhatsAppConnected === null;
  const isDeactivateButtonLoading =
    isLoading ||
    !isWhatsAppConnected ||
    isWhatsAppConnected === null ||
    isDisconnectingWhatsApp;

  return (
    <Row className="gap-3">
      <Col>
        <h4 className="mb-2 text-black">
          Clique no botão "Recuperar qrCode" para carregar a imagem e aponte a
          câmera do celular para conectar no WhatsApp.
        </h4>
        <h6>
          Caso a imagem carregada não apresente o qrCode, clique novamente no
          botão para atualizar a imagem.
        </h6>
        <h6 className="mb-3">
          Para trocar de número, você deve primeiro clicar no botão para
          desconectar do número anterior.
        </h6>
        <div className="d-flex gap-1">
          <Button
            color="primary"
            onClick={() => {
              qrCodeChecksRef.current = qrCodeChecksInitial;
              dispatch(getWhatsAppQrCode());
            }}
            disabled={isRequestButtonLoading}
            className="mr-2"
          >
            {isLoading ? 'Carregando...' : 'Recuperar qrCode'}
          </Button>
          <Button
            onClick={() => {
              qrCodeChecksRef.current = qrCodeChecksInitial;
              dispatch(disconnectWhatsApp());
            }}
            color="danger"
            disabled={isDeactivateButtonLoading}
          >
            {isDisconnectingWhatsApp ? 'Carregando...' : 'Desconectar WhatsApp'}
          </Button>
        </div>
      </Col>
      <Col xs={4}>
        <div className="h-100 bg-light rounded d-flex align-items-center justify-content-center p-4">
          <Icon name="qrCode" size={3} />
        </div>
      </Col>
    </Row>
  );
}
