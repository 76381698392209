import {
  GET_ME,
  USERS_ERROR,
  USERS_SUCCESS,
  GET_REGISTRANTS,
} from './actionTypes';

const initialState = {
  user: {},
  registrants: [],
  isLoading: false,
};

const users = (state = initialState, { type, payload }) => {
  const { user = state.user, registrants = state.registrants } = payload ?? {};

  switch (type) {
    case GET_ME:
    case GET_REGISTRANTS:
      return {
        ...state,
        isLoading: true,
      };

    case USERS_ERROR:
    case USERS_SUCCESS:
      return {
        ...state,
        user,
        registrants,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default users;
