import Icon from '../../../../../components/ui/Icon';
import fields from '../politicianFields';

const handleCards = (isUpdate = false) => {
  const politicianFields = fields();

  const cards = [
    {
      titleHeader: `${isUpdate ? 'Atualizar' : 'Adicionar'} político`,
      Icon: () => <Icon name={isUpdate ? 'pencil' : 'plus'} />,
      fields: politicianFields,
      isShowFormControlButtons: true,
      showCardBackButton: false,
    },
  ];
  return cards;
};

export default handleCards;
