export const reportsVotersDashboardKeys = [
  'reportsEthnicity',
  'reportsGenre',
  'reportsAges',
  'reportsRegion',
  'reportsReligion',
  'reportsTags',
  'reportsInterests',
  'reportsOrientation',
  'reportsMultipliers',
  'reportsEngagement',
  'reportsEducation',
  'reportsIncome',
  'reportsElectoralCollege',
];

export const reportsResearchDashboardKeys = [
  'reportsResearchWater',
  'reportsResearchAsphalt',
  'reportsResearchDaycare',
  'reportsResearchEducation',
  'reportsResearchIlumination',
  'reportsResearchBus',
  'reportsResearchHealthCenter',
  'reportsResearchMayor',
  'reportsResearchHealh',
  'reportsResearchTraffic',
  'reportsResearchTransport',
  'reportsResearchCouncilors',
];

export const reportsPerformanceDashboardKeys = [
  'reportsPerformanceMultipliers',
];

export const reportsHomeKeys = [
  'candidatePerformanceReports',
  'multiplierPerformanceReports',
];
