import React from 'react';
import { Row } from 'reactstrap';
import Icon from '../../../../ui/Icon';
import { Button, Col } from './styles';
import { Col as BootstrapCol } from 'reactstrap';
import LoadingButton from '../../../../ui/LoadingButton';

const FormControlButtons = ({
  onBack,
  showBackButton = true,
  isShow,
  isLoading,
  titlePrimaryButton,
  extraButtons,
}) => {
  if (!isShow) return null;

  const backButton = (
    <Button
      color="light"
      type="button"
      onClick={onBack}
      disabled={isLoading}
      className="btn border"
    >
      <Icon name="arrowBack" size={1} />
      <span>Voltar</span>
    </Button>
  );

  const primaryButton = (
    <Button
      type="submit"
      disabled={isLoading}
      className="d-flex items-center btn btn-primary"
    >
      {isLoading ? (
        <LoadingButton />
      ) : (
        <>
          <Icon name="checkCircle" size={1} />
          <span>{titlePrimaryButton}</span>
        </>
      )}
    </Button>
  );

  return (
    <Row className="mt-4">
      <Col className="gap-1">
        {showBackButton ? backButton : <span />}

        <Row>
          <BootstrapCol className="d-flex gap-1">
            {extraButtons && extraButtons}
            {primaryButton}
          </BootstrapCol>
        </Row>
      </Col>
    </Row>
  );
};

export default FormControlButtons;
