/**
 *
 * @returns {number}
 */

const getOptionForHowManyRecordsToShowPerPage = () => {
  const value = localStorage.getItem('optionForHowManyRecordsToShowPerPage');

  const defaultValue = 50;
  if (!value) return defaultValue;

  return Number(value);
};

export default getOptionForHowManyRecordsToShowPerPage;
