import { engagementOptions } from '../../../../utils/optionsCommon';
import handleInitialValuesPersonObject from '../../../../utils/transformsValues/handleInitialValuesPersonObject';

const handleInitialValuesUpdate = (currentVoter) => {
  if (!currentVoter) return;

  const person = handleInitialValuesPersonObject(currentVoter);
  const { escolaridade, etnia, interesses, religiao } = currentVoter;
  const { contactar, email, engajamento, whatsApp, votante, grupo } =
    currentVoter;
  const { etiquetas, filhos, renda, regiao, telefone, titulo, instagram } =
    currentVoter;
  const { zona, colegio, mensagem, profissao, melhorMeioContato } =
    currentVoter;

  const currentEngagement = engagementOptions.find(
    ({ value }) => value == engajamento
  );
  const engagement = currentEngagement
    ? { label: currentEngagement.label, value: currentEngagement.value }
    : null;

  const ethnicity = etnia ? { label: etnia, value: etnia } : null;
  const region = regiao ? { label: regiao.nome, value: regiao.id } : null;
  const religion = religiao
    ? { label: religiao.nome, value: religiao.id }
    : null;
  const schooling = escolaridade
    ? { label: escolaridade.nome, value: escolaridade.id }
    : null;
  const tags = etiquetas
    ? etiquetas.map(({ id, nome }) => ({ label: nome, value: id }))
    : null;
  const interests = interesses
    ? interesses.map(({ id, nome }) => ({ label: nome, value: id }))
    : null;
  const preferredContactMethod = melhorMeioContato
    ? { label: melhorMeioContato, value: melhorMeioContato }
    : null;

  const values = {
    ...person,
    tags,
    email,
    region,
    religion,
    interests,
    ethnicity,
    schooling,
    instagram,
    engagement,
    income: renda || '',
    title: titulo,
    phone: telefone || '',
    children: filhos,
    isVoter: votante,
    isContact: contactar,
    isPhoneWhatsApp: whatsApp,
    electoralCollege: colegio || '',
    electoralZone: zona || '',
    message: mensagem || '',
    group: grupo || '',
    profession: profissao || '',
    preferredContactMethod,
  };

  return values;
};

export default handleInitialValuesUpdate;
