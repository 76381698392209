import * as Yup from 'yup';

const usePoliticianSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Preencha o campo'),
    position: Yup.object().required('Preencha o campo'),
  });
};

export default usePoliticianSchema;
