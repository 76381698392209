import genderField from '../../../../../../../utils/fields/common/genderField';

const fields = ({ form }) => {
  return [
    [
      {
        type: 'radio',
        name: 'isActive',
        label: 'Situação',
        defaultValue: form.isActive,
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Ativo', value: true },
          { label: 'Inativo', value: false },
        ],
      },
    ],
    [
      {
        md: 4,
        name: 'name',
        type: 'text',
        label: 'Nome',
        placeholder: 'Informe o nome',
      },
      {
        md: 4,
        name: 'email',
        type: 'email',
        label: 'E-mail',
        placeholder: 'Informe o e-mail',
      },
      {
        md: 4,
        name: 'level',
        type: 'number',
        label: 'Nivel',
        placeholder: 'Informe o nivel',
      },
    ],
    [
      {
        md: 6,
        name: 'group',
        type: 'select',
        label: 'Perfil',
        required: false,
        options: [
          { label: 'Administrador', value: 'Administrador' },
          { label: 'Multiplicador', value: 'Multiplicador' },
        ],
      },
      ...[genderField(6)],
    ],
  ];
};

export default fields;
