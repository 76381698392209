import {
  FILTER_MESSAGES_BY_TEMPLATE_ID,
  GENERATE_MESSAGES,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
} from './actionTypes';

const initialState = {
  messages: [],
  isLoading: false,
};

const messages = (state = initialState, { type, payload }) => {
  const { messages = state.messages } = payload ?? {};

  switch (type) {
    case FILTER_MESSAGES_BY_TEMPLATE_ID:
    case GENERATE_MESSAGES:
      return {
        ...state,
        isLoading: true,
      };

    case MESSAGE_ERROR:
    case MESSAGE_SUCCESS:
      return {
        ...state,
        messages,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default messages;
