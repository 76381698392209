import handleCreateUserObject from '../../../../utils/transformsValues/handleCreateUserObject';

const handleDataSubmit = ({ data, isUpdate = false }) => {
  const userData = handleCreateUserObject({ data, isUpdate });

  const formattedData = {
    foto: data.photo,
    site: data.website,
    jingle: data.jingle,
    nome: data.campaignName,
    usuario: { ...userData },
    curriculo: data.curriculum,
    partido: { id: data.party.value },
    numero: data.candidateNumber || 0,
    cargo: { id: data.position.value },
    eleicaoAno: data.electionYear || null,
    metaEleitores: data.voterTarget || null,
  };

  return formattedData;
};

export default handleDataSubmit;
