const fields = [
  [
    {
      type: 'select',
      name: 'nextDays',
      label: 'Próximos dias',
      options: [
        { label: 'Aniversariantes do dia', value: 1 },
        { label: 'Aniversariantes dos próximos 3 dias', value: 3 },
        { label: 'Aniversariantes dos próximos 7 dias', value: 7 },
        { label: 'Aniversariantes dos próximos 15 dias', value: 15 },
        { label: 'Aniversariantes dos próximos 30 dias', value: 30 }
      ],
    },
  ],
];

export default fields;
