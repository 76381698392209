import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Container, Spinner } from 'reactstrap';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import {
  downloadDataFileModel,
  uploadVotersDataFile,
} from '../../../store/actions';
import Instructions from './components/Instructions';

export default function ImportDataSettings() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const selector = createDynamicSelector('Settings', [
    'isLoading',
    'wasDataFileSent',
  ]);
  const { isLoading, wasDataFileSent } = useSelector(selector);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xsl'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      // 'text/csv': ['.csv'],
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10 MB
    onDrop,
  });

  const handleUploadFile = (e) => {
    e.stopPropagation();
    if (files.length == 1) {
      const file = files[0];
      dispatch(uploadVotersDataFile({ file }));
    }
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setFiles([]);
    acceptedFiles.length = 0;
  };

  useEffect(() => {
    document.title = 'Importar dados';
  }, []);

  useEffect(() => {
    if (!isLoading && wasDataFileSent) {
      setFiles([]);
      acceptedFiles.length = 0;
    }
  }, [isLoading, wasDataFileSent]);

  const generateSelectedFileString = (file) =>
    `Arquivo selecionado: ${file.path} (${(file.size / 1024).toFixed(2)} KB)`;
  return (
    <Container className="page-content">
      <Card>
        <CardBody>
          <Instructions />
          <Button
            color="primary"
            onClick={() => dispatch(downloadDataFileModel())}
          >
            Baixar planilha modelo
          </Button>
          {isLoading ? (
            <div className="w-full p-5 d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : (
            <section className="mt-2">
              <div
                {...getRootProps({
                  className:
                    'dropzone d-flex flex-column justify-content-center align-items-center p-2',
                })}
              >
                <input {...getInputProps()} />
                <p className="text-center text-muted mb-0">
                  Arraste o arquivo aqui ou clique para selecioná-lo.
                </p>
                <p className="text-center text-muted mb-3">
                  {/* (Apenas arquivos dos tipos *.xsl, *.xlsx e *.csv de até 25MB são aceitos) */}
                  (Apenas arquivos dos tipos *.xsl e *.xlsx de até 10MB são
                  aceitos)
                </p>
                {acceptedFiles.length > 0 ? (
                  <>
                    <p className="text-center">
                      {generateSelectedFileString(acceptedFiles[0])}
                    </p>
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={handleUploadFile}>
                        Enviar
                      </Button>
                      <Button color="danger" onClick={handleRemoveFile}>
                        Remover
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
            </section>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}
