import { Row } from 'reactstrap';
import { useFormikContext } from 'formik';
import Field from '../../../FormPrototype/components/FormCard/components/Field';

const RenderField = ({ fields }) => {
  const { values: form, setFieldValue } = useFormikContext();

  const onUpdateForm = (field, newValue) => {
    setFieldValue(field, newValue);
  };

  return (
    <>
      {fields({ form, onUpdateForm }).map((fieldGroup, fieldIndex) => (
        <Row key={fieldIndex}>
          {fieldGroup.map((field, fieldKey) => (
            <Field key={fieldKey} field={field} />
          ))}
        </Row>
      ))}
    </>
  );
};

export default RenderField;
