import { Card } from './styles';
import { Link } from 'react-router-dom';
import Form from '../../../components/Formik/Form';
import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/logo-centralizada.png';
import { Formik, Form as FormFormik } from 'formik';
import useLoginSchema from '../../../schemas/login';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../store/auth/login/actions';
import withRouter from '../../../components/common/withRouter';
import LoadingButton from '../../../components/ui/LoadingButton';
import FieldPassword from '../../../components/ui/FieldPassword';
import ParticlesAuth from '../../AuthenticationInner/ParticlesAuth';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import getRememberLocalStorage from '../../../utils/actionsRemember/getRememberLocalStorage';
import addRememberLocalStorage from '../../../utils/actionsRemember/addRememberLocalStorage';
import removeRememberLocalStorage from '../../../utils/actionsRemember/removeRememberLocalStorage';
import removeUserDataAndAccessToken from '../../../utils/actionsCookies/removeUserDataAndAccessToken';
import {
  Col,
  Row,
  Label,
  Input,
  Button,
  CardBody,
  Container,
} from 'reactstrap';

const Login = (props) => {
  const { isRemember, lastLogin } = getRememberLocalStorage();

  const { router } = props;
  const { navigate } = router;

  const dispatch = useDispatch();

  const [rememberMe, setRememberMe] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const selectLayoutProperties = createDynamicSelector('Login', ['isLoading']);
  const { isLoading } = useSelector(selectLayoutProperties);

  const schema = useLoginSchema();
  const initialValues = { email: '', password: '' };

  const handleSubmit = (values) => {
    dispatch(loginUser({ ...values, navigate }));
  };

  const handleGoHome = () => navigate('/dashboard');

  const handleClickRememberMe = () => {
    const newValue = !rememberMe;
    setRememberMe(newValue);

    if (!newValue) removeRememberLocalStorage();
    else addRememberLocalStorage();
  };

  const checkRememberAndRedirect = () => {
    if (!isRemember || !lastLogin) {
      removeUserDataAndAccessToken();
      return;
    }

    const currentTime = new Date();
    const calculateTimeSinceLastLogin = currentTime - new Date(lastLogin);
    const diffInHours = calculateTimeSinceLastLogin / (1000 * 60 * 60);

    if (diffInHours < 4) handleGoHome();
    else removeRememberLocalStorage();
  };

  useEffect(() => {
    document.title = 'Login';
  }, []);

  useEffect(() => {
    checkRememberAndRedirect();
  }, []);

  const isDisabledButtonEnter = isLoading;

  return (
    <>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <img src={logo} alt="logo" className="logo" />

                      <h5>Bem-vindo(a) de volta!</h5>

                      <p className="text-muted">
                        Faça login com suas credenciais.
                      </p>
                    </div>

                    <div className="p-2 mt-4">
                      <Formik
                        enableReinitialize
                        onSubmit={handleSubmit}
                        validationSchema={schema}
                        initialValues={initialValues}
                      >
                        <FormFormik>
                          <Form.Field>
                            <Form.Label htmlFor="email">E-mail</Form.Label>

                            <Form.Input
                              name="email"
                              type="email"
                              placeholder="Entre com seu E-mail"
                            />

                            <Form.ErrorMessage field="email" />
                          </Form.Field>

                          <Form.Field>
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                Esqueceu sua senha?
                              </Link>
                            </div>

                            <FieldPassword
                              isShowPassword={isShowPassword}
                              setIsShowPassword={setIsShowPassword}
                            />
                          </Form.Field>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              name="auth-remember-check"
                              className="form-check-input"
                              onChange={() => handleClickRememberMe}
                            />

                            <Label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Lembre-me
                            </Label>
                          </div>

                          <div className="mt-4">
                            <Button
                              type="submit"
                              color="none"
                              className="btn btn-primary w-100"
                              disabled={isDisabledButtonEnter}
                            >
                              {isDisabledButtonEnter ? (
                                <LoadingButton />
                              ) : (
                                'Entrar'
                              )}
                            </Button>
                          </div>
                        </FormFormik>
                      </Formik>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Não tenho uma conta ?{' '}
                    <Link
                      to="/register"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      Registre-se
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </>
  );
};

export default withRouter(Login);
