import {
  ADD_TAG,
  AUTOREGISTER_VOTER,
  BIRTHDAYS_LIST,
  BIRTHDAYS_LIST_FILTER,
  CLEAR_MUNICIPAL_RESEARCH,
  CLEAR_VOTERS_AFFECTED_BY_FILTER_COUNTER,
  COUNT_VOTERS_AFFECTED_BY_FILTER,
  CREATE_MUNICIPAL_RESEARCH,
  CREATE_VOTER,
  DELETE_VOTER,
  GENERATE_VOTERS_CSV,
  GET_MUNICIPAL_RESEARCH_BY_ID,
  GET_MUNICIPAL_RESEARCH_BY_VOTER_ID,
  GET_VOTER_BY_ID,
  UPDATE_MUNICIPAL_RESEARCH,
  UPDATE_VOTER,
  VOTERS_ERROR,
  VOTERS_LIST,
  VOTERS_LIST_FILTER,
  VOTERS_SUCCESS,
} from './actionTypes';

export const addTag = (payload) => {
  return {
    type: ADD_TAG,
    payload,
  };
};

export const createVoter = (payload) => {
  return {
    type: CREATE_VOTER,
    payload,
  };
};

export const autoregisterVoter = (payload) => {
  return {
    type: AUTOREGISTER_VOTER,
    payload,
  };
};

export const updateVoter = (payload) => {
  return {
    type: UPDATE_VOTER,
    payload,
  };
};

export const votersList = (payload) => {
  return {
    type: VOTERS_LIST,
    payload,
  };
};

export const getVoterById = (payload) => {
  return {
    type: GET_VOTER_BY_ID,
    payload,
  };
};

export const birthdaysList = () => {
  return {
    type: BIRTHDAYS_LIST,
    payload: {},
  };
};

export const birthdaysListFilter = (payload) => {
  return {
    type: BIRTHDAYS_LIST_FILTER,
    payload,
  };
};

export const votersListFilter = (payload) => {
  return {
    type: VOTERS_LIST_FILTER,
    payload,
  };
};

export const deleteVoter = (voterId) => {
  return {
    type: DELETE_VOTER,
    payload: { voterId },
  };
};

export const votersError = () => {
  return {
    type: VOTERS_ERROR,
    payload: {},
  };
};

export const votersSuccess = (payload) => {
  return {
    type: VOTERS_SUCCESS,
    payload,
  };
};

export const countVotersAffectedByFilter = (payload) => {
  return {
    type: COUNT_VOTERS_AFFECTED_BY_FILTER,
    payload,
  };
};

export const clearVotersAffectedByFilterCounter = () => {
  return {
    type: CLEAR_VOTERS_AFFECTED_BY_FILTER_COUNTER,
    payload: {},
  };
};

export const generateVotersCSV = (payload) => {
  return {
    type: GENERATE_VOTERS_CSV,
    payload,
  };
};

export const createMunicipalResearch = (payload) => {
  return {
    type: CREATE_MUNICIPAL_RESEARCH,
    payload,
  };
};

export const getMunicipalResearchById = (payload) => {
  return {
    type: GET_MUNICIPAL_RESEARCH_BY_ID,
    payload,
  };
};

export const clearMunicipalResearch = () => {
  return {
    type: CLEAR_MUNICIPAL_RESEARCH,
    payload: {},
  };
};

export const updateMunicipalResearch = (payload) => {
  return {
    type: UPDATE_MUNICIPAL_RESEARCH,
    payload,
  };
};

export const getMunicipalResearchByVoterId = (payload) => {
  return {
    type: GET_MUNICIPAL_RESEARCH_BY_VOTER_ID,
    payload,
  };
};
