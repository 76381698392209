import { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FormFilterPrototype from '../../../../../components/Prototypes/FormFilterPrototype';
import useVotersFilterSchema from '../../../../../schemas/votersFilter';
import {
  getInterests,
  getRegions,
  getRegistrants,
  getReligions,
  getSchoolings,
  getSexualOrientations,
  getTags,
  votersList,
  votersListFilter,
} from '../../../../../store/actions';
import getOptionForHowManyRecordsToShowPerPage from '../../../../../utils/actionsListing/getOptionForHowManyRecordsToShowPerPage';
import getVotersPageIndex from '../../../../../utils/actionsListing/getVotersPageIndex';
import fields from '../../../../../utils/fields/common/filterVotersFields';
import handleDataSubmit from './steps/handleDataSubmit';
import handleInitialValues from './steps/handleInitialValues';

const FormFilter = (_, ref) => {
  const dispatch = useDispatch();

  const defaultPageIndex = getVotersPageIndex();
  const defaultPageSize = getOptionForHowManyRecordsToShowPerPage();

  const schema = useVotersFilterSchema();
  const initialValues = handleInitialValues();

  const payload = { pageIndex: defaultPageIndex, pageSize: defaultPageSize };

  const handleClearFilter = () => {
    dispatch(votersList(payload));
  };

  const handleSubmit = (values) => {
    const data = handleDataSubmit(values);
    dispatch(votersListFilter({ filters: data, ...payload }));
  };

  useEffect(() => {
    dispatch(getTags());
    dispatch(getRegions());
    dispatch(getReligions());
    dispatch(getInterests());
    dispatch(getSchoolings());
    dispatch(getRegistrants());
    dispatch(getSexualOrientations());
  }, [dispatch]);

  return (
    <FormFilterPrototype
      ref={ref}
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={initialValues}
      onClearFilter={handleClearFilter}
      fields={(params) => fields({ ...params, dispatch })}
    />
  );
};

export default forwardRef(FormFilter);
