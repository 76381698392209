import { Container } from 'reactstrap';
import { forwardRef, useEffect, useState } from 'react';
import FormCard from './components/FormCard';
import { Formik, Form as FormFormik } from 'formik';

const FormPrototype = forwardRef(
  (
    {
      router,
      onCards,
      onSubmit,
      documentTitle,
      validationSchema,
      initialValuesForm,
      pageContent = true,
      validateOnChange = false,
      validateOnBlur = false,
    },
    ref
  ) => {
    const { navigate } = router;
    const onBack = () => navigate(-1);

    const handleSubmit = (values) => {
      onSubmit({ ...values, navigate });
    };

    useEffect(() => {
      document.title = documentTitle;
    }, []);

    return (
      <Container className={pageContent ? 'page-content' : ''} fluid>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValuesForm}
          validationSchema={validationSchema}
          validateOnChange={validateOnChange}
          validateOnBlur={validateOnBlur}
        >
          <FormFormik ref={ref}>
            <FormCard onBack={onBack} onCards={onCards} />
          </FormFormik>
        </Formik>
      </Container>
    );
  }
);

export default FormPrototype;
