import {
  MULTIPLIERS_LIST,
  MULTIPLIERS_ERROR,
  CREATE_MULTIPLIER,
  UPDATE_MULTIPLIER,
  MULTIPLIERS_SUCCESS,
  MULTIPLIERS_LIST_FILTER,
  ACTIVE_OR_INACTIVATE_MULTIPLIER,
} from './actionTypes';

const initialState = {
  multipliers: [],
  isLoading: false,
};

const multipliers = (state = initialState, { type, payload }) => {
  const { multipliers = state.multipliers } = payload ?? {};

  switch (type) {
    case MULTIPLIERS_LIST:
    case CREATE_MULTIPLIER:
    case UPDATE_MULTIPLIER:
    case ACTIVE_OR_INACTIVATE_MULTIPLIER:
      return {
        ...state,
        isLoading: true,
      };

    case MULTIPLIERS_ERROR:
    case MULTIPLIERS_SUCCESS:
    case MULTIPLIERS_LIST_FILTER:
      return {
        ...state,
        multipliers,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default multipliers;
