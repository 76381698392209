import { styled } from 'styled-components';
import { Card as CardReactstrap } from 'reactstrap';

export const Card = styled(CardReactstrap)`
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.4);

  .logo {
    margin-bottom: 1rem;
  }
`;
