import { Alert } from 'reactstrap';
import Icon from '../../../../../components/ui/Icon';
import fields from '../../fields/municipalResearchNotRegistered';
import { Link } from 'react-router-dom';

const handleCards = ({ form, onUpdateForm }) => {
  const cardFields = fields({ form, onUpdateForm });

  const cards = [
    {
      fields: cardFields.lgpdNotice,
      titleHeader: 'Lei Geral de Proteção de Dados Pessoais (LGPD)',
      Icon: () => <Icon name="alertCircle" />,
    },
    {
      customContent: true,
      CustomContentComponent: () => (
        <Alert className="mb-0" color="primary">
          <p className="mb-0">
            Caso o eleitor possua cadastro, realizar a pesquisa municipal
            através das ações em <Link to="/voters">Listagem de eleitor</Link>
          </p>
        </Alert>
      ),
    },
    {
      fields: cardFields.personalInformation,
      titleHeader: 'Informação pessoal',
      Icon: () => <Icon name="user" />,
    },
    {
      fields: cardFields.zeladoria,
      titleHeader: 'Zeladoria',
      Icon: () => <Icon name="city" />,
    },
    {
      fields: cardFields.transporte,
      titleHeader: 'Transporte',
      Icon: () => <Icon name="bus" />,
    },
    {
      fields: cardFields.educacao,
      titleHeader: 'Educação',
      Icon: () => <Icon name="school" />,
    },
    {
      fields: cardFields.saude,
      titleHeader: 'Saúde',
      Icon: () => <Icon name="health" />,
    },
    {
      fields: cardFields.intencaoVoto,
      titleHeader: 'Intenção de voto',
      isShowFormControlButtons: true,
      Icon: () => <Icon name="medal" />,
    },
  ];

  return cards;
};

export default handleCards;
