import * as Yup from 'yup';
import validationFilter from '../common/validationFilter';

const useVotersFilterSchema = () => {
  return Yup.object().shape({
    ...validationFilter,
    electoralZone: Yup.number().nullable(),
    electoralCollege: Yup.string().nullable(),
  });
};

export default useVotersFilterSchema;
