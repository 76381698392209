import {
  CHANGE_POLITICIAN_SITUATION,
  CREATE_POLITICIAN,
  GET_COUNCILORS,
  GET_MAYORS,
  GET_POLITICIANS,
  POLITICIANS_ERROR,
  POLITICIANS_SUCCESS,
  UPDATE_POLITICIAN,
} from './actionTypes';

export const politiciansError = () => {
  return {
    type: POLITICIANS_ERROR,
    payload: {},
  };
};

export const politiciansSuccess = (payload) => {
  return {
    type: POLITICIANS_SUCCESS,
    payload,
  };
};

export const getPoliticians = () => {
  return {
    type: GET_POLITICIANS,
    payload: {},
  };
};

export const createPolitician = (payload) => {
  return {
    type: CREATE_POLITICIAN,
    payload,
  };
};

export const changePoliticianSituation = (payload) => {
  return {
    type: CHANGE_POLITICIAN_SITUATION,
    payload,
  };
};

export const updatePolitician = (payload) => {
  return {
    type: UPDATE_POLITICIAN,
    payload,
  };
};

export const getMayors = () => {
  return {
    type: GET_MAYORS,
    payload: {},
  };
};

export const getCouncilors = () => {
  return {
    type: GET_COUNCILORS,
    payload: {},
  };
};
