import phoneField from '../phoneField';
import emailField from '../emailField';
import personFields from '../personFields';

const fields = ({
  form,
  isUpdate,
  dispatch,
  onUpdateForm,
  isCpfRequired = true,
}) => {
  return [
    ...personFields({ form, dispatch, onUpdateForm, isCpfRequired }),
    [
      ...[phoneField({ form, isUpdate, onUpdateForm })],
      ...[emailField({ isUpdate })],
      ...(!isUpdate
        ? [
            {
              md: 4,
              label: 'Senha',
              type: 'password',
              name: 'password',
              isRequired: true,
              placeholder: 'Crie uma senha',
            },
          ]
        : []),
    ],
  ];
};

export default fields;
