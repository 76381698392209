import { useFormikContext } from 'formik';
import React from 'react';
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';

const TermsOfUse = ({ isOpen, toggle }) => {
  const { setFieldValue } = useFormikContext();

  const handleAcceptedTermsUse = () => {
    toggle();
    setFieldValue('termsOfUse', true);
  };

  const handleNotAcceptedTermsUse = () => {
    toggle();
    setFieldValue('termsOfUse', false);
  };

  return (
    <Modal isOpen={isOpen} centered>
      <Container fluid className="mt-3">
        <Row className="justify-content-center">
          <Col className="col-lg-10">
            <Card>
              <div className="bg-primary-subtle position-relative">
                <CardBody className="card-body p-5">
                  <div className="text-center">
                    <h3>Termos de uso do sistema Multivotos</h3>

                    <p className="mb-0">
                      Última atualização: 17 de novembro de 2023.
                    </p>
                  </div>
                </CardBody>
              </div>

              <CardBody className="p-4">
                <div>
                  <h5>Bem-vindo(a) ao Multivotos!</h5>

                  <p>
                    Estes termos e condições delineiam as regras e regulamentos
                    para o uso do Sistema Multivotos.
                  </p>

                  <p>
                    Ao acessar este sistema, presumimos que você aceita estes
                    termos e condições. Não continue a usar o Sistema Multivotos
                    se você não concordar com todos os termos e condições
                    declarados nesta página.
                  </p>
                </div>

                <div>
                  <h5>1. Definições:</h5>

                  <ul className="vstack gap-2">
                    <li>
                      <strong>Multivotos:</strong> sistema web desenvolvido pela
                      Multivotos que permite que candidatos políticos,
                      representantes legais de partidos políticos e
                      multiplicadores cadastrem e gerenciem bases de dados
                      eleitorais com informações relevantes de cidadãos que
                      consentiram previamente no compartilhamento de seus dados
                      e aceitaram receber mensagens por vários meios, incluindo
                      aplicativos de mensagens instantâneas, SMS, e-mail e
                      carta.
                    </li>

                    <li>
                      <strong>Responsável pela conta:</strong> candidato
                      político ou representante legal de partido político que
                      possui uma conta no sistema Multivotos.
                    </li>

                    <li>
                      <strong>Cidadão cadastrado:</strong> pessoa que consentiu
                      previamente com o compartilhamento de seus dados e que
                      aceitou receber mensagens por meio do sistema Multivotos.
                    </li>

                    <li>
                      <strong>Multiplicador:</strong> colaborador do político ou
                      representante legal de partido político que possui uma
                      conta no sistema Multivotos e que tem a atribuição de
                      cadastrar e atualizar os dados dos cidadãos.
                    </li>
                  </ul>
                </div>

                <div>
                  <h5>2. Objeto:</h5>

                  <p>
                    O objetivo do presente termo de uso é estabelecer as
                    condições para o uso do Sistema Multivotos pelo Responsável
                    pela Conta, Multiplicadores e Cidadãos Cadastrados.
                  </p>
                </div>

                <div>
                  <h5>3. Responsabilidades do responsável pela conta:</h5>

                  <ul className="vstack gap-2">
                    <li>
                      <strong>Respeitar as leis vigentes:</strong> o responsável
                      pela conta deve respeitar todas as leis vigentes,
                      incluindo a legislação eleitoral brasileira, a Lei Geral
                      de Proteção de Dados (LGPD) e outras leis aplicáveis.
                    </li>

                    <li>
                      <strong>
                        Cadastrar no sistema apenas dados de cidadãos que
                        consentiram previamente com o compartilhamento de seus
                        dados e que aceitaram receber mensagens:
                      </strong>{' '}
                      o responsável pela conta deve garantir que todos os dados
                      cadastrados no sistema sejam de cidadãos que consentiram
                      previamente com o compartilhamento de seus dados e que
                      aceitaram receber mensagens.
                    </li>

                    <li>
                      <strong>
                        Disponibilizar meios para que os cidadãos cadastrados
                        solicitem o descadastramento de seus dados e excluam no
                        sistema os dadosdesses cidadãos:
                      </strong>{' '}
                      o responsável pela conta deve disponibilizar meios para
                      que os cidadãos cadastrados possam solicitar o
                      descadastramento de seus dados e a exclusão desses dados
                      do sistema.
                    </li>

                    <li>
                      <strong>
                        Todo o conteúdo das mensagens enviadas através do
                        sistema:
                      </strong>{' '}
                      o responsável pela conta é responsável por todo o conteúdo
                      das mensagens enviadas através do sistema. As mensagens
                      não devem conter informações falsas ou ofensivas.
                    </li>

                    <li>
                      <strong>
                        Divulgação dos termos de uso para os usuários
                        cadastrados em sua conta:
                      </strong>{' '}
                      o responsável pela conta deve divulgar os termos de uso
                      para os usuários cadastrados em sua conta.
                    </li>
                  </ul>
                </div>

                <div>
                  <h5>4. Direitos do responsável pela conta:</h5>

                  <p>O responsável pela conta tem o direito de:</p>

                  <ul className="vstack gap-2">
                    <li>
                      <strong>
                        Usar o sistema Multivotos para cadastrar e gerenciar
                        base de dados eleitorais:
                      </strong>{' '}
                      o responsável pela conta pode utilizar o sistema
                      Multivotos para cadastrar e gerenciar base de dados
                      eleitorais, desde que respeite as leis vigentes e as
                      condições estabelecidas neste termo de uso.
                    </li>

                    <li>
                      <strong>
                        Enviar mensagens personalizadas para os cidadãos
                        cadastrados:
                      </strong>{' '}
                      o responsável pela conta pode enviar mensagens
                      personalizadas para os cidadãos cadastrados, desde que as
                      mensagens não contenham informações falsas ou ofensivas.
                    </li>

                    <li>
                      <strong>
                        Utilizar gráficos e relatórios elaborados pelo sistema
                        com base nos dados cadastrados:
                      </strong>{' '}
                      o responsável pela conta pode utilizar gráficos e
                      relatórios elaborados pelo sistema com base nos dados
                      cadastrados.
                    </li>

                    <li>
                      <strong>Privacidade de seus dados pessoais:</strong> o
                      responsável pela conta tem o direito de que seus dados
                      pessoais sejam tratados de forma confidencial e segura, de
                      acordo com a LGPD.
                    </li>

                    <li>
                      <strong>
                        Privacidade dos dados dos cidadãos cadastrados:
                      </strong>{' '}
                      o responsável pela conta tem o direito de que os dados dos
                      cidadãos cadastrados no sistema sejam tratados de forma
                      confidencial e segura, de acordo com a LGPD.
                    </li>
                  </ul>
                </div>

                <div>
                  <h5>5. Direitos dos cidadãos cadastrados:</h5>

                  <p>Os cidadãos cadastrados têm o direito de:</p>

                  <ul className="vstack gap-2">
                    <li>
                      <strong>
                        Solicitar o descadastramento de seus dados:
                      </strong>{' '}
                      os cidadãos cadastrados podem solicitar o descadastramento
                      de seus dados do sistema Multivotos a qualquer momento
                    </li>

                    <li>
                      <strong>Receber mensagens personalizadas:</strong> os
                      cidadãos cadastrados podem optar por receber ou não
                      mensagens personalizadas do sistema Multivotos.
                    </li>
                  </ul>
                </div>

                <div>
                  <h5>6. Modificações do termo de uso:</h5>

                  <p>
                    A empresa Zelu Digital pode modificar o presente termo de
                    uso a qualquer momento, mediante publicação no site do
                    sistema Multivotos.
                  </p>
                </div>

                <div>
                  <h5>7. Lei aplicável:</h5>

                  <p>
                    O presente termo de uso será regido pela legislação
                    brasileira, incluindo a LGPD
                  </p>
                </div>

                <div>
                  <h5>8. Foro competente:</h5>

                  <p>
                    Para dirimir quaisquer controvérsias decorrentes do presente
                    termo de uso, as partes elegem o foro da Comarca de
                    Salvador/Bahia.
                  </p>
                </div>

                <div className="text-end">
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAcceptedTermsUse}
                  >
                    <i className="ri-check-line align-bottom" /> Aceito
                  </Button>

                  <Button
                    type="button"
                    color="outline-danger"
                    onClick={handleNotAcceptedTermsUse}
                    className="btn btn-outline-danger m-1"
                  >
                    <i className="ri-close-line align-bottom" /> Não aceito
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default TermsOfUse;
