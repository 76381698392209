import levels from '../../levels';

const fields = ({ form, currentImages }) => {
  return [
    [
      {
        type: 'radio',
        name: 'isActive',
        label: 'Situação',
        defaultValue: form.isActive,
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Ativo', value: true },
          { label: 'Inativo', value: false },
        ],
      },
    ],
    [
      {
        md: 6,
        name: 'group',
        type: 'select',
        label: 'Perfil',
        isRequired: true,
        options: [
          { label: 'Administrador', value: 'Administrador' },
          { label: 'Multiplicador', value: 'Multiplicador' },
        ],
      },
      {
        md: 6,
        type: 'file',
        name: 'photo',
        label: 'Foto',
        placeholder: '',
        accept: 'image/*',
        currentImage: currentImages && currentImages[0],
      },
    ],
    [
      {
        md: 6,
        name: 'level',
        type: 'select',
        label: 'Nível',
        isRequired: true,
        options: levels,
      },

      {
        md: 6,
        type: 'number',
        name: 'voterTarget',
        label: 'Meta de Eleitores',
        placeholder: 'Informe a meta de eleitores',
      },
    ],
  ];
};

export default fields;
