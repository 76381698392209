import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeSidebarVisibility } from '../../store/actions';
import ProfileDropdown from '../../components/ui/ProfileDropdown';
import createDynamicSelector from '../../components/common/createDynamicSelector';

const Header = ({ headerClass }) => {
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Layout', ['sidebarIsVisibility']);
  const { sidebarIsVisibility } = useSelector(selector);

  const toggleSidebar = () => {
    dispatch(changeSidebarVisibility(true));

    const smWindowSize = 767;
    const mdWindowSize = 1025;
    const sidebarSizekey = 'data-sidebar-size';
    const windowSize = document.documentElement.clientWidth;

    const hamburgerIcon = document.querySelector('.hamburger-icon');
    if (windowSize > smWindowSize) hamburgerIcon.classList.toggle('open');

    if (!sidebarIsVisibility) return;

    if (windowSize <= smWindowSize) {
      document.body.classList.add('vertical-sidebar-enable');
      document.documentElement.setAttribute(sidebarSizekey, 'lg');
    } else if (windowSize <= mdWindowSize) {
      document.body.classList.remove('vertical-sidebar-enable');
      document.documentElement.setAttribute(sidebarSizekey, 'sm');
    } else {
      const sidebarSize = document.documentElement.getAttribute(sidebarSizekey);
      const addNewSize = sidebarSize === 'lg' ? 'sm' : 'lg';

      document.body.classList.remove('vertical-sidebar-enable');
      document.documentElement.setAttribute(sidebarSizekey, addNewSize);
    }
  };

  return (
    <header id="page-topbar" className={headerClass}>
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={toggleSidebar}
              id="topnav-hamburger-icon"
              className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger border-none"
            >
              <span className="hamburger-icon">
                <span />
                <span />
                <span />
              </span>
            </button>
          </div>

          <div className="d-flex align-items-center gap-2">
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
