import { useSelector as handleUseSelector } from 'react-redux';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';
import phoneField from '../../../../../utils/fields/common/phoneField';
import emailField from '../../../../../utils/fields/common/emailField';

const satisfactionScaleOptions = [
  { label: 'Péssimo', value: 'Péssimo' },
  { label: 'Ruim', value: 'Ruim' },
  { label: 'Regular', value: 'Regular' },
  { label: 'Bom', value: 'Bom' },
  { label: 'Ótimo', value: 'Ótimo' },
];

const yesNoOptions = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

const fields = ({ form, onUpdateForm }) => {
  const selector = createDynamicSelector('Politicians', [
    'mayors',
    'councilors',
  ]);
  const selector2 = createDynamicSelector('GeneralFeature', [
    'interests',
    'tags',
  ]);
  const { mayors, councilors } = handleUseSelector(selector);
  const { interests, tags } = handleUseSelector(selector2);

  return {
    zeladoria: [
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.iluminacao,
          name: 'iluminacao',
          label: 'Qual sua avaliação da iluminação pública?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.saneamento,
          name: 'saneamento',
          label: 'Qual sua avaliação do saneamento básico?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.agua,
          name: 'agua',
          label: 'Qual sua avaliação do serviço de abastecimento de água?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.asfalto,
          name: 'asfalto',
          label: 'Qual sua avaliação do asfalto?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.limpeza,
          name: 'limpeza',
          label: 'Qual sua avaliação da limpeza das ruas?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.chuvas,
          name: 'chuvas',
          label: 'Como fica a rua em período de chuvas?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.lixo,
          name: 'lixo',
          label: 'Qual sua avaliação da coleta de lixo?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.arvores,
          name: 'arvores',
          label: 'Qual sua avaliação do serviço de poda de árvores?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.praca,
          name: 'praca',
          label: 'Qual sua avaliação das praças públicas?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'textarea',
          name: 'zeladoria_comentario',
          label:
            'Sugestão ou comentário adicional sobre a infraestrutura do município',
        },
      ],
    ],
    transporte: [
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.transporte_publico_uso,
          name: 'transporte_publico_uso',
          label: 'Você usa transporte público?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: yesNoOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.transporte_publico,
          name: 'transporte_publico',
          label: 'Qual sua avaliação do transporte público?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.onibus,
          name: 'onibus',
          label: 'Qual sua avaliação da oferta de ônibus?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.transito,
          name: 'transito',
          label: 'Qual sua avaliação do trânsito do município?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 12,
          type: 'textarea',
          name: 'transporte_comentario',
          label:
            'Sugestão ou comentário adicional sobre o transporte no municipio',
        },
      ],
    ],
    educacao: [
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.educacao_publica_uso,
          name: 'educacao_publica_uso',
          label: 'Você ou seus filhos estudam na rede municipal?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: yesNoOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.educacao_publica,
          name: 'educacao_publica',
          label: 'Qual sua avaliação da educação pública municipal?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.merenda,
          name: 'merenda',
          label: 'Qual sua avaliação da merenda nas escolas municipais?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.transporte_escolar,
          name: 'transporte_escolar',
          label: 'Qual sua avaliação do transporte escolar no município?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.creche,
          name: 'creche',
          label: 'Qual sua avaliação da oferta de creche?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'textarea',
          name: 'educacao_comentario',
          label: 'Sugestão ou comentário adicional sobre a educação municipio',
        },
      ],
    ],
    saude: [
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.saude_publica_uso,
          name: 'saude_publica_uso',
          label: 'Você depende do serviço de saúde pública?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: yesNoOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.saude_publica,
          name: 'saude_publica',
          label: 'Qual sua avaliação do serviço de saúde municipal?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.posto_saude,
          name: 'posto_saude',
          label: 'Qual sua avaliação do posto de saúde?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'radio',
          defaultValue: form.medicamento_continuo_uso,
          name: 'medicamento_continuo_uso',
          label: 'Você faz uso de algum medicamento de uso contínuo?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: yesNoOptions,
        },
      ],
      [
        {
          md: 6,
          type: 'radio',
          defaultValue: form.medicamento,
          name: 'medicamento',
          label:
            'Qual sua avaliação da disponibilidade de medicamento gratuito no municipio?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: satisfactionScaleOptions,
        },
        {
          md: 6,
          type: 'textarea',
          name: 'saude_publica_comentario',
          label:
            'Sugestão ou comentário adicional sobre a saúde pública do municipio',
        },
      ],
    ],
    intencaoVoto: [
      [
        {
          md: 6,
          type: 'select',
          name: 'prefeito',
          label: 'Se as eleições fossem hoje, quem você votaria para prefeito?',
          isRequired: false,
          required: false,
          options: mayors.map((mayor) => {
            return {
              label: mayor.nome,
              value: mayor.id,
            };
          }),
        },
        {
          md: 6,
          type: 'select',
          name: 'vereador',
          label: 'Se as eleições fossem hoje, quem você votaria para vereador?',
          isRequired: false,
          required: false,
          options: councilors.map((councilor) => {
            return {
              label: councilor.nome,
              value: councilor.id,
            };
          }),
        },
      ],
      [
        {
          md: 12,
          type: 'textarea',
          name: 'comentario',
          label:
            'Tem alguma sugestão ou comentário sobre um assunto importante que não foi abordado nesta pesquisa?',
        },
      ],
    ],
    personalInformation: [
      [
        {
          md: 4,
          type: 'text',
          name: 'nome',
          label: 'Nome',
          placeholder: 'Nome',
          isRequired: true,
        },
        {
          md: 4,
          type: 'text',
          isRequired: true,
          name: 'sobrenome',
          label: 'Sobrenome',
          placeholder: 'Sobrenome',
        },
        {
          md: 4,
          name: 'preferredContactMethod',
          type: 'select',
          isRequired: true,
          label: 'Melhor meio de contato',
          options: [
            { label: 'E-mail', value: 'Email' },
            { label: 'WhatsApp', value: 'WhatsApp' },
          ],
        },
      ],
      [
        ...[phoneField({ form, onUpdateForm, isRequired: false, md: 4 })],
        {
          md: 4,
          type: 'radio',
          defaultValue: null,
          name: 'isPhoneWhatsApp',
          label: 'Telefone é WhatsApp?',
          className: 'd-flex gap-2 mb-1',
          radiosFields: [
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ],
        },
        {
          md: 4,
          type: 'radio',
          name: 'isContact',
          defaultValue: null,
          label: 'Autoriza contactar? ',
          className: 'd-flex gap-2 mb-1',
          radiosFields: [
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ],
        },
      ],
      [
        ...[emailField({ isUpdate: false, isRequired: false, md: 4 })],
        {
          md: 4,
          isMulti: true,
          type: 'select',
          required: false,
          name: 'interests',
          label: 'Interesses',
          options: interests.map(({ id: value, nome: label }) => ({
            value,
            label,
          })),
        },
        {
          md: 4,
          name: 'tags',
          type: 'select',
          isMulti: true,
          required: false,
          label: 'Etiquetas',
          options: tags.map(({ id: value, nome: label }) => ({
            value,
            label,
          })),
        },
      ],
    ],
    lgpdNotice: [
      [
        {
          md: 10,
          type: 'checkbox',
          isRequired: true,
          label:
            'Eu autorizo o uso dos meus dados pessoais para fins de cadastro eleitoral, conforme a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018).',
          name: 'acceptTerms',
          className: 'checkbox-spacing',
        },
      ],
    ],
  };
};

export default fields;
