import withRouter from '../../../components/common/withRouter';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';
import ActionsDropdown from '../../../components/ui/ActionsDropdown';
import StatusItem from '../../../components/ui/StatusItem';
import {
  managementImportWhatsAppSlots,
  managementListWhatsAppSlots,
} from '../../../store/actions';
import headerTitles from './steps/headerTitles';
import generateActions from './steps/actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import ConfirmationModal from '../../../components/ui/ConfirmationModal';
import { Button } from 'reactstrap';

const ListingWhatsAppSlots = () => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    body: '',
    isOpen: false,
    title: '',
    confirmText: '',
    onConfirm: () => {},
    confirmButtonDisabled: true,
    hideConfirmButton: false,
    size: '',
  });

  const selectorOptions = ['isLoading', 'whatsappSlots'];
  const [
    name,
    outputNetwork,
    number,
    status,
    email,
    city,
    candidateName,
    situation,
    actions,
  ] = headerTitles;

  const handleData = (slots) => {
    return slots.map((data) => {
      return {
        [name]: data.nome || '--',
        [outputNetwork]: data.redeSaida || '--',
        [number]: data.numero || '--',
        [status]: data.status || '--',
        [email]: data.conta && data.conta.email ? data.conta.email : '--',
        [city]:
          data.conta && data.conta.municipio
            ? data.conta.municipio.nome +
              ' - ' +
              data.conta.municipio.siglaEstado
            : '--',
        [candidateName]: (data.conta && data.conta.nomeCandidato) || '--',
        [situation]: (
          <StatusItem
            status={
              data.conta
                ? data.conta.bloqueada !== null
                  ? !data.conta.bloqueada
                  : null
                : null
            }
            labelTrue="Ativa"
            labelFalse="Bloqueada"
            labelEmpty="Idefinida"
            checkForEmpty
          />
        ),
        [actions]: (
          <ActionsDropdown
            actions={generateActions(data, dispatch, setModalData)}
          />
        ),
      };
    });
  };

  return (
    <>
      <ConfirmationModal
        body={modalData.body}
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title={modalData.title}
        confirmText={modalData.confirmText}
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        confirmButtonDisabled={modalData.confirmButtonDisabled}
        hideConfirmButton={modalData.hideConfirmButton}
        size={modalData.size}
      />

      <ListingPrototype
        handleData={handleData}
        entityName={'Management'}
        headerTitles={headerTitles}
        title={'Administração - Slots Whatsapp'}
        dataSelector={managementListWhatsAppSlots}
        selectorOptions={selectorOptions}
        isShowButtonCreateNewItem={false}
        emptyMessage={'Oops, sem slots para exibir.'}
        extraHeaderContent={
          <>
            <Button
              color="primary"
              onClick={() => dispatch(managementImportWhatsAppSlots())}
            >
              Importar e atualizar slots
            </Button>
          </>
        }
      />
    </>
  );
};

export default withRouter(ListingWhatsAppSlots);
