const iconsName = {
  cake: 'bx bx-cake',
  user: 'bx bxs-user',
  chat: 'bx bxs-chat',
  edit: 'bx bxs-edit',
  bill: 'ri-bill-line',
  more: 'ri-more-fill',
  phone: 'bx bxs-phone',
  group: 'bx bxs-group',
  layer: 'bx bxs-layer',
  search: 'bx bxs-search',
  pencil: 'ri-pencil-fill',
  reload: 'mdi mdi-reload',
  lockAlt: 'bx bxs-lock-alt',
  business: 'bx bxs-business',
  filter: 'bx bxs-filter-alt',
  envelope: 'bx bxs-envelope',
  delete: 'ri-delete-bin-fill',
  whatsapp: 'mdi mdi-whatsapp',
  whatsappFill: 'ri-whatsapp-fill',
  dashboard: 'bx bxs-dashboard',
  upArrow: 'mdi mdi-chevron-up',
  arrowBack: 'mdi mdi-arrow-left',
  mapMarker: 'mdi mdi-map-marker',
  plusCircle: 'bx bxs-plus-circle',
  downArrow: 'mdi mdi-chevron-down',
  account: 'mdi mdi-account-circle',
  checkCircle: 'bx bxs-check-circle',
  minusCircle: 'bx bxs-minus-circle',
  chevronLeft: 'bx bxs-chevron-left',
  dolarCircle: 'bx bxs-dollar-circle',
  lockOpenAlt: 'bx bxs-lock-open-alt',
  chevronRight: 'bx bxs-chevron-right',
  collapseVertical: 'mdi mdi-unfold-more-horizontal',
  chatDots: 'bx bxs-message-dots',
  list: 'bx bx-list-ul',
  send: 'ri-send-plane-fill',
  filter2: 'ri-filter-2-fill',
  medal: 'bx bxs-medal',
  star: 'bx bx-star',
  starHalfFill: 'bx bxs-star-half',
  starFill: 'bx bxs-star',
  userChat: 'bx bxs-user-pin',
  plus: 'bx bx-plus',
  envelopeSend: 'mdi mdi-email-fast',
  download: 'bx bxs-download',
  bus: 'bx bxs-bus',
  school: 'bx bxs-school',
  health: 'bx bxs-first-aid',
  city: 'bx bxs-building-house',
  alertCircle: 'mdi mdi-alert-circle',
  qrCode: 'bx bx-qr-scan',
  linkAccount: 'bx bx-user-plus',
  unlink: 'las la-unlink',
  exit: 'las la-sign-out-alt',
};

export default iconsName;
