import { Form as FormFormik, Formik } from 'formik';
import { forwardRef } from 'react';
import { Row } from 'reactstrap';
import Icon from '../../ui/Icon';
import RenderField from './components/RenderField';
import { Button } from './styles';

const FormFilterPrototype = (
  { fields, onSubmit, initialValues, onClearFilter, validationSchema },
  ref
) => {
  return (
    <div className="border mb-3">
      <div className="p-3">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <FormFormik ref={ref}>
            <Row>
              <RenderField fields={fields} />
            </Row>

            <div className="d-flex justify-content-end gap-2">
              <Button className="btn btn-primary" type="submit">
                <Icon name="filter" size={1} />
                Filtrar
              </Button>

              <Button color="light" type="button" onClick={onClearFilter}>
                <Icon name="reload" size={1} />
                Mostrar tudo
              </Button>
            </div>
          </FormFormik>
        </Formik>
      </div>
    </div>
  );
};

export default forwardRef(FormFilterPrototype);
