import * as Yup from 'yup';
import phoneMask from '../../../utils/masks/phoneMask';
import phoneRegex from '../../../utils/regexs/phoneRegex';

const validationPhone = (isOptional = false) => {
  const phoneSchema = Yup.string().test(
    'is-phone-valid',
    'Número de telafone inválido',
    (value = '') => {
      if (value.length === 0) return true;

      const phone = phoneMask(value).replace(' ', '');
      return phoneRegex.test(phone);
    }
  );

  return {
    phone: isOptional
      ? phoneSchema.nullable()
      : phoneSchema.required('Informe seu telefone'),
  };
};

export default validationPhone;
