import formatValue from '../formatValue';
import handleCreatePersonObject from '../../../../utils/transformsValues/handleCreatePersonObject';

const handleDataSubmit = ({ data }) => {
  const pessoa = handleCreatePersonObject(data);

  const isTag = !!data.tags?.length > 0;
  const tags = isTag ? data.tags.map(({ value }) => ({ id: value })) : null;

  const isInterests = data.interests?.length > 0;
  const interests = isInterests
    ? data.interests.map(({ value }) => ({ id: value }))
    : null;

  const regiao = data.region ? { id: data.region?.value } : null;
  const engajamento = data.engagement ? data.engagement.value : null;
  const religiao = data.religion ? { id: data.religion?.value } : null;
  const escolaridade = data.schooling ? { id: data.schooling?.value } : null;

  const melhorMeioContato = data.preferredContactMethod.value;

  const formattedData = {
    pessoa,
    regiao,
    religiao,
    engajamento,
    escolaridade,
    etiquetas: tags,
    email: data.email || null,
    telefone: data.phone || null,
    votante: data.isVoter,
    interesses: interests,
    contactar: data.isContact,
    titulo: data.title || null,
    filhos: data.children || 0,
    whatsApp: data.isPhoneWhatsApp,
    renda: formatValue(data.income),
    instagram: data?.instagram || null,
    etnia: data.ethnicity?.label || null,
    mensagem: data.message || null,
    colegio: data.electoralCollege || null,
    zona: data.electoralZone || null,
    grupo: data.group || null,
    profissao: data.profession || null,
    melhorMeioContato,
  };

  return formattedData;
};

export default handleDataSubmit;
