import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function ConfirmationModal({
  isOpen,
  title,
  body,
  onConfirm,
  toggle,
  confirmButtonDisabled = false,
  hideConfirmButton = false,
  size = '',
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
}) {
  const clickConfirm = () => {
    onConfirm();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size={size}>
      <ModalHeader className="color-primary" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color="light">
          {cancelText}
        </Button>
        {!hideConfirmButton && (
          <Button
            onClick={clickConfirm}
            color="primary"
            disabled={confirmButtonDisabled}
          >
            {confirmText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationModal;
