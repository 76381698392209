import {
  MANAGEMENT_DISCONNECT_WHATSAPP_SLOT,
  MANAGEMENT_ERROR,
  MANAGEMENT_FREE_WHATSAPP_SLOT,
  MANAGEMENT_IMPORT_WHATSAPP_SLOTS,
  MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT,
  MANAGEMENT_LIST_ACCOUNTS,
  MANAGEMENT_LIST_PLANS,
  MANAGEMENT_LIST_ROOT_USERS,
  MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS,
  MANAGEMENT_LIST_WHATSAPP_SLOTS,
  MANAGEMENT_SUCCESS,
} from './actionTypes';

export const managementListAccounts = () => {
  return {
    type: MANAGEMENT_LIST_ACCOUNTS,
    payload: {},
  };
};

export const managementListRootUsers = () => {
  return {
    type: MANAGEMENT_LIST_ROOT_USERS,
    payload: {},
  };
};

export const managementListPlans = () => {
  return {
    type: MANAGEMENT_LIST_PLANS,
    payload: {},
  };
};

export const managementListWhatsAppSlots = () => {
  return {
    type: MANAGEMENT_LIST_WHATSAPP_SLOTS,
    payload: {},
  };
};

export const managementFreeWhatsAppSlot = (payload) => {
  return {
    type: MANAGEMENT_FREE_WHATSAPP_SLOT,
    payload,
  };
};

export const managementDisconnectWhatsAppSlot = (payload) => {
  return {
    type: MANAGEMENT_DISCONNECT_WHATSAPP_SLOT,
    payload,
  };
};

export const managementListSlotFreeAccounts = () => {
  return {
    type: MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS,
    payload: {},
  };
};

export const managementLinkAccountToWhatsAppSlot = (payload) => {
  return {
    type: MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT,
    payload,
  };
};

export const managementImportWhatsAppSlots = () => {
  return {
    type: MANAGEMENT_IMPORT_WHATSAPP_SLOTS,
    payload: {},
  };
};

export const managementError = () => {
  return {
    type: MANAGEMENT_ERROR,
    payload: {},
  };
};

export const managementSuccess = (payload) => {
  return {
    type: MANAGEMENT_SUCCESS,
    payload,
  };
};
