import { Link } from 'react-router-dom';
import { IconContaner } from './styles';
import React, { useEffect } from 'react';
import Icon from '../../../components/ui/Icon';
import { Col, Container, Row } from 'reactstrap';
import error from '../../../assets/images/error.svg';
import ParticlesAuth from '../../AuthenticationInner/ParticlesAuth';

const NotFound = () => {
  useEffect(() => {
    document.title = 'Página não encontrada';
  }, []);

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center pt-4">
                <div>
                  <img src={error} className="error-basic-img move-animation" />
                </div>

                <div className="mt-n4">
                  <h1 className="display-1 fw-medium">404</h1>

                  <h3 className="text-uppercase">
                    Desculpe, página não encontrada 😭
                  </h3>

                  <p className="text-muted mb-4">
                    A página que você procura não está disponível!
                  </p>

                  <Link to="/home" className="btn btn-primary">
                    <IconContaner>
                      <Icon className="me-1" name="home" size={1} />
                      Voltar para Home
                    </IconContaner>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default NotFound;
