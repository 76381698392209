import {
  CREATE_INTEREST,
  CREATE_REGION,
  CREATE_TAG,
  DELETE_INTEREST,
  DELETE_REGION,
  DELETE_TAG,
  GENERAL_FEATURE_ERROR,
  GENERAL_FEATURE_SUCCESS,
  GET_AUTOREGISTER_INTERESTS,
  GET_AUTOREGISTER_REGIONS,
  GET_AUTOREGISTER_RELIGIONS,
  GET_AUTOREGISTER_SCHOOLINGS,
  GET_AUTOREGISTER_SEXUAL_ORIENTATIONS,
  GET_INTERESTS,
  GET_MUNICIPALITIES_BY_STATE,
  GET_PARTIES,
  GET_PLANS,
  GET_POSITIONS,
  GET_REGIONS,
  GET_RELIGIONS,
  GET_SCHOOLINGS,
  GET_SEXUAL_ORIENTATIONS,
  GET_STATES,
  GET_TAGS,
  UPDATE_FROM_WITH_ADDRESS_FROM_API,
} from './actionTypes';

export const getTags = () => {
  return {
    type: GET_TAGS,
    payload: {},
  };
};

export const createTag = (payload) => {
  return {
    type: CREATE_TAG,
    payload,
  };
};

export const deleteTag = (payload) => {
  return {
    type: DELETE_TAG,
    payload,
  };
};

export const getPlans = () => {
  return {
    type: GET_PLANS,
    payload: {},
  };
};

export const getStates = () => {
  return {
    type: GET_STATES,
    payload: {},
  };
};

export const getRegions = () => {
  return {
    type: GET_REGIONS,
    payload: {},
  };
};

export const createRegion = (payload) => {
  return {
    type: CREATE_REGION,
    payload,
  };
};

export const deleteRegion = (payload) => {
  return {
    type: DELETE_REGION,
    payload,
  };
};

export const getAutoregisterRegions = (payload) => {
  return {
    type: GET_AUTOREGISTER_REGIONS,
    payload,
  };
};

export const getParties = () => {
  return {
    type: GET_PARTIES,
    payload: {},
  };
};

export const getPositions = () => {
  return {
    type: GET_POSITIONS,
    payload: {},
  };
};

export const getReligions = () => {
  return {
    type: GET_RELIGIONS,
    payload: {},
  };
};

export const getAutoregisterReligions = (payload) => {
  return {
    type: GET_AUTOREGISTER_RELIGIONS,
    payload,
  };
};

export const getInterests = () => {
  return {
    type: GET_INTERESTS,
    payload: {},
  };
};

export const createInterest = (payload) => {
  return {
    type: CREATE_INTEREST,
    payload,
  };
};

export const deleteInterest = (payload) => {
  return {
    type: DELETE_INTEREST,
    payload,
  };
};

export const getAutoregisterInterests = (payload) => {
  return {
    type: GET_AUTOREGISTER_INTERESTS,
    payload,
  };
};

export const getSchoolings = () => {
  return {
    type: GET_SCHOOLINGS,
    payload: {},
  };
};

export const getAutoregisterSchoolings = (payload) => {
  return {
    type: GET_AUTOREGISTER_SCHOOLINGS,
    payload,
  };
};

export const generalFeatureError = () => {
  return {
    type: GENERAL_FEATURE_ERROR,
    payload: {},
  };
};

export const getSexualOrientations = () => {
  return {
    type: GET_SEXUAL_ORIENTATIONS,
    payload: {},
  };
};

export const getAutoregisterSexualOrientations = (payload) => {
  return {
    type: GET_AUTOREGISTER_SEXUAL_ORIENTATIONS,
    payload,
  };
};

export const generalFeatureSuccess = (payload) => {
  return {
    type: GENERAL_FEATURE_SUCCESS,
    payload,
  };
};

export const updateFormWithAddressFromAPI = (payload) => {
  return {
    type: UPDATE_FROM_WITH_ADDRESS_FROM_API,
    payload,
  };
};

export const getMunicipalitiesByState = (stateAcronym) => {
  return {
    type: GET_MUNICIPALITIES_BY_STATE,
    payload: { stateAcronym },
  };
};
