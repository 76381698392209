import {
  GET_REPORT_BY_CATEGORY,
  REPORTS_ERROR,
  REPORTS_SUCCESS,
} from './actionTypes';

const initialState = {
  isLoading: false,
  reportsTags: [],
  reportsAges: [],
  reportsGenre: [],
  reportsRegion: [],
  reportsReligion: [],
  reportsEthnicity: [],
  reportsInterests: [],
  reportsOrientation: [],
  reportsMultipliers: [],
  reportsEngagement: [],
  reportsEducation: [],
  reportsIncome: [],
  reportsElectoralCollege: [],
  candidatePerformanceReports: [],
  multiplierPerformanceReports: [],
  reportsResearchWater: [],
  reportsResearchAsphalt: [],
  reportsResearchDaycare: [],
  reportsResearchEducation: [],
  reportsResearchIlumination: [],
  reportsResearchBus: [],
  reportsResearchHealthCenter: [],
  reportsResearchMayor: [],
  reportsResearchHealh: [],
  reportsResearchTraffic: [],
  reportsResearchTransport: [],
  reportsResearchCouncilors: [],
  reportsPerformanceMultipliers: [],
};

const reports = (state = initialState, { type, payload }) => {
  const {
    reportsTags = state.reportsTags,
    reportsAges = state.reportsAges,
    reportsGenre = state.reportsGenre,
    reportsRegion = state.reportsRegion,
    reportsReligion = state.reportsReligion,
    reportsEthnicity = state.reportsEthnicity,
    reportsInterests = state.reportsInterests,
    reportsOrientation = state.reportsOrientation,
    reportsMultipliers = state.reportsMultipliers,
    reportsEngagement = state.reportsEngagement,
    reportsEducation = state.reportsEducation,
    reportsIncome = state.reportsIncome,
    reportsElectoralCollege = state.reportsElectoralCollege,
    candidatePerformanceReports = state.candidatePerformanceReports,
    multiplierPerformanceReports = state.candidatePerformanceReports,
    reportsResearchWater = state.reportsResearchWater,
    reportsResearchAsphalt = state.reportsResearchAsphalt,
    reportsResearchDaycare = state.reportsResearchDaycare,
    reportsResearchEducation = state.reportsResearchEducation,
    reportsResearchIlumination = state.reportsResearchIlumination,
    reportsResearchBus = state.reportsResearchBus,
    reportsResearchHealthCenter = state.reportsResearchHealthCenter,
    reportsResearchMayor = state.reportsResearchMayor,
    reportsResearchHealh = state.reportsResearchHealh,
    reportsResearchTraffic = state.reportsResearchTraffic,
    reportsResearchTransport = state.reportsResearchTransport,
    reportsResearchCouncilors = state.reportsResearchCouncilors,
    reportsPerformanceMultipliers = state.reportsPerformanceMultipliers,
  } = payload ?? {};

  switch (type) {
    case GET_REPORT_BY_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };

    case REPORTS_ERROR:
    case REPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportsTags,
        reportsAges,
        reportsGenre,
        reportsRegion,
        reportsReligion,
        reportsEthnicity,
        reportsInterests,
        reportsOrientation,
        reportsMultipliers,
        reportsEngagement,
        reportsEducation,
        reportsIncome,
        reportsElectoralCollege,
        candidatePerformanceReports,
        multiplierPerformanceReports,
        reportsResearchWater,
        reportsResearchAsphalt,
        reportsResearchDaycare,
        reportsResearchEducation,
        reportsResearchIlumination,
        reportsResearchBus,
        reportsResearchHealthCenter,
        reportsResearchMayor,
        reportsResearchHealh,
        reportsResearchTraffic,
        reportsResearchTransport,
        reportsResearchCouncilors,
        reportsPerformanceMultipliers,
      };

    default:
      return { ...state };
  }
};

export default reports;
