import {
  ACCOUNTS_ERROR,
  CREATE_ACCOUNT,
  ACCOUNTS_SUCCESS,
} from './actionTypes';

export const createAccount = (payload) => {
  return {
    type: CREATE_ACCOUNT,
    payload,
  };
};

export const accountsError = () => {
  return {
    type: ACCOUNTS_ERROR,
    payload: {},
  };
};

export const accountsSuccess = () => {
  return {
    type: ACCOUNTS_SUCCESS,
    payload: {},
  };
};
