export const GET_TAGS = 'GET_TAGS';
export const CREATE_TAG = 'CREATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const GET_PLANS = 'GET_PLANS';
export const GET_STATES = 'GET_STATES';
export const GET_PARTIES = 'GET_PARTIES';
export const GET_REGIONS = 'GET_REGIONS';
export const CREATE_REGION = 'CREATE_REGION';
export const DELETE_REGION = 'DELETE_REGION';
export const GET_AUTOREGISTER_REGIONS = 'GET_AUTOREGISTER_REGIONS';
export const GET_RELIGIONS = 'GET_RELIGIONS';
export const GET_AUTOREGISTER_RELIGIONS = 'GET_AUTOREGISTER_RELIGIONS';
export const GET_INTERESTS = 'GET_INTERESTS';
export const CREATE_INTEREST = 'CREATE_INTEREST';
export const DELETE_INTEREST = 'DELETE_INTEREST';
export const GET_AUTOREGISTER_INTERESTS = 'GET_AUTOREGISTER_INTERESTS';
export const GET_POSITIONS = 'GET_POSITIONS';
export const GET_SCHOOLINGS = 'GET_SCHOOLINGS';
export const GET_AUTOREGISTER_SCHOOLINGS = 'GET_AUTOREGISTER_SCHOOLINGS';
export const GENERAL_FEATURE_ERROR = 'GENERAL_FEATURE_ERROR';
export const GET_SEXUAL_ORIENTATIONS = 'GET_SEXUAL_ORIENTATIONS';
export const GET_AUTOREGISTER_SEXUAL_ORIENTATIONS =
  'GET_AUTOREGISTER_SEXUAL_ORIENTATIONS';
export const GENERAL_FEATURE_SUCCESS = 'GENERAL_FEATURE_SUCCESS';
export const GET_MUNICIPALITIES_BY_STATE = 'GET_MUNICIPALITIES_BY_STATE';
export const UPDATE_FROM_WITH_ADDRESS_FROM_API =
  'UPDATE_FROM_WITH_ADDRESS_FROM_API';
