import {
  managementDisconnectWhatsAppSlot,
  managementFreeWhatsAppSlot,
} from '../../../../../store/actions';
import LisitngFreeSlotAccounts from '../../components/ListingFreeSlotAccounts';

const actions = (data, dispatch, setModalData) => [
  {
    id: 1,
    title: 'Liberar slot',
    tag: 'button',
    icon: 'exit',
    text: 'Liberar slot',
    showCondition: () => data.status == 'OCIOSA' || data.status == 'QR_CODE',
    attributes: {
      onClick: () => {
        const newModalData = {
          isOpen: true,
          body: `Tem certeza que deseja liberar o slot?`,
          title: 'Liberar slot',
          confirmText: 'Liberar',
          confirmButtonDisabled: false,
          hideConfirmButton: false,
          size: '',
          onConfirm: () => {
            dispatch(managementFreeWhatsAppSlot({ uuid: data.uuid }));
          },
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      },
    },
  },
  {
    id: 2,
    title: 'Desconectar slot',
    tag: 'button',
    icon: 'unlink',
    text: 'Desconectar slot',
    showCondition: () => data.status == 'ATIVA',
    attributes: {
      onClick: () => {
        const newModalData = {
          isOpen: true,
          body: `Tem certeza que deseja desconectar o slot?`,
          title: 'Desconectar slot',
          confirmText: 'Desconectar',
          confirmButtonDisabled: false,
          hideConfirmButton: false,
          size: '',
          onConfirm: () => {
            dispatch(managementDisconnectWhatsAppSlot({ uuid: data.uuid }));
          },
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      },
    },
  },
  {
    id: 3,
    title: 'Vincular conta',
    tag: 'button',
    icon: 'linkAccount',
    text: 'Vincular conta',
    showCondition: () => data.status == 'OCIOSA',
    attributes: {
      onClick: () => {
        const newModalData = {
          isOpen: true,
          body: (
            <LisitngFreeSlotAccounts
              uuid={data.uuid}
              setOuterModalData={setModalData}
            />
          ),
          title: 'Vincular conta',
          confirmText: 'Vincular',
          size: 'lg',
          confirmButtonDisabled: true,
          hideConfirmButton: true,
          onConfirm: () => {},
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      },
    },
  },
];

export default actions;
