import { useSelector as handleUseSelector } from 'react-redux';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';

const fields = (currentImages) => {
  const selector = createDynamicSelector('GeneralFeature', [
    'parties',
    'positions',
  ]);

  const { parties, positions } = handleUseSelector(selector);

  return [
    [
      {
        md: 4,
        type: 'select',
        label: 'Cargo',
        name: 'position',
        isRequired: true,
        options: positions.map(({ id, descricao }) => ({
          value: id,
          label: descricao,
        })),
      },
      {
        md: 4,
        name: 'party',
        type: 'select',
        label: 'Partido',
        isRequired: true,
        options: parties.map(({ id, nome }) => ({
          value: id,
          label: nome,
        })),
      },
      {
        md: 4,
        type: 'number',
        name: 'voterTarget',
        label: 'Meta de Eleitores',
        placeholder: 'Informe a meta de eleitores',
      },
    ],
    [
      {
        md: 4,
        type: 'number',
        name: 'electionYear',
        label: 'Ano da Eleição',
        placeholder: 'Informe o ano da eleição',
      },
      {
        md: 4,
        type: 'number',
        label: 'Número',
        name: 'candidateNumber',
        placeholder: 'Informe o número do(a) candidato(a)',
      },
      {
        md: 4,
        type: 'url',
        name: 'website',
        label: 'Website',
        placeholder: 'Informe o website',
      },
    ],
    [
      {
        md: 6,
        type: 'text',
        isRequired: true,
        name: 'campaignName',
        label: 'Nome de Campanha',
        placeholder: 'Informe o nome de campanha',
      },
      {
        md: 6,
        type: 'url',
        name: 'jingle',
        label: 'Jingle',
        placeholder: 'Informe o link do jingle',
      },
      {
        md: 12,
        type: 'file',
        name: 'photo',
        label: 'Foto',
        accept: 'image/*',
        currentImage: currentImages[0],
      },
    ],
    [
      {
        md: 12,
        type: 'textarea',
        name: 'curriculum',
        label: 'Currículo',
        placeholder: 'Uma breve descrição do currículo',
      },
    ],
  ];
};

export default fields;
