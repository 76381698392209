import { Label as LabelReactstrap } from 'reactstrap';

export default function Label(props) {
  const { children } = props;

  if (!children) return null;

  return (
    <LabelReactstrap className="form-label" {...props}>
      {children}
    </LabelReactstrap>
  );
}
