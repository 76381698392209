import React from 'react';
import { Navigate } from 'react-router-dom';

import Home from '../../pages/Home';

import Profile from '../../pages/Profile';

import VotersDashboard from '../../pages/Dashboard/Voters';

import Birthdays from '../../pages/Birthdays';

import NotFound from '../../pages/Errors/NotFound';

import ForgetPassword from '../../pages/Authentication/ForgetPassword';
import Login from '../../pages/Authentication/Login';
import Logout from '../../pages/Authentication/Logout';
import Register from '../../pages/Authentication/Register';
import UpdatePassword from '../../pages/Authentication/UpdatePassword';

import CreateNewVoter from '../../pages/Voters/CreateNewVoter';
import ListingVoters from '../../pages/Voters/ListingVoters';
import UpdateVoter from '../../pages/Voters/UpdateVoter';

import CreateNewTemplate from '../../pages/Templates/CreateNewTemplate';
import ListingTemplates from '../../pages/Templates/ListingTemplates';
import UpdateTemplate from '../../pages/Templates/UpdateTemplate';

import CreateNewCandidate from '../../pages/Candidates/CreateNewCandidate';
import ListingCandidates from '../../pages/Candidates/ListingCandidates';
import UpdateCandidate from '../../pages/Candidates/UpdateCandidate';

import CreateNewMultiplier from '../../pages/Multipliers/CreateNewMultiplier';
import ListingMultipliers from '../../pages/Multipliers/ListingMultipliers';
import UpdateMultiplier from '../../pages/Multipliers/UpdateMultiplier';

import ListingMessages from '../../pages/Messages/ListingMessages';
import Interests from '../../pages/Settings/Interests';
import Tags from '../../pages/Settings/Tags';
import Regions from '../../pages/Settings/Regions';
import SelfRegister from '../../pages/Voters/SelfRegister';
import CreateListSystemParam from '../../pages/Settings/SystemParams/CreateListSystemParam';
import UpdateSystemParam from '../../pages/Settings/SystemParams/UpdateSystemParam';
import Politicians from '../../pages/Settings/Politicians';
import CreateMunicipalResearch from '../../pages/Voters/CreateMunicipalResearch';
import ResearchDashboard from '../../pages/Dashboard/Research';
import PerformanceDashboard from '../../pages/Dashboard/Performance';
import ListingAccounts from '../../pages/Admin/ListingAccounts';
import ListingRootUsers from '../../pages/Admin/ListingRootUsers';
import ListingPlans from '../../pages/Admin/ListingPlans';
import ListingWhatsAppSlots from '../../pages/Admin/ListingWhatsAppSlots';
import WhatsAppSettings from '../../pages/Settings/WhatsApp';
import ImportDataSettings from '../../pages/Settings/ImportData';
import CreateMunicipalResearchNotRegistered from '../../pages/Voters/CreateMunicipalResearchNotRegistered';

const rootProtectedRoutes = [
  {
    isRoot: true,
    component: <ListingAccounts />,
    path: '/admin/accounts',
  },
  {
    isRoot: true,
    component: <ListingRootUsers />,
    path: '/admin/root-users',
  },
  {
    isRoot: true,
    component: <ListingPlans />,
    path: '/admin/plans',
  },
  {
    isRoot: true,
    component: <ListingWhatsAppSlots />,
    path: '/admin/whatsapp-slots',
  },
];

const authProtectedRoutes = [
  { path: '/profile', component: <Profile />, isNotAdmin: true },

  { path: '/birthdays', component: <Birthdays />, isNotAdmin: true },

  {
    path: '/voters',
    isNotAdmin: true,
    component: <ListingVoters />,
  },
  {
    isNotAdmin: true,
    path: '/voters/new',
    component: <CreateNewVoter />,
  },
  {
    isNotAdmin: true,
    component: <UpdateVoter />,
    path: '/voters/update/:voterId',
  },
  {
    isNotAdmin: true,
    component: <CreateMunicipalResearchNotRegistered />,
    path: '/voters/municipal-research/no-registered',
  },
  {
    isNotAdmin: true,
    component: <CreateMunicipalResearch />,
    path: '/voters/municipal-research/:voterId',
  },

  { path: '/home', component: <Home />, isAdmin: true },

  { path: '/dashboard/voters', component: <VotersDashboard />, isAdmin: true },
  {
    path: '/dashboard/research',
    component: <ResearchDashboard />,
    isAdmin: true,
  },
  {
    path: '/dashboard/performance',
    component: <PerformanceDashboard />,
    isAdmin: true,
  },

  { path: '/templates', component: <ListingTemplates />, isAdmin: true },
  { path: '/templates/new', component: <CreateNewTemplate />, isAdmin: true },
  {
    isAdmin: true,
    component: <UpdateTemplate />,
    path: '/templates/update/:templateId',
  },

  { path: '/messages', component: <ListingMessages />, isAdmin: true },

  { path: '/candidates', component: <ListingCandidates />, isAdmin: true },
  { path: '/candidates/new', component: <CreateNewCandidate />, isAdmin: true },
  {
    isAdmin: true,
    component: <UpdateCandidate />,
    path: '/candidates/update/:candidateId',
  },

  { path: '/multipliers', component: <ListingMultipliers />, isAdmin: true },
  {
    isAdmin: true,
    path: '/multipliers/new',
    component: <CreateNewMultiplier />,
  },
  {
    isAdmin: true,
    component: <UpdateMultiplier />,
    path: '/multipliers/update/:multiplierId',
  },

  {
    isAdmin: true,
    component: <Regions />,
    path: '/settings/regions',
  },
  {
    isAdmin: true,
    component: <Interests />,
    path: '/settings/interests',
  },
  {
    isAdmin: true,
    component: <Tags />,
    path: '/settings/tags',
  },
  {
    isAdmin: true,
    component: <CreateListSystemParam />,
    path: '/settings/system-params',
  },
  {
    isAdmin: true,
    component: <UpdateSystemParam />,
    path: '/settings/system-params/update',
  },
  {
    isAdmin: true,
    component: <Politicians />,
    path: '/settings/politicians',
  },
  {
    isAdmin: true,
    component: <WhatsAppSettings />,
    path: '/settings/whatsapp',
  },
  {
    isAdmin: true,
    component: <ImportDataSettings />,
    path: '/settings/import-data',
  },
];

const publicRoutes = [
  { path: '/login', component: <Login /> },
  { path: '/logout', component: <Logout /> },
  { path: '/register', component: <Register /> },
  { path: '/self-registration/:autoRegisterCode', component: <SelfRegister /> },
  { path: '/not-found', component: <NotFound /> },
  { path: '/reset/:code', component: <UpdatePassword /> },
  { path: '/forgot-password', component: <ForgetPassword /> },

  { path: '/', component: <Navigate to="/login" /> },
  { path: '*', component: <Navigate to="/not-found" /> },
];

export { authProtectedRoutes, publicRoutes, rootProtectedRoutes };
