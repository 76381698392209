import axios from 'axios';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import {
  CREATE_INTEREST,
  CREATE_REGION,
  CREATE_TAG,
  DELETE_INTEREST,
  DELETE_REGION,
  DELETE_TAG,
  GET_AUTOREGISTER_INTERESTS,
  GET_AUTOREGISTER_REGIONS,
  GET_AUTOREGISTER_RELIGIONS,
  GET_AUTOREGISTER_SCHOOLINGS,
  GET_AUTOREGISTER_SEXUAL_ORIENTATIONS,
  GET_INTERESTS,
  GET_MUNICIPALITIES_BY_STATE,
  GET_PARTIES,
  GET_PLANS,
  GET_POSITIONS,
  GET_REGIONS,
  GET_RELIGIONS,
  GET_SCHOOLINGS,
  GET_SEXUAL_ORIENTATIONS,
  GET_STATES,
  GET_TAGS,
  UPDATE_FROM_WITH_ADDRESS_FROM_API,
} from './actionTypes';
import {
  getMunicipalitiesByState as actionGetMunicipalitiesByState,
  generalFeatureError,
  generalFeatureSuccess,
  getInterests as getInterestsAction,
  getRegions as getRegionsAction,
  getTags as getTagsAction,
} from './actions';

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(generalFeatureError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* getPlans() {
  try {
    const { data } = yield call(api.get, '/planos');

    yield put(generalFeatureSuccess({ plans: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getStates() {
  try {
    const { data } = yield call(api.get, '/estados');

    yield put(generalFeatureSuccess({ states: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getParties() {
  try {
    const { data } = yield call(api.get, '/partidos');

    yield put(generalFeatureSuccess({ parties: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getPositions() {
  try {
    const { data } = yield call(api.get, '/cargos');

    yield put(generalFeatureSuccess({ positions: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getMunicipalitiesByState({ payload }) {
  try {
    const { stateAcronym } = payload;
    const { data } = yield call(api.get, `/municipios/${stateAcronym}`);

    yield put(generalFeatureSuccess({ municipalities: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getSexualOrientations() {
  try {
    const { data } = yield call(api.get, `/orientacoes`);
    yield put(generalFeatureSuccess({ sexualOrientations: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getAutoregisterSexualOrientations({ payload }) {
  try {
    const { code } = payload;
    const { data } = yield call(api.get, `/autocadastro/orientacoes/${code}`);
    yield put(generalFeatureSuccess({ sexualOrientations: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getTags() {
  try {
    const { data } = yield call(api.get, `/etiquetas`);
    yield put(generalFeatureSuccess({ tags: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* createTag({ payload }) {
  try {
    const { data } = payload;
    yield call(api.post, '/etiquetas', data);

    yield put(generalFeatureSuccess());
    yield put(getTagsAction());
    toast('success', 'Etiqueta criada com sucesso');
  } catch (error) {
    const errorDefaultMessage = 'Ocorreu um erro ao criar a etiqueta.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* deleteTag({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `/etiquetas/${id}`);

    yield put(generalFeatureSuccess());
    yield put(getTagsAction());
    toast('success', 'Etiqueta apagada com sucesso');
  } catch (error) {
    const errorDefaultMessage = 'Ocorreu um erro ao apagar a etiqueta.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getInterests() {
  try {
    const { data } = yield call(api.get, `/interesses`);
    yield put(generalFeatureSuccess({ interests: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* createInterest({ payload }) {
  try {
    const { data } = payload;
    yield call(api.post, '/interesses', data);

    yield put(generalFeatureSuccess());
    yield put(getInterestsAction());
    toast('success', 'Interesse criado com sucesso');
  } catch (error) {
    const errorDefaultMessage = 'Ocorreu um erro ao criar o interesse.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* deleteInterest({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `/interesses/${id}`);

    yield put(generalFeatureSuccess());
    yield put(getInterestsAction());
    toast('success', 'Interesse apagado com sucesso');
  } catch (error) {
    const errorDefaultMessage = 'Ocorreu um erro ao apagar o interesse.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getAutoregisterInterests({ payload }) {
  try {
    const { code } = payload;
    const { data } = yield call(api.get, `/autocadastro/interesses/${code}`);
    yield put(generalFeatureSuccess({ interests: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getSchoolings() {
  try {
    const { data } = yield call(api.get, `/escolaridades`);
    yield put(generalFeatureSuccess({ schoolings: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getAutoregisterSchoolings({ payload }) {
  try {
    const { code } = payload;
    const { data } = yield call(api.get, `/autocadastro/escolaridades/${code}`);
    yield put(generalFeatureSuccess({ schoolings: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getRegions() {
  try {
    const { data } = yield call(api.get, `/regioes`);
    yield put(generalFeatureSuccess({ regions: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* createRegion({ payload }) {
  try {
    const { data } = payload;
    yield call(api.post, '/regioes', data);

    yield put(generalFeatureSuccess());
    yield put(getRegionsAction());
    toast('success', 'Região criada com sucesso');
  } catch (error) {
    const errorDefaultMessage = 'Ocorreu um erro ao criar a região.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* deleteRegion({ payload }) {
  try {
    const { id } = payload;
    yield call(api.delete, `/regioes/${id}`);

    yield put(generalFeatureSuccess());
    yield put(getRegionsAction());
    toast('success', 'Região apagada com sucesso');
  } catch (error) {
    const errorDefaultMessage = 'Ocorreu um erro ao apagar a região.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getAutoregisterRegions({ payload }) {
  try {
    const { code } = payload;
    const { data } = yield call(api.get, `/autocadastro/regioes/${code}`);
    yield put(generalFeatureSuccess({ regions: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getReligions() {
  try {
    const { data } = yield call(api.get, `/religioes`);
    yield put(generalFeatureSuccess({ religions: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* getAutoregisterReligions({ payload }) {
  try {
    const { code } = payload;
    const { data } = yield call(api.get, `/autocadastro/religioes/${code}`);
    yield put(generalFeatureSuccess({ religions: data }));
  } catch (error) {
    yield put(generalFeatureError());
  }
}

function* updateFormWithAddressFromAPI({ payload }) {
  const { cep, onUpdateForm } = payload;
  const baseURL = `https://viacep.com.br/ws/${cep}/json/`;
  const errorDefaultMessage = 'Oops, não foi possível buscar endereço por cep.';

  try {
    const { data } = yield call(axios.get, baseURL);

    if (data.erro) {
      toast('error', errorDefaultMessage);
      return;
    }

    yield put(actionGetMunicipalitiesByState(data.uf));
    yield take('GENERAL_FEATURE_SUCCESS');

    const municipalities = yield select(
      ({ GeneralFeature }) => GeneralFeature.municipalities
    );

    const addressFields = {
      logradouro: 'street',
      bairro: 'neighborhood',
      complemento: 'complement',
      localidade: 'municipality',
    };

    for (const [apiField, formField] of Object.entries(addressFields)) {
      const isLocalityField = apiField === 'localidade';

      if (!isLocalityField) {
        onUpdateForm(formField, data[apiField]);
        continue;
      }

      const convertToLowerCase = (value) => value.toLowerCase().trim();

      const { localidade } = data;
      const selectedMunicipality = municipalities.find(({ nome }) => {
        return convertToLowerCase(nome) === convertToLowerCase(localidade);
      });

      if (!selectedMunicipality) continue;

      const { id: value, nome: label } = selectedMunicipality;
      const municipality = { value, label };

      onUpdateForm(formField, municipality);
    }
  } catch (error) {
    toast('error', errorDefaultMessage);
  }
}

export function* watchGetTags() {
  yield takeEvery(GET_TAGS, getTags);
}

export function* watchCreateTag() {
  yield takeEvery(CREATE_TAG, createTag);
}

export function* watchDeleteTag() {
  yield takeEvery(DELETE_TAG, deleteTag);
}

export function* watchGetPlans() {
  yield takeEvery(GET_PLANS, getPlans);
}

export function* watchGetStates() {
  yield takeEvery(GET_STATES, getStates);
}

export function* watchGetRegions() {
  yield takeEvery(GET_REGIONS, getRegions);
}

export function* watchCreateRegion() {
  yield takeEvery(CREATE_REGION, createRegion);
}

export function* watchDeleteRegion() {
  yield takeEvery(DELETE_REGION, deleteRegion);
}

export function* watchGetAutoregisterRegions() {
  yield takeEvery(GET_AUTOREGISTER_REGIONS, getAutoregisterRegions);
}

export function* watchGetParties() {
  yield takeEvery(GET_PARTIES, getParties);
}

export function* watchGetPositions() {
  yield takeEvery(GET_POSITIONS, getPositions);
}

export function* watchGetReligions() {
  yield takeEvery(GET_RELIGIONS, getReligions);
}

export function* watchGetAutoregisterReligions() {
  yield takeEvery(GET_AUTOREGISTER_RELIGIONS, getAutoregisterReligions);
}

export function* watchGetInterests() {
  yield takeEvery(GET_INTERESTS, getInterests);
}

export function* watchCreateInterest() {
  yield takeEvery(CREATE_INTEREST, createInterest);
}

export function* watchDeleteInterest() {
  yield takeEvery(DELETE_INTEREST, deleteInterest);
}

export function* watchGetAutoregisterInterests() {
  yield takeEvery(GET_AUTOREGISTER_INTERESTS, getAutoregisterInterests);
}

export function* watchGetSchoolings() {
  yield takeEvery(GET_SCHOOLINGS, getSchoolings);
}

export function* watchGetAutoregisterSchoolings() {
  yield takeEvery(GET_AUTOREGISTER_SCHOOLINGS, getAutoregisterSchoolings);
}

export function* watchGetSexualOrientations() {
  yield takeEvery(GET_SEXUAL_ORIENTATIONS, getSexualOrientations);
}

export function* watchGetAutoregisterSexualOrientations() {
  yield takeEvery(
    GET_AUTOREGISTER_SEXUAL_ORIENTATIONS,
    getAutoregisterSexualOrientations
  );
}

export function* watchGetMunicipalitiesByState() {
  yield takeEvery(GET_MUNICIPALITIES_BY_STATE, getMunicipalitiesByState);
}

export function* watchUpdateFormWithAddressFromAPI() {
  yield takeEvery(
    UPDATE_FROM_WITH_ADDRESS_FROM_API,
    updateFormWithAddressFromAPI
  );
}

function* generalFeature() {
  yield all([
    fork(watchGetTags),
    fork(watchCreateTag),
    fork(watchDeleteTag),
    fork(watchGetPlans),
    fork(watchGetStates),
    fork(watchGetParties),
    fork(watchGetRegions),
    fork(watchCreateRegion),
    fork(watchDeleteRegion),
    fork(watchGetAutoregisterRegions),
    fork(watchGetReligions),
    fork(watchGetAutoregisterReligions),
    fork(watchGetPositions),
    fork(watchGetInterests),
    fork(watchCreateInterest),
    fork(watchDeleteInterest),
    fork(watchGetAutoregisterInterests),
    fork(watchGetSchoolings),
    fork(watchGetAutoregisterSchoolings),
    fork(watchGetSexualOrientations),
    fork(watchGetAutoregisterSexualOrientations),
    fork(watchGetMunicipalitiesByState),
    fork(watchUpdateFormWithAddressFromAPI),
  ]);
}

export default generalFeature;
