import React from 'react';
import { useFormikContext } from 'formik';
import SelectReactSelect from 'react-select';

export default function Select({ name, onChange, noOptionsMessage, ...rest }) {
  const { handleChange, handleBlur, touched, errors, values } =
    useFormikContext();

  const hasValidationError = touched[name] && errors[name];
  const isInvalidField = !!hasValidationError;

  const handleInputChange = (selectedOption) => {
    const event = { target: { name, value: selectedOption } };

    handleChange(event);
    onChange && onChange(selectedOption);
  };

  return (
    <SelectReactSelect
      isClearable
      name={name}
      isSearchable
      isRtl={false}
      onBlur={handleBlur}
      value={values[name]}
      className={`basic-single ${
        isInvalidField && 'is-invalid border rounded border-danger'
      }`}
      classNamePrefix="select"
      placeholder="Selecione..."
      onChange={handleInputChange}
      loadingMessage={({ inputValue }) => {
        return !inputValue ? 'Carregando...' : '';
      }}
      noOptionsMessage={({ inputValue }) => {
        return !inputValue ? noOptionsMessage ?? 'Ops, sem opções!' : '';
      }}
      {...rest}
    />
  );
}
