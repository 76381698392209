import * as Yup from 'yup';
import validationUser from '../common/validationUser';

const useCandidatesSchema = (formRef = {}, isUpdate = false) => {
  const isCpfOptional = false;
  const isShowPasswordField = false;

  const paramsValidationUser = { isUpdate, isCpfOptional, isShowPasswordField };

  return Yup.object().shape({
    ...validationUser(paramsValidationUser),
    photo: Yup.mixed()
      .nullable()
      .test(
        'fileSize',
        `O arquivo é muito grande, use imagens de até ${process.env.REACT_APP_IMAGE_UPLOAD_LIMIT} MB`,
        () => {
          if (formRef.current?.photo.files[0]) {
            const fileSize = formRef.current?.photo.files[0]?.size || 0;
            return (
              fileSize &&
              fileSize <= 1024 * 1024 * process.env.REACT_APP_IMAGE_UPLOAD_LIMIT
            );
          }
          return true;
        }
      ),
    jingle: Yup.string().nullable(),
    website: Yup.string().nullable(),
    curriculum: Yup.string().nullable(),
    voterTarget: Yup.number().nullable(),
    candidateNumber: Yup.number().nullable(),
    isUseCurrentUserAsCandidate: Yup.boolean(),
    party: Yup.object().required('Informe o partido'),
    position: Yup.object().required('Informe o cargo'),
    campaignName: Yup.string().required('Informe o nome de campanha'),
    password: Yup.string().when(['isUseCurrentUserAsCandidate'], {
      is: (isUseCurrentUserAsCandidate) => {
        return !isUseCurrentUserAsCandidate && !isUpdate;
      },
      then: () =>
        Yup.string()
          .min(6, 'A senha deve ter no mínimo 6 caracteres')
          .required('Informe sua senha'),
      otherwise: () =>
        Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
    }),
    electionYear: Yup.number()
      .min(new Date().getFullYear(), 'Ano de eleição inválido')
      .test(
        'is-four-digits',
        'O ano de eleição deve conter exatamente 4 dígitos',
        (value) => {
          const electionYear = value?.toString() || '';

          if (electionYear.length === 0) return true;
          return electionYear.length === 4;
        }
      )
      .nullable(),
  });
};

export default useCandidatesSchema;
