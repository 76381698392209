import { useState } from 'react';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import Icon from '../../../../../../ui/Icon';
import { ContainerHeader, FilterContainer } from './styles';
import optionsRegistersByPage from '../../steps/optionsRegistersByPage';

const Header = ({
  children,
  pathPageCreate,
  titleButtonHeader,
  handleChangeSearch,
  selectedRegistersByPage,
  isShowButtonCreateNewItem,
  handleChangeRegistersByPage,
  isShowButtonToDisplayFilter,
  extraHeaderContent = null,
}) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const location = useLocation();

  const handleIsShowFilter = () => {
    setIsShowFilter((prevState) => !prevState);
  };

  const titleButton = isShowFilter
    ? 'Esconder Filtro de busca'
    : 'Exibir Filtro de busca';

  return (
    <>
      <ContainerHeader>
        <div className={children ? 'buttons-container' : null}>
          {children && isShowButtonToDisplayFilter && (
            <Button
              type="button"
              title={titleButton}
              onClick={handleIsShowFilter}
              className="align-center gap-1 btn btn-primary"
            >
              <Icon name={isShowFilter ? 'upArrow' : 'downArrow'} size={1.1} />
              {isShowFilter ? 'Esconder' : 'Exibir'} Filtro de busca
            </Button>
          )}

          <div className={extraHeaderContent && 'header-extra-buttons'}>
            {extraHeaderContent}

            {isShowButtonCreateNewItem && (
              <div
                className={!children ? 'button-add-new-item-container' : null}
              >
                <Link
                  to={pathPageCreate}
                  className="align-center gap-1 btn btn-primary"
                >
                  <Icon name="plusCircle" size={1.1} />
                  {titleButtonHeader}
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="header-controls">
          <div className="d-flex gap-1 items-center">
            <Select
              isSearchable={false}
              placeholder="Selecione..."
              value={selectedRegistersByPage}
              options={optionsRegistersByPage}
              onChange={handleChangeRegistersByPage}
            />

            <label className="mt-2">Registros por página.</label>
          </div>

          { location.pathname !== '/voters' &&
            <div>
              <Input
                name="search"
                type="search"
                placeholder="Pesquisar..."
                onChange={handleChangeSearch}
              />
            </div>
          }
        </div>

        {(isShowFilter || !isShowButtonToDisplayFilter) && (
          <FilterContainer>{children}</FilterContainer>
        )}
      </ContainerHeader>
    </>
  );
};

export default Header;
