const headerTitles = [
  'Código',
  'Descrição',
  'Candidatos',
  'Eleitores',
  'Usuários',
  'Mensagens',
  'WhatsApp',
];

export default headerTitles;
