import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import withRouter from '../../../components/common/withRouter';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import useMessagesSchema from '../../../schemas/messages';
import {
  clearVotersAffectedByFilterCounter,
  createTemplate,
  getInterests,
  getRegions,
  getRegistrants,
  getReligions,
  getSchoolings,
  getSexualOrientations,
  getTags,
  getTemplateVariables,
} from '../../../store/actions';
import handleCards from '../steps/handleCards';
import handleDataSubmit from '../steps/handleDataSubmit';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate';

const CreateNewTemplate = ({ router }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const validationSchema = useMessagesSchema();
  const initialValues = handleInitialValuesCreate();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: rest });

    dispatch(createTemplate({ ...data, navigate }));
  };

  useEffect(() => {
    dispatch(getTags());
    dispatch(getRegions());
    dispatch(getReligions());
    dispatch(getInterests());
    dispatch(getSchoolings());
    dispatch(getRegistrants());
    dispatch(getSexualOrientations());
    dispatch(clearVotersAffectedByFilterCounter());
    dispatch(getTemplateVariables());
  }, [dispatch]);

  return (
    <FormPrototype
      ref={formRef}
      router={router}
      onCards={(args) => handleCards({ formRef, ...args })}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Criar novo modelo de mensagem"
    />
  );
};

export default withRouter(CreateNewTemplate);
