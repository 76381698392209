import {
  MANAGEMENT_DISCONNECT_WHATSAPP_SLOT,
  MANAGEMENT_ERROR,
  MANAGEMENT_FREE_WHATSAPP_SLOT,
  MANAGEMENT_IMPORT_WHATSAPP_SLOTS,
  MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT,
  MANAGEMENT_LIST_ACCOUNTS,
  MANAGEMENT_LIST_PLANS,
  MANAGEMENT_LIST_ROOT_USERS,
  MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS,
  MANAGEMENT_LIST_WHATSAPP_SLOTS,
  MANAGEMENT_SUCCESS,
} from './actionTypes';

const initialState = {
  accounts: [],
  rootUsers: [],
  plans: [],
  whatsappSlots: [],
  slotFreeAccounts: [],
  isLoading: false,
  isLoadingFreeSlotAccounts: false,
};

const management = (state = initialState, { type, payload }) => {
  const {
    accounts = state.accounts,
    rootUsers = state.rootUsers,
    plans = state.plans,
    whatsappSlots = state.whatsappSlots,
    slotFreeAccounts = state.slotFreeAccounts,
  } = payload ?? {};
  switch (type) {
    case MANAGEMENT_LIST_ACCOUNTS:
    case MANAGEMENT_LIST_ROOT_USERS:
    case MANAGEMENT_LIST_PLANS:
    case MANAGEMENT_LIST_WHATSAPP_SLOTS:
    case MANAGEMENT_FREE_WHATSAPP_SLOT:
    case MANAGEMENT_DISCONNECT_WHATSAPP_SLOT:
    case MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT:
    case MANAGEMENT_IMPORT_WHATSAPP_SLOTS:
      return {
        ...state,
        isLoading: true,
      };

    case MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS:
      return {
        ...state,
        isLoadingFreeSlotAccounts: true,
      };

    case MANAGEMENT_SUCCESS:
    case MANAGEMENT_ERROR:
      return {
        ...state,
        accounts,
        rootUsers,
        plans,
        whatsappSlots,
        slotFreeAccounts,
        isLoading: false,
        isLoadingFreeSlotAccounts: false,
      };

    default:
      return { ...state };
  }
};

export default management;
