import {
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  GENERATE_TEMPLATE_CSV,
  GET_TEMPLATE_BY_ID,
  GET_TEMPLATE_VARIABLES,
  TEMPLATE_ERROR,
  TEMPLATE_LIST,
  TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_AND_SEND_MESSAGES,
} from './actionTypes';

const initialState = {
  templates: [],
  template: undefined,
  variables: [],
  isLoading: false,
  isGeneratingTemplateCSV: false,
};

const templates = (state = initialState, { type, payload }) => {
  const {
    templates = state.templates,
    template = state.template,
    variables = state.variables,
  } = payload ?? {};

  switch (type) {
    case GET_TEMPLATE_BY_ID:
    case TEMPLATE_LIST:
    case CREATE_TEMPLATE:
    case UPDATE_TEMPLATE:
    case DELETE_TEMPLATE:
    case UPDATE_TEMPLATE_AND_SEND_MESSAGES:
    case GET_TEMPLATE_VARIABLES:
      return {
        ...state,
        isLoading: true,
      };

    case GENERATE_TEMPLATE_CSV:
      return {
        ...state,
        isGeneratingTemplateCSV: true,
      };

    case TEMPLATE_ERROR:
    case TEMPLATE_SUCCESS:
      return {
        ...state,
        template,
        templates,
        variables,
        isLoading: false,
        isGeneratingTemplateCSV: false,
      };

    default:
      return { ...state };
  }
};

export default templates;
