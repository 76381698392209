import * as Yup from 'yup';
import validationCpf from '../validationCpf';
import formatDate from '../../../utils/formatDate';
import validationAddress from '../validationAddress';
import toast from '../../../components/ui/toast';
import validateHasLgpd from '../../../utils/validations/validateHasLgpd';


const validationPerson = (isCpfOptional = false) => {
  const location = window.location;
  
  validateHasLgpd(location.pathname);

  return {
    ...validationAddress,
    ...validationCpf(isCpfOptional),
    gender: Yup.object().nullable(),
    maritalStatus: Yup.object().nullable(),
    sexualOrientation: Yup.object().nullable(),
    userName: Yup.string().required(() => {
      toast('error','Informe o nome');
      return 'Informe o nome'
    }),
    lastName: Yup.string().required(() => {
      toast('error','Informe o sobrenome');
      return 'Informe o sobrenome'
    }),
    dateOfBirth: Yup.date()
      .max(formatDate(new Date()), 'Data de nascimento inválida')
      .nullable(),
    acceptTerms:   validateHasLgpd(location.pathname) ? 
      Yup.boolean().required(() => {
      toast('error','É necessário aceitar os termos');
      return 'É necessário aceitar os termos'})
    .oneOf([true], 'É necessário aceitar os termos') : Yup.boolean(),
  };
};

export default validationPerson;
