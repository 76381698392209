import toast from '../../../components/ui/toast';
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import api, { setAuthorizationHeader } from '../../../services/api';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';
import addUserDataAndAccessToken from '../../../utils/actionsCookies/addUserDataAndAccessToken';
import removeUserDataAndAccessToken from '../../../utils/actionsCookies/removeUserDataAndAccessToken';

function* handleSuccessLogin(data) {
  const {
    foto,
    jwt,
    nome,
    grupo,
    email,
    nivel,
    navigate,
    codigoAutoCadastro,
    sobrenome,
    root,
  } = data;

  const userDataAndAccessToken = {
    email,
    root,
    name: nome,
    level: nivel,
    group: grupo,
    picture: foto,
    accessToken: jwt,
    autoRegisterCode: codigoAutoCadastro,
    lastName: sobrenome,
  };

  setAuthorizationHeader(jwt);
  addUserDataAndAccessToken(userDataAndAccessToken);

  navigate('/home');
  yield put(loginSuccess());
  toast('success', 'Autenticado com sucesso.');
}

function* loginUser({ payload }) {
  try {
    const { navigate, ...user } = payload;
    const { email, password: senha } = user;

    const body = { email, senha };

    const sendLoginRequest = (data) => api.post('/usuarios/login', data);
    const { data } = yield call(sendLoginRequest, body);

    yield handleSuccessLogin({ ...data, navigate });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível realizar o seu login.';

    const { response } = error;
    const { data } = response;

    toast('error', data || errorDefaultMessage);
    yield put(apiError());
  }
}

function* logoutUser() {
  try {
    removeUserDataAndAccessToken();

    yield put(logoutUserSuccess());
  } catch (error) {
    yield put(apiError({}));
  }
}

function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}

export default authSaga;
