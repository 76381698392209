import Footer from './components/Footer';
import Header from './components/Header';
import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import LoadingButton from '../../../../ui/LoadingButton';
import React, { useEffect, useMemo, useState } from 'react';
import addVotersPageIndex from '../../../../../utils/actionsListing/addVotersPageIndex';
import addOptionForHowManyRecordsToShowPerPage from '../../../../../utils/actionsListing/addOptionForHowManyRecordsToShowPerPage';
import getOptionForHowManyRecordsToShowPerPage from '../../../../../utils/actionsListing/getOptionForHowManyRecordsToShowPerPage';
import {
  Card,
  Table,
  CardBody,
  Container,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  useRowSelect,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';

const DataTableWithPagination = ({
  columns,
  children,
  fetchData,
  isLoading,
  totalPages,
  data: values,
  emptyMessage,
  pathPageCreate,
  totalRegisters,
  titleButtonHeader,
  isShowButtonCreateNewItem,
  isShowButtonToDisplayFilter,
  initialSortBy = [],
  extraHeaderContent = null,
}) => {
  const data = useMemo(() => values, [values]);

  const defaultPageSize = getOptionForHowManyRecordsToShowPerPage();
  const defaultRegistersByPage = {
    label: defaultPageSize,
    value: defaultPageSize,
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo) => setCurrentPage(pageNo);

  const pageOptions = Array.from({ length: totalPages }, (_, index) => index);

  const [selectedRegistersByPage, setSelectedRegistersByPage] = useState(
    defaultRegistersByPage
  );

  const initialState = useMemo(() => {
    return {
      pageIndex: 0,
      selectedRowIds: 0,
      sortBy: initialSortBy,
      pageSize: defaultPageSize,
    };
  }, []);

  const {
    prepareRow,
    getTableProps,
    getTableBodyProps,
    page,
    setPageSize,
    headerGroups,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: totalPages,
      manualPagination: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const handleChangeSearch = useAsyncDebounce(({ target }) => {
    const { value } = target;
    setGlobalFilter(value || undefined);
  }, 200);

  const handleChangeRegistersByPage = (newValue) => {
    setSelectedRegistersByPage(newValue);

    setCurrentPage(0);
    setPageSize(newValue.value);
    addOptionForHowManyRecordsToShowPerPage(newValue.value);
  };

  useEffect(() => {
    addVotersPageIndex(currentPage);

    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (totalPages === currentPage + 1) setCanGoNext(false);
    else setCanGoNext(true);

    if (currentPage === 0) setCanGoBack(false);
    else setCanGoBack(true);
  }, [totalPages, currentPage]);

  return (
    <>
      <Container className="page-content" fluid>
        <Card>
          <CardHeader>
            <Header
              extraHeaderContent={extraHeaderContent}
              pathPageCreate={pathPageCreate}
              titleButtonHeader={titleButtonHeader}
              handleChangeSearch={handleChangeSearch}
              selectedRegistersByPage={selectedRegistersByPage}
              isShowButtonCreateNewItem={isShowButtonCreateNewItem}
              isShowButtonToDisplayFilter={isShowButtonToDisplayFilter}
              handleChangeRegistersByPage={handleChangeRegistersByPage}
            >
              {children}
            </Header>
          </CardHeader>

          <CardBody>
            {isLoading ? (
              <div className="d-flex justify-content-center items-center">
                <LoadingButton />
              </div>
            ) : (
              <div className="table-responsive mb-1">
                <Table
                  hover
                  {...getTableProps()}
                  className="mb-0 align-middle table-borderless"
                >
                  <TableHeader headerGroups={headerGroups} />

                  <TableBody
                    page={page}
                    columns={columns}
                    isLoading={isLoading}
                    prepareRow={prepareRow}
                    emptyMessage={emptyMessage}
                    getTableBodyProps={getTableBodyProps}
                  />
                </Table>
              </div>
            )}
          </CardBody>

          <CardFooter>
            <Footer
              data={data}
              page={page}
              pageSize={pageSize}
              nextPage={onNextPage}
              gotoPage={onPageSelect}
              pageIndex={currentPage}
              canNextPage={canGoNext}
              pageOptions={pageOptions}
              previousPage={onPrevPage}
              canPreviousPage={canGoBack}
              totalRegisters={totalRegisters}
            />
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default DataTableWithPagination;
