import withRouter from '../../../components/common/withRouter';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';
import StatusItem from '../../../components/ui/StatusItem';
import { managementListAccounts } from '../../../store/actions';
import formatDate from '../../../utils/formatDate';
import headerTitles from './steps/headerTitles';

const ListingAccounts = () => {
  const selectorOptions = ['isLoading', 'accounts'];
  const [
    name,
    role,
    city,
    email,
    phone,
    registerDate,
    lastAccess,
    plan,
    situation,
  ] = headerTitles;

  const handleData = (accounts) => {
    return accounts.map((data) => {
      return {
        [name]: data.nomeCandidato || '--',
        [role]: data.cargoCandidato || '--',
        [city]: data.municipio.nome + ' - ' + data.municipio.siglaEstado,
        [email]: data.email || '--',
        [phone]: data.telefone || '--',
        [registerDate]: data.dataCadastro
          ? formatDate(data.dataCadastro, 'DD/MM/YYYY')
          : '--',
        [lastAccess]: data.ultimoAcesso
          ? formatDate(data.ultimoAcesso, 'DD/MM/YYYY')
          : '--',
        [plan]: data.plano || '--',
        [situation]: (
          <StatusItem
            status={!data.bloqueada}
            labelTrue="Liberada"
            labelFalse="Bloqueada"
          />
        ),
      };
    });
  };

  return (
    <ListingPrototype
      handleData={handleData}
      entityName={'Management'}
      headerTitles={headerTitles}
      title={'Administração - Contas'}
      dataSelector={managementListAccounts}
      selectorOptions={selectorOptions}
      isShowButtonCreateNewItem={false}
      emptyMessage={'Oops, sem contas(s) para exibir.'}
    />
  );
};

export default withRouter(ListingAccounts);
