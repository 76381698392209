import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../components/Prototypes/FormPrototype/components/FormControlButtons/styles';
import Icon from '../../../../components/ui/Icon';
import createDynamicSelector from '../../../../components/common/createDynamicSelector';
import { countVotersAffectedByFilter } from '../../../../store/actions';
import { getVoterFilterFromFormRef } from '../../../../utils/transformsValues/getVoterFilterFromFormRef';
import { useEffect } from 'react';

const SaveAndSendButton = ({ setModalData, onConfirm, formRef }) => {
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Voters', [
    'votersAffectedByFilter',
    'isCalculatingAffectedVoters',
  ]);
  const { votersAffectedByFilter, isCalculatingAffectedVoters } =
    useSelector(selector);

  const calculateAffectedVoters = () => {
    dispatch(
      countVotersAffectedByFilter({ data: getVoterFilterFromFormRef(formRef) })
    );
  };

  useEffect(() => {
    setModalData((prev) => {
      return {
        ...prev,
        body:
          isCalculatingAffectedVoters || votersAffectedByFilter == null
            ? `Aguarde, calculado eleitores afetados...`
            : `Essa ação não pode ser desfeita e enviará mensagens para ${votersAffectedByFilter} contato(s). Tem certeza que deseja realizá-la?`,
        confirmButtonDisabled: isCalculatingAffectedVoters,
      };
    });
  }, [votersAffectedByFilter]);

  return (
    <Button
      type="button"
      className="d-flex items-center btn btn-primary"
      onClick={() => {
        calculateAffectedVoters();
        const newModalData = {
          isOpen: true,
          title: 'Salvar e enviar',
          confirmText: 'Salvar e enviar',
          onConfirm: onConfirm,
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      }}
    >
      <Icon name="whatsapp" size={1} />
      <span>Salvar e enviar</span>
    </Button>
  );
};

export default SaveAndSendButton;
