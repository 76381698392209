import React from 'react';
import Icon from '../../../../components/ui/Icon';
import filterVotersTemplateFields from '../../../../utils/fields/common/filterVotersTemplateFields';
import AffectedVoters from '../../components/AffectedVoters';
import SaveAndSendButton from '../../components/SaveAndSendButton';
import templateFields from '../fields/templateFields';

const handleCards = ({
  form,
  onUpdateForm,
  dispatch,
  formRef,
  isUpdate,
  setModalData,
  handleSubmitAndSendMessages,
  setFieldValue,
}) => {
  const isToHideFields = true;

  const paramsFilterVotersFields = {
    form,
    dispatch,
    onUpdateForm,
    isToHideFields,
  };

  const cards = [
    {
      fields: templateFields({ form, setFieldValue }),
      titleHeader: 'Dados da Messagem',
      Icon: () => <Icon name="chat" />,
    },
    {
      isShowFormControlButtons: true,
      Icon: () => <Icon name="user" />,
      titleHeader: 'Perfil do eleitor',
      fields: filterVotersTemplateFields(paramsFilterVotersFields),
      extraButtons: isUpdate && (
        <SaveAndSendButton
          onConfirm={() => handleSubmitAndSendMessages(form)}
          setModalData={setModalData}
          formRef={formRef}
        />
      ),
    },
    {
      customContent: true,
      Icon: () => null,
      titleHeader: '',
      CustomContentComponent: () => <AffectedVoters formRef={formRef} />,
    },
  ];

  return cards;
};

export default handleCards;
