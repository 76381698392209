import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import withRouter from '../../../components/common/withRouter';
import LoadingPage from '../../../components/ui/LoadingPage';
import useMuniciaplResearchSchema from '../../../schemas/municipalResearch';
import {
  clearMunicipalResearch,
  createMunicipalResearch,
  getCouncilors,
  getMayors,
  getMunicipalResearchByVoterId,
  updateMunicipalResearch,
} from '../../../store/actions';
import handleCards from '../steps/handleCards/municipalResearch';
import handleDataSubmit from '../steps/handleDataSubmit/municipalResearch';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate/municipalResearch';
import handleInitialValuesUpdate from '../steps/handleInitialValuesUpdate/municipalResearch';

function CreateMunicipalResearch({ router }) {
  const { voterId } = useParams();
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Voters', [
    'isLoading',
    'municipalResearch',
  ]);
  const { isLoading, municipalResearch } = useSelector(selector);

  const validationSchema = useMuniciaplResearchSchema();
  const [initialValues, setInitialValues] = useState(
    handleInitialValuesCreate()
  );

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;

    const data = handleDataSubmit({ data: { ...rest, eleitor: voterId } });
    if (municipalResearch) {
      dispatch(updateMunicipalResearch({ data, navigate }));
      return;
    }
    dispatch(createMunicipalResearch({ data, navigate }));
  };

  useEffect(() => {
    dispatch(getMayors());
    dispatch(getCouncilors());
    voterId && dispatch(getMunicipalResearchByVoterId({ voterId }));

    return () => dispatch(clearMunicipalResearch());
  }, []);

  useEffect(() => {
    if (municipalResearch) {
      setInitialValues(handleInitialValuesUpdate(municipalResearch));
    }
  }, [municipalResearch]);

  if (isLoading) return <LoadingPage />;
  return (
    <FormPrototype
      key={initialValues.id || 0}
      router={router}
      onCards={handleCards}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Realizar pesquisa municipal"
    />
  );
}

export default withRouter(CreateMunicipalResearch);
