import handleInitialValuesUserObject from '../../../../utils/transformsValues/handleInitialValuesUserObject';

const handleInitialValuesUpdate = (currentCandidate) => {
  if (!currentCandidate) return;

  const {
    cargo,
    jingle,
    usuario,
    partido,
    eleicaoAno,
    site: website,
    nome: campaignName,
    curriculo: curriculum,
    numero: candidateNumber,
    metaEleitores: voterTarget,
  } = currentCandidate;

  const userData = handleInitialValuesUserObject(usuario);
  const party = { label: partido.nome, value: partido.id };
  const position = { label: cargo.descricao, value: cargo.id };
  const electionYear = eleicaoAno?.toString()?.length === 1 ? '' : eleicaoAno;

  const initialValues = {
    ...userData,
    party,
    jingle,
    website,
    position,
    curriculum,
    voterTarget,
    electionYear,
    campaignName,
    candidateNumber,
  };

  return initialValues;
};

export default handleInitialValuesUpdate;
