import validationPhone from '../validationPhone';
import validationEmail from '../validationEmail';
import validationPerson from '../validationPerson';
import validationPassword from '../validationPassword';

const validationUser = ({
  isUpdate = false,
  isCpfOptional = false,
  isShowPasswordField = true,
}) => {
  return {
    ...validationEmail(),
    ...validationPhone(),
    ...validationPerson(isCpfOptional),
    ...(!isUpdate || isShowPasswordField ? validationPassword() : {}),
  };
};

export default validationUser;
