import React, { useState } from 'react';
import getUserDataAndAccessToken from '../../../../../utils/actionsCookies/getUserDataAndAccessToken';
import Form from '../../../../Formik/Form';
import FieldPassword from '../../../../ui/FieldPassword';
import FieldWithButtonToAddTag from '../../../../ui/FieldWithButtonToAddTag';
import { SpanRequiredInput } from './styles';
import { FormText } from 'reactstrap';

const FormField = ({
  onSearch,
  defaultValue,
  radiosFields = [],
  isRequired = false,
  isRenderFieldWithButtonSearch,
  helpText,
  ...rest
}) => {
  const { userData } = getUserDataAndAccessToken();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const { md, name, type, label, className, placeholder, accept = '' } = rest;

  const isTagField = name === 'tags';
  const isUserAdmin = userData.group === 'Administrador';

  const isRadio = type === 'radio';
  const isSelect = type === 'select';
  const isPassword = type === 'password';
  const isCheckbox = type === 'checkbox';
  const isImage = type === 'file' && accept.includes('image');

  const renderLabel = () => {
    return (
      <>
        <Form.Label htmlFor={`${name}_${label}`}>{label}</Form.Label>
        {isRequired && <SpanRequiredInput> *</SpanRequiredInput>}
      </>
    );
  };

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const renderFieldRadio = () => {
    return (
      <div className="flex-column">
        {renderLabel()}

        <div className="flex-gap">
          {radiosFields.map(({ label, value }) => (
            <div key={label} className="flex-gap">
              <Form.Label htmlFor={`${name}_${label}`}>{label}</Form.Label>
              <Form.Radio
                {...rest}
                id={`${name}_${label}`}
                label={label}
                value={value}
                checked={selectedValue === value}
                onChange={(value) => {
                  handleRadioChange(value);
                  rest?.onChange && rest.onChange(value);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderField = () => {
    if (isTagField && isUserAdmin)
      return <FieldWithButtonToAddTag rest={rest} />;

    if (isRadio) return renderFieldRadio();

    if (isSelect) return <Form.Select {...rest} />;

    if (isCheckbox) return <Form.Checkbox {...rest} />;

    if (isImage) {
      const { currentImage, ...attributes } = rest;

      if (currentImage)
        return (
          <div
            className="d-flex align-items-center bg-white"
            style={{ gap: '4px' }}
          >
            <img
              src={currentImage}
              alt="Imagem usada atualmente"
              title="Imagem usada atualmente"
              className="rounded col-2 col-sm-1"
            />
            <Form.Input
              id={`${name}_${label}`}
              {...attributes}
              onChange={(e) => {
                setCurrentImages([e]);
              }}
            />
          </div>
        );
      return <Form.Input id={`${name}_${label}`} {...attributes} />;
    }

    if (isPassword)
      return (
        <FieldPassword
          {...rest}
          id={`${name}_${label}`}
          name={name}
          label={label}
          placeholder={placeholder}
          isShowRequired={isRequired}
          isShowPassword={isShowPassword}
          setIsShowPassword={setIsShowPassword}
        />
      );

    return <Form.Input id={`${name}_${label}`} {...rest} />;
  };

  return (
    <Form.Field md={md} className={`${className} mb-3`}>
      {!isCheckbox && !isPassword && !isRadio && renderLabel()}

      {renderField()}

      {isCheckbox && renderLabel()}

      <Form.ErrorMessage field={name} />

      {helpText && <FormText>{helpText}</FormText>}
    </Form.Field>
  );
};

export default FormField;
