const headerTitles = [
  'Titulo',
  'Meio',
  'Cadastrante',
  'Data de Cadastro',
  'Eleitores',
  'Ações',
];

export default headerTitles;
