const handleInitialValuesFilterObject = (data) => {
  const { cadastranteNome, filtros } = data;

  const {
    etnia,
    genero,
    regiao,
    religiao,
    etiquetas,
    instagram,
    interesses,
    estadoCivil,
    cadastrante,
    engajamento,
    escolaridade,
    orientacaoSexual,
  } = filtros;

  const ethnicity = etnia ? { label: etnia, value: etnia } : null;
  const gender = genero ? { label: genero, value: genero } : null;
  const region = regiao ? { label: regiao.nome, value: regiao.id } : null;

  const engagement = engajamento
    ? { label: engajamento, value: engajamento }
    : null;
  const religion = religiao
    ? { label: religiao.nome, value: religiao.id }
    : null;
  const schooling = escolaridade
    ? { label: escolaridade.nome, value: escolaridade.id }
    : null;
  const registrant = cadastrante
    ? { label: cadastranteNome, value: cadastrante.id }
    : null;
  const tags = etiquetas
    ? etiquetas.map(({ id, nome }) => ({ label: nome, value: id }))
    : null;
  const interests = interesses
    ? interesses.map(({ id, nome }) => ({ label: nome, value: id }))
    : null;
  const maritalStatus = estadoCivil
    ? { label: estadoCivil, value: estadoCivil }
    : null;
  const sexualOrientation = orientacaoSexual
    ? {
        value: orientacaoSexual.id,
        label: orientacaoSexual.descricao,
      }
    : null;

  return {
    tags,
    region,
    gender,
    religion,
    interests,
    schooling,
    ethnicity,
    instagram,
    registrant,
    engagement,
    maritalStatus,
    sexualOrientation,
    name: filtros.nome || '',
    isVoter: filtros.votante,
    isContact: filtros.contactar,
    isPhoneWhatsApp: filtros.whatsapp,
    electoralZone: filtros.zona,
    electoralCollege: filtros.colegio,
    email: filtros.email || '',
    phone: filtros.telefone || '',
    children: filtros.filhos || '',
    minimumIncome: filtros.rendaMin || '',
    maximumIncome: filtros.rendaMax || '',
  };
};

export default handleInitialValuesFilterObject;
