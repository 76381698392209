import { Button } from 'reactstrap';
import styled from 'styled-components';

export const Contanier = styled.div`
  width: 100%;
  display: flex;

  .containerField {
    width: 100%;
  }
`;

export const ButtonContainer = styled(Button)`
  display: flex;
  width: 2.5rem;
  height: 2.3rem;
  align-items: center;
  justify-content: center;
`;
