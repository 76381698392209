import formatDate from '../../formatDate';

const handleCreatePersonObject = (data) => {
  const isGender = !!data.gender;
  const isDateOfBirth = !!data.dateOfBirth;
  const isMunicipality = !!data.municipality;
  const isMaritalStatus = !!data.maritalStatus;
  const isSexualOrientation = !!data.sexualOrientation;

  const genero = isGender ? data.gender.value : null;
  const estadoCivil = isMaritalStatus ? data.maritalStatus.value : null;
  const municipio = isMunicipality ? { id: data.municipality.value } : null;
  const dataNascimento = isDateOfBirth ? formatDate(data.dateOfBirth) : null;
  const orientacaoSexual = isSexualOrientation
    ? { id: data.sexualOrientation.value }
    : null;
  const endereco = data.zipCode
    ? {
        municipio,
        cep: data.zipCode || null,
        logradouro: data.street || null,
        bairro: data.neighborhood || null,
        numero: data.addressNumber || null,
        complemento: data.complement || null,
      }
    : null;

  return {
    genero,
    endereco,
    estadoCivil,
    dataNascimento,
    orientacaoSexual,
    nome: data.userName,
    cpf: data.cpf || null,
    sobrenome: data.lastName,
  };
};

export default handleCreatePersonObject;
