import Icon from '../Icon';
import React, { useState } from 'react';
import getUserDataAndAccessToken from '../../../utils/actionsCookies/getUserDataAndAccessToken';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

const ProfileDropdown = () => {
  const { userData } = getUserDataAndAccessToken();
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn border-none">
          <span className="d-flex align-items-center">
            {userData.picture ? (
              <img
                alt="Foto usuário"
                src={userData.picture}
                className="rounded-circle header-profile-user"
              />
            ) : (
              <Icon name="account" size={1.5} />
            )}

            <span className="text-start">
              <span className="d-none d-xl-inline-block ms-1 fw-semibold text-black">
                {userData.name}
              </span>
            </span>
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header text-black">
            Bem-vindo(a) {userData.name}!
          </h6>

          <DropdownItem href={process.env.PUBLIC_URL + '/profile'}>
            <Icon size={1} name="account" className="fs-16 align-middle me-2" />
            <span className="align-middle">Perfil</span>
          </DropdownItem>

          <DropdownItem href={process.env.PUBLIC_URL + '/logout'}>
            <i className="mdi mdi-logout text-black fs-16 align-middle me-1" />{' '}
            <span className="align-middle" data-key="t-logout">
              Sair
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
