import Icon from '../../../../../../ui/Icon';
import { ColumnHeaderWithSortIcon } from './styles';

const renderIcon = (column) => {
  if (column.isSorted) {
    return column.isSortedDesc ? (
      <Icon name="downArrow" size={1} />
    ) : (
      <Icon name="upArrow" size={1} />
    );
  }

  return <Icon name="collapseVertical" size={1} />;
};

const TableHeader = ({ headerGroups }) => {
  return (
    <thead className="table-light text-muted">
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            return (
              <th
                key={column.id}
                {...column.getSortByToggleProps()}
                className="table-light text-muted"
              >
                <ColumnHeaderWithSortIcon>
                  {column.render('Header')}
                  {!column.disableSortBy && <span>{renderIcon(column)}</span>}
                </ColumnHeaderWithSortIcon>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
