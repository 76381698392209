import moneyMask from '../../../../../utils/masks/moneyMask';
import { useSelector as handleUseSelector } from 'react-redux';
import emailField from '../../../../../utils/fields/common/emailField';
import phoneField from '../../../../../utils/fields/common/phoneField';
import { engagementOptions } from '../../../../../utils/optionsCommon';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';

const formatMoneyValue = (value) => {
  const isNumber = typeof value === 'number';
  if (isNumber) return moneyMask(value.toFixed(2));

  return value;
};

const fields = ({ form, onUpdateForm }) => {
  const selector = createDynamicSelector('GeneralFeature', [
    'regions',
    'religions',
    'interests',
    'schoolings',
  ]);

  const { interests, schoolings, regions, religions } =
    handleUseSelector(selector);

  return [
    [
      ...[phoneField({ form, onUpdateForm, isRequired: false, md: 4 })],
      {
        md: 4,
        type: 'radio',
        defaultValue: true,
        name: 'isPhoneWhatsApp',
        label: 'Telefone é WhatsApp?',
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
      },
      {
        md: 4,
        type: 'radio',
        name: 'isContact',
        defaultValue: true,
        label: 'Autoriza contactar? ',
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
      },
    ],
    [
      {
        md: 4,
        type: 'text',
        name: 'group',
        label: 'Grupo ou Lista de Transmissão',
        placeholder: 'Informe o grupo do qual faz parte',
      },
      {
        md: 4,
        type: 'text',
        name: 'instagram',
        label: 'Instagram',
        placeholder: 'Informe o instagram',
      },
      ...[emailField({ isUpdate: false, isRequired: false, md: 4 })],
    ],
    [
      {
        md: 12,
        isMulti: true,
        type: 'select',
        required: false,
        name: 'interests',
        label: 'Interesses',
        options: interests.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
    ],
    [
      {
        md: 4,
        type: 'text',
        name: 'title',
        maxLength: 12,
        label: 'Título de eleitor',
        placeholder: 'Informe o título de eleitor',
      },
      {
        md: 4,
        type: 'number',
        name: 'electoralZone',
        min: 0,
        label: 'Zona eleitoral',
        placeholder: 'Informe a zona eleitoral',
      },
      {
        md: 4,
        type: 'text',
        name: 'electoralCollege',
        label: 'Colégio eleitoral',
        placeholder: 'Informe o colégio eleitoral',
      },
    ],
    [
      {
        md: 4,
        type: 'select',
        name: 'region',
        required: false,
        label: 'Região',
        options: regions.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
      {
        md: 4,
        type: 'select',
        required: false,
        name: 'engagement',
        options: engagementOptions,
        label: 'Nível de engajamento',
      },
      {
        md: 4,
        type: 'radio',
        name: 'isVoter',
        defaultValue: null,
        label: 'Já votou no candidato?',
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
      },
    ],
    [
      {
        md: 4,
        type: 'select',
        required: false,
        name: 'schooling',
        label: 'Escolaridade',
        options: schoolings.map(({ id: value, descricao: label }) => ({
          value,
          label,
        })),
      },
      {
        md: 4,
        type: 'text',
        required: false,
        name: 'profession',
        label: 'Profissão',
        placeholder: 'Informe a sua profissão',
      },
      {
        md: 4,
        type: 'text',
        label: 'Renda',
        name: 'income',
        value: formatMoneyValue(form.income),
        placeholder: 'Informe a sua renda',
        onChange: (value) => {
          onUpdateForm('income', moneyMask(value));
        },
      },
    ],
    [
      {
        md: 4,
        type: 'select',
        required: false,
        name: 'religion',
        label: 'Religião',
        options: religions.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
      {
        md: 4,
        type: 'select',
        required: false,
        label: 'Etinia',
        name: 'ethnicity',
        options: [
          { label: 'Pardo', value: 'Pardo' },
          { label: 'Branco', value: 'Branco' },
          { label: 'Negro', value: 'Negro' },
          { label: 'Indígena', value: 'Indígena' },
          { label: 'Amarelo', value: 'Amarelo' },
        ],
      },
      {
        md: 4,
        type: 'number',
        name: 'children',
        label: 'Quantos filhos o(a) eleitor(a) tem?',
        placeholder: 'Informe o seu total de filhos',
      },
    ],
    [
      {
        md: 12,
        type: 'textarea',
        name: 'message',
        label: 'Mensagem',
        placeholder: 'Informe a mensagem',
      },
    ],
  ];
};

export default fields;
