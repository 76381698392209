import axios from 'axios';
import getUserDataAndAccessToken from '../../utils/actionsCookies/getUserDataAndAccessToken';

const { accessToken } = getUserDataAndAccessToken();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthorizationHeader = (accessToken) => {
  const token = accessToken ? `Bearer ${accessToken}` : undefined;
  api.defaults.headers.common.Authorization = token;
};

setAuthorizationHeader(accessToken);

export default api;
