import api from '../../services/api';
import toast from '../../components/ui/toast';
import { takeEvery, fork, all, call, put } from 'redux-saga/effects';
import {
  multipliersError,
  multipliersSuccess,
  multipliersList as multipliersListAction,
} from './actions';
import {
  MULTIPLIERS_LIST,
  CREATE_MULTIPLIER,
  UPDATE_MULTIPLIER,
  MULTIPLIERS_LIST_FILTER,
  ACTIVE_OR_INACTIVATE_MULTIPLIER,
} from './actionTypes';

function* handleSuccess({ navigate, message }) {
  toast('success', message);
  yield put(multipliersSuccess());

  navigate && navigate('/multipliers');
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(multipliersError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* createMultiplier({ payload }) {
  try {
    const { navigate, formRef, ...data } = payload;
    const photoRef = formRef.current.photo;

    let fotoUrl = '';
    if (photoRef?.files[0]) {
      const formData = new FormData();
      formData.append('foto', photoRef.files[0]);

      const res = yield call(api.post, '/foto/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fotoUrl = res.data;
    }
    data.usuario.foto = fotoUrl;

    yield call(api.post, '/multiplicadores', data);

    const message = 'Multiplicador(a) criado(a) com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível criar um(a) novo(a) multiplicador(a).';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* multipliersList() {
  try {
    const { data } = yield call(api.get, '/multiplicadores');
    yield put(multipliersSuccess({ multipliers: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os(as) multiplicadores(as).');
    yield put(multipliersError());
  }
}

function* multipliersListFilter({ payload }) {
  try {
    const { filters: data } = payload;

    const response = yield call(api.post, '/multiplicadores/busca', data);

    yield put(multipliersSuccess({ multipliers: response.data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os(as) multiplicadores(as).');
    yield put(multipliersError());
  }
}

function* updateMultiplier({ payload }) {
  try {
    const { multiplierId, navigate, formRef, oldPhoto, ...data } = payload;
    const newPhotoRef = formRef.current.photo;

    let fotoUrl = oldPhoto;
    if (newPhotoRef?.files[0]) {
      const formData = new FormData();
      formData.append('foto', newPhotoRef.files[0]);

      const res = yield call(api.post, '/foto/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fotoUrl = res.data;
    }
    data.usuario.foto = fotoUrl;

    yield call(api.put, `/multiplicadores/${multiplierId}`, data);

    yield put(multipliersListAction());

    const message = 'Multiplicador(a) atualizado(a) com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível atualizar o(a) multiplicador(a).';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* activeOrInactivateMultiplier({ payload }) {
  const { multiplierId, isUserActive } = payload;

  try {
    const route = `/multiplicadores/${multiplierId}`;
    yield call(isUserActive ? api.delete : api.post, route);

    yield put(multipliersListAction());

    const message = `Multiplicador(a) ${
      isUserActive ? 'desativado(a)' : 'ativado(a)'
    } com sucesso ;)`;

    yield handleSuccess({ message });
  } catch (error) {
    const errorDefaultMessage = `Oops, não foi possível ${
      isUserActive ? 'desativar' : 'ativar'
    } o(a) multiplicador(a).`;

    yield handleError({ error, errorDefaultMessage });
  }
}

export function* watchMultipliersList() {
  yield takeEvery(MULTIPLIERS_LIST, multipliersList);
}

export function* watchCreateMultiplier() {
  yield takeEvery(CREATE_MULTIPLIER, createMultiplier);
}

export function* watchUpdateMultiplier() {
  yield takeEvery(UPDATE_MULTIPLIER, updateMultiplier);
}

export function* watchMultipliersListFilter() {
  yield takeEvery(MULTIPLIERS_LIST_FILTER, multipliersListFilter);
}

export function* watchActiveOrInactivateMultiplier() {
  yield takeEvery(
    ACTIVE_OR_INACTIVATE_MULTIPLIER,
    activeOrInactivateMultiplier
  );
}

function* multipliers() {
  yield all([
    fork(watchMultipliersList),
    fork(watchCreateMultiplier),
    fork(watchUpdateMultiplier),
    fork(watchMultipliersListFilter),
    fork(watchActiveOrInactivateMultiplier),
  ]);
}

export default multipliers;
