import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import handleCards from '../steps/handleCards';
import { useDispatch, useSelector } from 'react-redux';
import handleDataSubmit from '../steps/handleDataSubmit';
import LoadingPage from '../../../components/ui/LoadingPage';
import withRouter from '../../../components/common/withRouter';
import useMultipliersSchema from '../../../schemas/multipliers';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import handleInitialValuesUpdate from '../steps/handleInitialValuesUpdate';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import {
  multipliersList,
  updateMultiplier,
  getSexualOrientations,
} from '../../../store/actions';

const UpdateMultiplier = ({ router }) => {
  const dispatch = useDispatch();
  const { multiplierId } = useParams();

  const formRef = useRef();

  const isUpdate = true;
  const validationSchema = useMultipliersSchema(formRef, isUpdate);

  const [initialValues, setInitialValues] = useState(null);
  const [oldPhoto, setOldPhoto] = useState(null);
  const [currentImages, setCurrentImages] = useState([undefined]);

  const selector = createDynamicSelector('Multipliers', ['multipliers']);
  const { multipliers } = useSelector(selector);

  const currentMultiplier = multipliers.find(({ id }) => id == multiplierId);

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;

    const userId = currentMultiplier.usuario.id;
    const params = { data: { ...rest, userId }, isUpdate };

    const data = handleDataSubmit(params);
    dispatch(
      updateMultiplier({ ...data, multiplierId, navigate, formRef, oldPhoto })
    );
  };

  useEffect(() => {
    dispatch(multipliersList());
    dispatch(getSexualOrientations());
  }, [dispatch]);

  useEffect(() => {
    const initialValues = handleInitialValuesUpdate(currentMultiplier);
    setOldPhoto(`${initialValues?.photo}`);
    setCurrentImages([initialValues?.photo]);

    if (initialValues) initialValues.photo = '';
    setInitialValues(initialValues);
  }, [currentMultiplier]);

  if (!initialValues) return <LoadingPage />;

  return (
    <FormPrototype
      ref={formRef}
      router={router}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Atualizar Multiplicador(a)"
      onCards={(params) => {
        return handleCards({ ...params, currentImages, isUpdate });
      }}
    />
  );
};

export default withRouter(UpdateMultiplier);
