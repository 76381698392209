const headerTitles = [
  'Nome do(a) candidato(a)',
  'Cargo',
  'Município',
  'E-mail',
  'Telefone',
  'Data de cadastro',
  'Último acesso',
  'Plano',
  'Situação',
];

export default headerTitles;
