import handleInitialValuesUserObject from '../../../../utils/transformsValues/handleInitialValuesUserObject';
import levels from '../levels';

const handleInitialValuesUpdate = (currentMultiplier) => {
  if (!currentMultiplier) return;

  const { usuario, nivel, metaEleitores } = currentMultiplier;
  const { ativo: isActive, grupo, foto: photo } = usuario;

  const voterTarget = metaEleitores || '';
  const group = { label: grupo, value: grupo };
  const level = { label: levels[nivel].label, value: nivel };
  const userData = handleInitialValuesUserObject(usuario);

  const values = {
    ...userData,
    photo,
    level,
    group,
    isActive,
    voterTarget,
  };

  return values;
};

export default handleInitialValuesUpdate;
