import React from 'react';
import voterFields from '../../fields/voterFields';
import Icon from '../../../../../components/ui/Icon';
import personFields from '../../../../../utils/fields/common/personFields';

const handleCards = ({ form, onUpdateForm, dispatch }) => {
  const voterFieldsData = voterFields({ form, onUpdateForm });
  const personFieldsData = personFields({
    form,
    dispatch,
    onUpdateForm,
    isCpfRequired: false,
  });

  const checkField = [
    {
      md: 10,
      type: 'checkbox',
      label: 'Eu autorizo o uso dos meus dados pessoais para fins de cadastro eleitoral, conforme a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018).',
      name: 'acceptTerms',
      isRequired: true,
      className: 'checkbox-spacing',
    }
  ]

  const cards = [
    {
      fields: [checkField],
      titleHeader: 'Lei Geral de Proteção de Dados Pessoais (LGPD)',
      Icon: () => <Icon name="alertCircle" />,
    },
    {
      fields: personFieldsData,
      titleHeader: 'Dados Pessoais',
      Icon: () => <Icon name="user" />,
    },
    {
      fields: voterFieldsData,
      titleHeader: 'Dados do eleitor',
      isShowFormControlButtons: true,
      Icon: () => <Icon name="user" />,
    },
  ];

  return cards;
};

export default handleCards;
