import * as Yup from 'yup';

const validationEmail = (isOptional = false) => {
  const emailSchema = Yup.string().email('O formato do e-mail está inválido');

  return {
    email: isOptional
      ? emailSchema.nullable()
      : emailSchema.required('Informe seu e-mail'),
  };
};

export default validationEmail;
