import React from 'react';
import Icon from '../Icon';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const ActionsItem = ({
  onDelete,
  updatePath,
  isShowOptionDelete = false,
  titleActionDelete = 'Apagar',
}) => {
  const navigate = useNavigate();
  const optionsIcons = {
    ['Apagar']: 'delete',
    ['Inativar']: 'lockAlt',
    ['Ativar']: 'lockOpenAlt',
  };

  return (
    <UncontrolledDropdown
      title="Ações"
      direction="end"
      className="d-flex justify-content-end"
    >
      <DropdownToggle
        tag="button"
        className="btn btn-soft-light btn-primary btn-sm"
      >
        <Icon name="more" size={0.8} />
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-end" flip>
        <DropdownItem
          tag="button"
          onClick={() => navigate(updatePath)}
          title="Editar"
          className="dropdown-item"
        >
          <Icon name="pencil" size={0.9} /> Editar
        </DropdownItem>

        {isShowOptionDelete && (
          <>
            <DropdownItem divider />

            <DropdownItem
              tag="button"
              role="menuitem"
              onClick={onDelete}
              className="dropdown-item"
              title={titleActionDelete}
              aria-label={titleActionDelete}
            >
              <Icon size={0.9} name={optionsIcons[titleActionDelete]} />
              {titleActionDelete}
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActionsItem;
