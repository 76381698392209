const handleInitialValuesCreate = () => {
  return {
    iluminacao: null,
    saneamento: null,
    agua: null,
    asfalto: null,
    limpeza: null,
    chuvas: null,
    lixo: null,
    arvores: null,
    praca: null,
    zeladoria_comentario: null,
    transporte_publico_uso: null,
    transporte_publico: null,
    onibus: null,
    transito: null,
    transporte_comentario: null,
    educacao_publica_uso: null,
    educacao_publica: null,
    merenda: null,
    transporte_escolar: null,
    creche: null,
    educacao_comentario: null,
    saude_publica_uso: null,
    saude_publica: null,
    posto_saude: null,
    medicamento_continuo_uso: null,
    medicamento: null,
    saude_publica_comentario: null,
    prefeito: '',
    vereador: '',
    comentario: null,
  };
};

export default handleInitialValuesCreate;
