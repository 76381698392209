const handleInitialValuesCreate = () => {
  const initialValues = {
    cpf: '',
    email: '',
    level: '',
    phone: '',
    group: '',
    photo: null,
    userName: '',
    lastName: '',
    password: '',
    gender: null,
    street: null,
    zipCode: null,
    isActive: true,
    complement: null,
    dateOfBirth: null,
    voterTarget: null,
    municipality: null,
    neighborhood: null,
    addressNumber: null,
    maritalStatus: null,
    sexualOrientation: null,
  };

  return initialValues;
};

export default handleInitialValuesCreate;
