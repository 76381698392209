import {
  UPDATE_PASSWORD,
  SEND_EMAIL_WITH_CODE,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_SUCCESS,
} from './actionTypes';

export const updatePassword = (payload) => {
  return {
    payload,
    type: UPDATE_PASSWORD,
  };
};

export const sendEmailWithCode = (payload) => {
  return {
    payload,
    type: SEND_EMAIL_WITH_CODE,
  };
};

export const forgetPasswordSuccess = () => {
  return {
    payload: {},
    type: FORGET_PASSWORD_SUCCESS,
  };
};

export const forgetPasswordError = () => {
  return {
    payload: {},
    type: FORGET_PASSWORD_ERROR,
  };
};
