import handleCreatePersonObject from '../handleCreatePersonObject';

const handleCreateUserObject = ({ data, isUpdate }) => {
  const { isUseCurrentUserAsCandidate = false, userId } = data;

  const isPassword = !!data.password;
  const pessoa = handleCreatePersonObject(data);

  const userData = {
    pessoa,
    email: data.email,
    telefone: data.phone,
    foto: data.photo || null,
  };

  if (isUseCurrentUserAsCandidate || isUpdate) userData.id = userId;
  if (isPassword || !isUpdate) userData.senha = data.password || null;

  return userData;
};

export default handleCreateUserObject;
