import * as Yup from 'yup';
import validationEmail from '../common/validationEmail';
import validationPassword from '../common/validationPassword';
import validationRepeatPassword from '../common/validationRepeatPassword';

const useUpdatePasswordSchema = () => {
  return Yup.object().shape({
    ...validationEmail(),
    ...validationPassword(),
    ...validationRepeatPassword,
  });
};

export default useUpdatePasswordSchema;
