import withRouter from '../../../components/common/withRouter';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';
import StatusItem from '../../../components/ui/StatusItem';
import { managementListPlans } from '../../../store/management/actions';
import headerTitles from './steps/headerTitles';

const ListingPlans = () => {
  const selectorOptions = ['isLoading', 'plans'];
  const [code, description, candidates, voters, users, messages, whatsapp] =
    headerTitles;

  const handleData = (rootUsers) => {
    return rootUsers.map((data) => {
      return {
        [code]: data.codigo || '--',
        [description]: data.descricao || '--',
        [candidates]: data.limiteCandidatos || '--',
        [voters]: data.limiteEleitores || '--',
        [users]: data.limiteUsuarios || '--',
        [messages]: <StatusItem status={data.mensagens} />,
        [whatsapp]: <StatusItem status={data.whatsapp} />,
      };
    });
  };

  return (
    <ListingPrototype
      handleData={handleData}
      entityName={'Management'}
      headerTitles={headerTitles}
      title={'Administração - Planos'}
      dataSelector={managementListPlans}
      selectorOptions={selectorOptions}
      isShowButtonCreateNewItem={false}
      emptyMessage={'Oops, sem plano(s) para exibir.'}
    />
  );
};

export default withRouter(ListingPlans);
