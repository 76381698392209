const fields = () => {
  return [
    [
      {
        md: 12,
        type: 'text',
        name: 'name',
        label: 'Nome',
        placeholder: 'Informe o nome da etiqueta',
        isRequired: true,
      },
    ],
  ];
};

export default fields;
