import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import {
  CANDIDATES_LIST,
  CREATE_CANDIDATE,
  GET_CANDIDATES_BY_SELFREGISTRATION,
  UPDATE_CANDIDATE,
} from './actionTypes';
import {
  candidatesError,
  candidatesList as candidatesListAction,
  candidatesSuccess,
} from './actions';

function* handleSuccess({ navigate, message }) {
  toast('success', message);
  yield put(candidatesSuccess());

  navigate('/candidates');
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(candidatesError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* createCandidate({ payload }) {
  try {
    const { navigate, ...data } = payload;
    const foto = data.foto.current.photo;

    let fotoUrl = null;
    if (foto?.files[0]) {
      const formData = new FormData();
      formData.append('foto', foto.files[0]);

      const res = yield call(api.post, '/foto/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fotoUrl = res.data;
    }

    data.foto = fotoUrl;
    data.usuario.foto = fotoUrl;
    yield call(api.post, '/candidatos', data);

    const message = 'Candidato(a) criado(a) com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível criar um(a) novo(a) candidato(a).';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* candidatesList() {
  try {
    const { data } = yield call(api.get, '/candidatos');
    yield put(candidatesSuccess({ candidates: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os(as) candidatos(as).');
    yield put(candidatesError());
  }
}

function* updateCandidate({ payload }) {
  try {
    const { candidateId, navigate, oldPhoto, ...data } = payload;
    const newPhotoRef = data.foto.current.photo;

    let fotoUrl = oldPhoto;
    if (newPhotoRef?.files[0]) {
      const formData = new FormData();
      formData.append('foto', newPhotoRef.files[0]);

      const res = yield call(api.post, '/foto/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fotoUrl = res.data;
    }

    data.foto = fotoUrl;
    data.usuario.foto = fotoUrl;

    yield call(api.put, `/candidatos/${candidateId}`, data);
    yield put(candidatesListAction());

    const message = 'Candidato(a) atualizado(a) com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível atualizar o(a) candidato(a).';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* getCandidatesBySelfregistration({ payload }) {
  try {
    const { code } = payload;
    const url = `/candidatos/autocadastro/${code}`;
    const { data } = yield call(api.get, url);

    yield put(candidatesSuccess({ candidates: data }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar os dados do(a) candidato(a).';
    yield handleError({ error, errorDefaultMessage });
  }
}

export function* watchCandidatesList() {
  yield takeEvery(CANDIDATES_LIST, candidatesList);
}

export function* watchCreateCandidate() {
  yield takeEvery(CREATE_CANDIDATE, createCandidate);
}

export function* watchUpdateCandidate() {
  yield takeEvery(UPDATE_CANDIDATE, updateCandidate);
}

export function* watchGetCandidatesBySelfregistration() {
  yield takeEvery(
    GET_CANDIDATES_BY_SELFREGISTRATION,
    getCandidatesBySelfregistration
  );
}

function* candidates() {
  yield all([
    fork(watchCandidatesList),
    fork(watchCreateCandidate),
    fork(watchUpdateCandidate),
    fork(watchGetCandidatesBySelfregistration),
  ]);
}

export default candidates;
