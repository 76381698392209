import React from 'react';
import headerTitles from './steps/headerTitles';
import selectorOptions from '../steps/selectorOptions';
import { candidatesList } from '../../../store/actions';
import ActionsItem from '../../../components/ui/ActionsItem';
import withRouter from '../../../components/common/withRouter';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';

const ListingCandidates = () => {
  const [
    candidateName,
    position,
    voterTarget,
    party,
    electionYear,
    candidateNumber,
    actions,
  ] = headerTitles;

  const handleData = (candidates) => {
    return candidates.map((data) => ({
      [candidateName]: data.usuario.pessoa.nome,
      [position]: data.cargo.descricao,
      [voterTarget]: data.metaEleitores,
      [party]: data.partido.nome,
      [electionYear]: data.eleicaoAno,
      [candidateNumber]: data.numero,
      [actions]: <ActionsItem updatePath={`/candidates/update/${data.id}`} />,
    }));
  };

  return (
    <ListingPrototype
      handleData={handleData}
      title={'Candidatos(as)'}
      entityName={'Candidates'}
      headerTitles={headerTitles}
      dataSelector={candidatesList}
      selectorOptions={selectorOptions}
      pathPageCreate={'/candidates/new'}
      titleButtonHeader={'Novo(a) candidato(a)'}
      emptyMessage={'Oops, sem candidatos(as) para exibir.'}
    />
  );
};

export default withRouter(ListingCandidates);
