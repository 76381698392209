import {
  CREATE_INTEREST,
  CREATE_REGION,
  CREATE_TAG,
  DELETE_INTEREST,
  DELETE_REGION,
  DELETE_TAG,
  GENERAL_FEATURE_ERROR,
  GENERAL_FEATURE_SUCCESS,
  GET_AUTOREGISTER_INTERESTS,
  GET_AUTOREGISTER_REGIONS,
  GET_AUTOREGISTER_RELIGIONS,
  GET_AUTOREGISTER_SCHOOLINGS,
  GET_AUTOREGISTER_SEXUAL_ORIENTATIONS,
  GET_INTERESTS,
  GET_MUNICIPALITIES_BY_STATE,
  GET_PARTIES,
  GET_PLANS,
  GET_POSITIONS,
  GET_REGIONS,
  GET_RELIGIONS,
  GET_SCHOOLINGS,
  GET_SEXUAL_ORIENTATIONS,
  GET_STATES,
  GET_TAGS,
  UPDATE_FROM_WITH_ADDRESS_FROM_API,
} from './actionTypes';

const initialState = {
  tags: [],
  plans: [],
  states: [],
  regions: [],
  parties: [],
  religions: [],
  interests: [],
  positions: [],
  schoolings: [],
  isLoading: false,
  municipalities: [],
  sexualOrientations: [],
};

const generalFeature = (state = initialState, { type, payload }) => {
  const {
    tags = state.tags,
    plans = state.plans,
    states = state.states,
    parties = state.parties,
    regions = state.regions,
    interests = state.interests,
    religions = state.religions,
    positions = state.positions,
    schoolings = state.schoolings,
    municipalities = state.municipalities,
    sexualOrientations = state.sexualOrientations,
  } = payload ?? {};

  switch (type) {
    case GET_TAGS:
    case GET_PLANS:
    case GET_STATES:
    case GET_PARTIES:
    case GET_REGIONS:
    case GET_AUTOREGISTER_REGIONS:
    case GET_RELIGIONS:
    case GET_AUTOREGISTER_RELIGIONS:
    case GET_INTERESTS:
    case GET_AUTOREGISTER_INTERESTS:
    case GET_POSITIONS:
    case GET_SCHOOLINGS:
    case GET_AUTOREGISTER_SCHOOLINGS:
    case GET_SEXUAL_ORIENTATIONS:
    case GET_AUTOREGISTER_SEXUAL_ORIENTATIONS:
    case GET_MUNICIPALITIES_BY_STATE:
    case UPDATE_FROM_WITH_ADDRESS_FROM_API:
    case CREATE_REGION:
    case DELETE_REGION:
    case CREATE_INTEREST:
    case DELETE_INTEREST:
    case CREATE_TAG:
    case DELETE_TAG:
      return {
        ...state,
        isLoading: true,
      };

    case GENERAL_FEATURE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case GENERAL_FEATURE_SUCCESS:
      return {
        ...state,
        tags,
        plans,
        states,
        parties,
        regions,
        interests,
        positions,
        religions,
        schoolings,
        municipalities,
        isLoading: false,
        sexualOrientations,
      };

    default:
      return { ...state };
  }
};

export default generalFeature;
