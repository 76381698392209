const handleInitialValues = () => {
  return {
    name: '',
    email: '',
    level: 0,
    group: null,
    gender: null,
    isActive: true,
  };
};

export default handleInitialValues;
