import * as Yup from 'yup';
import validationEmail from '../common/validationEmail';

const useLoginSchema = () => {
  return Yup.object().shape({
    ...validationEmail(),
    password: Yup.string()
      .min(6, 'A senha deve ter no mínimo 6 caracteres')
      .required('Informe sua senha'),
  });
};

export default useLoginSchema;
