import { ContainerHeader } from '../../styles';

const CardHeader = ({ Icon, titleHeader }) => (
  <ContainerHeader>
    <Icon />

    <h4 className="mt-2 text-black">{titleHeader}</h4>
  </ContainerHeader>
);

export default CardHeader;
