const handleInitialValuesUpdate = (municipalResearch) => {
  return {
    id: municipalResearch.id,
    iluminacao: municipalResearch.iluminacao || null,
    saneamento: municipalResearch.saneamento || null,
    agua: municipalResearch.agua || null,
    asfalto: municipalResearch.asfalto || null,
    limpeza: municipalResearch.limpeza || null,
    chuvas: municipalResearch.chuvas || null,
    lixo: municipalResearch.lixo || null,
    arvores: municipalResearch.arvores || null,
    praca: municipalResearch.praca || null,
    zeladoria_comentario: municipalResearch.zeladoria_comentario || null,
    transporte_publico_uso: municipalResearch.transporte_publico_uso,
    transporte_publico: municipalResearch.transporte_publico || null,
    onibus: municipalResearch.onibus || null,
    transito: municipalResearch.transito || null,
    transporte_comentario: municipalResearch.transporte_comentario || null,
    educacao_publica_uso: municipalResearch.educacao_publica_uso,
    educacao_publica: municipalResearch.educacao_publica || null,
    merenda: municipalResearch.merenda || null,
    transporte_escolar: municipalResearch.transporte_escolar || null,
    creche: municipalResearch.creche || null,
    educacao_comentario: municipalResearch.educacao_comentario || null,
    saude_publica_uso: municipalResearch.saude_publica_uso,
    saude_publica: municipalResearch.saude_publica || null,
    posto_saude: municipalResearch.posto_saude || null,
    medicamento_continuo_uso: municipalResearch.medicamento_continuo_uso,
    medicamento: municipalResearch.medicamento || null,
    saude_publica_comentario:
      municipalResearch.saude_publica_comentario || null,
    prefeito: municipalResearch.prefeito
      ? {
          label: municipalResearch.prefeito.nome,
          value: municipalResearch.prefeito.id,
        }
      : '',
    vereador: municipalResearch.vereador
      ? {
          label: municipalResearch.vereador.nome,
          value: municipalResearch.vereador.id,
        }
      : '',
    comentario: municipalResearch.comentario || null,
  };
};

export default handleInitialValuesUpdate;
