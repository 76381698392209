import { useEffect } from 'react';
import { Alert, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import LoadingPage from '../../components/ui/LoadingPage';
import { getReportByCategory } from '../../store/actions';
import DonutChart from '../../components/charts/DonutChart';
import withRouter from '../../components/common/withRouter';
import { reportsHomeKeys } from '../../config/reports/reportsKeys';
import { reportsHomeRoutes } from '../../config/reports/reportsRoutes';
import createDynamicSelector from '../../components/common/createDynamicSelector';
import getUserDataAndAccessToken from '../../utils/actionsCookies/getUserDataAndAccessToken';

const Home = () => {
  const dispatch = useDispatch();

  const { userData } = getUserDataAndAccessToken();
  const isUserAdmin = userData.group === 'Administrador';

  const selector = createDynamicSelector('Reports', [
    'isLoading',
    ...reportsHomeKeys,
  ]);

  const {
    isLoading,
    candidatePerformanceReports,
    multiplierPerformanceReports,
  } = useSelector(selector);

  useEffect(() => {
    document.title = 'Início';

    reportsHomeRoutes.forEach((route, index) => {
      const category = route;
      const key = reportsHomeKeys[index];

      dispatch(getReportByCategory({ category, key }));
    });
  }, []);

  const data = isUserAdmin
    ? candidatePerformanceReports
    : [multiplierPerformanceReports];

  const hasData = data?.length > 0;

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Container className="page-content">
          {hasData ? (
            <>
              {data.map((item, index) => (
                <Row className="m-3" key={`${item.pessoa}-${index}`}>
                  <DonutChart
                    {...item}
                    titleHeader="Progresso da meta de cadastro"
                    colors={['#008FFB', '#9e9495']}
                  />
                </Row>
              ))}
            </>
          ) : (
            <Row className="m-3">
              <Alert color="info">Oops, sem dados para exibir as metas.</Alert>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default withRouter(Home);
