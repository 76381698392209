import { Spinner } from 'reactstrap';

const LoadingPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Spinner />
    </div>
  );
};

export default LoadingPage;
