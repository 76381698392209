import React from 'react';
import Icon from '../../../../components/ui/Icon';
import candidateFields from '../fields/candidateFields';
import userFields from '../../../../utils/fields/common/userFields';
import isUseCurrentUserAsCandidateFields from '../fields/isUseCurrentUserAsCandidate';

const handleCards = ({
  form,
  onUpdateForm,
  dispatch,
  currentImages = [''],
  isUpdate = false,
}) => {
  const candidateFieldsData = candidateFields(currentImages);
  const isUseCurrentUserFieldsData = isUseCurrentUserAsCandidateFields({
    dispatch,
  });
  const userFieldsData = userFields({
    form,
    isUpdate,
    dispatch,
    onUpdateForm,
  });

  const conditionalCard = !isUpdate
    ? [
        {
          Icon: () => <Icon name="user" />,
          titleHeader: 'Usar usuário atual como candidato',
          fields: isUseCurrentUserFieldsData,
        },
      ]
    : [];

  const cards = [
    ...conditionalCard,
    {
      titleHeader: 'Dados Pessoais',
      Icon: () => <Icon name="user" />,
      fields: userFieldsData,
    },
    {
      titleHeader: 'Dados políticos',
      Icon: () => <Icon name="user" />,
      fields: candidateFieldsData,
      isShowFormControlButtons: true,
    },
  ];

  return cards;
};

export default handleCards;
