import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';
import withRouter from '../../../components/common/withRouter';
import ActionsDropdown from '../../../components/ui/ActionsDropdown';
import ConfirmationModal from '../../../components/ui/ConfirmationModal';
import { getRegistrants, templateList } from '../../../store/actions';
import formatDate from '../../../utils/formatDate';
import selectorOptions from '../steps/selectorOptions';
import actionsOptions from './steps/actions';
import headerTitles from './steps/headerTitles';

const ListingTemplates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, quite, register, registrationDate, voters, actions] =
    headerTitles;
  const [modalData, setModalData] = useState({
    body: '',
    isOpen: false,
    title: '',
    confirmText: '',
    onConfirm: () => {},
  });

  const handleData = (templates) => {
    return templates.map((data) => ({
      [title]: data.titulo || '--',
      [quite]: data.meio,
      [register]: data.cadastrante,
      [registrationDate]: formatDate(data.dataCadastro, 'DD/MM/YYYY'),
      [voters]: data.eleitores || '-',
      [actions]: (
        <ActionsDropdown
          actions={actionsOptions(data, dispatch, navigate, setModalData)}
        />
      ),
    }));
  };

  useEffect(() => {
    dispatch(getRegistrants());
  }, []);

  return (
    <>
      <ListingPrototype
        title={'Modelos de mensagem'}
        handleData={handleData}
        entityName={'Templates'}
        dataSelector={templateList}
        headerTitles={headerTitles}
        pathPageCreate={'/templates/new'}
        selectorOptions={selectorOptions}
        titleButtonHeader={'Novo modelo de mensagem'}
        emptyMessage={'Oops, sem modelos de mensagem para exibir.'}
      />

      <ConfirmationModal
        body={modalData.body}
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title={modalData.title}
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        confirmText={modalData.confirmText}
      />
    </>
  );
};

export default withRouter(ListingTemplates);
