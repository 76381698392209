import moment from 'moment';

/**
 *
 * @param {string} date
 * @param {string?} format
 * @returns {string}
 */

const formatDate = (date, format = 'YYYY-MM-DD') => {
  return moment(date).format(format);
};

export default formatDate;
