import {
  REPORTS_ERROR,
  REPORTS_SUCCESS,
  GET_REPORT_BY_CATEGORY,
} from './actionTypes';

export const getReportByCategory = (payload) => {
  return {
    type: GET_REPORT_BY_CATEGORY,
    payload,
  };
};

export const reportsError = () => {
  return {
    type: REPORTS_ERROR,
    payload: {},
  };
};

export const reportsSuccess = (payload) => {
  return {
    type: REPORTS_SUCCESS,
    payload,
  };
};
