import * as Yup from 'yup';
import validationEmail from '../validationEmail';
import validationPhone from '../validationPhone';

const isOptional = true;

const validationFilter = {
  ...validationEmail(isOptional),
  ...validationPhone(isOptional),
  children: Yup.number(),
  userName: Yup.string(),
  isContact: Yup.boolean(),
  minimumIncome: Yup.string(),
  maximumIncome: Yup.string(),
  tags: Yup.array().nullable(),
  isPhoneWhatsApp: Yup.boolean(),
  gender: Yup.object().nullable(),
  region: Yup.object().nullable(),
  interests: Yup.array().nullable(),
  religion: Yup.object().nullable(),
  isVoter: Yup.boolean().nullable(),
  instagram: Yup.string().nullable(),
  ethnicity: Yup.object().nullable(),
  schooling: Yup.object().nullable(),
  engagement: Yup.object().nullable(),
  maritalStatus: Yup.object().nullable(),
  sexualOrientation: Yup.object().nullable(),
  selfRegistered: Yup.boolean().nullable(),
};

export default validationFilter;
