import createDynamicSelector from '../../../../../components/common/createDynamicSelector';
import { useSelector as handleUseSelector } from 'react-redux';

const fields = ({ formRef, isUpdate }) => {
  const selector = createDynamicSelector('Settings', [
    'pendingSystemParams',
    'systemParamsDescriptions',
  ]);

  const { pendingSystemParams, systemParamsDescriptions } =
    handleUseSelector(selector);

  return [
    [
      {
        md: 6,
        name: 'key',
        type: isUpdate ? 'text' : 'select',
        label: 'Chave',
        isRequired: true,
        helpText: systemParamsDescriptions[formRef.current?.key.value],
        required: false,
        options: isUpdate
          ? null
          : pendingSystemParams.map((param) => ({
              label: param,
              value: param,
            })),
        readOnly: isUpdate,
      },
      {
        md: 6,
        type: 'text',
        name: 'value',
        label: 'Valor',
        placeholder: 'Informe o valor da chave',
        isRequired: true,
      },
    ],
  ];
};

export default fields;
