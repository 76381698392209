import ListingPrototype from '../../../../../../components/Prototypes/ListingPrototype';
import { getSystemParams } from '../../../../../../store/actions';
import headerTitles from './steps/headerTitles';
import selectorOptions from './steps/selectorOptions';
import ActionsDropdown from '../../../../../../components/ui/ActionsDropdown';
import actionOptions from './steps/actionOptions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function ListingSystemParams() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [chave, valor, atualizacao, usuario, acoes] = headerTitles;

  const handleData = (systemParams) => {
    return systemParams.map((data) => {
      return {
        [chave]: data.chave,
        [valor]: data.valor,
        [atualizacao]: data.dataAtualizacao,
        [usuario]: data.usuarioCadastrante,
        [acoes]: (
          <ActionsDropdown
            actions={actionOptions({ data, navigate, dispatch })}
          />
        ),
      };
    });
  };
  return (
    <ListingPrototype
      pageContent={false}
      isShowButtonCreateNewItem={false}
      handleData={handleData}
      entityName={'Settings'}
      headerTitles={headerTitles}
      dataSelector={getSystemParams}
      selectorOptions={selectorOptions}
      emptyMessage={'Oops, sem parâmetros de sistema para exibir'}
      title={'Parâmetros do sistema'}
    />
  );
}
