import styled from 'styled-components';
import {
  Card as CardReactstrap,
  Button as ButtonReactstrap,
  CardBody as CardBodyReactstrap,
} from 'reactstrap';

export const Card = styled(CardReactstrap)`
  margin: auto;
  max-width: 20rem;
  text-align: center;
`;

export const Button = styled(ButtonReactstrap)`
  width: 50%;
`;

export const CardBody = styled(CardBodyReactstrap)`
  gap: 0.5rem;
  width: 100%;
  display: flex;
`;
