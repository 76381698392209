import { Form as FormFormik, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import logo from '../../../assets/images/logo.png';
import FormCard from '../../../components/Prototypes/FormPrototype/components/FormCard';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import LoadingPage from '../../../components/ui/LoadingPage';
import useVotersSchema from '../../../schemas/voters';
import {
  autoregisterVoter,
  getAutoregisterInterests,
  getAutoregisterRegions,
  getAutoregisterReligions,
  getAutoregisterSchoolings,
  getAutoregisterSexualOrientations,
  getCandidatesBySelfregistration,
} from '../../../store/actions';
import handleCards from '../steps/handleCards/autoregisterVoters';
import handleDataSubmit from '../steps/handleDataSubmit';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate';

function SelfRegister() {
  const dispatch = useDispatch();
  const { autoRegisterCode: code } = useParams();
  const navigate = useNavigate();

  const validationSchema = useVotersSchema();
  const initialValues = handleInitialValuesCreate();

  const selector = createDynamicSelector('Candidates', ['candidates']);
  const { candidates } = useSelector(selector);
  const candidate = candidates[0];

  const handleSubmit = (values) => {
    const data = handleDataSubmit({ data: values });
    dispatch(
      autoregisterVoter({ data, code, navigate, site: candidate?.site })
    );
  };

  useEffect(() => {
    dispatch(getCandidatesBySelfregistration({ code }));
    dispatch(getAutoregisterRegions({ code }));
    dispatch(getAutoregisterReligions({ code }));
    dispatch(getAutoregisterInterests({ code }));
    dispatch(getAutoregisterSchoolings({ code }));
    dispatch(getAutoregisterSexualOrientations({ code }));
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Autocadastro';
  }, []);

  if (!candidate) return <LoadingPage />;
  return (
    <Container className="justify-content-center mx-auto">
      <Row>
        <Col>
          <Card className="my-4">
            <CardBody>
              <div className="text-center">
                <img
                  className="rounded mx-auto col-6 col-sm-4 col-lg-2 col-xxl-1"
                  src={candidate.usuario.foto}
                  alt={
                    candidate.usuario.pessoa.nome +
                    ' ' +
                    candidate.usuario.pessoa.sobrenome
                  }
                />
                <h1 className="text-primary word-wrap mt-3">
                  {candidate.usuario.pessoa.nome +
                    ' ' +
                    candidate.usuario.pessoa.sobrenome}
                </h1>
                <h2 className="fs-1 mb-0">{candidate.numero}</h2>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h5 className="fs-4">Autocadastro</h5>
              <p className="text-muted mb-0 ">
                Preencha seus dados para se cadastrar na nossa lista de contatos
              </p>
            </CardBody>
          </Card>

          <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            <FormFormik>
              <FormCard onCards={handleCards} showBackButton={false} />
            </FormFormik>
          </Formik>
        </Col>
      </Row>

      <Row>
        <img
          src={logo}
          alt="Multivotos"
          className="col-4 col-sm-3 col-lg-2 pb-4 mx-auto"
        />
      </Row>
    </Container>
  );
}

export default SelfRegister;
