import { useState } from 'react';
import SidebarItem from '../SidebarItem';
import getUserDataAndAccessToken from '../../../../utils/actionsCookies/getUserDataAndAccessToken';

const SidebarItems = () => {
  const { userData } = getUserDataAndAccessToken();
  const { group, root } = userData;

  const isUserAdmin = group === 'Administrador';
  const isUserRoot = root;

  const collapseAllClosed = {
    home: false,
    voters: false,
    templates: false,
    settings: false,
    dashboard: false,
    candidates: false,
    multipliers: false,
    admin: false,
  };
  const [isOpenCollapse, setIsOpenCollapse] = useState(collapseAllClosed);
  const closeAll = () => setIsOpenCollapse(collapseAllClosed);

  const sidebarItems = [
    {
      id: 'home',
      link: '/home',
      label: 'Início',
      icon: 'bx bxs-home-smile',
      stateVariables: isOpenCollapse.home,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          home: !prev.home,
        }));
      },
    },

    {
      id: 'dashboard',
      link: '/#',
      label: 'Gráficos',
      icon: 'bx bxs-dashboard',
      stateVariables: isOpenCollapse.dashboard,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          dashboard: !prev.dashboard,
        }));
      },
      subItems: [
        {
          id: 'votersDashboard',
          parentId: 'dashboard',
          link: '/dashboard/voters',
          label: 'Eleitores',
          click: closeAll,
        },
        {
          id: 'researchDashboard',
          parentId: 'dashboard',
          link: '/dashboard/research',
          label: 'Pesquisa',
          click: closeAll,
        },
        {
          id: 'performanceDashboard',
          parentId: 'dashboard',
          link: '/dashboard/performance',
          label: 'Desempenho de Multiplicadores',
          click: closeAll,
        },
      ],
    },

    {
      link: '/#',
      id: 'candidates',
      icon: 'las la-user-tie',
      label: 'Candidatos(as)',
      stateVariables: isOpenCollapse.candidates,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          candidates: !prev.candidates,
        }));
      },
      subItems: [
        {
          id: 'newCandidate',
          parentId: 'candidates',
          link: '/candidates/new',
          label: 'Novo(a) Cantidato(a)',
          click: closeAll,
        },
        {
          label: 'Listagem',
          link: '/candidates',
          parentId: 'candidates',
          id: 'listingCandidates',
          click: closeAll,
        },
      ],
    },

    {
      link: '/#',
      id: 'multipliers',
      icon: 'las la-handshake',
      label: 'Multiplicadores(as)',
      stateVariables: isOpenCollapse.multipliers,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          multipliers: !prev.multipliers,
        }));
      },
      subItems: [
        {
          id: 'newMultiplier',
          parentId: 'multipliers',
          link: '/multipliers/new',
          label: 'Novo(a) Multiplicador(a)',
          click: closeAll,
        },
        {
          label: 'Listagem',
          link: '/multipliers',
          parentId: 'multipliers',
          id: 'listingMultipliers',
          click: closeAll,
        },
      ],
    },

    {
      link: '/#',
      id: 'voters',
      icon: 'bx bxs-group',
      label: 'Eleitores(as)',
      stateVariables: isOpenCollapse.voters,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          voters: !prev.voters,
        }));
      },
      subItems: [
        {
          id: 'newVoter',
          parentId: 'voters',
          link: '/voters/new',
          label: 'Novo(a) Eleitor(a)',
          click: closeAll,
        },
        {
          id: 'birthdays',
          parentId: 'voters',
          link: '/birthdays',
          label: 'Aniversariantes',
          click: closeAll,
        },
        {
          link: '/voters',
          label: 'Listagem',
          parentId: 'voters',
          id: 'listingVoters',
          click: closeAll,
        },
        {
          link: '/voters/municipal-research/no-registered',
          label: 'Pesquisa de eleitor não cadastrado',
          parentId: 'voters',
          id: 'listingVoters',
          click: closeAll,
        },
      ],
    },

    {
      link: '/#',
      id: 'templates',
      label: 'Modelos de mensagem',
      icon: 'bx bxs-chat',
      stateVariables: isOpenCollapse.templates,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          templates: !prev.templates,
        }));
      },
      subItems: [
        {
          id: 'newTemplate',
          parentId: 'templates',
          link: '/templates/new',
          label: 'Novo modelo',
          click: closeAll,
        },
        {
          label: 'Listagem',
          link: '/templates',
          parentId: 'templates',
          id: 'listingTemplates',
          click: closeAll,
        },
      ],
    },

    {
      link: '/#',
      id: 'settings',
      label: 'Configurações',
      icon: 'bx bxs-cog',
      stateVariables: isOpenCollapse.settings,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          settings: !prev.settings,
        }));
      },
      subItems: [
        {
          id: 'regions',
          parentId: 'settings',
          link: '/settings/regions',
          label: 'Regiões',
          click: closeAll,
        },
        {
          label: 'Etiquetas',
          link: '/settings/tags',
          parentId: 'settings',
          id: 'tags',
          click: closeAll,
        },
        {
          label: 'Interesses',
          link: '/settings/interests',
          parentId: 'settings',
          id: 'interests',
          click: closeAll,
        },
        {
          label: 'Parâmetros do sistema',
          link: '/settings/system-params',
          parentId: 'settings',
          id: 'system-params',
          click: closeAll,
        },
        {
          label: 'Políticos',
          link: '/settings/politicians',
          parentId: 'settings',
          id: 'politicians',
          click: closeAll,
        },
        {
          label: 'WhatsApp',
          link: '/settings/whatsapp',
          parentId: 'settings',
          id: 'settings-whatsapp',
          click: closeAll,
        },
        {
          label: 'Importar dados',
          link: '/settings/import-data',
          parentId: 'settings',
          id: 'settings-import-data',
          click: closeAll,
        },
      ],
    },

    {
      id: 'admin',
      link: '/#',
      label: 'Administração',
      icon: 'las la-user-cog',
      stateVariables: isOpenCollapse.admin,
      click: (event) => {
        event.preventDefault();
        setIsOpenCollapse((prev) => ({
          ...collapseAllClosed,
          admin: !prev.admin,
        }));
      },
      subItems: [
        {
          id: 'adminAccounts',
          parentId: 'admin',
          link: '/admin/accounts',
          label: 'Contas',
          click: closeAll,
        },
        {
          id: 'adminRootUsers',
          parentId: 'admin',
          link: '/admin/root-users',
          label: 'Usuários Root',
          click: closeAll,
        },
        {
          id: 'adminPlans',
          parentId: 'admin',
          link: '/admin/plans',
          label: 'Planos',
          click: closeAll,
        },
        {
          id: 'adminWhatsappSlots',
          parentId: 'admin',
          link: '/admin/whatsapp-slots',
          label: 'Slots WhatsApp',
          click: closeAll,
        },
      ],
    },
  ];

  const filteredSidebarItems = sidebarItems.filter(({ id }) => {
    const itemsBlockedForNonRootUsers = ['admin'];
    if (!isUserRoot && itemsBlockedForNonRootUsers.includes(id)) {
      return false;
    }

    if (isUserAdmin) return true;

    const itemsAccessNotAdmin = ['voters', 'birthdays', 'listingVoters'];
    return itemsAccessNotAdmin.includes(id);
  });

  return (
    <>
      {filteredSidebarItems.map((item, key) => (
        <SidebarItem item={item} key={key} />
      ))}
    </>
  );
};

export default SidebarItems;
