import { deleteRegion } from '../../../../../../../store/actions';

const actions = (data, dispatch, setModalData) => [
  {
    id: 1,
    title: 'Apagar',
    tag: 'button',
    icon: 'delete',
    text: 'Apagar',
    attributes: {
      onClick: () => {
        const newModalData = {
          isOpen: true,
          body: data.nome || '--',
          onConfirm: () => {
            dispatch(deleteRegion({ id: data.id }));
          },
        };
        setModalData((prev) => {
          return {
            ...prev,
            ...newModalData,
          };
        });
      },
    },
  },
];

export default actions;
