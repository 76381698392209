const headerTitles = [
  'Nome do(a) multiplicador(a)',
  'Email',
  'Grupo',
  'Nivel',
  'Genero',
  'Status',
  'Ações',
];

export default headerTitles;
