import removeSpecialChars from "../../removeSpecialChars";

const isDigitSequence = (CPF) => {
  return /^(.)\1+$/.test(CPF);
};

const cpfValidation = (CPF) => {
  if (!CPF) return false;

  const valueSanitized = removeSpecialChars(CPF);

  if (valueSanitized.length !== 11 || isDigitSequence(valueSanitized)) {
    return false;
  }

  let sum = 0;
  let rest;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(valueSanitized.charAt(i - 1), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(valueSanitized.charAt(9), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(valueSanitized.charAt(i - 1), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(valueSanitized.charAt(10), 10)) {
    return false;
  }

  return true;
};

export default cpfValidation;
