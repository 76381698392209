import Footer from './components/Footer';
import Header from './components/Header';
import TableBody from './components/TableBody';
import React, { useMemo, useState } from 'react';
import TableHeader from './components/TableHeader';
import LoadingPage from '../../../../ui/LoadingPage';
import addOptionForHowManyRecordsToShowPerPage from '../../../../../utils/actionsListing/addOptionForHowManyRecordsToShowPerPage';
import getOptionForHowManyRecordsToShowPerPage from '../../../../../utils/actionsListing/getOptionForHowManyRecordsToShowPerPage';
import {
  Card,
  Table,
  CardBody,
  Container,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  useRowSelect,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';

const DataTableWithPagination = ({
  columns,
  children,
  isLoading,
  data: values,
  emptyMessage,
  pathPageCreate,
  titleButtonHeader,
  isShowButtonCreateNewItem,
  isShowButtonToDisplayFilter,
  initialSortBy = [],
  pageContent = true,
  extraHeaderContent = null,
}) => {
  const data = useMemo(() => values, [values]);

  const defaultPageSize = getOptionForHowManyRecordsToShowPerPage();
  const defaultRegistersByPage = {
    label: defaultPageSize,
    value: defaultPageSize,
  };

  const [selectedRegistersByPage, setSelectedRegistersByPage] = useState(
    defaultRegistersByPage
  );

  const initialState = useMemo(() => {
    return {
      pageIndex: 0,
      selectedRowIds: 0,
      sortBy: initialSortBy,
      pageSize: defaultPageSize,
    };
  }, []);

  const {
    prepareRow,
    getTableProps,
    getTableBodyProps,
    page,
    nextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    canNextPage,
    setPageIndex,
    headerGroups,
    previousPage,
    setGlobalFilter,
    canPreviousPage,
    state: { pageIndex },
  } = useTable(
    {
      data,
      columns,
      initialState,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const handleChangeSearch = useAsyncDebounce(({ target }) => {
    const { value } = target;
    setGlobalFilter(value || undefined);
  }, 200);

  const handleChangeRegistersByPage = (newValue) => {
    setSelectedRegistersByPage(newValue);

    gotoPage(0);
    setPageSize(newValue.value);
    addOptionForHowManyRecordsToShowPerPage(newValue.value);
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Container className={pageContent ? 'page-content' : ''} fluid>
          <Card>
            <CardHeader>
              <Header
                pathPageCreate={pathPageCreate}
                titleButtonHeader={titleButtonHeader}
                handleChangeSearch={handleChangeSearch}
                selectedRegistersByPage={selectedRegistersByPage}
                isShowButtonCreateNewItem={isShowButtonCreateNewItem}
                isShowButtonToDisplayFilter={isShowButtonToDisplayFilter}
                handleChangeRegistersByPage={handleChangeRegistersByPage}
                extraHeaderContent={extraHeaderContent}
              >
                {children}
              </Header>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-1">
                <Table
                  hover
                  {...getTableProps()}
                  className="mb-0 align-middle table-borderless"
                >
                  <TableHeader headerGroups={headerGroups} />

                  <TableBody
                    page={page}
                    columns={columns}
                    prepareRow={prepareRow}
                    emptyMessage={emptyMessage}
                    getTableBodyProps={getTableBodyProps}
                  />
                </Table>
              </div>
            </CardBody>

            <CardFooter>
              <Footer
                data={data}
                page={page}
                nextPage={nextPage}
                gotoPage={gotoPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                canNextPage={canNextPage}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
              />
            </CardFooter>
          </Card>
        </Container>
      )}
    </>
  );
};

export default DataTableWithPagination;
