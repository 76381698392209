import * as Yup from 'yup';
import cpfValidation from '../../../utils/validations/cpfValidation';

const validationCpf = (isOptional = false) => {
  const cpfSchema = Yup.string().test(
    'is-CPF-valid',
    'CPF inválido',
    (value) => {
      if (!value) return true;
      return cpfValidation(value.trim());
    }
  );

  return {
    cpf: isOptional ? cpfSchema : cpfSchema.required('Informe o CPF'),
  };
};

export default validationCpf;
