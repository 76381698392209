import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createDynamicSelector from '../../common/createDynamicSelector';
import DataTableWithPagination from './components/DataTableWithPagination';

const ListingPrototypeControlledByBackend = ({
  title,
  entityName,
  handleData,
  headerTitles,
  emptyMessage,
  dataSelector,
  deleteAction,
  pathPageCreate,
  children = null,
  selectorOptions,
  titleButtonHeader,
  isShowButtonCreateNewItem = true,
  isShowButtonToDisplayFilter = true,
  initialSortBy = [],
  extraHeaderContent = null,
}) => {
  const dispatch = useDispatch();

  const lastItem = selectorOptions[selectorOptions.length - 1];
  const selectLayoutProperties = createDynamicSelector(
    entityName,
    selectorOptions
  );

  const { isLoading, [lastItem]: entityData } = useSelector(
    selectLayoutProperties
  );

  const columns = useMemo(
    () =>
      headerTitles.map((title) => ({
        Header: title,
        accessor: title,
        disableSortBy: true,
      })),
    [headerTitles]
  );

  const onDelete = (itemId) => {
    dispatch(deleteAction(itemId));
  };

  const fetchData = async (pageIndex, pageSize) => {
    dispatch(dataSelector({ pageIndex, pageSize }));
  };

  useEffect(() => {
    document.title = title;
  }, []);

  const isEntityData = Object.keys(entityData).length > 0;
  const data = handleData(isEntityData ? entityData : [], onDelete);

  const { paginas: totalPages = 0, registros: totalRegisters } = isEntityData
    ? entityData
    : {};

  const memoizedComponent = useMemo(() => {
    return (
      <DataTableWithPagination
        extraHeaderContent={extraHeaderContent}
        data={data}
        columns={columns}
        fetchData={fetchData}
        isLoading={isLoading}
        totalPages={totalPages}
        emptyMessage={emptyMessage}
        totalRegisters={totalRegisters}
        pathPageCreate={pathPageCreate}
        titleButtonHeader={titleButtonHeader}
        isShowButtonCreateNewItem={isShowButtonCreateNewItem}
        isShowButtonToDisplayFilter={isShowButtonToDisplayFilter}
        initialSortBy={initialSortBy}
      >
        {children}
      </DataTableWithPagination>
    );
  }, [
    columns,
    fetchData,
    isLoading,
    entityData,
    entityName,
    deleteAction,
    pathPageCreate,
  ]);

  return memoizedComponent;
};

export default ListingPrototypeControlledByBackend;
