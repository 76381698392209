import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import React, { useEffect } from 'react';
import VerticalLayout from '../VerticalLayout';
import logo from '../../assets/images/logo.png';
import logoSm from '../../assets/images/simbolo.png';

const Sidebar = () => {
  const addEventListenerOnSmHoverMenu = () => {
    const smHoverKey = 'sm-hover';
    const smHoverActiveKey = 'sm-hover-active';
    const sidebarSizeKey = 'data-sidebar-size';

    const sidebarSize = document.documentElement.getAttribute(sidebarSizeKey);
    const setAttribute = (qualifiedName, value) => {
      document.documentElement.setAttribute(qualifiedName, value);
    };

    if (sidebarSize === smHoverKey) {
      setAttribute(sidebarSizeKey, smHoverActiveKey);
      return;
    }

    if (sidebarSize === smHoverActiveKey) {
      setAttribute(sidebarSizeKey, smHoverKey);
      return;
    }

    setAttribute(sidebarSizeKey, smHoverKey);
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-layout', 'vertical');
    const verticalOverlay = document.getElementsByClassName('vertical-overlay');

    if (!verticalOverlay) return;

    verticalOverlay[0].addEventListener('click', () => {
      document.body.classList.remove('vertical-sidebar-enable');
    });
  }, []);

  return (
    <>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to="/home" className="logo">
            <span className="logo-sm">
              <img src={logoSm} alt="Logo" width="100%" height="40" />
            </span>

            <span className="logo-lg">
              <img src={logo} alt="Logo" width="100%" height="40" />
            </span>
          </Link>

          <button
            type="button"
            id="vertical-hover"
            onClick={addEventListenerOnSmHoverMenu}
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          >
            <i className="ri-record-circle-line" />
          </button>
        </div>

        <SimpleBar id="scrollbar" className="h-100">
          <Container fluid>
            <div id="two-column-menu" />

            <ul className="navbar-nav" id="navbar-nav">
              <VerticalLayout />
            </ul>
          </Container>
        </SimpleBar>

        <div className="sidebar-background" />
      </div>

      <div className="vertical-overlay" />
    </>
  );
};

export default Sidebar;
