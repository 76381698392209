import {
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  GENERATE_TEMPLATE_CSV,
  GET_TEMPLATE_BY_ID,
  GET_TEMPLATE_VARIABLES,
  SEND_TEMPLATE_MESSAGES,
  TEMPLATE_ERROR,
  TEMPLATE_LIST,
  TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_AND_SEND_MESSAGES,
} from './actionTypes';

export const createTemplate = (payload) => {
  return {
    type: CREATE_TEMPLATE,
    payload,
  };
};

export const updateTemplate = (payload) => {
  return {
    type: UPDATE_TEMPLATE,
    payload,
  };
};

export const getTemplateById = (payload) => {
  return {
    type: GET_TEMPLATE_BY_ID,
    payload,
  };
};

export const templateList = () => {
  return {
    type: TEMPLATE_LIST,
    payload: {},
  };
};

export const deleteTemplate = (payload) => {
  return {
    type: DELETE_TEMPLATE,
    payload,
  };
};

export const templateError = () => {
  return {
    type: TEMPLATE_ERROR,
    payload: {},
  };
};

export const templateSuccess = (payload) => {
  return {
    type: TEMPLATE_SUCCESS,
    payload,
  };
};

export const generateTemplateCSV = (payload) => {
  return {
    type: GENERATE_TEMPLATE_CSV,
    payload,
  };
};

export const sendTemplateMessages = (payload) => {
  return {
    type: SEND_TEMPLATE_MESSAGES,
    payload,
  };
};

export const updateTemplateAndSendMessages = (payload) => {
  return {
    type: UPDATE_TEMPLATE_AND_SEND_MESSAGES,
    payload,
  };
};

export const getTemplateVariables = () => {
  return {
    type: GET_TEMPLATE_VARIABLES,
    payload: {},
  };
};
