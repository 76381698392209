import { Card } from '../Login/styles';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import Form from '../../../components/Formik/Form';
import { Formik, Form as FormFormik } from 'formik';
import logo from '../../../assets/images/logo-centralizada.png';
import { useDispatch, useSelector } from 'react-redux';
import withRouter from '../../../components/common/withRouter';
import LoadingButton from '../../../components/ui/LoadingButton';
import ParticlesAuth from '../../AuthenticationInner/ParticlesAuth';
import useForgetPasswordSchema from '../../../schemas/forgetPassword';
import { Row, Col, Alert, Button, CardBody, Container } from 'reactstrap';
import { sendEmailWithCode } from '../../../store/auth/forgetPassword/actions';
import createDynamicSelector from '../../../components/common/createDynamicSelector';

const ForgetPassword = (props) => {
  const { router } = props;
  const { navigate } = router;

  const dispatch = useDispatch();

  const initialValues = { email: '' };
  const schema = useForgetPasswordSchema();

  const selector = createDynamicSelector('ForgetPassword', ['isLoading']);
  const { isLoading } = useSelector(selector);

  const handleSubmit = ({ email }, { resetForm }) => {
    dispatch(sendEmailWithCode({ email, resetForm }));
  };

  useEffect(() => {
    document.title = 'Redefinir senha';
  }, []);

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <img src={logo} alt="log" className="logo" />

                    <h5>Esqueceu sua senha?</h5>

                    <p className="text-muted">
                      Não tem problema. Você pode redefinir sua senha
                      rapidamente e de forma simples. ;)
                    </p>
                  </div>

                  <Alert
                    role="alert"
                    className="border-0 alert-warning text-center mb-2 mx-2"
                  >
                    Digite seu e-mail e as instruções serão enviadas para você!
                  </Alert>

                  <div className="p-2">
                    <Formik
                      enableReinitialize
                      onSubmit={handleSubmit}
                      validationSchema={schema}
                      initialValues={initialValues}
                    >
                      <FormFormik>
                        <div className="mb-4">
                          <Form.Label htmlFor="email">E-mail</Form.Label>

                          <Form.Input
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Seu E-mail"
                          />

                          <Form.ErrorMessage field="email" />
                        </div>

                        <div className="text-center mt-4">
                          <Button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary w-100"
                          >
                            {isLoading ? <LoadingButton /> : 'Enviar e-mail'}
                          </Button>
                        </div>
                      </FormFormik>
                    </Formik>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Espere, eu lembro da minha senha...{' '}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    Clique aqui
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(ForgetPassword);
