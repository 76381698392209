import {
  CHECK_IF_WHATSAPP_IS_CONNECTED,
  DISCONNECT_WHATSAPP,
  DOWNLOAD_DATA_FILE_MODEL,
  GET_PENDING_SYSTEM_PARAMS,
  GET_SYSTEM_PARAMS,
  GET_SYSTEM_PARAMS_DESCRIPTIONS,
  GET_WHATSAPP_QRCODE,
  SAVE_SYSTEM_PARAMS,
  SETTINGS_ERROR,
  SETTINGS_SUCCESS,
  UPDATE_SYSTEM_PARAM,
  UPLOAD_VOTERS_DATA_FILE,
} from './actionTypes';

export const settingsError = () => {
  return {
    type: SETTINGS_ERROR,
    payload: {},
  };
};

export const settingsSuccess = (payload) => {
  return {
    type: SETTINGS_SUCCESS,
    payload,
  };
};

export const getSystemParamsDescriptions = () => {
  return {
    type: GET_SYSTEM_PARAMS_DESCRIPTIONS,
    payload: {},
  };
};

export const getSystemParams = () => {
  return {
    type: GET_SYSTEM_PARAMS,
    payload: {},
  };
};

export const getPendingSystemParams = () => {
  return {
    type: GET_PENDING_SYSTEM_PARAMS,
    payload: {},
  };
};

export const saveSystemParams = (payload) => {
  return {
    type: SAVE_SYSTEM_PARAMS,
    payload,
  };
};

export const updateSystemParam = (payload) => {
  return {
    type: UPDATE_SYSTEM_PARAM,
    payload,
  };
};

export const getWhatsAppQrCode = () => {
  return {
    type: GET_WHATSAPP_QRCODE,
    payload: {},
  };
};

export const checkIfWhatsAppIsConnected = () => {
  return {
    type: CHECK_IF_WHATSAPP_IS_CONNECTED,
    payload: {},
  };
};

export const disconnectWhatsApp = () => {
  return {
    type: DISCONNECT_WHATSAPP,
    payload: {},
  };
};

export const uploadVotersDataFile = (payload) => {
  return {
    type: UPLOAD_VOTERS_DATA_FILE,
    payload,
  };
};

export const downloadDataFileModel = () => {
  return {
    type: DOWNLOAD_DATA_FILE_MODEL,
    payload: {},
  };
};
