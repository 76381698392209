const handleDataSubmit = ({ data, isUpdate }) => {
  const formattedData = {
    valor: data.value,
  };

  if (isUpdate) {
    formattedData.chave = data.key;
  } else {
    formattedData.chave = data.key.value;
  }

  return formattedData;
};

export default handleDataSubmit;
