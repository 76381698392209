import { useSelector as handleUseSelector } from 'react-redux';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';

const fields = () => {
  const selector = createDynamicSelector('GeneralFeature', ['positions']);

  const { positions } = handleUseSelector(selector);

  return [
    [
      {
        md: 6,
        type: 'text',
        name: 'name',
        label: 'Nome',
        placeholder: 'Informe o nome do político',
        isRequired: true,
      },
      {
        md: 6,
        name: 'position',
        type: 'select',
        label: 'Cargo',
        isRequired: true,
        required: false,
        options: positions.map((position) => ({
          label: position.descricao,
          value: position.id,
        })),
      },
    ],
  ];
};

export default fields;
