import { createSelector } from 'reselect';

const createDynamicSelector = (selectorName, options) => {
  const selectState = (state) => state[selectorName];

  return createSelector(selectState, (data) => {
    const result = options.reduce((acc, currentOption) => {
      acc[currentOption] = data[currentOption];
      return acc;
    }, {});

    return result;
  });
};

export default createDynamicSelector;
