import { useEffect, useState } from 'react';
import Form from '../../../components/Formik/Form';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form as FormFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../../store/actions';
import withRouter from '../../../components/common/withRouter';
import LoadingButton from '../../../components/ui/LoadingButton';
import FieldPassword from '../../../components/ui/FieldPassword';
import ParticlesAuth from '../../AuthenticationInner/ParticlesAuth';
import useUpdatePasswordSchema from '../../../schemas/updatePassword';
import { Row, Col, Card, Alert, Button, CardBody, Container } from 'reactstrap';
import createDynamicSelector from '../../../components/common/createDynamicSelector';

const FormUpdatePassword = (props) => {
  const { router } = props;
  const { navigate } = router;

  const { code } = useParams();

  const dispatch = useDispatch();

  const schema = useUpdatePasswordSchema();
  const initialValues = { email: '', password: '', repeatPassword: '' };

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowRepeatPassword, setIsShowRepeatPassword] = useState(false);

  const handleSubmit = (values) => {
    dispatch(updatePassword({ ...values, code, navigate }));
  };

  const selector = createDynamicSelector('ForgetPassword', ['isLoading']);
  const { isLoading } = useSelector(selector);

  useEffect(() => {
    document.title = 'Redefinir senha';
  }, []);

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <Row className="justify-content-center">
                    <div className="text-center">
                      <h5>Redefinir senha</h5>
                    </div>
                  </Row>

                  <Row className="justify-content-center text-center p-3">
                    <Alert color="danger">
                      Não recebeu o código?{' '}
                      <Link
                        to="/forgot-password"
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        <strong className="color-primary">Clique aqui</strong>
                      </Link>
                      .
                    </Alert>
                  </Row>

                  <div className="p-2">
                    <Formik
                      enableReinitialize
                      onSubmit={handleSubmit}
                      validationSchema={schema}
                      initialValues={initialValues}
                    >
                      <FormFormik>
                        <Form.Field>
                          <Form.Label htmlFor="code">E-mail</Form.Label>

                          <Form.Input
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Informe seu E-mail"
                          />

                          <Form.ErrorMessage field="email" />
                        </Form.Field>

                        <Form.Field>
                          <FieldPassword
                            placeholder="Sua nova senha"
                            isShowPassword={isShowPassword}
                            setIsShowPassword={setIsShowPassword}
                          />
                        </Form.Field>

                        <Form.Field>
                          <FieldPassword
                            name="repeatPassword"
                            placeholder="Sua nova senha"
                            label="Repita sua nova senha"
                            isShowPassword={isShowRepeatPassword}
                            setIsShowPassword={setIsShowRepeatPassword}
                          />
                        </Form.Field>

                        <div className="text-center mt-4">
                          <Button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            {isLoading ? <LoadingButton /> : 'Atualizar senha'}
                          </Button>
                        </div>
                      </FormFormik>
                    </Formik>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Espere, eu lembro da minha senha...{' '}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    Clique aqui
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(FormUpdatePassword);
