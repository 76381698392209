const handleInitialValuesCreate = () => {
  const initialValues = {
    key: '',
    value: '',
  };

  return initialValues;
};

export default handleInitialValuesCreate;
