import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import handleCards from '../steps/handleCards';
import { createVoter } from '../../../store/actions';
import useVotersSchema from '../../../schemas/voters';
import handleDataSubmit from '../steps/handleDataSubmit';
import withRouter from '../../../components/common/withRouter';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate';
import {
  getTags,
  getRegions,
  getReligions,
  getInterests,
  getSchoolings,
  getSexualOrientations,
} from '../../../store/generalFeature/actions';

const CreateNewVoter = ({ router }) => {
  const dispatch = useDispatch();

  const validationSchema = useVotersSchema();
  const initialValues = handleInitialValuesCreate();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: { ...rest } });

    dispatch(createVoter({ ...data, navigate }));
  };

  useEffect(() => {
    dispatch(getTags());
    dispatch(getRegions());
    dispatch(getReligions());
    dispatch(getInterests());
    dispatch(getSchoolings());
    dispatch(getSexualOrientations());
  }, [dispatch]);

  return (
    <FormPrototype
      router={router}
      onCards={handleCards}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Criar novo(a) Eleitor(a)"
    />
  );
};

export default withRouter(CreateNewVoter);
