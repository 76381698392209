import {
  FILTER_MESSAGES_BY_TEMPLATE_ID,
  GENERATE_MESSAGES,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  REGISTER_MESSAGE_SENT,
  SEND_WHATSAPP_VIA_API,
} from './actionTypes';

export const generateMessages = (payload) => {
  return {
    type: GENERATE_MESSAGES,
    payload,
  };
};

export const messageError = () => {
  return {
    type: MESSAGE_ERROR,
    payload: {},
  };
};

export const messageSuccess = (payload) => {
  return {
    type: MESSAGE_SUCCESS,
    payload,
  };
};

export const filterMessagesByTemplateId = (payload) => {
  return {
    type: FILTER_MESSAGES_BY_TEMPLATE_ID,
    payload,
  };
};

export const registerMessageSent = (payload) => {
  return {
    type: REGISTER_MESSAGE_SENT,
    payload,
  };
};

export const sendWhatsappViaApi = (payload) => {
  return {
    type: SEND_WHATSAPP_VIA_API,
    payload,
  };
};
