import api from '../../services/api';
import toast from '../../components/ui/toast';
import { GET_REPORT_BY_CATEGORY } from './actionTypes';
import { reportsError, reportsSuccess } from './actions';
import { takeEvery, fork, all, call, put } from 'redux-saga/effects';

function* handleSuccess(data) {
  yield put(reportsSuccess(data));
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(reportsError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status === 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* getReportByCategory({ payload }) {
  try {
    const { category, key } = payload;

    const { data } = yield call(api.get, `/relatorios${category}`);
    yield handleSuccess({ [key]: data });
  } catch (error) {
    const errorDefaultMessage = `Oops, não foi possível obter os dados para realizar o relatório.`;
    yield handleError({ error, errorDefaultMessage });
  }
}

export function* watchGetReportByCategory() {
  yield takeEvery(GET_REPORT_BY_CATEGORY, getReportByCategory);
}

function* reports() {
  yield all([fork(watchGetReportByCategory)]);
}

export default reports;
