import api from '../../../services/api';
import toast from '../../../components/ui/toast';
import { takeEvery, fork, all, call, put } from 'redux-saga/effects';
import { SEND_EMAIL_WITH_CODE, UPDATE_PASSWORD } from './actionTypes';
import { forgetPasswordError, forgetPasswordSuccess } from './actions';

function* updatePassword({ payload }) {
  try {
    const { code, email, navigate, password: senha } = payload;

    yield call(api.post, `/usuarios/redefinir/${code}`, { email, senha });

    yield put(forgetPasswordSuccess());
    toast('success', 'Senha atualizada com sucesso.');

    navigate('/login');
  } catch (error) {
    yield put(forgetPasswordError());

    const { response } = error;
    const errorDefaultMessage =
      'Oops, não foi possível atualizar sua senha. Tente novamente.';

    const message = response?.data || errorDefaultMessage;
    toast('error', message);
  }
}

function* sendEmailWithCode({ payload }) {
  try {
    const { email, resetForm } = payload;
    yield call(api.get, `/usuarios/recupera/${email}`);

    yield put(forgetPasswordSuccess());

    resetForm({});
    toast('success', 'O e-mail foi enviado com sucesso.');
  } catch (error) {
    yield put(forgetPasswordError());

    const { response } = error;
    const errorDefaultMessage = 'Oops, não foi possível enviar o e-mail.';

    const message = response?.data || errorDefaultMessage;
    toast('error', message);
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}

export function* watchSendEmailWithCode() {
  yield takeEvery(SEND_EMAIL_WITH_CODE, sendEmailWithCode);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUpdatePassword), fork(watchSendEmailWithCode)]);
}

export default forgetPasswordSaga;
