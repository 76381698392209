import { useFormikContext } from 'formik';
import { FormFeedback } from 'reactstrap';

export default function ErrorMessage({ field }) {
  const { errors, touched } = useFormikContext();

  const hasValidationError = (fieldName) => {
    return touched[fieldName] && errors[fieldName];
  };

  const isInvalidField = hasValidationError(field);
  if (!isInvalidField) return null;

  return <FormFeedback type="invalid">{errors[field]}</FormFeedback>;
}
