const chartColors = [
  '#1693a7',
  '#9c5252',
  '#376930',
  '#c7540c',
  '#37053b',
  '#b08827',
  '#c26166',
  '#648276',
];

export default chartColors;
