import Icon from '../../../../../components/ui/Icon';
import regionFields from '../regionFields';

const handleCards = () => {
  const regionFieldsData = regionFields();

  const cards = [
    {
      titleHeader: 'Adicionar região',
      Icon: () => <Icon name="plus" />,
      fields: regionFieldsData,
      isShowFormControlButtons: true,
      showCardBackButton: false,
    },
  ];

  return cards;
};

export default handleCards;
