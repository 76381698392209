import { changePoliticianSituation } from '../../../../../store/actions';
import handleInitialValuesUpdate from '../handleInitialValuesUpdate';

const actionsOptions = ({ data, dispatch, setFormInitialValues }) => [
  {
    id: 1,
    title: 'Editar',
    tag: 'button',
    icon: 'pencil',
    text: 'Editar',
    attributes: {
      onClick: () => {
        setFormInitialValues(handleInitialValuesUpdate(data));
      },
    },
  },
  {
    id: 2,
    title: 'Alterar situação',
    tag: 'button',
    icon: 'reload',
    text: 'Alterar situação',
    attributes: {
      onClick: () => {
        dispatch(changePoliticianSituation({ id: data.id }));
      },
    },
  },
];

export default actionsOptions;
