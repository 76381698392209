import React from 'react';
import CreateNewInterest from './CreateNewInterest';
import ListingInterests from './ListingInterests';

export default function Interests() {
  return (
    <div className="page-content">
      <CreateNewInterest />
      <ListingInterests />
    </div>
  );
}
