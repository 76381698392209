import {
  UPDATE_PASSWORD,
  SEND_EMAIL_WITH_CODE,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_SUCCESS,
} from './actionTypes';

const initialState = {
  isLoading: false,
};

const forgetPassword = (state = initialState, { type }) => {
  switch (type) {
    case UPDATE_PASSWORD:
    case SEND_EMAIL_WITH_CODE:
      return {
        ...state,
        isLoading: true,
      };

    case FORGET_PASSWORD_ERROR:
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default forgetPassword;
