import React from 'react';
import ListingTags from './ListingTags';
import CreateNewTag from './CreateNewTag';

export default function Tags() {
  return (
    <div className="page-content">
      <CreateNewTag />
      <ListingTags />
    </div>
  );
}
