const headerTitles = [
  'Nome',
  'Email',
  'Telefone',
  'Genero',
  'Autocadastro',
  'Ações',
];

export default headerTitles;
