import { useSelector as handleUseSelector } from 'react-redux';
import createDynamicSelector from '../../../../components/common/createDynamicSelector';

const registrationField = ({ md = 4, isRequired = false }) => {
  const selector = createDynamicSelector('Users', ['registrants']);

  const { registrants } = handleUseSelector(selector);

  return {
    md,
    isRequired,
    type: 'select',
    required: false,
    name: 'registrant',
    label: 'Cadastrante',
    options: registrants.map(({ id: value, nome: label }) => ({
      value,
      label,
    })),
  };
};

export default registrationField;
