import React from 'react';
import { useFormikContext } from 'formik';
import { Input as InputReactstrap } from 'reactstrap';

export default function Input({ name, onChange, ...rest }) {
  const { handleChange, handleBlur, touched, errors, values } =
    useFormikContext();

  const hasValidationError = touched[name] && errors[name];
  const isInvalidField = !!hasValidationError;

  const handleInputChange = (event) => {
    handleChange(event);
    onChange && onChange(event.target.value);
  };

  return (
    <InputReactstrap
      name={name}
      onBlur={handleBlur}
      className="form-control"
      invalid={isInvalidField}
      onChange={handleInputChange}
      value={values[name] === 0 ? 0 : values[name] || ''}
      {...rest}
    />
  );
}
