import { useEffect, useRef } from 'react';
import handleCards from '../steps/handleCards';
import { useDispatch, useSelector } from 'react-redux';
import handleDataSubmit from '../steps/handleDataSubmit';
import useCandidatesSchema from '../../../schemas/candidates';
import withRouter from '../../../components/common/withRouter';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import {
  getParties,
  getPositions,
  createCandidate,
  getSexualOrientations,
} from '../../../store/actions';

const CreateNewCandidate = ({ router }) => {
  const dispatch = useDispatch();

  const formRef = useRef();

  const validationSchema = useCandidatesSchema(formRef);
  const initialValues = handleInitialValuesCreate();

  const selector = createDynamicSelector('Users', ['user']);
  const { user } = useSelector(selector);

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;

    const userId = user.id;
    const data = handleDataSubmit({
      data: { ...rest, userId, photo: formRef },
    });

    dispatch(createCandidate({ ...data, navigate }));
  };

  useEffect(() => {
    dispatch(getParties());
    dispatch(getPositions());
    dispatch(getSexualOrientations());
  }, [dispatch]);

  return (
    <FormPrototype
      router={router}
      onCards={handleCards}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Criar novo(a) Candidato(a)"
      ref={formRef}
    />
  );
};

export default withRouter(CreateNewCandidate);
