import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import downloadCSVDataAsFile from '../../utils/downloadCSVDataAsFile';
import getCurrentDateTimeFileString from '../../utils/getCurrentDateTimeFileString';
import {
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  GENERATE_TEMPLATE_CSV,
  GET_TEMPLATE_BY_ID,
  GET_TEMPLATE_VARIABLES,
  SEND_TEMPLATE_MESSAGES,
  TEMPLATE_LIST,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_AND_SEND_MESSAGES,
} from './actionTypes';
import {
  templateError,
  templateList as templateListAction,
  templateSuccess,
} from './actions';

function* handleSuccess({ navigate, message }) {
  toast('success', message);
  yield put(templateSuccess());

  navigate && navigate('/templates');
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(templateError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* createTemplate({ payload }) {
  try {
    const { navigate, ...data } = payload;
    yield call(api.post, '/modelos', data);

    const message = 'Modelo de mensagem criado com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível criar um novo modelo.';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* templateList() {
  try {
    const { data } = yield call(api.get, '/modelos');
    yield put(templateSuccess({ templates: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar as mensagens.');
    yield put(templateError());
  }
}

function* updateTemplate({ payload }) {
  try {
    const { templateId, navigate, ...data } = payload;
    yield call(api.put, `/modelos/${templateId}`, data);

    yield put(templateListAction());

    const message = 'Modelo atualizado com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível atualizar o modelo.';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* getTemplateById({ payload }) {
  try {
    const { templateId } = payload;

    if (templateId) {
      const route = `/modelos/${templateId}`;
      const { data } = yield call(api.get, route);

      yield put(templateSuccess({ template: data }));
    }
  } catch (error) {
    toast('error', 'Oops, não foi possível pegar os dados do modelo.');
    yield put(templateError());
  }
}

function* deleteTemplate({ payload }) {
  try {
    const { templateId } = payload;

    if (!templateId) throw Error();
    const route = `/modelos/${templateId}`;
    yield call(api.delete, route);

    yield put(templateListAction());

    const message = 'Modelo excluído com sucesso.';
    yield handleSuccess({ message });
  } catch (error) {
    toast('error', 'Oops, não foi possível apagar o modelo.');
    yield put(templateError());
  }
}

function* generateTemplateCSV({ payload }) {
  try {
    const { templateId } = payload;
    const { data } = yield call(api.get, `/mensagens/${templateId}/csv`);

    downloadCSVDataAsFile(
      [data],
      `modelo#${templateId}-${getCurrentDateTimeFileString()}`
    );

    const message = 'CSV gerado com sucesso';
    yield handleSuccess({ message });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível gerar o CSV.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* sendTemplateMessages({ payload }) {
  try {
    const { templateId } = payload;

    if (!templateId) throw Error();
    const route = `/mensagens/modelo/enviar/${templateId}`;
    yield call(api.post, route);

    const message = 'Mensagens enviadas com sucesso.';
    yield handleSuccess({ message });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível enviar as mensagens.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* updateTemplateAndSendMessages({ payload }) {
  try {
    const { templateId, navigate, ...data } = payload;
    yield call(api.put, `/modelos/${templateId}`, data);

    const message = 'Modelo atualizado com sucesso.';
    yield handleSuccess({ message });

    yield put(sendTemplateMessages({ templateId }));
    yield put(templateListAction());
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível atualizar o modelo.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getTemplateVariables() {
  try {
    const { data: variables } = yield call(api.get, `/variaveis`);
    yield put(templateSuccess({ variables }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível carregar as variáveis de template.';
    yield handleError({ error, errorDefaultMessage });
  }
}

export function* watchTemplateList() {
  yield takeEvery(TEMPLATE_LIST, templateList);
}

export function* watchCreateTemplate() {
  yield takeEvery(CREATE_TEMPLATE, createTemplate);
}

export function* watchUpdateTemplate() {
  yield takeEvery(UPDATE_TEMPLATE, updateTemplate);
}

export function* watchGetTemplateById() {
  yield takeEvery(GET_TEMPLATE_BY_ID, getTemplateById);
}

export function* watchDeleteTemplate() {
  yield takeEvery(DELETE_TEMPLATE, deleteTemplate);
}

export function* watchGenerateTemplateCSV() {
  yield takeEvery(GENERATE_TEMPLATE_CSV, generateTemplateCSV);
}

export function* watchSendTemplateMessages() {
  yield takeEvery(SEND_TEMPLATE_MESSAGES, sendTemplateMessages);
}

export function* watchUpdateTemplateAndSendMessages() {
  yield takeEvery(
    UPDATE_TEMPLATE_AND_SEND_MESSAGES,
    updateTemplateAndSendMessages
  );
}

export function* watchGetTemplateVariables() {
  yield takeEvery(GET_TEMPLATE_VARIABLES, getTemplateVariables);
}

function* templates() {
  yield all([
    fork(watchTemplateList),
    fork(watchCreateTemplate),
    fork(watchUpdateTemplate),
    fork(watchGetTemplateById),
    fork(watchDeleteTemplate),
    fork(watchGenerateTemplateCSV),
    fork(watchSendTemplateMessages),
    fork(watchUpdateTemplateAndSendMessages),
    fork(watchGetTemplateVariables),
  ]);
}

export default templates;
