import Users from './users/saga';
import Voters from './voters/saga';
import Layout from './layout/saga';
import Reports from './reports/saga';
import Login from './auth/login/saga';
import Accounts from './accounts/saga';
import Messages from './messages/saga';
import Candidates from './candidates/saga';
import Multipliers from './multipliers/saga';
import { all, fork } from 'redux-saga/effects';
import GeneralFeature from './generalFeature/saga';
import ForgetPassword from './auth/forgetPassword/saga';
import Templates from './templates/saga';
import Settings from './settings/saga';
import Politicians from './politicians/saga';
import Management from './management/saga';

export default function* rootSaga() {
  yield all([
    fork(Login),
    fork(Users),
    fork(Layout),
    fork(Voters),
    fork(Reports),
    fork(Accounts),
    fork(Messages),
    fork(Candidates),
    fork(Multipliers),
    fork(ForgetPassword),
    fork(GeneralFeature),
    fork(Templates),
    fork(Settings),
    fork(Politicians),
    fork(Management),
  ]);
}
