import { Row } from 'reactstrap';
import Icon from '../../../../../../ui/Icon';
import { BackAndNextButton, ButtonItem, InfoBarResult } from './styles';

const Footer = ({
  page,
  gotoPage,
  nextPage,
  pageIndex,
  pageOptions,
  canNextPage,
  previousPage,
  totalRegisters,
  canPreviousPage,
}) => {
  const totalPages = pageOptions.length;
  const maxButtons = 5;
  let startPage = Math.max(0, pageIndex - Math.floor(maxButtons / 2));
  let endPage = startPage + maxButtons - 1;

  if (endPage >= totalPages) {
    endPage = totalPages - 1;
    startPage = Math.max(0, endPage - maxButtons + 1);
  }

  const visiblePages = [];

  if (startPage > 0) {
    visiblePages.push(0); // primeira página
    if (startPage > 1) visiblePages.push('...'); // elipses
  }

  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  if (endPage < totalPages - 1) {
    if (endPage < totalPages - 2) visiblePages.push('...'); // elipses
    visiblePages.push(totalPages - 1); // última página
  }

  return (
    <Row className="align-items-center g-3 text-center text-sm-start">
      <InfoBarResult className="col-sm">
        <div>
          Mostrando
          <span className="fw-semibold ms-1">{page.length}</span> de{' '}
          <span className="fw-semibold">{totalRegisters}</span> Resultados.
        </div>
      </InfoBarResult>

      <div className="col-sm-auto">
        <div className="pagination pagination-separated pagination-md justify-content-end gap-1 mb-0">
          {visiblePages.map((index, key) => {
            const isNumber = typeof index === 'number';
            const item = isNumber ? pageOptions[index] : null;
            return (
              <ButtonItem
                key={key}
                disabled={!isNumber}
                onClick={() => isNumber && gotoPage(item)}
                color={pageIndex === item ? 'none' : 'light'}
                className={pageIndex === item ? 'btn-primary' : ''}
                aria-current={pageIndex === item ? 'page' : undefined}
              >
                {isNumber ? item + 1 : '...'}
              </ButtonItem>
            );
          })}

          <div className="d-flex gap-1">
            <BackAndNextButton
              type="button"
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
              className="page-item btn btn-primary"
            >
              <Icon size={1.3} name="chevronLeft" />
            </BackAndNextButton>

            <BackAndNextButton
              type="button"
              disabled={!canNextPage}
              onClick={() => nextPage()}
              className="page-item btn btn-primary"
            >
              <Icon size={1.3} name="chevronRight" />
            </BackAndNextButton>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default Footer;
