import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import {
  CHANGE_POLITICIAN_SITUATION,
  CREATE_POLITICIAN,
  GET_COUNCILORS,
  GET_MAYORS,
  GET_POLITICIANS,
  UPDATE_POLITICIAN,
} from './actionTypes';
import {
  getPoliticians as getPoliticiansAction,
  politiciansError,
  politiciansSuccess,
} from './actions';

function* handleSuccess({ message }) {
  toast('success', message);
  yield put(politiciansSuccess());
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(politiciansError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* getPoliticians() {
  try {
    const { data: politicians } = yield call(api.get, '/politicos');
    yield put(politiciansSuccess({ politicians }));
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível recuperar os políticos';
    handleError({ error, errorDefaultMessage });
  }
}

function* createPolitician({ payload }) {
  try {
    const { data } = payload;
    yield call(api.post, '/politicos', data);
    yield handleSuccess({ message: 'Político criado com sucesso!' });

    yield put(getPoliticiansAction());
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível salvar o político';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* changePoliticianSituation({ payload }) {
  try {
    const { id } = payload;
    yield call(api.put, `/politicos/situacao/${id}`);
    yield handleSuccess({ message: 'Situação atualizada com sucesso!' });
    yield put(getPoliticiansAction());
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível alterar a situação';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* updatePolitician({ payload }) {
  try {
    const { data: politician } = payload;
    const { id, ...data } = politician;

    yield call(api.put, `/politicos/${id}`, data);
    yield handleSuccess({ message: 'Político atualizado com sucesso!' });

    yield put(getPoliticiansAction());
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível atualizar o político';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getMayors() {
  try {
    const { data: mayors } = yield call(api.get, '/politicos/prefeitos');
    yield put(politiciansSuccess({ mayors }));
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível recuperar os prefeitos';
    handleError({ error, errorDefaultMessage });
  }
}

function* getCouncilors() {
  try {
    const { data: councilors } = yield call(api.get, '/politicos/vereadores');
    yield put(politiciansSuccess({ councilors }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar os vereadores';
    handleError({ error, errorDefaultMessage });
  }
}

export function* watchGetPoliticians() {
  yield takeEvery(GET_POLITICIANS, getPoliticians);
}

export function* watchCreatePolitician() {
  yield takeEvery(CREATE_POLITICIAN, createPolitician);
}

export function* watchChangePoliticianSituation() {
  yield takeEvery(CHANGE_POLITICIAN_SITUATION, changePoliticianSituation);
}

export function* watchUpdatePolitician() {
  yield takeEvery(UPDATE_POLITICIAN, updatePolitician);
}

export function* watchGetMayors() {
  yield takeEvery(GET_MAYORS, getMayors);
}

export function* watchGetCouncilors() {
  yield takeEvery(GET_COUNCILORS, getCouncilors);
}

function* politicians() {
  yield all([
    fork(watchGetPoliticians),
    fork(watchCreatePolitician),
    fork(watchChangePoliticianSituation),
    fork(watchUpdatePolitician),
    fork(watchGetMayors),
    fork(watchGetCouncilors),
  ]);
}

export default politicians;
