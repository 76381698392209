import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import handleCards from '../steps/handleCards';
import handleDataSubmit from '../steps/handleDataSubmit';
import withRouter from '../../../components/common/withRouter';
import useMultipliersSchema from '../../../schemas/multipliers';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate';
import {
  getStates,
  createMultiplier,
  getSexualOrientations,
} from '../../../store/actions';

const CreateNewMultiplier = ({ router }) => {
  const dispatch = useDispatch();

  const formRef = useRef();

  const validationSchema = useMultipliersSchema(formRef);
  const initialValues = handleInitialValuesCreate();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: { ...rest } });

    dispatch(createMultiplier({ ...data, navigate, formRef }));
  };

  useEffect(() => {
    dispatch(getStates());
    dispatch(getSexualOrientations());
  }, [dispatch]);

  return (
    <FormPrototype
      ref={formRef}
      router={router}
      onCards={handleCards}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Criar novo(a) Multiplicador(a)"
    />
  );
};

export default withRouter(CreateNewMultiplier);
