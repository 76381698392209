import React, { useState } from 'react';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';
import withRouter from '../../../components/common/withRouter';
import ActionsItem from '../../../components/ui/ActionsItem';
import ConfirmationModal from '../../../components/ui/ConfirmationModal';
import StatusItem from '../../../components/ui/StatusItem';
import {
  activeOrInactivateMultiplier,
  multipliersList,
} from '../../../store/actions';
import selectorOptions from '../steps/selectorOptions';
import FormFilter from './components/FormFilter';
import headerTitles from './steps/headerTitles';

const ListingMultipliers = () => {
  const [multiplierName, email, group, level, gender, status, actions] =
    headerTitles;
  const [modalData, setModalData] = useState({
    body: '',
    isOpen: false,
    onConfirm: () => {},
  });

  const handleData = (multipliers, onActiveOrInactivate) => {
    return multipliers.map((data) => {
      const isUserActive = data.usuario.ativo;

      return {
        [multiplierName]: data.usuario.pessoa.nome || '--',
        [email]: data.usuario.email,
        [group]: data.usuario.grupo,
        [level]: data.nivel,
        [gender]: data.usuario.pessoa.genero || '--',
        [status]: <StatusItem status={isUserActive} />,
        [actions]: (
          <ActionsItem
            isShowOptionDelete
            updatePath={`/multipliers/update/${data.id}`}
            titleActionDelete={isUserActive ? 'Inativar' : 'Ativar'}
            onDelete={() => {
              const onConfirm = () => {
                const params = { isUserActive, multiplierId: data.id };
                return onActiveOrInactivate(params);
              };
              // verifica se o modal de confirmação deve ser exibido
              if (isUserActive) {
                setModalData((prev) => {
                  return {
                    ...prev,
                    body: data.usuario.pessoa.nome || '--',
                    isOpen: true,
                    onConfirm,
                  };
                });
              } else {
                onConfirm();
              }
            }}
          />
        ),
      };
    });
  };

  return (
    <>
      <ListingPrototype
        handleData={handleData}
        entityName={'Multipliers'}
        headerTitles={headerTitles}
        title={'Multiplicadores(as)'}
        dataSelector={multipliersList}
        selectorOptions={selectorOptions}
        pathPageCreate={'/multipliers/new'}
        deleteAction={activeOrInactivateMultiplier}
        titleButtonHeader={'Novo(a) multiplicador(a)'}
        emptyMessage={'Oops, sem multiplicadores(as) para exibir.'}
      >
        <FormFilter />
      </ListingPrototype>

      <ConfirmationModal
        body={`Tem certeza que deseja inativar o multiplicador "${modalData.body}"?`}
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title="Inativar multiplicador"
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        confirmText="Inativar"
      />
    </>
  );
};

export default withRouter(ListingMultipliers);
