import systemParamFields from '../systemParamFields';

const handleCards = ({ formRef, isUpdate }) => {
  const systemParamsFields = systemParamFields({ formRef, isUpdate });

  const cards = [
    {
      titleHeader: 'Definir parâmetro do sistema',
      Icon: () => null,
      fields: systemParamsFields,
      isShowFormControlButtons: true,
      showCardBackButton: false,
    },
  ];

  return cards;
};

export default handleCards;
