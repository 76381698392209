import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import { checkIfWhatsAppIsConnected } from '../../../store/actions';
import QrCodeDisplayer from './components/QrCodeDisplayer';
import QrCodeRequester from './components/QrCodeRequester';

export default function WhatsAppSettings() {
  const dispatch = useDispatch();
  const selector = createDynamicSelector('Settings', [
    'isWhatsAppConnected',
    'isCheckingWhatsAppConnection',
    'whatsappQrCode',
  ]);

  const { isWhatsAppConnected, isCheckingWhatsAppConnection, whatsappQrCode } =
    useSelector(selector);

  const timeoutRef = useRef(null);
  const qrCodeChecksInitial = 60;
  const qrCodeChecksRef = useRef(qrCodeChecksInitial);

  useEffect(() => {
    dispatch(checkIfWhatsAppIsConnected());
    document.title = 'WhatsApp';
  }, []);

  useEffect(() => {
    if (whatsappQrCode && !isWhatsAppConnected) {
      const checkIfConnected = () => {
        if (isWhatsAppConnected || qrCodeChecksRef.current <= 0) return;
        qrCodeChecksRef.current -= 1;

        if (!isCheckingWhatsAppConnection) {
          dispatch(checkIfWhatsAppIsConnected());
        }
        timeoutRef.current = setTimeout(checkIfConnected, 2000);
      };

      timeoutRef.current = setTimeout(checkIfConnected, 2000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [whatsappQrCode, isWhatsAppConnected]);

  useEffect(() => {
    if (timeoutRef.current && isWhatsAppConnected) {
      clearTimeout(timeoutRef.current);
    }
  }, [isWhatsAppConnected]);

  return (
    <Container className="page-content">
      <Card>
        <CardBody>
          <Row className="gap-3">
            <Col>
              <QrCodeRequester
                qrCodeChecksRef={qrCodeChecksRef}
                qrCodeChecksInitial={qrCodeChecksInitial}
              />
            </Col>
            {isWhatsAppConnected ? (
              <div>
                <h5 className="mt-2">O WhatsApp já está conectado!</h5>
                <h6>
                  Agora você pode enviar mensagens para o WhatsApp dos seus
                  contatos cadastrados acessando a tela do menu 'Modelos de
                  Mensagem'.
                </h6>
              </div>
            ) : (
              <QrCodeDisplayer />
            )}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}
