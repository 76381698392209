import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import {
  managementError,
  managementListWhatsAppSlots as managementListWhatsAppSlotsAction,
  managementSuccess,
} from './actions';
import {
  MANAGEMENT_DISCONNECT_WHATSAPP_SLOT,
  MANAGEMENT_FREE_WHATSAPP_SLOT,
  MANAGEMENT_IMPORT_WHATSAPP_SLOTS,
  MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT,
  MANAGEMENT_LIST_ACCOUNTS,
  MANAGEMENT_LIST_PLANS,
  MANAGEMENT_LIST_ROOT_USERS,
  MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS,
  MANAGEMENT_LIST_WHATSAPP_SLOTS,
} from './actionTypes';
import { toast as toastify } from 'react-toastify';

function* handleSuccess({ message }) {
  toast('success', message);
  yield put(managementSuccess());
}

function* handleError({ error, errorDefaultMessage, toastId }) {
  const { response } = error;
  const { data, status } = response;

  yield put(managementError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    if (toastId) {
      toastify.update(toastId, {
        render: errorMessage500,
        type: 'error',
        isLoading: false,
        closeOnClick: true,
        autoClose: 5000,
      });
      return;
    }
    toast('error', errorMessage500);
    return;
  }

  if (toastId) {
    toastify.update(toastId, {
      render: data || errorDefaultMessage,
      type: 'error',
      isLoading: false,
      closeOnClick: true,
      autoClose: 5000,
    });
    return;
  }
  toast('error', data || errorDefaultMessage);
}

function* managementListAccounts() {
  try {
    const { data } = yield call(api.get, '/administracao/contas');
    yield put(managementSuccess({ accounts: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar as contas.');
    yield put(managementError());
  }
}

function* managementListRootUsers() {
  try {
    const { data } = yield call(api.get, '/administracao/usuarios/root');
    yield put(managementSuccess({ rootUsers: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os usuários root.');
    yield put(managementError());
  }
}

function* managementListPlans() {
  try {
    const { data } = yield call(api.get, '/administracao/planos');
    yield put(managementSuccess({ plans: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os planos.');
    yield put(managementError());
  }
}

function* managementListWhatsAppSlots() {
  try {
    const { data } = yield call(api.get, '/administracao/cbtk/slots');
    yield put(managementSuccess({ whatsappSlots: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os slots de WhatsApp.');
    yield put(managementError());
  }
}

function* managementFreeWhatsAppSlot({ payload }) {
  try {
    const { uuid } = payload;
    yield call(api.put, `/administracao/cbtk/slots/${uuid}/liberar`);
    yield put(managementListWhatsAppSlotsAction());
    yield handleSuccess({ message: 'Slot liberado com sucesso!' });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível liberar o slot.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* managementDisconnectWhatsAppSlot({ payload }) {
  try {
    const { uuid } = payload;
    yield call(api.put, `/administracao/cbtk/slots/${uuid}/desconectar`);
    yield put(managementListWhatsAppSlotsAction());
    yield handleSuccess({ message: 'Slot desconectado com sucesso!' });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível desconectar o slot.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* managementListSlotFreeAccounts() {
  try {
    const { data } = yield call(api.get, '/administracao/cbtk/contas/livres');
    yield put(managementSuccess({ slotFreeAccounts: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar as contas livres.');
    yield put(managementError());
  }
}

function* managementLinkAccountToWhatsAppSlot({ payload }) {
  try {
    const { uuid, accountId } = payload;
    yield call(api.put, `/administracao/cbtk/slots/${uuid}/${accountId}`);

    yield put(managementListWhatsAppSlotsAction());
    yield handleSuccess({ message: 'Conta vinculada com sucesso!' });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível vincular a conta ao slot.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* managementImportWhatsAppSlots() {
  const toastId = toast('loading', 'Importando slots, aguarde...');
  try {
    yield call(api.post, `/administracao/cbtk/slots/importar`);
    toastify.update(toastId, {
      render: 'Slots importados, recarregando a tabela...',
    });

    yield put(managementListWhatsAppSlotsAction());
    toastify.update(toastId, {
      render: 'Slots importados com sucesso!',
      type: 'success',
      isLoading: false,
      closeOnClick: true,
      autoClose: 5000,
    });

    yield put(managementSuccess());
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível importar os slots.';
    yield handleError({ error, errorDefaultMessage, toastId });
  }
}

export function* watchManagementListAccounts() {
  yield takeEvery(MANAGEMENT_LIST_ACCOUNTS, managementListAccounts);
}

export function* watchManagementListRootUsers() {
  yield takeEvery(MANAGEMENT_LIST_ROOT_USERS, managementListRootUsers);
}

export function* watchManagementListPlans() {
  yield takeEvery(MANAGEMENT_LIST_PLANS, managementListPlans);
}

export function* watchManagementListWhatsAppSlots() {
  yield takeEvery(MANAGEMENT_LIST_WHATSAPP_SLOTS, managementListWhatsAppSlots);
}

export function* watchManagementFreeWhatsAppSlot() {
  yield takeEvery(MANAGEMENT_FREE_WHATSAPP_SLOT, managementFreeWhatsAppSlot);
}

export function* watchManagementDisconnectWhatsAppSlot() {
  yield takeEvery(
    MANAGEMENT_DISCONNECT_WHATSAPP_SLOT,
    managementDisconnectWhatsAppSlot
  );
}

export function* watchManagementListSlotFreeAccounts() {
  yield takeEvery(
    MANAGEMENT_LIST_SLOT_FREE_ACCOUNTS,
    managementListSlotFreeAccounts
  );
}

export function* watchManagementLinkAccountToWhatsAppSlot() {
  yield takeEvery(
    MANAGEMENT_LINK_ACCOUNT_TO_WHATSAPP_SLOT,
    managementLinkAccountToWhatsAppSlot
  );
}

export function* watchManagementImportWhatsAppSlots() {
  yield takeEvery(
    MANAGEMENT_IMPORT_WHATSAPP_SLOTS,
    managementImportWhatsAppSlots
  );
}

function* management() {
  yield all([
    fork(watchManagementListAccounts),
    fork(watchManagementListRootUsers),
    fork(watchManagementListPlans),
    fork(watchManagementListWhatsAppSlots),
    fork(watchManagementFreeWhatsAppSlot),
    fork(watchManagementDisconnectWhatsAppSlot),
    fork(watchManagementListSlotFreeAccounts),
    fork(watchManagementLinkAccountToWhatsAppSlot),
    fork(watchManagementImportWhatsAppSlots),
  ]);
}

export default management;
