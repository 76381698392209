import genderField from '../genderField';
import cpfMask from '../../../masks/cpfMask';
import cepMask from '../../../masks/cepMask';
import maritalStatusField from '../maritalStatusField';
import sexualOrientationField from '../sexualOrientationField';
import { useSelector as handleUseSelector } from 'react-redux';
import createDynamicSelector from '../../../../components/common/createDynamicSelector';
import { updateFormWithAddressFromAPI } from '../../../../store/generalFeature/actions';

const fields = ({ form, dispatch, onUpdateForm, isCpfRequired = true }) => {
  const selector = createDynamicSelector('GeneralFeature', ['municipalities']);
  const { municipalities } = handleUseSelector(selector);

  return [
    [
      {
        md: 4,
        type: 'text',
        label: 'Nome',
        name: 'userName',
        isRequired: true,
        placeholder: 'Informe o nome',
      },
      {
        md: 4,
        type: 'text',
        name: 'lastName',
        label: 'Sobrenome',
        isRequired: true,
        placeholder: 'Informe o sobrenome',
      },
      {
        md: 4,
        name: 'cpf',
        type: 'text',
        label: 'CPF',
        maxLength: 14,
        value: form.cpf,
        isRequired: isCpfRequired,
        placeholder: 'Informe o CPF',
        onChange: (value) => {
          const cpf = cpfMask(value);
          onUpdateForm('cpf', cpf);
        },
      },
    ],
    [
      {
        md: 3,
        type: 'date',
        name: 'dateOfBirth',
        label: 'Data de Nascimento',
        placeholder: 'Informe a data de nascimento',
      },
      ...[sexualOrientationField()],
      ...[genderField()],
      ...[maritalStatusField],
    ],
    [
      {
        md: 4,
        label: 'CEP',
        type: 'text',
        maxLength: 9,
        name: 'zipCode',
        value: form.zipCode || '',
        placeholder: 'Digite para buscar',
        onChange: (value) => {
          const cep = cepMask(value);
          onUpdateForm('zipCode', cep);

          if (cep.length < 9) return;

          dispatch(updateFormWithAddressFromAPI({ cep, onUpdateForm }));
        },
      },
      {
        md: 4,
        type: 'text',
        label: 'Bairro',
        name: 'neighborhood',
        placeholder: 'Informe o bairro',
      },
      {
        md: 4,
        type: 'text',
        name: 'street',
        label: 'Logradouro',
        placeholder: 'Informe o logradouro',
      },
    ],
    [
      {
        md: 4,
        type: 'select',
        required: false,
        label: 'Município',
        name: 'municipality',
        noOptionsMessage: 'Informe o cep primeiro ;)',
        options: municipalities.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
      {
        md: 4,
        type: 'text',
        label: 'Número',
        name: 'addressNumber',
        placeholder: 'Informe o número',
      },
      {
        md: 4,
        type: 'text',
        name: 'complement',
        label: 'Complemento',
        placeholder: 'Informe o complemento',
      },
    ],
  ];
};

export default fields;
