import React from 'react';
import Route from './routes';
import { ToastContainer } from 'react-toastify';

import './assets/scss/themes.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Route />
      <ToastContainer />
    </>
  );
}

export default App;
