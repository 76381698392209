function formatNumberWithoutThousands(value) {
  if (!value) return 0;
  
  const [integerPart, decimalPart] = value.split(',');

  const formattedIntegerPart = integerPart.replace(/\./g, '');
  const formattedDecimalPart = decimalPart ? `.${decimalPart}` : '';

  return Number(formattedIntegerPart + formattedDecimalPart);
}

export default formatNumberWithoutThousands;
