import EmpatyData from '../../../../../../ui/EmptyData';

const TableBody = ({
  page,
  columns,
  prepareRow,
  emptyMessage,
  getTableBodyProps,
}) => {
  return (
    <tbody {...getTableBodyProps()}>
      {page.length === 0 ? (
        <tr>
          <td colSpan={columns.length}>
            <EmpatyData message={emptyMessage} />
          </td>
        </tr>
      ) : (
        <>
          {page.map((row) => {
            prepareRow(row);

            return (
              <tr key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <td key={cell.id} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </>
      )}
    </tbody>
  );
};

export default TableBody;
