const phoneMask = (phoneInput = '') => {
let cleaned = phoneInput.replace(/\D/g, '');
let formattedNumber = cleaned;

if (cleaned.length > 2) {
  if (cleaned.length <= 6) {
    formattedNumber = cleaned.replace(/^(\d{2})(\d{0,4})$/, '($1) $2');
  } else if (cleaned.length <= 10) {
    formattedNumber = cleaned.replace(/^(\d{2})(\d{4})(\d{0,4})$/, '($1) $2-$3');
  } else {
    formattedNumber = cleaned.replace(/^(\d{2})(\d{5})(\d{0,4})$/, '($1) $2-$3');
  }
}

return formattedNumber;
};

export default phoneMask;
