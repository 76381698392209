import {
  CANDIDATES_ERROR,
  CANDIDATES_LIST,
  CANDIDATES_SUCCESS,
  CREATE_CANDIDATE,
  GET_CANDIDATES_BY_SELFREGISTRATION,
  UPDATE_CANDIDATE,
} from './actionTypes';

const initialState = {
  candidates: [],
  isLoading: false,
};

const candidates = (state = initialState, { type, payload }) => {
  const { candidates = state.candidates } = payload ?? {};

  switch (type) {
    case CANDIDATES_LIST:
    case CREATE_CANDIDATE:
    case UPDATE_CANDIDATE:
    case GET_CANDIDATES_BY_SELFREGISTRATION:
      return {
        ...state,
        isLoading: true,
      };

    case CANDIDATES_ERROR:
    case CANDIDATES_SUCCESS:
      return {
        ...state,
        candidates,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default candidates;
