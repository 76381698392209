import Icon from '../../../../../components/ui/Icon';
import tagFields from '../tagFields';

const handleCards = () => {
  const tagFieldsData = tagFields();

  const cards = [
    {
      titleHeader: 'Adicionar etiqueta',
      Icon: () => <Icon name="plus" />,
      fields: tagFieldsData,
      isShowFormControlButtons: true,
      showCardBackButton: false,
    },
  ];

  return cards;
};

export default handleCards;
