import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import ListingPrototypeControlledByBackend from '../../../components/Prototypes/ListingPrototypeControlledByBackend';
import withRouter from '../../../components/common/withRouter';
import ActionsDropdown from '../../../components/ui/ActionsDropdown';
import ConfirmationModal from '../../../components/ui/ConfirmationModal';
import {
  deleteVoter,
  generateVotersCSV,
  votersList,
} from '../../../store/actions';
import { getVoterFilterFromFormRef } from '../../../utils/transformsValues/getVoterFilterFromFormRef';
import selectorOptions from '../steps/selectorOptions';
import FormFilter from './components/FormFilter';
import actionsOptions from './steps/actions';
import headerTitles from './steps/headerTitles';

const ListingVoters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formFilterRef = useRef();

  const generateCSV = () => {
    const data = getVoterFilterFromFormRef(formFilterRef);
    dispatch(generateVotersCSV({ data }));
  };

  const [name, email, phone, gender, selfRegistration, actions] = headerTitles;
  const [modalData, setModalData] = useState({
    body: '',
    isOpen: false,
    onConfirm: () => {},
  });

  const handleData = ({ eleitores: voters = [] }, onDelete) => {
    return voters.map((data) => ({
      [name]: data.pessoa.nome,
      [email]: data.email,
      [phone]: data.telefone,
      [gender]: data.pessoa.genero || '--',
      [selfRegistration]: data.autocadastro ? 'Sim' : 'Não',
      [actions]: (
        <ActionsDropdown
          hrefs={['', '', '', '']}
          actions={actionsOptions({ data, setModalData, onDelete, navigate })}
          title={actions}
        />
      ),
    }));
  };

  return (
    <>
      <ListingPrototypeControlledByBackend
        // extraHeaderContent={
        //   <Button
        //     color="primary"
        //     className="align-center"
        //     type="button"
        //     onClick={generateCSV}
        //   >
        //     Gerar CSV
        //   </Button>
        // }
        entityName={'Voters'}
        title={'Eleitores(as)'}
        handleData={handleData}
        dataSelector={votersList}
        deleteAction={deleteVoter}
        headerTitles={headerTitles}
        pathPageCreate={'/voters/new'}
        selectorOptions={selectorOptions}
        titleButtonHeader={'Novo(a) Eleitor(a)'}
        emptyMessage={'Oops, sem eleitores(as) para exibir.'}
      >
        <FormFilter ref={formFilterRef} />
      </ListingPrototypeControlledByBackend>

      <ConfirmationModal
        body={`Tem certeza que deseja apagar o eleitor "${modalData.body}"?`}
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title="Apagar eleitor"
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        confirmText="Apagar"
      />
    </>
  );
};

export default withRouter(ListingVoters);
