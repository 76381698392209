import * as Yup from 'yup';

const validationRepeatPassword = {
  repeatPassword: Yup.string()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .oneOf([Yup.ref('password'), null], 'As senhas não coincidem')
    .required('Repita sua senha'),
};

export default validationRepeatPassword;
