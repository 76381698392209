import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListingPrototype from '../../../components/Prototypes/ListingPrototype';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import withRouter from '../../../components/common/withRouter';
import ActionsDropdown from '../../../components/ui/ActionsDropdown';
import LoadingPage from '../../../components/ui/LoadingPage';
import { filterMessagesByTemplateId } from '../../../store/actions';
import actions from './steps/actions';
import headerTitles from './steps/headerTitles';
import selectorOptions from './steps/selectorOptions';

const ListingMessages = () => {
  const dispatch = useDispatch();

  const [nomeEleitor, meio, texto, dataEnvio, destino, situacao, acoes] =
    headerTitles;
  const [shouldShowListing, setShouldShowListing] = useState(false);

  const selector = createDynamicSelector('Templates', ['template']);
  const { template } = useSelector(selector);

  const handleData = (messages = []) => {
    return messages.map((data) => ({
      [nomeEleitor]: data.nomeEleitor || '--',
      [meio]: data.meio,
      [texto]: data.textoFinal,
      [dataEnvio]: data.dataEnvio ? data.dataEnvio : '--',
      [destino]: data.enderecoDestinatario ? data.enderecoDestinatario : '--',
      [situacao]: data.situacao,
      [acoes]: (
        <ActionsDropdown
          actions={actions({ data, dispatch })}
          hrefs={['', '']}
        />
      ),
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldShowListing(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!shouldShowListing) return <LoadingPage />;
  return (
    <ListingPrototype
      title={'Mensagens'}
      handleData={handleData}
      entityName={'Messages'}
      dataSelector={filterMessagesByTemplateId}
      dataSelectorArgs={[
        {
          templateId: template?.id,
        },
      ]}
      headerTitles={headerTitles}
      selectorOptions={selectorOptions}
      emptyMessage={'Oops, sem mensagens para exibir.'}
      isShowButtonCreateNewItem={false}
      isShowButtonToDisplayFilter={false}
    />
  );
};

export default withRouter(ListingMessages);
