import keys from '../common';

const { lastLoginDateKey, rememberKey } = keys;

const removeRememberLocalStorage = () => {
  localStorage.removeItem(rememberKey);
  localStorage.removeItem(lastLoginDateKey);
};

export default removeRememberLocalStorage;
