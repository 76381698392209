import fields from './steps/fields';
import { useDispatch } from 'react-redux';
import handleDataSubmit from './steps/handleDataSubmit';
import handleInitialValues from './steps/handleInitialValues';
import useMultipliersFilterSchema from '../../../../../schemas/multipliersFilter';
import FormFilterPrototype from '../../../../../components/Prototypes/FormFilterPrototype';
import {
  multipliersList,
  multipliersListFilter,
} from '../../../../../store/actions';

const FormFilter = () => {
  const dispatch = useDispatch();

  const schema = useMultipliersFilterSchema();
  const initialValues = handleInitialValues();

  const handleClearFilter = () => {
    dispatch(multipliersList());
  };

  const handleSubmit = (values) => {
    const data = handleDataSubmit(values);
    dispatch(multipliersListFilter({ filters: data }));
  };

  return (
    <FormFilterPrototype
      fields={fields}
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={initialValues}
      onClearFilter={handleClearFilter}
    />
  );
};

export default FormFilter;
