import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BarChart from '../../../components/charts/BarChart';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import LoadingPage from '../../../components/ui/LoadingPage';
import { reportsResearchDashboardKeys } from '../../../config/reports/reportsKeys';
import { reportsResearchDashboardRoutes } from '../../../config/reports/reportsRoutes';
import { getReportByCategory } from '../../../store/actions';
import charts from './steps/charts';
import PieChart from '../../../components/charts/PieChart';

export default function ResearchDashboard() {
  const dispatch = useDispatch();
  const chartsData = charts();

  const selector = createDynamicSelector('Reports', ['isLoading']);
  const { isLoading } = useSelector(selector);

  useEffect(() => {
    document.title = 'Gráficos - Pesquisa';

    reportsResearchDashboardRoutes.forEach((route, index) => {
      const category = route;
      const key = reportsResearchDashboardKeys[index];

      dispatch(getReportByCategory({ category, key }));
    });
  }, []);

  if (isLoading) return <LoadingPage />;
  return (
    <Container className="page-content">
      {chartsData.map((row, index) => {
        return (
          <Row key={index}>
            {row.map((chart) => (
              <Col lg={chart.lg} key={chart.key}>
                {chart.type === 'pie' ? (
                  <PieChart {...chart.props} />
                ) : (
                  <BarChart {...chart.props} />
                )}
              </Col>
            ))}
          </Row>
        );
      })}
    </Container>
  );
}
