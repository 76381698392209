import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { logoutUser } from '../../store/actions';
import getUserDataAndAccessToken from '../../utils/actionsCookies/getUserDataAndAccessToken';

const RootProtected = ({ children, isRoot, isNotRoot }) => {
  const dispatch = useDispatch();

  const { accessToken, userData } = getUserDataAndAccessToken();
  const { root } = userData;

  const isAccessToken = !!accessToken;
  const isUserRoot = root;
  const isCanAccess = isUserRoot === isRoot || isNotRoot;

  useEffect(() => {
    if (isAccessToken) return;

    dispatch(logoutUser());
  }, [isAccessToken, dispatch]);

  const isNotAuthorized = !isAccessToken || !isCanAccess;
  const pathname = !isAccessToken ? '/login' : !isCanAccess ? '/voters' : null;

  if (isNotAuthorized) return <Navigate to={{ pathname }} />;

  return <>{children}</>;
};

export { RootProtected };
