import Cookies from 'js-cookie';

/**
 *
 * @param {{
 * name: string;
 * email: string;
 * group: string;
 * level: number;
 * picture: string;
 * accessToken: string;
 * root: boolean;
 * }} data
 */

const addUserDataAndAccessToken = (data = {}) => {
  // const isProduction = Boolean(process.env.REACT_APP_IS_PRODUCTION);

  const expirationDate = new Date();
  const fourHoursToMilliseconds = 4 * 60 * 60 * 1000;

  expirationDate.setTime(expirationDate.getTime() + fourHoursToMilliseconds);
  const expires = expirationDate;

  const commonOptions = { expires };
  const { accessToken, ...userData } = data;

  Cookies.set('accessToken', accessToken, commonOptions);
  Cookies.set('userData', JSON.stringify(userData), commonOptions);
};

export default addUserDataAndAccessToken;
