import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../services/api';
import { GET_ME, GET_REGISTRANTS } from './actionTypes';
import { usersError, usersSuccess } from './actions';

function* getMe() {
  try {
    const { data } = yield call(api.get, '/usuarios');

    yield put(usersSuccess({ user: data }));
  } catch (error) {
    yield put(usersError());
  }
}

function* getRegistrants() {
  try {
    const { data } = yield call(api.get, '/usuarios/cadastrantes');
    yield put(usersSuccess({ registrants: data }));
  } catch (error) {
    yield put(usersError());
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_ME, getMe);
}

export function* watchGetRegistrants() {
  yield takeEvery(GET_REGISTRANTS, getRegistrants);
}

function* users() {
  yield all([fork(watchGetUsers), fork(watchGetRegistrants)]);
}

export default users;
