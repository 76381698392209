import {
  registerMessageSent,
  sendWhatsappViaApi,
} from '../../../../../store/actions';

const actions = ({ data, dispatch }) => [
  {
    id: 1,
    title: 'Enviar via Whatsapp Web',
    tag: 'button',
    icon: 'whatsapp',
    text: 'Enviar via Whatsapp Web',
    showCondition: () => data.linkWhatsapp != null && data.situacao == 'Criada',
    attributes: {
      onClick: () => {
        dispatch(
          registerMessageSent({ messageId: data.id, templateId: data.idModelo })
        );
        window.open(data.linkWhatsapp, '_blank');
      },
    },
  },
  {
    id: 2,
    title: 'Enviar via API',
    tag: 'button',
    icon: 'whatsappFill',
    text: 'Enviar via API',
    showCondition: () => data.linkWhatsapp != null && data.situacao == 'Criada',
    attributes: {
      onClick: () => {
        dispatch(sendWhatsappViaApi({ messageId: data.id }));
      },
    },
  },
  {
    id: 3,
    title: 'Contatar via Email',
    tag: 'button',
    icon: 'envelopeSend',
    text: 'Contatar via Email',
    showCondition: () => data.linkEmail != null,
    attributes: {
      onClick: () => {
        dispatch(
          registerMessageSent({ messageId: data.id, templateId: data.idModelo })
        );
        window.open(data.linkEmail, '_blank');
      },
    },
  },
];

export default actions;
