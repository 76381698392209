import { useSelector } from 'react-redux';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';
import { Col } from 'reactstrap';

export default function QrCodeDisplayer() {
  const selector = createDynamicSelector('Settings', ['whatsappQrCode']);
  const { whatsappQrCode } = useSelector(selector);
  return (
    <Col xs={12}>
      {whatsappQrCode ? (
        <img className="w-100 rounded" src={whatsappQrCode} alt="" />
      ) : null}
    </Col>
  );
}
