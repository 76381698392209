import React from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

const SidebarItem = (props) => {
  const { item } = props;

  const renderBadge = (badgeName, badgeColor) => {
    if (!badgeName) return null;

    return (
      <span className={`badge badge-pill bg-${badgeColor}`} data-key="t-new">
        {badgeName}
      </span>
    );
  };

  if (item.isHeader) {
    return (
      <li className="menu-title">
        <span data-key="t-menu">{item.label}</span>
      </li>
    );
  }

  return (
    <li className="nav-item">
      {item.subItems ? (
        <Link
          onClick={item.click}
          to={item.link || '/#'}
          data-bs-toggle="collapse"
          className="nav-link menu-link"
        >
          <i className={item.icon} />{' '}
          <span data-key="t-apps">{item.label}</span>
          {renderBadge(item.badgeName, item.badgeColor)}
        </Link>
      ) : (
        <Link
          to={item.link || '/#'}
          onClick={item.click}
          className="nav-link menu-link"
        >
          <i className={item.icon} /> <span>{item.label}</span>
        </Link>
      )}

      {item.subItems && (
        <Collapse
          id={item.collapseId}
          className="menu-dropdown"
          isOpen={item.stateVariables}
        >
          <ul className="nav nav-sm flex-column">
            {item.subItems.map((subItem, key) => (
              <SidebarItem key={key} item={subItem} />
            ))}
          </ul>
        </Collapse>
      )}
    </li>
  );
};

export default SidebarItem;
