import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ListingPrototype from '../../../../components/Prototypes/ListingPrototype';
import ActionsDropdown from '../../../../components/ui/ActionsDropdown';
import ConfirmationModal from '../../../../components/ui/ConfirmationModal';
import { getTags } from '../../../../store/actions';
import selectorOptions from '../steps/selectorOptions';
import actionsOptions from './steps/actionsOptions';
import headerTitles from './steps/headerTitles';

export default function ListingTags() {
  const dispatch = useDispatch();

  const [interestName, actions] = headerTitles;
  const [modalData, setModalData] = useState({
    body: '',
    isOpen: false,
    onConfirm: () => {},
  });

  const handleData = (tags) => {
    return tags.map((data) => {
      return {
        [interestName]: data.nome || '--',
        [actions]: (
          <ActionsDropdown
            actions={actionsOptions(data, dispatch, setModalData)}
            hrefs={[]}
          />
        ),
      };
    });
  };

  return (
    <>
      <ListingPrototype
        pageContent={false}
        isShowButtonCreateNewItem={false}
        handleData={handleData}
        entityName={'GeneralFeature'}
        headerTitles={headerTitles}
        title={'Etiquetas'}
        dataSelector={getTags}
        selectorOptions={selectorOptions}
        emptyMessage={'Oops, sem etiquetas para exibir.'}
      />

      <ConfirmationModal
        body={`Tem certeza que deseja apagar a etiqueta "${modalData.body}"?`}
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title="Apagar etiqueta"
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        confirmText="Apagar"
      />
    </>
  );
}
