import Icon from '../../../../../components/ui/Icon';
import regionFields from '../interestFields';

const handleCards = () => {
  const interestFields = regionFields();

  const cards = [
    {
      titleHeader: 'Adicionar interesse',
      Icon: () => <Icon name="plus" />,
      fields: interestFields,
      isShowFormControlButtons: true,
      showCardBackButton: false,
    },
  ];

  return cards;
};

export default handleCards;
