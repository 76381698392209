import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withRouter from '../components/common/withRouter';
import createDynamicSelector from '../components/common/createDynamicSelector';
import {
  changeSidebarVisibility,
  changeLeftsidebarSizeType,
} from '../store/actions';

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const [headerClass, setHeaderClass] = useState('');

  const selector = createDynamicSelector('Layout', [
    'leftsidbarSizeType',
    'sidebarIsVisibility',
  ]);
  const { leftsidbarSizeType, sidebarIsVisibility } = useSelector(selector);

  const scrollNavigation = () => {
    const scrollup = document.documentElement.scrollTop;

    if (scrollup > 50) setHeaderClass('topbar-shadow');
    else setHeaderClass('');
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });

  useEffect(() => {
    if (sidebarIsVisibility) {
      document.querySelector('.hamburger-icon').classList.remove('open');
      return;
    }

    document.querySelector('.hamburger-icon').classList.add('open');
  }, [sidebarIsVisibility]);

  useEffect(() => {
    if (leftsidbarSizeType || sidebarIsVisibility) {
      window.dispatchEvent(new Event('resize'));

      dispatch(changeSidebarVisibility(sidebarIsVisibility));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
    }
  }, [dispatch, leftsidbarSizeType, sidebarIsVisibility]);

  return (
    <>
      <div id="layout-wrapper">
        <Header headerClass={headerClass} />

        <Sidebar />

        <div className="main-content">
          {children}

          <Footer />
        </div>
      </div>
    </>
  );
};

export default withRouter(Layout);
