/**
 *
 * @param {number} value
 * @returns {void}
 */

const addVotersPageIndex = (value) => {
  localStorage.setItem('votersPageIndex', value);
};

export default addVotersPageIndex;
