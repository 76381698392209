import * as Yup from 'yup';

const useMuniciaplResearchSchema = () => {
  const schema = Yup.object().shape({
    iluminacao: Yup.string().nullable(),
    saneamento: Yup.string().nullable(),
    agua: Yup.string().nullable(),
    asfalto: Yup.string().nullable(),
    limpeza: Yup.string().nullable(),
    chuvas: Yup.string().nullable(),
    lixo: Yup.string().nullable(),
    arvores: Yup.string().nullable(),
    praca: Yup.string().nullable(),
    zeladoria_comentario: Yup.string().nullable(),
    transporte_publico_uso: Yup.boolean().nullable(),
    transporte_publico: Yup.string().nullable(),
    onibus: Yup.string().nullable(),
    transito: Yup.string().nullable(),
    transporte_comentario: Yup.string().nullable(),
    educacao_publica_uso: Yup.boolean().nullable(),
    educacao_publica: Yup.string().nullable(),
    merenda: Yup.string().nullable(),
    transporte_escolar: Yup.string().nullable(),
    creche: Yup.string().nullable(),
    educacao_comentario: Yup.string().nullable(),
    saude_publica_uso: Yup.boolean().nullable(),
    saude_publica: Yup.string().nullable(),
    posto_saude: Yup.string().nullable(),
    medicamento_continuo_uso: Yup.boolean().nullable(),
    medicamento: Yup.string().nullable(),
    saude_publica_comentario: Yup.string().nullable(),
    prefeito: Yup.object().nullable(),
    vereador: Yup.object().nullable(),
    comentario: Yup.string().nullable(),
  });

  return schema;
};

export default useMuniciaplResearchSchema;
