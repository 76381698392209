import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Icon from '../../components/ui/Icon';
import toast from '../../components/ui/toast';
import getUserDataAndAccessToken from '../../utils/actionsCookies/getUserDataAndAccessToken';
import UserLevel from '../../components/ui/UserLevel';
import { useDispatch, useSelector } from 'react-redux';
import { candidatesList } from '../../store/actions';
import EmpatyData from '../../components/ui/EmptyData';

const Profile = () => {
  const { userData } = getUserDataAndAccessToken();
  const dispatch = useDispatch();
  const { candidates } = useSelector((state) => state.Candidates);

  const copyAutoRegisterLink = () => {
    try {
      const userData = JSON.parse(Cookies.get('userData'));
      const baseUrl = process.env.REACT_APP_PUBLIC_URL;
      const shareUrl = `${baseUrl}/self-registration/${userData.autoRegisterCode}`;
      navigator.clipboard.writeText(shareUrl);

      toast('success', 'Link copiado para a área de transferência');
    } catch (error) {
      toast('error', 'Não foi possível recuperar o link de autocadastro');
    }
  };

  useEffect(() => {
    document.title = 'Perfil';
    dispatch(candidatesList())
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="mx-3">
                    {userData.picture ? (
                      <img
                        alt="foto usuário"
                        src={userData.picture}
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    ) : (
                      <Icon name="account" size={3} />
                    )}
                  </div>

                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <h5>{userData.name + ' ' + userData.lastName}</h5>

                      <p className="mb-1 text-black">Email: {userData.email}</p>
                      <p className="mb-1 text-black">Grupo: {userData.group}</p>
                      <p className="mb-1">
                        <UserLevel level={userData.level} />
                      </p>

                      {candidates.length ? <Button
                        color="primary"
                        onClick={copyAutoRegisterLink}
                        className="mt-2"
                      >
                        Compartilhar link de autocadastro
                      </Button>:
                      <EmpatyData message={"Não há candidato cadastrado nessa conta."} />}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
