const genderField = (md = 3) => {
  return {
    md,
    type: 'select',
    name: 'gender',
    label: 'Gênero',
    required: false,
    options: [
      { label: 'Feminino', value: 'Feminino' },
      { label: 'Masculino', value: 'Masculino' },
    ],
  };
};

export default genderField;
