import React from 'react';
import CreateNewRegion from './components/CreateNewRegion';
import ListingRegions from './components/ListingRegions';

export default function Regions() {
  return (
    <div className="page-content">
      <CreateNewRegion />
      <ListingRegions />
    </div>
  );
}
