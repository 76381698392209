/**
 * @param {any} data
 * @param {string} [filename]
 */
export default function downloadFile(data, filename) {
  const url = window.URL.createObjectURL(data);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.setAttribute('download', filename);
  downloadLink.click();
}
