import * as Yup from 'yup';
import titleValidation from '../../../utils/validations/titleValidation';

const validationTitle = (isOptional = false) => {
  const titleSchema = Yup.string()
    .nullable()
    .test('is-title-valid', 'Titulo de eleitor inválido', (value = '') => {
      if (!value || value?.length === 0) return true;

      return titleValidation(value.trim());
    });

  return {
    title: isOptional
      ? titleSchema
      : titleSchema.required('Informe o titulo do(a) eleitor(a)'),
  };
};

export default validationTitle;
