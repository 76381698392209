import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FormPrototype from '../../../../components/Prototypes/FormPrototype';
import withRouter from '../../../../components/common/withRouter';
import usePoliticianSchema from '../../../../schemas/settings/politician';
import {
  createPolitician,
  getPositions,
  updatePolitician,
} from '../../../../store/actions';
import handleCards from '../steps/handleCards';
import handleDataSubmit from '../steps/handleDataSubmit';
import handleInitialValuesCreate from '../steps/handleInitialValuesCreate';

export function CreateUpdatePolitician({
  router,
  initialValues,
  setInitialValues,
}) {
  const dispatch = useDispatch();

  const validationSchema = usePoliticianSchema();

  const formRef = useRef();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const isUpdate = !!initialValues.id;
    const data = handleDataSubmit({ data: rest, isUpdate });

    if (isUpdate) {
      dispatch(updatePolitician({ data }));
    } else {
      dispatch(createPolitician({ data }));
    }

    setInitialValues(handleInitialValuesCreate());
    formRef.current?.reset();
  };

  useEffect(() => {
    dispatch(getPositions());
  }, [dispatch]);

  return (
    <FormPrototype
      router={router}
      pageContent={false}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      ref={formRef}
      onCards={() => handleCards(!!initialValues.id)}
      validationSchema={validationSchema}
    />
  );
}

export default withRouter(CreateUpdatePolitician);
