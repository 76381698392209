export default function Instructions() {
  return (
    <>
      <h5 className="text-black">Passo a passo para importação de dados:</h5>
      <ol>
        <li>
          <b>Baixar Planilha Modelo:</b> Clique no botão "Baixar planilha
          modelo" para fazer o download de um exemplo de planilha no formato
          esperado.
        </li>
        <li>
          <b>Preencher a Planilha: </b>Apague as 6 linhas de exemplo na planilha
          e insira os seus dados de contatos.
        </li>
        <li>
          <b>Dados Obrigatórios: </b>
          Certifique-se de que os seguintes campos estão preenchidos:
          <ul>
            <li>Nome</li>
            <li>
              Melhor meio de contato (com uma das opções: E-mail ou WhatsApp)
            </li>
            <li>Telefone ou E-mail</li>
          </ul>
        </li>
      </ol>
      <p>
        <b>Importante: </b>É necessário manter a ordem das colunas. Os dados
        inválidos serão desconsiderados e não serão importados para o sistema.
      </p>
    </>
  );
}
