import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import {
  FILTER_MESSAGES_BY_TEMPLATE_ID,
  GENERATE_MESSAGES,
  REGISTER_MESSAGE_SENT,
  SEND_WHATSAPP_VIA_API,
} from './actionTypes';
import {
  messageError,
  messageSuccess,
  filterMessagesByTemplateId as filterMessagesByTemplateIdAction,
} from './actions';
import { templateList } from '../actions';

function* handleSuccess({ navigate, message }) {
  toast('success', message);
  yield put(messageSuccess());

  navigate && navigate('/templates');
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(messageError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* generateMessages({ payload }) {
  try {
    const { templateId } = payload;
    if (!templateId) throw new Error();

    const route = `/mensagens/modelo/gerar/${templateId}`;
    yield call(api.post, route);

    yield put(templateList());

    const message = 'Mensagens geradas com sucesso.';
    yield handleSuccess({ message });
  } catch (error) {
    toast('error', 'Oops, não foi possível gerar as mensagens.');
    yield put(messageError());
  }
}

export function* filterMessagesByTemplateId({ payload }) {
  try {
    const { templateId } = payload;
    if (!templateId) throw new Error();

    const route = `/mensagens/busca/${templateId}`;
    const { data } = yield call(api.get, route);
    yield put(messageSuccess({ messages: data.mensagens }));
  } catch (error) {
    toast('error', 'Oops, não foi possível pegar os dados das mensagens.');
    yield put(messageError());
  }
}

export function* registerMessageSent({ payload }) {
  try {
    const { messageId, templateId } = payload;
    if (!messageId) throw new Error();

    const route = `/mensagens/${messageId}`;
    yield call(api.put, route);

    yield put(filterMessagesByTemplateIdAction({ templateId }));
  } catch (error) {
    const errorDefaultMessage = 'Oops, ação não registrada';
    yield handleError({ error, errorDefaultMessage });
  }
}

export function* sendWhatsappViaApi({ payload }) {
  try {
    const { messageId } = payload;
    if (!messageId) throw new Error();

    const route = `/mensagens/envia/${messageId}`;
    yield call(api.post, route);
    toast('success', 'Mensagem enviada com sucesso!');
  } catch (error) {
    const errorDefaultMessage = 'Oops, erro ao enviar mensagem';
    yield handleError({ error, errorDefaultMessage });
  }
}

export function* watchGenerateMessages() {
  yield takeEvery(GENERATE_MESSAGES, generateMessages);
}

export function* watchFilterMessagesByTemplateId() {
  yield takeEvery(FILTER_MESSAGES_BY_TEMPLATE_ID, filterMessagesByTemplateId);
}

export function* watchRegisterMessageSent() {
  yield takeEvery(REGISTER_MESSAGE_SENT, registerMessageSent);
}

export function* watchSendWhatsappViaApi() {
  yield takeEvery(SEND_WHATSAPP_VIA_API, sendWhatsappViaApi);
}

function* messages() {
  yield all([
    fork(watchGenerateMessages),
    fork(watchFilterMessagesByTemplateId),
    fork(watchRegisterMessageSent),
    fork(watchSendWhatsappViaApi),
  ]);
}

export default messages;
