import { useState } from 'react';
import CreateUpdatePolitician from './CreateUpdatePolitician';
import ListingPoliticians from './ListingPoliticians';
import handleInitialValuesCreate from './steps/handleInitialValuesCreate';

export default function Politicians() {
  const [formInitialValues, setFormInitialValues] = useState(
    handleInitialValuesCreate()
  );
  return (
    <div className="page-content">
      <CreateUpdatePolitician
        initialValues={formInitialValues}
        setInitialValues={setFormInitialValues}
      />
      <ListingPoliticians setFormInitialValues={setFormInitialValues} />
    </div>
  );
}
