import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import withRouter from '../../components/common/withRouter';
import SidebarItems from '../Sidebar/components/SidebarItems';
import createDynamicSelector from '../../components/common/createDynamicSelector';

const VerticalLayout = (props) => {
  const { router } = props;

  const selector = createDynamicSelector('Layout', [
    'leftsidbarSizeType',
    'sidebarIsVisibility',
  ]);
  const { leftsidbarSizeType, sidebarIsVisibility } = useSelector(selector);

  const activateParentDropdown = (item) => {
    item.classList.add('active');
    const parentCollapseDiv = item.closest('.collapse.menu-dropdown');

    if (!parentCollapseDiv) return false;

    parentCollapseDiv.classList.add('show');

    const parentItem = parentCollapseDiv.parentElement.children[0];
    parentItem.classList.add('active');
    parentItem.setAttribute('aria-expanded', 'true');

    activateParentDropdown(parentItem);

    return false;
  };

  const resizeSidebarMenu = useCallback(() => {
    const windowSize = document.documentElement.clientWidth;
    const sidebarIcon = document.querySelector('.hamburger-icon');

    const setSidebarSize = (size) => {
      document.documentElement.setAttribute('data-sidebar-size', size);
    };

    if (windowSize >= 1025) {
      setSidebarSize(leftsidbarSizeType);

      const isShow = sidebarIsVisibility;

      if (isShow || sidebarIcon) sidebarIcon?.classList.remove('open');
      else sidebarIcon.classList.add('open');

      return;
    }

    if (windowSize < 1025 && windowSize > 767) {
      setSidebarSize('sm');

      document.body.classList.remove('twocolumn-panel');
      if (sidebarIcon) sidebarIcon.classList.add('open');

      return;
    }

    if (windowSize <= 767) {
      setSidebarSize('lg');

      if (sidebarIcon) sidebarIcon.classList.add('open');
      document.body.classList.remove('vertical-sidebar-enable');
    }
  }, [leftsidbarSizeType, sidebarIsVisibility]);

  const removeActivation = (items) => {
    items.forEach((item) => {
      const { classList, nextElementSibling } = item;

      if (classList.contains('active')) {
        classList.remove('active');

        if (item.classList.contains('menu-link')) {
          nextElementSibling?.classList.remove('show');
          item.setAttribute('aria-expanded', 'false');
        } else if (item.classList.contains('nav-link')) {
          nextElementSibling?.classList.remove('show');
          item.setAttribute('aria-expanded', 'false');
        }
      }
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const initializeMenu = () => {
    const pathName = process.env.PUBLIC_URL + router.location.pathname;

    const ul = document.getElementById('navbar-nav');
    const items = ul.getElementsByTagName('a');

    removeActivation([...items]);

    const matchingMenuItem = [...items].find(
      (item) => item.pathname === pathName
    );

    if (!matchingMenuItem) return;

    activateParentDropdown(matchingMenuItem);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeSidebarMenu, true);

    return () => {
      window.removeEventListener('resize', removeActivation, true);
    };
  }, [resizeSidebarMenu]);

  useEffect(() => {
    scrollToTop();
    initializeMenu();
  }, [router.location.pathname]);

  return <SidebarItems />;
};

export default withRouter(VerticalLayout);
