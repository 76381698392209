import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import CardHeader from '../../../../components/Prototypes/FormPrototype/components/FormCard/components/CardHeader';
import createDynamicSelector from '../../../../components/common/createDynamicSelector';
import Icon from '../../../../components/ui/Icon';
import { countVotersAffectedByFilter } from '../../../../store/actions';
import { getVoterFilterFromFormRef } from '../../../../utils/transformsValues/getVoterFilterFromFormRef';
import React, { useRef } from 'react';

export default function AffectedVoters({ formRef }) {
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Voters', [
    'votersAffectedByFilter',
    'isCalculatingAffectedVoters',
  ]);
  const { votersAffectedByFilter, isCalculatingAffectedVoters } =
    useSelector(selector);

  const calculateAffectedVoters = () => {
    dispatch(
      countVotersAffectedByFilter({ data: getVoterFilterFromFormRef(formRef) })
    );
  };

  const buttonRef = useRef(null);

  return (
    <>
      <CardHeader
        Icon={() => <Icon name="userChat" />}
        titleHeader={'Prévia da quantidade de eleitores afetados'}
      />

      <hr className="pb-2" />

      <div className="d-flex justify-content-between align-items-center">
        <p className="fs-5 m-0">
          Eleitores afetados:{' '}
          {isCalculatingAffectedVoters
            ? 'Calculando...'
            : votersAffectedByFilter === null
            ? 'Não calculado'
            : votersAffectedByFilter}
        </p>

        <Button color="primary" innerRef={buttonRef} onClick={calculateAffectedVoters} onMouseEnter={() => buttonRef.current.focus()}>
          Calcular
        </Button>
      </div>
    </>
  );
}
