import { useSelector as handleUseSelector } from 'react-redux';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';
import { reportsResearchDashboardKeys } from '../../../../../config/reports/reportsKeys';
import chartColors from '../../../steps/colors';

export default function charts() {
  const selector = createDynamicSelector(
    'Reports',
    reportsResearchDashboardKeys
  );
  const {
    reportsResearchWater,
    reportsResearchAsphalt,
    reportsResearchDaycare,
    reportsResearchEducation,
    reportsResearchIlumination,
    reportsResearchBus,
    reportsResearchHealthCenter,
    reportsResearchMayor,
    reportsResearchHealh,
    reportsResearchTraffic,
    reportsResearchTransport,
    reportsResearchCouncilors,
  } = handleUseSelector(selector);

  return [
    [
      {
        lg: 6,
        type: 'bar',
        key: 1,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com o abastecimento de água',
          data: reportsResearchWater.map(({ quantidade }) => quantidade),
          categories: reportsResearchWater.map(({ descricao }) => descricao),
          colors: chartColors[0],
        },
      },
      {
        lg: 6,
        type: 'bar',
        key: 2,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com o asfaltamento',
          data: reportsResearchAsphalt.map(({ quantidade }) => quantidade),
          categories: reportsResearchAsphalt.map(({ descricao }) => descricao),
          colors: chartColors[1],
        },
      },
    ],
    [
      {
        lg: 6,
        type: 'bar',
        key: 3,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com a oferta de creches',
          data: reportsResearchDaycare.map(({ quantidade }) => quantidade),
          categories: reportsResearchDaycare.map(({ descricao }) => descricao),
          colors: chartColors[2],
        },
      },
      {
        lg: 6,
        type: 'bar',
        key: 4,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com a educação pública do município',
          data: reportsResearchEducation.map(({ quantidade }) => quantidade),
          categories: reportsResearchEducation.map(
            ({ descricao }) => descricao
          ),
          colors: chartColors[3],
        },
      },
    ],
    [
      {
        lg: 6,
        type: 'bar',
        key: 5,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com a iluminação do município',
          data: reportsResearchIlumination.map(({ quantidade }) => quantidade),
          categories: reportsResearchIlumination.map(
            ({ descricao }) => descricao
          ),
          colors: chartColors[4],
        },
      },
      {
        lg: 6,
        type: 'bar',
        key: 6,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com a oferta de ônibus',
          data: reportsResearchBus.map(({ quantidade }) => quantidade),
          categories: reportsResearchBus.map(({ descricao }) => descricao),
          colors: chartColors[5],
        },
      },
    ],
    [
      {
        lg: 6,
        type: 'bar',
        key: 7,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com os postos de saúde',
          data: reportsResearchHealthCenter.map(({ quantidade }) => quantidade),
          categories: reportsResearchHealthCenter.map(
            ({ descricao }) => descricao
          ),
          colors: chartColors[6],
        },
      },
      {
        lg: 6,
        type: 'bar',
        key: 8,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com a saúde no munícipio',
          data: reportsResearchHealh.map(({ quantidade }) => quantidade),
          categories: reportsResearchHealh.map(({ descricao }) => descricao),
          colors: chartColors[7],
        },
      },
    ],
    [
      {
        lg: 6,
        type: 'bar',
        key: 7,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com o trânsito no município',
          data: reportsResearchTraffic.map(({ quantidade }) => quantidade),
          categories: reportsResearchTraffic.map(({ descricao }) => descricao),
          colors: chartColors[0],
        },
      },
      {
        lg: 6,
        type: 'bar',
        key: 8,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Satisfação com o transporte público no munícipio',
          data: reportsResearchTransport.map(({ quantidade }) => quantidade),
          categories: reportsResearchTransport.map(
            ({ descricao }) => descricao
          ),
          colors: chartColors[1],
        },
      },
    ],
    [
      {
        lg: 12,
        type: 'pie',
        key: 9,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Intenção de voto para prefeito',
          data: reportsResearchMayor.map(({ quantidade }) => quantidade),
          labels: reportsResearchMayor.map(({ descricao }) => descricao),
          colors: chartColors,
        },
      },
    ],
    [
      {
        lg: 12,
        type: 'pie',
        key: 10,
        props: {
          title: 'Quantidade de eleitores',
          titleHeader: 'Intencao de voto para vereador',
          data: reportsResearchCouncilors.map(({ quantidade }) => quantidade),
          labels: reportsResearchCouncilors.map(({ descricao }) => descricao),
          colors: chartColors,
        },
      },
    ],
  ];
}
