/**
 *
 * @returns {number}
 */

const getVotersPageIndex = () => {
  const value = localStorage.getItem('votersPageIndex');

  const defaultValue = 0;
  if (!value) return defaultValue;

  return Number(value);
};

export default getVotersPageIndex;
