const handleDataSubmit = (data) => {
  const values = {
    ativo: data.isActive,
    nome: data.name || null,
    nivel: data.level || null,
    email: data.email || null,
    grupo: data.group?.value || null,
    genero: data.gender?.value || null,
  };

  return values;
};

export default handleDataSubmit;
