import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import FormPrototype from '../../../../../components/Prototypes/FormPrototype';
import withRouter from '../../../../../components/common/withRouter';
import useRegionSchema from '../../../../../schemas/settings/region';
import { createRegion } from '../../../../../store/actions';
import handleInitialValuesCreate from '../../../../Voters/steps/handleInitialValuesCreate';
import handleCards from '../../steps/handleCards';
import handleDataSubmit from '../../steps/handleDataSubmit';

const CreateNewRegion = ({ router }) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const initialValues = handleInitialValuesCreate();
  const validationSchema = useRegionSchema();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: rest });

    dispatch(createRegion({ data }));
    formRef.current.reset();
  };

  return (
    <FormPrototype
      ref={formRef}
      pageContent={false}
      router={router}
      onSubmit={handleSubmit}
      initialValuesForm={{ ...initialValues }}
      validationSchema={validationSchema}
      documentTitle="Regiões"
      onCards={(params) => {
        return handleCards({ ...params });
      }}
    />
  );
};

export default withRouter(CreateNewRegion);
