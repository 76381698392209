const actions = ({ data }) => [
  {
    id: 1,
    title: 'Parabenizar via WhatsApp',
    tag: 'button',
    icon: 'whatsapp',
    text: 'Parabenizar via WhatsApp',
    showCondition: () => data.linkWhatsapp != null,
    attributes: {
      onClick: () => {
        window.open(data.linkWhatsapp, '_blank');
      },
    },
  },
  {
    id: 2,
    title: 'Parabenizar via Email',
    tag: 'button',
    icon: 'envelopeSend',
    text: 'Parabenizar via Email',
    showCondition: () => data.linkEmail != null,
    attributes: {
      onClick: () => {
        window.open(data.linkEmail, '_blank');
      },
    },
  },
];

export default actions;
