import React from 'react';
import withRouter from '../../../components/common/withRouter';

const ParticlesAuth = ({ children }) => {
  return (
    <div className="auth-page-wrapper pt-5">
      {children}

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mb-0 text-muted">
                  &copy; {new Date().getFullYear()}. Criado com{' '}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default withRouter(ParticlesAuth);
