import Icon from '../Icon';
import Form from '../../Formik/Form';
import React, { useState } from 'react';
import ModalAddTag from '../ModalAddTag';
import { ButtonContainer, Contanier } from './styles';

const FieldWithButtonToAddTag = ({ rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Contanier>
        <div className="containerField">
          <Form.Select {...rest} />
        </div>

        <ButtonContainer
          type="button"
          onClick={toggle}
          className="btn btn-primary"
          title="Adicionar mais etiquetas"
        >
          <Icon name="plusCircle" size={1} />
        </ButtonContainer>
      </Contanier>

      <ModalAddTag onClose={toggle} isOpen={isOpen} />
    </>
  );
};

export default FieldWithButtonToAddTag;
