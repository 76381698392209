const headerTitles = [
  'Nome',
  'Rede de saída',
  'Número',
  'Status',
  'Email da conta',
  'Município da conta',
  'Nome do candidato',
  'Situação da conta',
  'Ações',
];

export default headerTitles;
