const handleDataSubmit = ({ data }) => {
  const formattedValues = {
    eleitor: {
      id: data.eleitor,
    },
    iluminacao: data.iluminacao || null,
    saneamento: data.saneamento || null,
    agua: data.agua || null,
    asfalto: data.asfalto || null,
    limpeza: data.limpeza || null,
    chuvas: data.chuvas || null,
    lixo: data.lixo || null,
    arvores: data.arvores || null,
    praca: data.praca || null,
    zeladoria_comentario: data.zeladoria_comentario || null,
    transporte_publico_uso: data.transporte_publico_uso,
    transporte_publico: data.transporte_publico || null,
    onibus: data.onibus || null,
    transito: data.transito || null,
    transporte_comentario: data.transporte_comentario || null,
    educacao_publica_uso: data.educacao_publica_uso,
    educacao_publica: data.educacao_publica || null,
    merenda: data.merenda || null,
    transporte_escolar: data.transporte_escolar || null,
    creche: data.creche || null,
    educacao_comentario: data.educacao_comentario || null,
    saude_publica_uso: data.saude_publica_uso,
    saude_publica: data.saude_publica || null,
    posto_saude: data.posto_saude || null,
    medicamento_continuo_uso: data.medicamento_continuo_uso,
    medicamento: data.medicamento || null,
    saude_publica_comentario: data.saude_publica_comentario || null,
    prefeito: data.prefeito.value ? { id: data.prefeito.value } : null,
    vereador: data.vereador.value ? { id: data.vereador.value } : null,
    comentario: data.comentario || null,
  };
  if (data.id) formattedValues.id = data.id;
  return formattedValues;
};

export default handleDataSubmit;
