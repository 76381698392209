const validateHasLgpd = (path) => {  
  const lgpdRoutes = [
    '/voters/new',
    '/self-registration'
  ]  
  const isLgpdRoute = lgpdRoutes.some(route => path.startsWith(route));
  return isLgpdRoute;  
};

export default validateHasLgpd;
