import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FormPrototype from '../../../../../../components/Prototypes/FormPrototype';
import withRouter from '../../../../../../components/common/withRouter';
import useSystemParamSchema from '../../../../../../schemas/settings/systemParam';
import {
  getPendingSystemParams,
  saveSystemParams,
} from '../../../../../../store/actions';
import handleCards from '../../../steps/handleCards';
import handleDataSubmit from '../../../steps/handleDataSubmit';
import handleInitialValuesCreate from '../../../steps/handleInitialValuesCreate';

function CreateNewSystemParam({ router }) {
  const dispatch = useDispatch();
  const initialValues = handleInitialValuesCreate();
  const validationSchema = useSystemParamSchema();

  const formRef = useRef();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: rest });
    dispatch(saveSystemParams({ data }));
    formRef.current.reset();
  };

  useEffect(() => {
    dispatch(getPendingSystemParams());
  }, [dispatch]);

  return (
    <FormPrototype
      router={router}
      pageContent={false}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      ref={formRef}
      onCards={(params) => {
        return handleCards({ ...params, formRef });
      }}
      validationSchema={validationSchema}
    />
  );
}

export default withRouter(CreateNewSystemParam);
