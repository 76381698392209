const actions = ({ data, setModalData, onDelete, navigate }) => [
  {
    id: 1,
    title: 'Contatar via WhatsApp',
    tag: 'button',
    icon: 'whatsapp',
    text: 'Contatar via WhatsApp',
    showCondition: () => data.linkWhatsapp != null,
    attributes: {
      onClick: () => {
        window.open(data.linkWhatsapp, '_blank');
      },
    },
  },
  {
    id: 2,
    title: 'Contatar via Email',
    tag: 'button',
    icon: 'envelopeSend',
    text: 'Contatar via Email',
    showCondition: () => data.linkEmail != null,
    attributes: {
      onClick: () => {
        window.open(data.linkEmail, '_blank');
      },
    },
  },
  {
    id: 5,
    title: 'Realizar pesquisa municipal',
    tag: 'button',
    icon: 'list',
    text: 'Realizar pesquisa municipal',
    attributes: {
      onClick: () => {
        navigate(`/voters/municipal-research/${data.id}`);
      },
    },
  },
  {
    id: 3,
    title: 'Editar',
    tag: 'button',
    icon: 'pencil',
    text: 'Editar',
    attributes: {
      onClick: () => navigate(`/voters/update/${data.id}`),
    },
  },
  {
    id: 4,
    title: 'Apagar',
    tag: 'button',
    icon: 'delete',
    text: 'Apagar',
    attributes: {
      onClick: () => {
        setModalData((prev) => {
          return {
            ...prev,
            body: data.pessoa.nome,
            isOpen: true,
            onConfirm: () => onDelete(data.id),
          };
        });
      },
    },
  },
];

export default actions;
