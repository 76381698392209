const headerTitles = [
  'Eleitor',
  'Meio',
  'Texto',
  'Data de envio',
  'Destino',
  'Situação',
  'Ações',
];

export default headerTitles;
