const handleInitialValuesUpdate = (currentPolitician) => {
  if (!currentPolitician) return;
  return {
    id: currentPolitician.id,
    name: currentPolitician.nome,
    active: currentPolitician.active,
    position: {
      label: currentPolitician.cargo.descricao,
      value: currentPolitician.cargo.id,
    },
  };
};

export default handleInitialValuesUpdate;
