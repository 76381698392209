import * as Yup from 'yup';
import validationFilter from '../common/validationFilter';

const useMessagesSchema = () => {
  return Yup.object().shape({
    ...validationFilter,
    isContact: Yup.boolean().nullable(),
    isPhoneWhatsApp: Yup.boolean().nullable(),
    quite: Yup.object().nullable(),
    title: Yup.string().required('Informe o titulo da mensagem'),
    model: Yup.string().required('Informe o modelo da mensagem'),
  });
};

export default useMessagesSchema;
