const headerTitles = [
  'Nome do(a) candidato(a)',
  'Cargo',
  'Meta de eleitores',
  'Partido',
  'Ano da eleição',
  'Número do(a) candidato(a)',
  'Ações',
];

export default headerTitles;
