import {
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VISIBILITY,
} from './actionType';

export const changeSidebarVisibility = (isVisibility) => ({
  type: CHANGE_SIDEBAR_VISIBILITY,
  payload: { isVisibility },
});

export const changeLeftsidebarSizeType = ({ leftsidebarSizetype }) => ({
  type: CHANGE_SIDEBAR_SIZE_TYPE,
  payload: leftsidebarSizetype,
});
