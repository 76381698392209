import Icon from '../Icon';
import { Badge } from './styles';

const StatusItem = ({
  status = false,
  flex = false,
  labelTrue = 'Ativo',
  labelFalse = 'Inativo',
  labelEmpty = 'Indefinido',
  checkForEmpty = false,
}) => {
  let color = status ? 'success' : 'danger';
  let text = status ? labelTrue : labelFalse;

  if (checkForEmpty && typeof status != 'boolean') {
    color = 'warning';
    text = labelEmpty;
  }

  return (
    <div className={flex ? 'd-flex' : ''}>
      <Badge color={color} pill>
        <Icon name={status ? 'checkCircle' : 'minusCircle'} size={1} />
        {text}
      </Badge>
    </div>
  );
};

export default StatusItem;
