import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import withRouter from '../../../components/common/withRouter';
import ConfirmationModal from '../../../components/ui/ConfirmationModal';
import LoadingPage from '../../../components/ui/LoadingPage';
import useMessagesSchema from '../../../schemas/messages';
import {
  clearVotersAffectedByFilterCounter,
  getInterests,
  getRegions,
  getRegistrants,
  getReligions,
  getSchoolings,
  getSexualOrientations,
  getTags,
  getTemplateById,
  getTemplateVariables,
  updateTemplate,
  updateTemplateAndSendMessages,
} from '../../../store/actions';
import handleCards from '../steps/handleCards';
import handleDataSubmit from '../steps/handleDataSubmit';
import handleInitialValuesUpdate from '../steps/handleInitialValuesUpdate';

const UpdateTemplate = ({ router }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { templateId } = useParams();

  const formRef = useRef();
  const isUpdate = true;
  const validationSchema = useMessagesSchema(isUpdate);

  const [initialValues, setInitialValues] = useState(null);

  const selector = createDynamicSelector('Templates', ['template']);
  const { template } = useSelector(selector);

  const currentTemplate = template;

  const [modalData, setModalData] = useState({
    body: '',
    isOpen: false,
    title: '',
    confirmText: '',
    onConfirm: () => {},
    confirmButtonDisabled: true,
  });

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;

    const params = { data: { ...rest }, isUpdate };

    const data = handleDataSubmit(params);
    dispatch(updateTemplate({ ...data, templateId, navigate }));
  };

  const handleSubmitAndSendMessages = (values) => {
    const data = handleDataSubmit({ data: { ...values }, isUpdate });
    dispatch(updateTemplateAndSendMessages({ ...data, templateId, navigate }));
  };

  useEffect(() => {
    dispatch(getTags());
    dispatch(getRegions());
    dispatch(getReligions());
    dispatch(getInterests());
    dispatch(getSchoolings());
    dispatch(getRegistrants());
    dispatch(getSexualOrientations());
    dispatch(clearVotersAffectedByFilterCounter());
    dispatch(getTemplateVariables());
  }, [dispatch]);

  useEffect(() => {
    if (templateId) {
      dispatch(getTemplateById({ templateId }));
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    const initialValues = handleInitialValuesUpdate(currentTemplate);
    setInitialValues(initialValues);
  }, [currentTemplate]);

  if (!initialValues) return <LoadingPage />;

  return (
    <>
      <ConfirmationModal
        body={modalData.body}
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title={modalData.title}
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        confirmText={modalData.confirmText}
        confirmButtonDisabled={modalData.confirmButtonDisabled}
      />

      <FormPrototype
        ref={formRef}
        router={router}
        onSubmit={handleSubmit}
        initialValuesForm={initialValues}
        documentTitle="Atualizar modelo de mensagem"
        validationSchema={validationSchema}
        onCards={(params) => {
          return handleCards({
            ...params,
            isUpdate,
            formRef,
            setModalData,
            handleSubmitAndSendMessages,
          });
        }}
      />
    </>
  );
};

export default withRouter(UpdateTemplate);
