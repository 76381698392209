import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createDynamicSelector from '../../common/createDynamicSelector';
import DataTableWithPagination from './components/DataTableWithPagination';

const ListingPrototype = ({
  title,
  entityName,
  handleData,
  headerTitles,
  emptyMessage,
  dataSelector,
  dataSelectorArgs = [],
  deleteAction,
  pathPageCreate,
  children = null,
  selectorOptions,
  titleButtonHeader,
  isShowButtonCreateNewItem = true,
  isShowButtonToDisplayFilter = true,
  initialSortBy = [],
  pageContent = true,
  extraHeaderContent = null,
}) => {
  const dispatch = useDispatch();

  const lastItem = selectorOptions[selectorOptions.length - 1];
  const selectLayoutProperties = createDynamicSelector(
    entityName,
    selectorOptions
  );

  const { isLoading, [lastItem]: entityData } = useSelector(
    selectLayoutProperties
  );

  const columns = useMemo(
    () =>
      headerTitles.map((title) => ({
        Header: title,
        accessor: title,
        disableSortBy: true,
      })),
    [headerTitles]
  );

  const onDelete = (itemId) => {
    dispatch(deleteAction(itemId));
  };

  useEffect(() => {
    document.title = title;
    dispatch(dataSelector(...dataSelectorArgs));
  }, [dispatch, dataSelector, title]);

  const data = handleData(entityData ?? [], onDelete);

  const memoizedComponent = useMemo(() => {
    return (
      <DataTableWithPagination
        pageContent={pageContent}
        data={data}
        columns={columns}
        isLoading={isLoading}
        emptyMessage={emptyMessage}
        pathPageCreate={pathPageCreate}
        titleButtonHeader={titleButtonHeader}
        isShowButtonCreateNewItem={isShowButtonCreateNewItem}
        isShowButtonToDisplayFilter={isShowButtonToDisplayFilter}
        initialSortBy={initialSortBy}
        extraHeaderContent={extraHeaderContent}
      >
        {children}
      </DataTableWithPagination>
    );
  }, [columns, isLoading, entityData, entityName, pathPageCreate]);

  return memoizedComponent;
};

export default ListingPrototype;
