/**
 *
 * @param {number} value
 * @returns {void}
 */

const addOptionForHowManyRecordsToShowPerPage = (value) => {
  localStorage.setItem('optionForHowManyRecordsToShowPerPage', value);
};

export default addOptionForHowManyRecordsToShowPerPage;
