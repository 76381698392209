import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BarChart from '../../../components/charts/BarChart';
import PieChart from '../../../components/charts/PieChart';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import LoadingPage from '../../../components/ui/LoadingPage';
import { reportsVotersDashboardKeys } from '../../../config/reports/reportsKeys';
import { reportsVotersDashboardRoutes } from '../../../config/reports/reportsRoutes';
import { getReportByCategory } from '../../../store/actions';
import chartColors from '../steps/colors';

const VotersDashboard = () => {
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Reports', [
    'isLoading',
    ...reportsVotersDashboardKeys,
  ]);

  const {
    isLoading,
    reportsAges,
    reportsTags,
    reportsGenre,
    reportsRegion,
    reportsReligion,
    reportsEthnicity,
    reportsInterests,
    reportsOrientation,
    reportsMultipliers,
    reportsEngagement,
    reportsEducation,
    reportsIncome,
    reportsElectoralCollege,
  } = useSelector(selector);

  useEffect(() => {
    document.title = 'Gráficos - Eleitores';

    reportsVotersDashboardRoutes.forEach((route, index) => {
      const category = route;
      const key = reportsVotersDashboardKeys[index];

      dispatch(getReportByCategory({ category, key }));
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Container className="page-content">
          <Row>
            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <BarChart
                title={'Quantidade de Interesses'}
                titleHeader={'Quantidade por Interesse'}
                data={reportsInterests.map(({ quantidade }) => quantidade)}
                categories={reportsInterests.map(({ descricao }) => descricao)}
                colors={[chartColors[0]]}
              />
            </Col>

            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <BarChart
                title={'Quantidade de Etiquetas'}
                titleHeader={'Quantidade por Etiqueta'}
                data={reportsTags.map(({ quantidade }) => quantidade)}
                categories={reportsTags.map(({ descricao }) => descricao)}
                colors={[chartColors[1]]}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <BarChart
                title={'Quantidade por Região'}
                titleHeader={'Quantidade por Região'}
                data={reportsRegion.map(({ quantidade }) => quantidade)}
                categories={reportsRegion.map(({ descricao }) => descricao)}
                colors={[chartColors[2]]}
              />
            </Col>

            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <BarChart
                title={'Quantidade por Religião'}
                titleHeader={'Quantidade por Religião'}
                data={reportsReligion.map(({ quantidade }) => quantidade)}
                categories={reportsReligion.map(({ descricao }) => descricao)}
                colors={[chartColors[3]]}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <BarChart
                title={'Quantidade por Engajamento'}
                titleHeader={'Quantidade por Engajamento'}
                data={reportsEngagement.map(({ quantidade }) => quantidade)}
                categories={reportsEngagement.map(({ descricao }) => descricao)}
                colors={[chartColors[4]]}
              />
            </Col>

            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <BarChart
                title={'Quantidade por Escolaridade'}
                titleHeader={'Quantidade por Escolaridade'}
                data={reportsEducation.map(({ quantidade }) => quantidade)}
                categories={reportsEducation.map(({ descricao }) => descricao)}
                colors={[chartColors[5]]}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12}>
              <PieChart
                title={'Distribuição por Multiplicadores'}
                titleHeader={'Quantidade de Multiplicadores'}
                data={reportsMultipliers.map(({ quantidade }) => quantidade)}
                labels={reportsMultipliers.map(({ descricao }) => descricao)}
                colors={chartColors}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <PieChart
                title={'Distribuição por Etnia'}
                titleHeader={'Quantidade por Etnia'}
                data={reportsEthnicity.map(({ quantidade }) => quantidade)}
                labels={reportsEthnicity.map(({ descricao }) => descricao)}
                colors={chartColors}
              />
            </Col>

            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <PieChart
                title={'Distribuição por Orientação Sexual'}
                titleHeader={'Quantidade por Orientação Sexual'}
                data={reportsOrientation.map(({ quantidade }) => quantidade)}
                labels={reportsOrientation.map(({ descricao }) => descricao)}
                colors={chartColors}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <PieChart
                title={'Distribuição por Faixa Etária'}
                titleHeader={'Quantidade por Faixa Etária'}
                data={reportsAges.map(({ quantidade }) => quantidade)}
                labels={reportsAges.map(({ descricao }) => descricao)}
                colors={chartColors}
              />
            </Col>

            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <PieChart
                title={'Distribuição por Gênero'}
                titleHeader={'Quantidade por Gênero'}
                data={reportsGenre.map(({ quantidade }) => quantidade)}
                labels={reportsGenre.map(({ descricao }) => descricao)}
                colors={chartColors}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <PieChart
                title={'Distribuição por Renda'}
                titleHeader={'Quantidade por Renda'}
                data={reportsIncome.map(({ quantidade }) => quantidade)}
                labels={reportsIncome.map(({ descricao }) => descricao)}
                colors={chartColors}
              />
            </Col>

            <Col xs={12} md={12} sm={12} lg={6} xl={6} xxl={6}>
              <PieChart
                title={'Distribuição por Colégio Eleitoral'}
                titleHeader={'Quantidade por Colégio Eleitoral'}
                data={reportsElectoralCollege.map(
                  ({ quantidade }) => quantidade
                )}
                labels={reportsElectoralCollege.map(
                  ({ descricao }) => descricao
                )}
                colors={chartColors}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default VotersDashboard;
