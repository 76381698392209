const headerTitles = [
  'Nome',
  'Email',
  'telefone',
  'Data de Nascimento',
  'Genero',
  'Ações',
];

export default headerTitles;
