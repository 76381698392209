export const reportsVotersDashboardRoutes = [
  '/etnia',
  '/genero',
  '/idades',
  '/regiao',
  '/religiao',
  '/etiquetas',
  '/interesses',
  '/orientacao',
  '/multiplicadores',
  '/desempenho/engajamento',
  '/desempenho/escolaridade',
  '/desempenho/renda',
  '/desempenho/colegio',
];

export const reportsResearchDashboardRoutes = [
  '/pesquisa/agua',
  '/pesquisa/asfalto',
  '/pesquisa/creche',
  '/pesquisa/educacao',
  '/pesquisa/iluminacao',
  '/pesquisa/onibus',
  '/pesquisa/posto',
  '/pesquisa/prefeito',
  '/pesquisa/saude',
  '/pesquisa/transito',
  '/pesquisa/transporte',
  '/pesquisa/vereador',
];

export const reportsPerformanceDashboardRoutes = [
  '/desempenho/multiplicadores',
];

export const reportsHomeRoutes = [
  '/desempenho/candidato',
  '/desempenho/multiplicador',
];
