const handleInitialValuesCreate = () => {
  const initialValues = {
    cpf: '',
    title: '',
    phone: '',
    email: '',
    tags: null,
    street: '',
    income: '',
    zipCode: '',
    lastName: '',
    userName: '',
    gender: null,
    children: '',
    region: null,
    engagement: '',
    isVoter: null,
    complement: '',
    religion: null,
    interests: null,
    ethnicity: null,
    dateOfBirth: '',
    schooling: null,
    isContact: null,
    neighborhood: '',
    addressNumber: '',
    municipality: null,
    maritalStatus: null,
    isPhoneWhatsApp: null,
    sexualOrientation: null,
    electoralCollege: '',
    electoralZone: '',
    message: '',
    group: '',
    profession: '',
    preferredContactMethod: null,
  };

  return initialValues;
};

export default handleInitialValuesCreate;
