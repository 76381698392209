import handleInitialValuesFilterObject from '../../../../utils/transformsValues/handleInitialValuesFilterObject';

const handleInitialValuesUpdate = (data = null) => {
  if (!data) return null;

  const initialValuesFilters = handleInitialValuesFilterObject(data);

  const values = {
    ...initialValuesFilters,
    title: data.titulo,
    model: data.modelo,
    quite: { label: data.meio, value: data.meio },
  };

  return values;
};

export default handleInitialValuesUpdate;
