import * as Yup from 'yup';
import validationTitle from '../common/validationTitle';
import validationEmail from '../common/validationEmail';
import validationPhone from '../common/validationPhone';
import validationPerson from '../common/validationPerson';

const useVotersSchema = () => {
  const isOptional = true;

  return Yup.object().shape({
    ...validationEmail(isOptional),
    ...validationPhone(isOptional),
    ...validationTitle(isOptional),
    ...validationPerson(isOptional),
    isVoter: Yup.boolean().nullable(),
    isContact: Yup.boolean().nullable(),
    tag: Yup.array().nullable(),
    isPhoneWhatsApp: Yup.boolean().nullable(),
    income: Yup.string().nullable(),
    region: Yup.object().nullable(),
    interest: Yup.array().nullable(),
    religion: Yup.object().nullable(),
    children: Yup.number().nullable(),
    ethnicity: Yup.object().nullable(),
    schooling: Yup.object().nullable(),
    instagram: Yup.string().nullable(),
    engagement: Yup.object().nullable(),
    electoralZone: Yup.number().nullable(),
    electoralCollege: Yup.string().nullable(),
    message: Yup.string().nullable(),
    profession: Yup.string().nullable(),
    preferredContactMethod: Yup.object().nullable(),
  });
};

export default useVotersSchema;
