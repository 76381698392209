import Users from './users/reducer';
import Voters from './voters/reducer';
import Layout from './layout/reducer';
import Reports from './reports/reducer';
import { combineReducers } from 'redux';
import Login from './auth/login/reducer';
import Messages from './messages/reducer';
import Accounts from './accounts/reducer';
import Candidates from './candidates/reducer';
import Multipliers from './multipliers/reducer';
import GeneralFeature from './generalFeature/reducer';
import ForgetPassword from './auth/forgetPassword/reducer';
import Templates from './templates/reducer';
import Settings from './settings/reducer';
import Politicians from './politicians/reducer';
import Management from './management/reducer';

const rootReducer = combineReducers({
  Users,
  Login,
  Voters,
  Layout,
  Reports,
  Messages,
  Accounts,
  Candidates,
  Multipliers,
  ForgetPassword,
  GeneralFeature,
  Templates,
  Settings,
  Politicians,
  Management,
});

export default rootReducer;
