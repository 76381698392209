import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSystemParamsDescriptions } from '../../../../store/actions';
import CreateNewSystemParam from './components/CreateNewSystemParam';
import ListingSystemParams from './components/ListingSystemParams';

export default function CreateListSystemParam() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSystemParamsDescriptions());
  }, [dispatch]);

  return (
    <div className="page-content">
      <CreateNewSystemParam />
      <ListingSystemParams />
    </div>
  );
}
