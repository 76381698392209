import Cookies from 'js-cookie';

/**
 * @returns { void }
 */

const removeUserDataAndAccessToken = () => {
  Cookies.remove('userData', { path: '' });
  Cookies.remove('accessToken', { path: '' });
};

export default removeUserDataAndAccessToken;
