import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import handleCards from '../steps/handleCards';
import useVotersSchema from '../../../schemas/voters';
import { useDispatch, useSelector } from 'react-redux';
import handleDataSubmit from '../steps/handleDataSubmit';
import LoadingPage from '../../../components/ui/LoadingPage';
import withRouter from '../../../components/common/withRouter';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import handleInitialValuesUpdate from '../steps/handleInitialValuesUpdate';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import {
  getTags,
  getRegions,
  updateVoter,
  getReligions,
  getInterests,
  getVoterById,
  getSchoolings,
  getSexualOrientations,
} from '../../../store/actions';

const UpdateVoter = ({ router }) => {
  const dispatch = useDispatch();
  const { voterId } = useParams();

  const isUpdate = true;
  const validationSchema = useVotersSchema(isUpdate);

  const [initialValues, setInitialValues] = useState(null);

  const selector = createDynamicSelector('Voters', ['voter']);
  const { voter } = useSelector(selector);

  const formKeyRef = useRef(Date.now());

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;

    const userId = voter.pessoa.id;
    const params = { data: { ...rest, userId }, isUpdate };

    const data = handleDataSubmit(params);
    dispatch(updateVoter({ ...data, voterId, navigate }));
  };

  useEffect(() => {
    dispatch(getTags());
    dispatch(getRegions());
    dispatch(getReligions());
    dispatch(getInterests());
    dispatch(getSchoolings());
    dispatch(getSexualOrientations());
    dispatch(getVoterById({ id: voterId }));
  }, [dispatch]);

  useEffect(() => {
    const initialValues = handleInitialValuesUpdate(voter);
    setInitialValues(initialValues);
  }, [voter]);

  const isVoter = Object.keys(voter).length > 0;
  const hasInitialValues = Object.values(initialValues || {}).some(
    (value) => !!value
  );

  if (!hasInitialValues || !isVoter) return <LoadingPage />;

  return (
    <FormPrototype
      key={formKeyRef.current}
      router={router}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Atualizar Eleitor(a)"
      onCards={(params) => {
        return handleCards({ ...params, isUpdate });
      }}
    />
  );
};

export default withRouter(UpdateVoter);
