import ListingPrototype from '../../components/Prototypes/ListingPrototype';
import withRouter from '../../components/common/withRouter';
import { birthdaysList } from '../../store/actions';
import formatDate from '../../utils/formatDate';
import ActionsDropdown from '../../components/ui/ActionsDropdown';
import FormFilter from './components/FormFilter';
import headerTitles from './steps/headerTitles';
import selectorOptions from './steps/selectorOptions';
import actionsOptions from './steps/actions';

const Birthdays = () => {
  const [name, email, phone, dateOfBirth, gender, actions] = headerTitles;

  const handleData = (users) => {
    return users.map((data) => {
      const { telefone, pessoa, genero } = data;
      const { nome, dataNascimento } = pessoa;

      const formattedDateOfBirth = formatDate(dataNascimento, 'DD/MM/YYYY');

      return {
        [name]: nome,
        [email]: data.email,
        [phone]: telefone,
        [dateOfBirth]: dataNascimento ? formattedDateOfBirth : '--',
        [gender]: genero || '--',
        [actions]: (
          <ActionsDropdown
            hrefs={['', '']}
            title={actions}
            actions={actionsOptions({ data })}
          />
        ),
      };
    });
  };

  return (
    <ListingPrototype
      entityName={'Voters'}
      handleData={handleData}
      title={'Aniversariantes'}
      headerTitles={headerTitles}
      dataSelector={birthdaysList}
      selectorOptions={selectorOptions}
      isShowButtonCreateNewItem={false}
      isShowButtonToDisplayFilter={false}
      emptyMessage={'Oops, sem aniversariantes para exibir.'}
    >
      <FormFilter />
    </ListingPrototype>
  );
};

export default withRouter(Birthdays);
