import * as Yup from 'yup';

const useSystemParamSchema = (isUpdate = false) => {
  if (isUpdate) {
    return Yup.object().shape({
      key: Yup.string().required('Preencha o campo'),
      value: Yup.string().required('Preencha o campo'),
    });
  }
  return Yup.object().shape({
    key: Yup.object().required('Preencha o campo'),
    value: Yup.string().required('Preencha o campo'),
  });
};

export default useSystemParamSchema;
