import * as Yup from 'yup';
import toast from '../../components/ui/toast';
import validationEmail from '../common/validationEmail';
import validationPhone from '../common/validationPhone';

const useMunicipalResearchNotRegisteredSchema = () => {
  const schema = Yup.object().shape({
    iluminacao: Yup.string().nullable(),
    saneamento: Yup.string().nullable(),
    agua: Yup.string().nullable(),
    asfalto: Yup.string().nullable(),
    limpeza: Yup.string().nullable(),
    chuvas: Yup.string().nullable(),
    lixo: Yup.string().nullable(),
    arvores: Yup.string().nullable(),
    praca: Yup.string().nullable(),
    zeladoria_comentario: Yup.string().nullable(),
    transporte_publico_uso: Yup.boolean().nullable(),
    transporte_publico: Yup.string().nullable(),
    onibus: Yup.string().nullable(),
    transito: Yup.string().nullable(),
    transporte_comentario: Yup.string().nullable(),
    educacao_publica_uso: Yup.boolean().nullable(),
    educacao_publica: Yup.string().nullable(),
    merenda: Yup.string().nullable(),
    transporte_escolar: Yup.string().nullable(),
    creche: Yup.string().nullable(),
    educacao_comentario: Yup.string().nullable(),
    saude_publica_uso: Yup.boolean().nullable(),
    saude_publica: Yup.string().nullable(),
    posto_saude: Yup.string().nullable(),
    medicamento_continuo_uso: Yup.boolean().nullable(),
    medicamento: Yup.string().nullable(),
    saude_publica_comentario: Yup.string().nullable(),
    prefeito: Yup.object().nullable(),
    vereador: Yup.object().nullable(),
    comentario: Yup.string().nullable(),

    acceptTerms: Yup.boolean()
      .required(() => {
        toast('error', 'É necessário aceitar os termos');
        return 'É necessário aceitar os termos';
      })
      .oneOf([true], () => {
        toast('error', 'É necessário aceitar os termos');
        return 'É necessário aceitar os termos';
      }),
    nome: Yup.string().required(() => {
      toast('error', 'Informe o nome');
      return 'Informe o nome';
    }),
    sobrenome: Yup.string().required(() => {
      toast('error', 'Informe o sobrenome');
      return 'Informe o sobrenome';
    }),
    preferredContactMethod: Yup.object().required(() => {
      toast('error', 'Selecione o melhor meio de contato');
      return 'Selecione o melhor meio de contato';
    }),
    ...validationPhone(true),
    isPhoneWhatsApp: Yup.boolean().nullable(),
    isContact: Yup.boolean().nullable(),
    ...validationEmail(true),
    interests: Yup.array().nullable(),
    tags: Yup.array().nullable(),
  });

  return schema;
};

export default useMunicipalResearchNotRegisteredSchema;
