import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormPrototype from '../../../components/Prototypes/FormPrototype';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import withRouter from '../../../components/common/withRouter';
import LoadingPage from '../../../components/ui/LoadingPage';
import useCandidatesSchema from '../../../schemas/candidates';
import {
  candidatesList,
  getParties,
  getPositions,
  getSexualOrientations,
  updateCandidate,
} from '../../../store/actions';
import handleCards from '../steps/handleCards';
import handleDataSubmit from '../steps/handleDataSubmit';
import handleInitialValuesUpdate from '../steps/handleInitialValuesUpdate';

const UpdateCandidate = ({ router }) => {
  const dispatch = useDispatch();
  const { candidateId } = useParams();

  const photoFormRef = useRef();

  const isUpdate = true;
  const validationSchema = useCandidatesSchema(photoFormRef, isUpdate);

  const [initialValues, setInitialValues] = useState(null);
  const [oldPhoto, setOldPhoto] = useState(null);
  const [currentImages, setCurrentImages] = useState(['']);

  const selector = createDynamicSelector('Candidates', ['candidates']);
  const { candidates } = useSelector(selector);

  const currentCandidate = candidates.find(({ id }) => id == candidateId);

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;

    const userId = currentCandidate.usuario.id;
    const params = { data: { ...rest, userId, photo: photoFormRef }, isUpdate };

    const data = handleDataSubmit(params);
    dispatch(
      updateCandidate({
        ...data,
        candidateId,
        navigate,
        oldPhoto,
      })
    );
  };

  useEffect(() => {
    dispatch(getParties());
    dispatch(getPositions());
    dispatch(candidatesList());
    dispatch(getSexualOrientations());
  }, [dispatch]);

  useEffect(() => {
    const initialValues = handleInitialValuesUpdate(currentCandidate);
    setOldPhoto(`${initialValues?.photo}`);
    setCurrentImages([initialValues?.photo]);

    if (initialValues) initialValues.photo = '';
    setInitialValues(initialValues);
  }, [currentCandidate]);

  if (!initialValues) return <LoadingPage />;

  return (
    <FormPrototype
      ref={photoFormRef}
      router={router}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      validationSchema={validationSchema}
      documentTitle="Atualizar Candidato(a)"
      onCards={(params) => {
        return handleCards({
          ...params,
          isUpdate,
          currentImages,
        });
      }}
    />
  );
};

export default withRouter(UpdateCandidate);
