import * as Yup from 'yup';
import validationEmail from '../common/validationEmail';

const useMultipliersFilterSchema = () => {
  const isOptional = true;

  return Yup.object().shape({
    ...validationEmail(isOptional),
    name: Yup.string(),
    level: Yup.number(),
    isActive: Yup.boolean(),
    group: Yup.object().nullable(),
    gender: Yup.object().nullable(),
  });
};

export default useMultipliersFilterSchema;
