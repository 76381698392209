const handleInitialValuesUpdate = (systemParam) => {
  if (!systemParam) return;

  const values = {
    key: systemParam.chave,
    value: systemParam.valor,
  };

  return values;
};

export default handleInitialValuesUpdate;
