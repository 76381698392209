import keys from '../common';

const { lastLoginDateKey, rememberKey } = keys;

const addRememberLocalStorage = () => {
  const currentDate = new Date().toISOString();

  localStorage.setItem(rememberKey, 'true');
  localStorage.setItem(lastLoginDateKey, currentDate);
};

export default addRememberLocalStorage;
