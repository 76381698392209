import keys from '../common';

const { lastLoginDateKey, rememberKey } = keys;

const getRememberLocalStorage = () => {
  const lastLogin = localStorage.getItem(lastLoginDateKey);
  const isRemember = localStorage.getItem(rememberKey) === 'true';

  return { lastLogin, isRemember };
};

export default getRememberLocalStorage;
