import {
  MULTIPLIERS_LIST,
  MULTIPLIERS_ERROR,
  CREATE_MULTIPLIER,
  UPDATE_MULTIPLIER,
  MULTIPLIERS_SUCCESS,
  MULTIPLIERS_LIST_FILTER,
  ACTIVE_OR_INACTIVATE_MULTIPLIER,
} from './actionTypes';

export const createMultiplier = (payload) => {
  return {
    type: CREATE_MULTIPLIER,
    payload,
  };
};

export const updateMultiplier = (payload) => {
  return {
    type: UPDATE_MULTIPLIER,
    payload,
  };
};

export const multipliersList = () => {
  return {
    type: MULTIPLIERS_LIST,
    payload: {},
  };
};

export const multipliersListFilter = (payload) => {
  return {
    type: MULTIPLIERS_LIST_FILTER,
    payload,
  };
};

export const activeOrInactivateMultiplier = (payload) => {
  return {
    type: ACTIVE_OR_INACTIVATE_MULTIPLIER,
    payload,
  };
};

export const multipliersError = () => {
  return {
    type: MULTIPLIERS_ERROR,
    payload: {},
  };
};

export const multipliersSuccess = (payload) => {
  return {
    type: MULTIPLIERS_SUCCESS,
    payload,
  };
};
