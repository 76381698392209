import Icon from '../../../../../components/ui/Icon';
import fields from '../../fields/municipalResearch';

const handleCards = ({ form, onUpdateForm }) => {
  const cardFields = fields({ form, onUpdateForm });

  const cards = [
    {
      fields: cardFields.zeladoria,
      titleHeader: 'Zeladoria',
      Icon: () => <Icon name="city" />,
    },
    {
      fields: cardFields.transporte,
      titleHeader: 'Transporte',
      Icon: () => <Icon name="bus" />,
    },
    {
      fields: cardFields.educacao,
      titleHeader: 'Educação',
      Icon: () => <Icon name="school" />,
    },
    {
      fields: cardFields.saude,
      titleHeader: 'Saúde',
      Icon: () => <Icon name="health" />,
    },
    {
      fields: cardFields.intencaoVoto,
      titleHeader: 'Intenção de voto',
      isShowFormControlButtons: true,
      Icon: () => <Icon name="medal" />,
    },
  ];
  return cards;
};

export default handleCards;
