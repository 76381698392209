import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import toast from '../../components/ui/toast';
import api from '../../services/api';
import getOptionForHowManyRecordsToShowPerPage from '../../utils/actionsListing/getOptionForHowManyRecordsToShowPerPage';
import getVotersPageIndex from '../../utils/actionsListing/getVotersPageIndex';
import downloadCSVDataAsFile from '../../utils/downloadCSVDataAsFile';
import getCurrentDateTimeFileString from '../../utils/getCurrentDateTimeFileString';
import {
  getMunicipalResearchById as getMunicipalResearchByIdAction,
  getTags,
  votersError,
  votersList as votersListAction,
  votersSuccess,
} from '../actions';
import {
  ADD_TAG,
  AUTOREGISTER_VOTER,
  BIRTHDAYS_LIST,
  BIRTHDAYS_LIST_FILTER,
  CLEAR_MUNICIPAL_RESEARCH,
  CLEAR_VOTERS_AFFECTED_BY_FILTER_COUNTER,
  COUNT_VOTERS_AFFECTED_BY_FILTER,
  CREATE_MUNICIPAL_RESEARCH,
  CREATE_VOTER,
  DELETE_VOTER,
  GENERATE_VOTERS_CSV,
  GET_MUNICIPAL_RESEARCH_BY_ID,
  GET_MUNICIPAL_RESEARCH_BY_VOTER_ID,
  GET_VOTER_BY_ID,
  UPDATE_MUNICIPAL_RESEARCH,
  UPDATE_VOTER,
  VOTERS_LIST,
  VOTERS_LIST_FILTER,
} from './actionTypes';

const defaultPageIndex = getVotersPageIndex();
const defaultPageSize = getOptionForHowManyRecordsToShowPerPage();

const payloadVotersListDefault = {
  pageSize: defaultPageSize,
  pageIndex: defaultPageIndex,
};

function* handleSuccess({ navigate, message }) {
  toast('success', message);
  yield put(votersSuccess());

  navigate && navigate('/voters');
}

function* handleError({ error, errorDefaultMessage }) {
  const { response } = error;
  const { data, status } = response;

  yield put(votersError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data || errorDefaultMessage);
}

function* addTag({ payload }) {
  try {
    const { onCloseModal, ...data } = payload;
    yield call(api.post, '/etiquetas', data);

    const message = 'Etiqueta adicionada com sucesso.';

    yield put(getTags());
    yield handleSuccess({ message });

    onCloseModal();
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível adicionar a etiqueta.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* createVoter({ payload }) {
  try {
    const { navigate, ...data } = payload;

    if (!data.email && !data.telefone) {
      toast('error', 'Preencha o email ou o telefone');
      return;
    }

    yield call(api.post, '/eleitores', data);

    const message = 'Eleitor(a) criado(a) com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível criar um(a) novo(a) eleitor(a).';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* autoRegisterVoter({ payload }) {
  try {
    const { data, code, navigate, site } = payload;
    const url = `/eleitores/autocadastro/${code}`;

    if (!data.email && !data.telefone) {
      toast('error', 'Preencha o email ou o telefone');
      return;
    }

    yield call(api.post, url, data);

    const message = 'Cadastro realizado com sucesso.';
    toast('success', message);

    yield put(votersSuccess());

    if (site) {
      window.location.href = site;
    } else {
      navigate && navigate(0);
    }
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível realizar o auto cadastro.';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* getVoterById({ payload }) {
  try {
    const { id } = payload;
    const route = `/eleitores/${id}`;

    const { data } = yield call(api.get, route);
    yield put(votersSuccess({ voter: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível pegar os dados do(a) eleitor(a).');
    yield put(votersError());
  }
}

function* votersList({ payload }) {
  try {
    const { pageIndex, pageSize } = payload;
    const route = `/eleitores/busca?pagina=${pageIndex}&tamanho=${pageSize}`;

    const { data } = yield call(api.post, route, {});
    yield put(votersSuccess({ voters: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os(as) eleitores(as).');
    yield put(votersError());
  }
}

function* votersListFilter({ payload }) {
  try {
    const { filters, pageIndex, pageSize } = payload;

    const route = `/eleitores/busca?pagina=${pageIndex}&tamanho=${pageSize}`;
    const { data } = yield call(api.post, route, filters);
    yield put(votersSuccess({ voters: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os(as) eleitores(as).');
    yield put(votersError());
  }
}

function* countVotersAffectedByFilter({ payload }) {
  try {
    const { data } = payload;
    const { data: count } = yield call(api.post, '/eleitores/filtros', data);

    yield put(votersSuccess({ votersAffectedByFilter: count }));
  } catch (error) {
    toast(
      'error',
      'Oops, não foi possível recuperar a quantidade de eleitores afetados.'
    );
    yield put(votersError());
  }
}

function* clearVotersAffectedByFilterCounter() {
  yield put(votersSuccess({ votersAffectedByFilter: null }));
}

function* birthdaysList() {
  try {
    const route = `/eleitores/aniversariantes`;
    const { data } = yield call(api.get, route);

    yield put(votersSuccess({ birthdays: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os aniversariantes.');
    yield put(votersError());
  }
}

function* birthdaysListFilter({ payload }) {
  try {
    const { nextDays } = payload;

    const route = `/eleitores/aniversariantes?dias=${nextDays}`;
    const { data } = yield call(api.get, route);

    yield put(votersSuccess({ birthdays: data }));
  } catch (error) {
    toast('error', 'Oops, não foi possível listar os aniversariantes.');
    yield put(votersError());
  }
}

function* updateVoter({ payload }) {
  try {
    const { voterId, navigate, ...data } = payload;

    if (!data.email && !data.telefone) {
      toast('error', 'Preencha o email ou o telefone');
      return;
    }

    yield call(api.put, `/eleitores/${voterId}`, data);

    yield put(votersListAction(payloadVotersListDefault));

    const message = 'Eleitor(a) atualizado(a) com sucesso.';
    yield handleSuccess({ navigate, message });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível atualizar o(a) eleitor(a).';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* deleteVoter({ payload }) {
  try {
    const { voterId } = payload;
    yield call(api.delete, `/eleitores/${voterId}`);

    yield put(votersListAction(payloadVotersListDefault));

    const message = 'Eleitor(a) excluído(a) com sucesso ;)';
    yield handleSuccess({ message });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível excluir o(a) eleitor(a).';

    yield handleError({ error, errorDefaultMessage });
  }
}

function* generateVotersCSV({ payload }) {
  try {
    const { data } = payload;
    const { data: csvData } = yield call(api.post, `/eleitores/csv`, data);

    downloadCSVDataAsFile(
      [csvData],
      `eleitores-${getCurrentDateTimeFileString()}`
    );

    const message = 'CSV gerado com sucesso';
    yield handleSuccess({ message });
  } catch (error) {
    const errorDefaultMessage = 'Oops, não foi possível gerar o CSV.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* createMunicipalResearch({ payload }) {
  try {
    const { data, navigate } = payload;
    yield call(api.post, '/pesquisas/municipal', data);

    const message = 'Pesquisa municipal criada com sucesso';
    yield handleSuccess({ message, navigate });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível criar a pesquisa municipal.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* updateMunicipalResearch({ payload }) {
  try {
    const { data, navigate } = payload;
    yield call(api.put, `/pesquisas/municipal/${data.id}`, data);

    const message = 'Pesquisa municipal atualizada com sucesso';
    yield handleSuccess({ message, navigate });
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível atualizar a pesquisa municipal.';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getMunicipalResearchById({ payload }) {
  try {
    const { id } = payload;
    const { data } = yield call(api.get, `/pesquisas/municipal/${id}`);
    yield put(votersSuccess({ municipalResearch: data }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar os dados da pesquisa';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* getMunicipalResearchByVoterId({ payload }) {
  try {
    const { voterId } = payload;
    const { data: voter } = yield call(api.get, `/eleitores/${voterId}`);
    if (voter.pesquisaMunicipalId) {
      yield put(
        getMunicipalResearchByIdAction({ id: voter.pesquisaMunicipalId })
      );
    }
    yield put(votersSuccess({ municipalResearch: null }));
  } catch (error) {
    const errorDefaultMessage =
      'Oops, não foi possível recuperar os dados da pesquisa';
    yield handleError({ error, errorDefaultMessage });
  }
}

function* clearMunicipalResearch() {
  yield put(votersSuccess({ municipalResearch: null }));
}

export function* watchAddTag() {
  yield takeEvery(ADD_TAG, addTag);
}

export function* watchVotersList() {
  yield takeEvery(VOTERS_LIST, votersList);
}

export function* watchCreateVoter() {
  yield takeEvery(CREATE_VOTER, createVoter);
}

export function* watchAutoregisterVoter() {
  yield takeEvery(AUTOREGISTER_VOTER, autoRegisterVoter);
}

export function* watchUpdateVoter() {
  yield takeEvery(UPDATE_VOTER, updateVoter);
}

export function* watchDeleteVoter() {
  yield takeEvery(DELETE_VOTER, deleteVoter);
}

export function* watchGetVoterById() {
  yield takeEvery(GET_VOTER_BY_ID, getVoterById);
}

export function* watchBirthdaysList() {
  yield takeEvery(BIRTHDAYS_LIST, birthdaysList);
}

export function* watchVotersListFilter() {
  yield takeEvery(VOTERS_LIST_FILTER, votersListFilter);
}

export function* watchBirthdaysListFilter() {
  yield takeEvery(BIRTHDAYS_LIST_FILTER, birthdaysListFilter);
}

export function* watchCountVotersAffectedByFilter() {
  yield takeEvery(COUNT_VOTERS_AFFECTED_BY_FILTER, countVotersAffectedByFilter);
}

export function* watchClearVotersAffectedByFilterCounter() {
  yield takeEvery(
    CLEAR_VOTERS_AFFECTED_BY_FILTER_COUNTER,
    clearVotersAffectedByFilterCounter
  );
}

export function* watchGenerateVotersCSV() {
  yield takeEvery(GENERATE_VOTERS_CSV, generateVotersCSV);
}

export function* watchCreateMunicipalResearch() {
  yield takeEvery(CREATE_MUNICIPAL_RESEARCH, createMunicipalResearch);
}

export function* watchGetMunicipalResearchById() {
  yield takeEvery(GET_MUNICIPAL_RESEARCH_BY_ID, getMunicipalResearchById);
}

export function* watchClearMunicipalResearch() {
  yield takeEvery(CLEAR_MUNICIPAL_RESEARCH, clearMunicipalResearch);
}

export function* watchUpdateMunicipalResearch() {
  yield takeEvery(UPDATE_MUNICIPAL_RESEARCH, updateMunicipalResearch);
}

export function* watchGetMunicipalResearchByVoterId() {
  yield takeEvery(
    GET_MUNICIPAL_RESEARCH_BY_VOTER_ID,
    getMunicipalResearchByVoterId
  );
}

function* voters() {
  yield all([
    fork(watchAddTag),
    fork(watchVotersList),
    fork(watchCreateVoter),
    fork(watchAutoregisterVoter),
    fork(watchUpdateVoter),
    fork(watchDeleteVoter),
    fork(watchGetVoterById),
    fork(watchBirthdaysList),
    fork(watchVotersListFilter),
    fork(watchBirthdaysListFilter),
    fork(watchCountVotersAffectedByFilter),
    fork(watchClearVotersAffectedByFilterCounter),
    fork(watchGenerateVotersCSV),
    fork(watchCreateMunicipalResearch),
    fork(watchGetMunicipalResearchById),
    fork(watchClearMunicipalResearch),
    fork(watchUpdateMunicipalResearch),
    fork(watchGetMunicipalResearchByVoterId),
  ]);
}

export default voters;
