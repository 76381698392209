import emailField from '../emailField';
import phoneField from '../phoneField';
import genderField from '../genderField';
import moneyMask from '../../../masks/moneyMask';
import registrationField from '../registrationField';
import maritalStatusField from '../maritalStatusField';
import { engagementOptions } from '../../../optionsCommon';
import { useSelector as handleUseSelector } from 'react-redux';
import sexualOrientationField from '../sexualOrientationField';
import createDynamicSelector from '../../../../components/common/createDynamicSelector';

const formatMoneyValue = (value) => {
  const isNumber = typeof value === 'number';
  if (isNumber) return moneyMask(value.toFixed(2));

  return value;
};

const fields = ({ form, onUpdateForm, isUpdate, isToHideFields = false }) => {
  const selector = createDynamicSelector('GeneralFeature', [
    'tags',
    'regions',
    'religions',
    'interests',
    'schoolings',
  ]);

  const { tags, interests, schoolings, regions, religions } =
    handleUseSelector(selector);

  return [
    [
      {
        md: 4,
        type: 'radio',
        name: 'isVoter',
        defaultValue: form.isVoter,
        label: 'Eleitor votante?',
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
      },
      {
        md: 4,
        type: 'radio',
        name: 'isContact',
        defaultValue: form.isContact,
        label: 'Autoriza contactar? ',
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
      },
      {
        md: 4,
        type: 'radio',
        defaultValue: form.isPhoneWhatsApp,
        name: 'isPhoneWhatsApp',
        label: 'Telefone é WhatsApp?',
        className: 'd-flex gap-2 mb-1',
        radiosFields: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
      },
    ],
    [
      ...(!isToHideFields
        ? [
            {
              md: 4,
              type: 'text',
              label: 'Nome',
              name: 'userName',
              placeholder: 'Informe o nome',
            },
            ...[emailField({ isUpdate, isRequired: false })],
            ...[phoneField({ form, onUpdateForm, isRequired: false })],
          ]
        : []),
    ],
    [
      {
        md: 4,
        type: 'number',
        name: 'children',
        label: 'Quantos filhos o(a) eleitor(a) tem?',
        placeholder: 'Informe o total de filhos do(a) eleitor(a)',
      },
      {
        md: 4,
        type: 'text',
        name: 'minimumIncome',
        label: 'Renda mínima',
        value: formatMoneyValue(form.minimumIncome),
        placeholder: 'Informe a renda mínima do eleitor',
        onChange: (value) => {
          onUpdateForm('minimumIncome', moneyMask(value));
        },
      },
      {
        md: 4,
        type: 'text',
        name: 'maximumIncome',
        label: 'Renda máxima',
        value: formatMoneyValue(form.maximumIncome),
        placeholder: 'Informe a renda máxima do eleitor',
        onChange: (value) => {
          onUpdateForm('maximumIncome', moneyMask(value));
        },
      },
    ],
    [
      ...[registrationField({ md: 3 })],
      ...[sexualOrientationField()],
      ...[genderField()],
      ...[maritalStatusField],
    ],
    [
      {
        md: 4,
        type: 'select',
        required: false,
        name: 'schooling',
        label: 'Escolaridade',
        options: schoolings.map(({ id: value, descricao: label }) => ({
          value,
          label,
        })),
      },
      {
        md: 4,
        type: 'select',
        isMulti: true,
        required: false,
        name: 'interests',
        label: 'Interesse',
        options: interests.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
      {
        md: 4,
        name: 'tags',
        isMulti: true,
        type: 'select',
        required: false,
        label: 'Etiqueta',
        options: tags.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
    ],
    [
      {
        md: 4,
        type: 'select',
        label: 'Etnia',
        required: false,
        name: 'ethnicity',
        options: [
          { label: 'Pardo', value: 'Pardo' },
          { label: 'Branco', value: 'Branco' },
          { label: 'Negro', value: 'Negro' },
          { label: 'Indígena', value: 'Indígena' },
          { label: 'Amarelo', value: 'Amarelo' },
        ],
      },
      {
        md: 4,
        type: 'select',
        name: 'region',
        required: false,
        label: 'Região',
        options: regions.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
      {
        md: 4,
        type: 'select',
        required: false,
        name: 'religion',
        label: 'Religião',
        options: religions.map(({ id: value, nome: label }) => ({
          value,
          label,
        })),
      },
    ],
    [
      {
        md: 4,
        type: 'select',
        required: false,
        name: 'engagement',
        label: 'Nível de engajamento',
        options: engagementOptions,
      },
      {
        md: 4,
        type: 'number',
        name: 'electoralZone',
        min: 0,
        label: 'Zona eleitoral',
        placeholder: 'Informe a zona eleitoral',
      },
      {
        md: 4,
        type: 'text',
        name: 'electoralCollege',
        label: 'Colégio eleitoral',
        placeholder: 'Informe o colégio eleitoral',
      },
    ],
  ];
};

export default fields;
