const handleInitialValuesCreate = () => {
  const initialValues = {
    tags: null,
    phone: '',
    email: '',
    title: '',
    model: '',
    quite: null,
    userName: '',
    gender: null,
    children: '',
    region: null,
    engagement: '',
    isVoter: null,
    interests: null,
    religion: null,
    ethnicity: null,
    schooling: null,
    registrant: null,
    minimumIncome: '',
    maximumIncome: '',
    maritalStatus: null,
    sexualOrientation: null,
    isContact: null,
    isPhoneWhatsApp: null,
    electoralZone: '',
    electoralCollege: '',
  };

  return initialValues;
};

export default handleInitialValuesCreate;
