import { getMe } from '../../../../../store/actions';
import { useSelector as handleUseSelector } from 'react-redux/es/hooks/useSelector';
import createDynamicSelector from '../../../../../components/common/createDynamicSelector';

const fields = ({ dispatch }) => {
  const selector = createDynamicSelector('Users', ['user']);
  const { user } = handleUseSelector(selector);

  const isUser = Object.values(user).length > 0;

  return [
    [
      {
        label: 'Usar',
        type: 'radio',
        defaultValue: false,
        className: 'd-flex gap-2 mb-1',
        name: 'isUseCurrentUserAsCandidate',
        radiosFields: [
          { label: 'Sim', value: true },
          { label: 'Não', value: false },
        ],
        onChange: (value) => {
          if (!value || isUser) return;
          dispatch(getMe());
        },
      },
    ],
  ];
};

export default fields;
