import { I } from './styles';
import iconsName from './steps/iconsName';

/**
 * @typedef {Object} IconProps
 * @property {string} name
 * @property {number} [size]
 * @property {string} [className]
 */

/**
 * @param {IconProps} props
 * @returns {JSX.Element}
 */

const Icon = ({ className = '', name, size }) => {
  const iconName = iconsName[name];

  return <I className={`${iconName} ${className}`} size={size} />;
};

export default Icon;
