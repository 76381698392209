import React from 'react';
import Layout from '../layout';
import { AuthProtected } from './AuthProtected';
import { Routes, Route } from 'react-router-dom';
import NonAuthLayout from '../layout/NonAuthLayout';
import {
  authProtectedRoutes,
  publicRoutes,
  rootProtectedRoutes,
} from './allRoutes';
import { RootProtected } from './RootProtected';

const Index = () => {
  return (
    <>
      <Routes>
        <Route>
          {publicRoutes.map(({ component, path }, index) => (
            <Route
              key={index}
              path={path}
              exact={true}
              element={<NonAuthLayout>{component}</NonAuthLayout>}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map(
            (
              { component, path, isAdmin = false, isNotAdmin = false },
              index
            ) => (
              <Route
                path={path}
                key={index}
                exact={true}
                element={
                  <AuthProtected isAdmin={isAdmin} isNotAdmin={isNotAdmin}>
                    <Layout>{component}</Layout>
                  </AuthProtected>
                }
              />
            )
          )}
        </Route>

        <Route>
          {rootProtectedRoutes.map(
            ({ component, path, isRoot = false, isNotRoot = false }, index) => (
              <Route
                path={path}
                key={index}
                exact={true}
                element={
                  <RootProtected isRoot={isRoot} isNotAdmin={isNotRoot}>
                    <Layout>{component}</Layout>
                  </RootProtected>
                }
              />
            )
          )}
        </Route>
      </Routes>
    </>
  );
};

export default Index;
