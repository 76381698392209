import { Col } from 'reactstrap';

export default function Field(props) {
  const { children } = props;

  return (
    <Col className="mb-3" {...props}>
      {children}
    </Col>
  );
}
