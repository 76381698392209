import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormPrototype from '../../../../components/Prototypes/FormPrototype';
import createDynamicSelector from '../../../../components/common/createDynamicSelector';
import withRouter from '../../../../components/common/withRouter';
import LoadingPage from '../../../../components/ui/LoadingPage';
import useSystemParamSchema from '../../../../schemas/settings/systemParam';
import { saveSystemParams } from '../../../../store/actions';
import handleCards from '../steps/handleCards';
import handleDataSubmit from '../steps/handleDataSubmit';
import handleInitialValuesUpdate from '../steps/handleInitialValuesUpdate';
import { Navigate } from 'react-router-dom';

function UpdateSystemParam({ router }) {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState(null);
  const selector = createDynamicSelector('Settings', ['updateSystemParam']);
  const { updateSystemParam } = useSelector(selector);

  const isUpdate = true;

  const validationSchema = useSystemParamSchema(isUpdate);

  const formRef = useRef();

  const handleSubmit = (values) => {
    const { navigate, ...rest } = values;
    const data = handleDataSubmit({ data: rest, isUpdate });
    dispatch(saveSystemParams({ data }));
    navigate('/settings/system-params');
  };

  useEffect(() => {
    const initialValues = handleInitialValuesUpdate(updateSystemParam);
    setInitialValues(initialValues);
  }, [updateSystemParam]);

  const isSystemParam = Object.keys(updateSystemParam).length > 0;
  const hasInitialValues = Object.values(initialValues || {}).some(
    (value) => !!value
  );

  if (!isSystemParam) return <Navigate to={'not-found'} />;
  if (!hasInitialValues) return <LoadingPage />;
  return (
    <FormPrototype
      documentTitle="Editar parâmetro do sistema"
      router={router}
      onSubmit={handleSubmit}
      initialValuesForm={initialValues}
      ref={formRef}
      onCards={(params) => {
        return handleCards({ ...params, formRef, isUpdate });
      }}
      validationSchema={validationSchema}
    />
  );
}

export default withRouter(UpdateSystemParam);
