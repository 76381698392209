import { leftsidbarSizeTypes } from '../../components/constants/layout';
import {
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VISIBILITY,
} from './actionType';

const initialState = {
  sidebarIsVisibility: true,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
};

const Layout = (state = initialState, action) => {
  const { payload, type } = action;
  const { isVisibility = true, leftsidbarSizeType = state.leftsidbarSizeType } =
    payload ?? {};

  switch (type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebarIsVisibility: isVisibility,
      };

    case CHANGE_SIDEBAR_SIZE_TYPE:
      return {
        ...state,
        leftsidbarSizeType,
      };

    default:
      return state;
  }
};

export default Layout;
