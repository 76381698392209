const handleInitialValuesPersonObject = (user) => {
  const { pessoa } = user;

  const {
    cpf,
    genero,
    endereco,
    estadoCivil,
    nome: userName,
    orientacaoSexual,
    sobrenome: lastName,
    dataNascimento: dateOfBirth,
  } = pessoa || {};

  const {
    municipio,
    cep: zipCode,
    logradouro: street,
    bairro: neighborhood,
    numero: addressNumber,
    complemento: complement,
  } = endereco || {};

  const gender = genero ? { label: genero, value: genero } : null;
  const maritalStatus = estadoCivil
    ? { label: estadoCivil, value: estadoCivil }
    : null;
  const municipality = municipio
    ? { label: municipio.nome, value: municipio.id }
    : null;
  const sexualOrientation = orientacaoSexual
    ? {
        value: orientacaoSexual.id,
        label: orientacaoSexual.descricao,
      }
    : null;

  const values = {
    gender,
    municipality,
    maritalStatus,
    cpf: cpf || '',
    sexualOrientation,
    street: street || '',
    zipCode: zipCode || '',
    lastName: lastName || '',
    userName: userName || '',
    complement: complement || '',
    dateOfBirth: dateOfBirth || '',
    neighborhood: neighborhood || '',
    addressNumber: addressNumber || '',
  };

  return values;
};

export default handleInitialValuesPersonObject;
