import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import Icon from '../Icon';

function ActionsDropdown({ title = 'Ações', hrefs, actions = [] } = props) {
  return (
    <UncontrolledDropdown
      title={title}
      direction="end"
      className="d-flex justify-content-end"
    >
      <DropdownToggle
        tag="button"
        className="btn btn-soft-light btn-primary btn-sm"
      >
        <Icon name="more" size={0.8} />
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-end" flip>
        {actions.map((action, i) => {
          if (action.showCondition && action.showCondition() != true)
            return null;
          return (
            <DropdownItem
              key={action.id || i}
              title={action.title}
              className="dropdown-item"
              href={hrefs && hrefs[i]}
              tag={action.tag}
              {...action.attributes}
            >
              <Icon name={action.icon} size={0.9} /> {action.text}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default ActionsDropdown;
