import {
  CHANGE_POLITICIAN_SITUATION,
  CREATE_POLITICIAN,
  GET_COUNCILORS,
  GET_MAYORS,
  GET_POLITICIANS,
  POLITICIANS_ERROR,
  POLITICIANS_SUCCESS,
  UPDATE_POLITICIAN,
} from './actionTypes';

const initialState = {
  politicians: [],
  mayors: [],
  councilors: [],
  isLoading: false,
};

const politicians = (state = initialState, { type, payload }) => {
  const {
    politicians = state.politicians,
    councilors = state.councilors,
    mayors = state.mayors,
  } = payload ?? {};

  switch (type) {
    case GET_POLITICIANS:
    case CREATE_POLITICIAN:
    case CHANGE_POLITICIAN_SITUATION:
    case UPDATE_POLITICIAN:
    case GET_MAYORS:
    case GET_COUNCILORS:
      return {
        ...state,
        isLoading: true,
      };

    case POLITICIANS_ERROR:
    case POLITICIANS_SUCCESS:
      return {
        ...state,
        politicians,
        mayors,
        councilors,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default politicians;
