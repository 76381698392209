export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export const GET_SYSTEM_PARAMS_DESCRIPTIONS = 'GET_SYSTEM_PARAMS_DESCRIPTIONS';
export const GET_SYSTEM_PARAMS = 'GET_SYSTEM_PARAMS';
export const GET_PENDING_SYSTEM_PARAMS = 'GET_PENDING_SYSTEM_PARAMS';
export const SAVE_SYSTEM_PARAMS = 'SAVE_SYSTEM_PARAMS';
export const UPDATE_SYSTEM_PARAM = 'UPDATE_SYSTEM_PARAM';
export const GET_WHATSAPP_QRCODE = 'GET_WHATSAPP_QRCODE';
export const CHECK_IF_WHATSAPP_IS_CONNECTED = 'CHECK_IF_WHATSAPP_IS_CONNECTED';
export const DISCONNECT_WHATSAPP = 'DISCONNECT_WHATSAPP';
export const UPLOAD_VOTERS_DATA_FILE = 'UPLOAD_VOTERS_DATA_FILE';
export const DOWNLOAD_DATA_FILE_MODEL = 'DOWNLOAD_DATA_FILE_MODEL';
