const handleDataSubmit = ({ data, isUpdate }) => {
  const formattedData = {
    nome: data.name,
    cargo: {
      id: data.position.value,
    },
  };

  if (isUpdate) {
    formattedData.id = data.id;
    formattedData.ativo = data.active;
  }

  return formattedData;
};

export default handleDataSubmit;
