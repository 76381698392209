import styled, { keyframes, css } from 'styled-components';

export const ContainerHeader = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  .align-center {
    display: flex;
    height: 2.2rem;
    align-items: center;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 28rem) {
      gap: 1rem;
      width: 100%;
      align-items: normal;
      flex-direction: column;
    }
  }

  .header-extra-buttons {
    display: flex;
    gap: 4px;
    alignitems: center;
    justifycontent: flex-end;
  }

  .button-add-new-item-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 28rem) {
      gap: 1rem;
      align-items: normal;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FilterContainer = styled.div`
  opacity: 0;
  transition: opacity 1s ease-in-out;
  ${css`
    animation: ${fadeIn} 1s ease-in-out forwards;
  `}
`;
