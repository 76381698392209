import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import { reportsPerformanceDashboardRoutes } from '../../../config/reports/reportsRoutes';
import { reportsPerformanceDashboardKeys } from '../../../config/reports/reportsKeys';
import { getReportByCategory } from '../../../store/reports/actions';
import LoadingPage from '../../../components/ui/LoadingPage';
import createDynamicSelector from '../../../components/common/createDynamicSelector';
import chartColors from '../steps/colors';
import DonutChart from '../../../components/charts/DonutChart';

export default function PerformanceDashboard() {
  const dispatch = useDispatch();

  const selector = createDynamicSelector('Reports', [
    'isLoading',
    ...reportsPerformanceDashboardKeys,
  ]);
  const { isLoading, reportsPerformanceMultipliers } = useSelector(selector);

  useEffect(() => {
    document.title = 'Gráficos - Desempenho de Multiplicadores';

    reportsPerformanceDashboardRoutes.forEach((route, index) => {
      const category = route;
      const key = reportsPerformanceDashboardKeys[index];

      dispatch(getReportByCategory({ category, key }));
    });
  }, []);

  const data = reportsPerformanceMultipliers;
  const hasData = data.length > 0;

  if (isLoading) return <LoadingPage />;
  return (
    <Container className="page-content">
      <Row className="m-3">
        <Col>
          <h5>Desempenho de eleitores qualificados</h5>
          <h6 className="text-muted">
            São considerados eleitores qualificados aqueles que são cadastrados
            com os campos "zona" e "colégio eleitoral" preenchidos
          </h6>
        </Col>
      </Row>
      {hasData ? (
        <>
          {data.map((item, index) => (
            <Row className="m-3" key={`${item.pessoa}-${index}`}>
              <DonutChart {...item} colors={chartColors.slice(0, 2)} />
            </Row>
          ))}
        </>
      ) : (
        <Row className="m-3">
          <Alert color="info">Oops, sem dados para exibir.</Alert>
        </Row>
      )}
    </Container>
  );
}
