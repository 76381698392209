import { useSelector as handleUseSelector } from 'react-redux';
import createDynamicSelector from '../../../../components/common/createDynamicSelector';

const sexualOrientationField = (md = 3) => {
  const selector = createDynamicSelector('GeneralFeature', [
    'municipalities',
    'sexualOrientations',
  ]);

  const { sexualOrientations } = handleUseSelector(selector);

  return {
    md,
    type: 'select',
    required: false,
    name: 'sexualOrientation',
    label: 'Orientação Sexual',
    options: sexualOrientations.map(({ id: value, descricao: label }) => ({
      value,
      label,
    })),
  };
};

export default sexualOrientationField;
