import formatValue from '../../../pages/Voters/steps/formatValue';

const handleCreateFilterObject = (data) => {
  const isTag = !!data.tags?.length > 0;
  const tags = isTag ? data.tags.map(({ value }) => ({ id: value })) : null;

  const isInterests = data.interests?.length > 0;
  const interests = isInterests
    ? data.interests.map(({ value }) => ({ id: value }))
    : null;

  const engajamento = data.engagement ? data.engagement.value : null;
  const cadastrante = data.registrant ? { id: data.registrant?.value } : null;
  const autocadastro = data.selfRegistered;

  return {
    autocadastro,
    cadastrante,
    engajamento,
    etiquetas: tags,
    votante: data.isVoter,
    interesses: interests,
    email: data.email || null,
    nome: data.userName || null,
    telefone: data.phone || null,
    filhos: data.children || null,
    genero: data.gender?.value || data.gender || null,
    regiao: data.region?.value || data.region || null,
    instagram: data?.instagram || null,
    etnia: data.ethnicity?.value || data.ethnicity || null,
    religiao: data.religion?.value || data.religion || null,
    escolaridade: data.schooling?.value || data.schooling || null,
    estadoCivil: data.maritalStatus?.value || data.maritalStatus || null,
    rendaMin: formatValue(data.minimumIncome) || null,
    rendaMax: formatValue(data.maximumIncome) || null,
    orientacaoSexual:
      data.sexualOrientation?.value || data.sexualOrientation || null,
    colegio: data.electoralCollege || null,
    zona: data.electoralZone || null,
    contactar: data.isContact,
    whatsApp: data.isPhoneWhatsApp,
  };
};

export default handleCreateFilterObject;
