import Form from '../../Formik/Form';
import LoadingButton from '../LoadingButton';
import { addTag } from '../../../store/actions';
import { Form as FormFormik, Formik } from 'formik';
import useAddTagSchema from '../../../schemas/addTag';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import createDynamicSelector from '../../common/createDynamicSelector';
import getUserDataAndAccessToken from '../../../utils/actionsCookies/getUserDataAndAccessToken';

const ModalAddTag = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const schema = useAddTagSchema();
  const initialValues = { name: '' };

  const selector = createDynamicSelector('Voters', ['isLoading']);
  const { isLoading } = useSelector(selector);

  const { userData } = getUserDataAndAccessToken();

  const handleDataSubmit = (values) => {
    const { name: nome } = values;
    const dataCadastro = new Date();

    return {
      nome,
      dataCadastro,
      usuarioCadastrante: userData.name,
    };
  };

  const handleSubmit = (values) => {
    const data = handleDataSubmit(values);
    dispatch(addTag({ ...data, onCloseModal: onClose }));
  };

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader className="color-primary" toggle={onClose}>
        Adicione uma nova etiqueta
      </ModalHeader>

      <ModalBody>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={schema}
          initialValues={initialValues}
        >
          <FormFormik>
            <Form.Field>
              <Form.Label htmlFor="name">Nome da etiqueta</Form.Label>

              <Form.Input
                name="name"
                placeholder="Informe o nome da etiqueta"
              />

              <Form.ErrorMessage field="name" />
            </Form.Field>

            <Button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary w-100"
            >
              {isLoading ? <LoadingButton /> : 'Salvar'}
            </Button>
          </FormFormik>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalAddTag;
