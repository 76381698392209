import React from 'react';
import { Input } from 'reactstrap';
import { useFormikContext } from 'formik';

const Checkbox = ({ name, label, onChange, ...rest }) => {
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext();

  const isChecked = !!values[name];

  const hasValidationError = touched[name] && errors[name];
  const isInvalidField = !!hasValidationError;

  const handleCheckboxChange = (event) => {
    const { target } = event;
    const { checked } = target;

    onChange && onChange(checked);
    handleChange({ target: { name, value: checked } });
  };

  return (
    <Input
      name={name}
      label={label}
      type="checkbox"
      checked={isChecked}
      onBlur={handleBlur}
      invalid={isInvalidField}
      onChange={handleCheckboxChange}
      {...rest}
    />
  );
};

export default Checkbox;
