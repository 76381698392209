import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Alert, Card, CardBody, CardHeader, Col } from 'reactstrap';

const PieChart = ({ data, labels, title, titleHeader, colors }) => {
  const options = {
    colors,
    labels,
    title: {
      text: title,
      align: 'left',
      style: { fontWeight: 500 },
    },
  };

  const series = data;

  return (
    <Col>
      <Card>
        <CardHeader>
          <h5>{titleHeader}</h5>
        </CardHeader>

        <CardBody>
          {data?.length === 0 ? (
            <Alert color="info">Sem dados disponíveis para exibir.</Alert>
          ) : (
            <ReactApexChart
              dir="ltr"
              type="donut"
              height="350"
              series={series}
              options={options}
              className="apex-charts"
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PieChart;
