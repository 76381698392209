import {
  CANDIDATES_ERROR,
  CANDIDATES_LIST,
  CANDIDATES_SUCCESS,
  CREATE_CANDIDATE,
  GET_CANDIDATES_BY_SELFREGISTRATION,
  UPDATE_CANDIDATE,
} from './actionTypes';

export const createCandidate = (payload) => {
  return {
    type: CREATE_CANDIDATE,
    payload,
  };
};

export const updateCandidate = (payload) => {
  return {
    type: UPDATE_CANDIDATE,
    payload,
  };
};

export const candidatesList = () => {
  return {
    type: CANDIDATES_LIST,
    payload: {},
  };
};

export const candidatesError = () => {
  return {
    type: CANDIDATES_ERROR,
    payload: {},
  };
};

export const candidatesSuccess = (payload) => {
  return {
    type: CANDIDATES_SUCCESS,
    payload,
  };
};

export const getCandidatesBySelfregistration = (payload) => {
  return {
    type: GET_CANDIDATES_BY_SELFREGISTRATION,
    payload,
  };
};
