import styled from 'styled-components';
import { Button as ButtonReactstrap, Col as ColReactstrap } from 'reactstrap';

export const Button = styled(ButtonReactstrap)`
  gap: 0.2rem;
  display: flex;
  align-items: center;
`;

export const Col = styled(ColReactstrap)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
