import {
  ACCOUNTS_ERROR,
  CREATE_ACCOUNT,
  ACCOUNTS_SUCCESS,
} from './actionTypes';

const initialState = {
  isLoading: false,
};

const accounts = (state = initialState, { type }) => {
  switch (type) {
    case CREATE_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };

    case ACCOUNTS_ERROR:
    case ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

export default accounts;
