import { Button } from 'reactstrap';
import styled from 'styled-components';

export const BackAndNextButton = styled(Button)`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonItem = styled(Button)`
  min-width: 2rem;
  padding: 0 0.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoBarResult = styled.div`
  @media (max-width: 36rem) {
    display: flex;
    justify-content: flex-end;
  }
`;
