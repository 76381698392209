export const engagementOptions = [
  {
    label: '1 - Familiar do candidato',
    value: 1,
  },
  {
    label: '2 - Conhecido próximo do candidato',
    value: 2,
  },
  {
    label: '3 - Equipe de campanha',
    value: 3,
  },
  {
    label: '4 - Militante político',
    value: 4,
  },
  {
    label: '5 - Apoiador',
    value: 5,
  },
  {
    label: '6 - Indiferente',
    value: 6,
  },
  {
    label: '7 - Opositor',
    value: 7,
  },
];
