import Cookies from 'js-cookie';

/**
 *
 * @returns {{
 * accessToken: string;
 * userData: {
 * name: string;
 * email: string;
 * group: string;
 * level: number;
 * picture: string;
 * autoRegisterCode: string;
 * lastName: string;
 * root: boolean;
 * } }}
 */

const getUserDataAndAccessToken = () => {
  const userData = Cookies.get('userData');
  const accessToken = Cookies.get('accessToken');

  return { userData: JSON.parse(userData ?? '{}'), accessToken };
};

export default getUserDataAndAccessToken;
