import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import headerTitles from './steps/headerTitles';
import selectorOptions from '../steps/selectorOptions';
import { getInterests } from '../../../../store/actions';
import ActionsDropdown from '../../../../components/ui/ActionsDropdown';
import ListingPrototype from '../../../../components/Prototypes/ListingPrototype';
import ConfirmationModal from '../../../../components/ui/ConfirmationModal';
import actionsOptions from './steps/actionsOptions';

export default function ListingInterests() {
  const dispatch = useDispatch();

  const [interestName, actions] = headerTitles;
  const [modalData, setModalData] = useState({
    body: '',
    isOpen: false,
    onConfirm: () => {},
  });

  const handleData = (interests) => {
    return interests.map((data) => {
      return {
        [interestName]: data.nome || '--',
        [actions]: (
          <ActionsDropdown
            actions={actionsOptions(data, dispatch, setModalData)}
            hrefs={[]}
          />
        ),
      };
    });
  };

  return (
    <>
      <ListingPrototype
        pageContent={false}
        isShowButtonCreateNewItem={false}
        handleData={handleData}
        entityName={'GeneralFeature'}
        headerTitles={headerTitles}
        title={'Interesses'}
        dataSelector={getInterests}
        selectorOptions={selectorOptions}
        emptyMessage={'Oops, sem interesses para exibir.'}
      />

      <ConfirmationModal
        body={`Tem certeza que deseja apagar o interesse "${modalData.body}"?`}
        isOpen={modalData.isOpen}
        onConfirm={modalData.onConfirm}
        title="Apagar interesse"
        toggle={() =>
          setModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        confirmText="Apagar"
      />
    </>
  );
}
