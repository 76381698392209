import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="d-sm-flex justify-content-between">
          <Col xs={12} sm={6} className="text-black">
            {new Date().getFullYear()} ©
          </Col>

          <Col xs={12} sm={6} className="text-sm-end text-black">
            © Multivotos.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
