import Field from './components/Field';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import CardHeader from './components/CardHeader';
import { Row, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormControlButtons from '../FormControlButtons';
import handleUpdateForm from './steps/handleUpdateForm';
import createDynamicSelector from '../../../../common/createDynamicSelector';

const FormCard = ({ onBack, onCards, showBackButton = true }) => {
  const dispatch = useDispatch();
  const { values: form, setFieldValue } = useFormikContext();

  const selector = createDynamicSelector('Users', ['user']);
  const { user } = useSelector(selector);

  const onUpdateForm = (field, newValue) => {
    setFieldValue(field, newValue);
  };

  const commonParams = { form, onUpdateForm, setFieldValue };
  const cards = onCards({ dispatch, ...commonParams });

  useEffect(() => {
    handleUpdateForm({ user, ...commonParams });
  }, [form?.isUseCurrentUserAsCandidate, user]);

  return (
    <>
      {cards.map(
        (
          {
            Icon,
            fields,
            titleHeader,
            extraButtons,
            isShowFormControlButtons,
            titlePrimaryButton = 'Salvar',
            customContent = false,
            CustomContentComponent,
            showCardBackButton = showBackButton,
          },
          cardIndex
        ) => {
          return customContent ? (
            <Card key={cardIndex}>
              <CardBody>
                <CustomContentComponent />
              </CardBody>
            </Card>
          ) : (
            <Card key={cardIndex}>
              <CardBody>
                <CardHeader Icon={Icon} titleHeader={titleHeader} />

                <hr className="pb-2" />

                {fields.map((fieldGroup, fieldIndex) => (
                  <Row key={fieldIndex}>
                    {fieldGroup.map((field, fieldKey) => (
                      <Field key={fieldKey} field={field} />
                    ))}
                  </Row>
                ))}

                <FormControlButtons
                  extraButtons={extraButtons}
                  showBackButton={showCardBackButton}
                  onBack={onBack}
                  isShow={isShowFormControlButtons}
                  titlePrimaryButton={titlePrimaryButton}
                />
              </CardBody>
            </Card>
          );
        }
      )}
    </>
  );
};

export default FormCard;
