const emailField = ({ isUpdate, md, isRequired = true }) => ({
  isRequired,
  type: 'email',
  name: 'email',
  label: 'Email',
  md: md ? md : isUpdate ? 4 : 6,
  placeholder: 'Informe o email',
});

export default emailField;
