import handleDataSubmit from '../../../pages/Voters/ListingVoters/components/FormFilter/steps/handleDataSubmit';
import handleInitialValues from '../../../pages/Voters/ListingVoters/components/FormFilter/steps/handleInitialValues';

export const getVoterFilterFromFormRef = (formRef) => {
  if (formRef.current) {
    const formValues = Object.fromEntries(
      new FormData(formRef.current).entries()
    );

    if (formRef.current.tags instanceof RadioNodeList) {
      formValues.tags = Array.from(formRef.current.tags);
    } else if (formRef.current.tags.value) {
      formValues.tags = [formRef.current.tags];
    }

    if (formRef.current.interests instanceof RadioNodeList) {
      formValues.interests = Array.from(formRef.current.interests);
    } else if (formRef.current.interests.value) {
      formValues.interests = [formRef.current.interests];
    }
    return handleDataSubmit(formValues);
  }
  return handleDataSubmit(handleInitialValues());
};
