import api from '../../services/api';
import toast from '../../components/ui/toast';
import { CREATE_ACCOUNT } from './actionTypes';
import { accountsError, accountsSuccess } from './actions';
import { takeEvery, fork, all, call, put } from 'redux-saga/effects';

function* handleCreateAccountSuccess(navigate) {
  yield put(accountsSuccess());
  toast('success', 'Sua conta foi criada com sucesso ;)');

  navigate('/login');
}

function* handleError({ error }) {
  const { response } = error;
  const { data, status } = response;

  yield put(accountsError());

  const errorMessage500 = 'Oops, ocorreu um erro interno no sistema.';
  if (status == 500) {
    toast('error', errorMessage500);
    return;
  }

  toast('error', data);
}

function* createAccount({ payload }) {
  try {
    const { navigate, ...data } = payload;
    yield call(api.post, '/contas', data);

    yield handleCreateAccountSuccess(navigate);
  } catch (error) {
    yield handleError({ error });
  }
}

export function* watchCreateAccount() {
  yield takeEvery(CREATE_ACCOUNT, createAccount);
}

function* accounts() {
  yield all([fork(watchCreateAccount)]);
}

export default accounts;
