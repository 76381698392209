const handleUpdateForm = ({ form, user, onUpdateForm }) => {
  const { isUseCurrentUserAsCandidate = null } = form;

  const isUser = Object.values(user).length > 0;
  if (!isUser) return;

  const dataCurrentUser = {
    email: user.email,
    password: user.senha,
    phone: user.telefone,
    cpf: user.pessoa.cpf,
    userName: user.pessoa.nome,
    zipCode: user.enderenco?.cep,
    lastName: user.pessoa.sobrenome,
    street: user.enderenco?.logradouro,
    neighborhood: user.enderenco?.bairro,
    birthDate: user.pessoa.dataNascimento,
    complement: user.enderenco?.complemento,
  };

  Object.entries(dataCurrentUser).forEach(([key, value]) => {
    if (!isUseCurrentUserAsCandidate) {
      onUpdateForm(key, '');
      return;
    }

    if (!form[key]) onUpdateForm(key, value || '');
  });
};

export default handleUpdateForm;
