import React from 'react';
import Icon from '../../../../components/ui/Icon';
import multiplierFields from '../fields/multiplierFields';
import userFields from '../../../../utils/fields/common/userFields';

const handleCards = ({
  form,
  onUpdateForm,
  dispatch,
  isUpdate,
  currentImages,
}) => {
  const multiplierFieldsData = multiplierFields({ form, currentImages });
  const userFieldsData = userFields({
    form,
    dispatch,
    isUpdate,
    onUpdateForm,
    isCpfRequired: false,
  });

  const cards = [
    {
      titleHeader: 'Dados Pessoais',
      Icon: () => <Icon name="user" />,
      fields: userFieldsData,
    },
    {
      fields: multiplierFieldsData,
      titleHeader: 'Dados do Usuário',
      isShowFormControlButtons: true,
      Icon: () => <Icon name="user" />,
    },
  ];

  return cards;
};

export default handleCards;
