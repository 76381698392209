import createDynamicSelector from '../../../../../components/common/createDynamicSelector';
import { useSelector as handleUseSelector } from 'react-redux';

let lastModelTextareaCursorPosition = 0;

const fields = ({ setFieldValue, form }) => {
  const selector = createDynamicSelector('Templates', ['variables']);
  const { variables } = handleUseSelector(selector);

  return [
    [
      {
        md: 4,
        name: 'quite',
        type: 'select',
        isRequired: true,
        label: 'Meio de comunicação',
        options: [
          { label: 'E-mail', value: 'Email' },
          { label: 'WhatsApp', value: 'WhatsApp' },
        ],
      },
      {
        md: 4,
        type: 'text',
        name: 'title',
        isRequired: true,
        label: 'Título da mensagem',
        placeholder: 'Informe o título da mensagem',
      },
      {
        md: 4,
        name: 'variables',
        type: 'select',
        label: 'Variáveis',
        isRequired: false,
        required: false,
        options: variables.map((variable) => ({
          label: variable.descricao,
          value: variable.valor,
        })),
        onChange: (op) => {
          if (op !== null && op.value) {
            const newText =
              form.model.slice(0, lastModelTextareaCursorPosition) +
              op.value +
              form.model.slice(lastModelTextareaCursorPosition);
            setFieldValue('model', newText);
            lastModelTextareaCursorPosition += op.value.length;
          }
        },
      },
    ],
    [
      {
        md: 12,
        name: 'model',
        isRequired: true,
        type: 'textarea',
        label: 'Modelo da mensagem',
        placeholder: 'Informe o modelo da mensagem',
        onSelect: (e) => {
          lastModelTextareaCursorPosition = e.target.selectionEnd;
        },
      },
    ],
  ];
};

export default fields;
