import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListingPrototype from '../../../../components/Prototypes/ListingPrototype';
import ActionsDropdown from '../../../../components/ui/ActionsDropdown';
import StatusItem from '../../../../components/ui/StatusItem';
import { getPoliticians } from '../../../../store/actions';
import actionsOptions from '../steps/actions';
import headerTitles from '../steps/headerTitles';
import selectorOptions from '../steps/selectorOptions';

export default function ListingPoliticians({ setFormInitialValues }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nome, cargo, situacao, acoes] = headerTitles;

  const handleData = (politicians) => {
    return politicians.map((data) => {
      return {
        [nome]: data.nome,
        [cargo]: data.cargo.descricao,
        [situacao]: <StatusItem status={data.ativo} flex />,
        [acoes]: (
          <ActionsDropdown
            actions={actionsOptions({
              data,
              dispatch,
              navigate,
              setFormInitialValues,
            })}
          />
        ),
      };
    });
  };

  return (
    <ListingPrototype
      pageContent={false}
      isShowButtonCreateNewItem={false}
      handleData={handleData}
      entityName="Politicians"
      headerTitles={headerTitles}
      title="Políticos"
      dataSelector={getPoliticians}
      selectorOptions={selectorOptions}
      emptyMessage={'Oops, sem políticos para exibir.'}
    />
  );
}
