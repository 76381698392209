import {
  ADD_TAG,
  AUTOREGISTER_VOTER,
  BIRTHDAYS_LIST,
  BIRTHDAYS_LIST_FILTER,
  CLEAR_MUNICIPAL_RESEARCH,
  COUNT_VOTERS_AFFECTED_BY_FILTER,
  CREATE_MUNICIPAL_RESEARCH,
  CREATE_VOTER,
  DELETE_VOTER,
  GENERATE_VOTERS_CSV,
  GET_MUNICIPAL_RESEARCH_BY_ID,
  GET_MUNICIPAL_RESEARCH_BY_VOTER_ID,
  GET_VOTER_BY_ID,
  UPDATE_MUNICIPAL_RESEARCH,
  UPDATE_VOTER,
  VOTERS_ERROR,
  VOTERS_LIST,
  VOTERS_LIST_FILTER,
  VOTERS_SUCCESS,
} from './actionTypes';

const initialState = {
  voter: {},
  voters: [],
  birthdays: [],
  isLoading: false,
  votersAffectedByFilter: null,
  isCalculatingAffectedVoters: false,
  isGeneratingVotersCSV: false,
  municipalResearch: null,
};

const voters = (state = initialState, { type, payload }) => {
  const {
    voter = state.voter,
    voters = state.voters,
    birthdays = state.birthdays,
    votersAffectedByFilter = state.votersAffectedByFilter,
    municipalResearch = state.municipalResearch,
  } = payload ?? {};

  switch (type) {
    case ADD_TAG:
    case VOTERS_LIST:
    case CREATE_VOTER:
    case UPDATE_VOTER:
    case DELETE_VOTER:
    case BIRTHDAYS_LIST:
    case GET_VOTER_BY_ID:
    case AUTOREGISTER_VOTER:
    case CREATE_MUNICIPAL_RESEARCH:
    case GET_MUNICIPAL_RESEARCH_BY_ID:
    case CLEAR_MUNICIPAL_RESEARCH:
    case UPDATE_MUNICIPAL_RESEARCH:
    case GET_MUNICIPAL_RESEARCH_BY_VOTER_ID:
      return {
        ...state,
        isLoading: true,
      };

    case GENERATE_VOTERS_CSV:
      return {
        ...state,
        isGeneratingVotersCSV: true,
      };

    case COUNT_VOTERS_AFFECTED_BY_FILTER:
      return {
        ...state,
        isCalculatingAffectedVoters: true,
      };

    case VOTERS_ERROR:
    case VOTERS_SUCCESS:
    case VOTERS_LIST_FILTER:
    case BIRTHDAYS_LIST_FILTER:
      return {
        ...state,
        voter,
        voters,
        birthdays,
        isLoading: false,
        votersAffectedByFilter,
        isCalculatingAffectedVoters: false,
        isGeneratingVotersCSV: false,
        municipalResearch,
      };

    default:
      return { ...state };
  }
};

export default voters;
